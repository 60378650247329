import React from "react";
import { Formik, Field } from "formik";
import classnames from "classnames";
import { Col, Card, Form, Select } from "antd";
import {
  Condition as Conditions,
  Permissions,
  DateRangeDropdown,
} from "components/fragments";

import { Button, Row, Titles, T } from "components/elements";
import { utils, constants } from "common";
import * as Fields from "../../fields";
import AutoSelect from "./AutoSelect";
import styles from "./filters.module.css";

const Tags = ({ placeholder, label, values, setFieldValue }) => {
  return (
    <>
      <T>
        <strong>{label}</strong>
      </T>
      <Select
        mode="tags"
        value={values.filters.tags}
        placeholder={placeholder}
        style={{ width: "90%" }}
        onChange={(value) => setFieldValue("filters.tags", value)}
      />
    </>
  );
};

const FilterFields = {
  AutoSelect,
  Date: DateRangeDropdown,
  Input: Fields.Input,
  Select: Fields.Select,
  Tags: Fields.TagsSelect,
};
const { arrayChunk } = utils;
const { states } = constants;
const { Condition, True, False } = Conditions;

states.forEach((state) => {
  state.value = state.abbreviation;
  delete state.abbreviation;
});

const Filters = ({
  updateQuery,
  filters,
  title,
  query,
  defaultValues,
  headerButton,
  subItem = undefined,
}) => {
  return (
    <Row>
      <Card bordered={false} className={styles.card}>
        <Row>
          <Col xs={24} sm={12} className={styles.searchTitle}>
            <Titles.BlueCardTitle>{`Search for ${title}`}</Titles.BlueCardTitle>
            {subItem && subItem()}
          </Col>
          <Col
            xs={24}
            sm={12}
            className={classnames(styles.rightAlign, styles.headerButton)}
          >
            {headerButton}
          </Col>
        </Row>
        <Row>
          <Formik
            enableReinitialization
            initialValues={query}
            onSubmit={(values, formActions) => {
              updateQuery(values);
              formActions.setSubmitting(false);
            }}
            render={({
              handleSubmit,
              isSubmitting,
              resetForm,
              values,
              setFieldValue,
            }) => {
              const filterRows = arrayChunk(filters, 3);
              return (
                <Form
                  colon={false}
                  onSubmit={(e) => {
                    e.stopPropagation();
                    handleSubmit(e);
                  }}
                >
                  <Row spacing={20}>
                    <Col xs={24}>
                      {filterRows.map((filterCol, index) => (
                        <Row
                          gutter={15}
                          className={styles.filterRow}
                          key={`filterRow${index}`}
                        >
                          {filterCol.map(
                            ({
                              label,
                              fieldName,
                              placeholder,
                              input,
                              options,
                              permission,
                              interval,
                            }) => (
                              <Condition key={fieldName} is={permission}>
                                <True>
                                  <Permissions.Permission
                                    requiredPermissions={permission}
                                    yes={() => (
                                      <Col
                                        xs={24}
                                        md={12}
                                        lg={6}
                                        key={fieldName}
                                      >
                                        <Field
                                          width="90%"
                                          label={label}
                                          name={fieldName}
                                          placeholder={placeholder}
                                          component={FilterFields[input]}
                                          options={options}
                                          updateFilters={updateQuery}
                                          initialValues={defaultValues}
                                        />
                                      </Col>
                                    )}
                                  />
                                </True>
                                <False>
                                  <Col xs={24} md={12} lg={6} key={fieldName}>
                                    <Field
                                      width="90%"
                                      label={label}
                                      name={fieldName}
                                      placeholder={placeholder}
                                      component={FilterFields[input]}
                                      options={options}
                                      updateFilters={updateQuery}
                                      initialValues={defaultValues}
                                    />
                                  </Col>
                                </False>
                              </Condition>
                            ),
                          )}
                        </Row>
                      ))}
                    </Col>
                  </Row>

                  <Row spacing={15} gutter={15}>
                    <Col xs={24} lg={8} xl={6} className={styles.filterButton}>
                      <Button
                        width="100%"
                        htmlType="submit"
                        disabled={isSubmitting}
                        color="blue"
                      >
                        Filter
                      </Button>
                    </Col>
                    <Col xs={24} lg={8} xl={6}>
                      <Button
                        width="100%"
                        htmlType="submit"
                        disabled={isSubmitting}
                        onClick={() => resetForm(defaultValues)}
                      >
                        Reset
                      </Button>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          />
        </Row>
      </Card>
    </Row>
  );
};

export default Filters;
