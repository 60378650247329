import React from "react";
import {Button as AntButton} from "antd";

const PrevNextPagination = ({next, previous}) => (
  <>
    <AntButton icon="left" disabled={!previous} onClick={previous} />
    &nbsp;
    <AntButton icon="right" disabled={!next} onClick={next} />
  </>
);

export default PrevNextPagination;
