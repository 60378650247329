import React, {useState, useEffect} from "react";
import {TimePicker, Table as AntTable} from "antd";
import moment from "moment";
import {utils} from "common";
import Table from "../Table/Table";
import styles from "./hoursTable.module.css";

const {toDayOfWeek} = utils;
const {Column} = AntTable;

// For temp hot-fix. Need to refactor hours table and operating time table
const SUNDAY_INDEX_FOR_OPERATING_TIME_TABLE = 6;

const setTime = ({
  errors,
  setErrors,
  index,
  selectedHours,
  setFieldValue,
  timeIndex,
  i,
  txt,
}) => {
  if (errors) setErrors(i);
  if (index) {
    if (selectedHours.length > 0) {
      selectedHours.forEach((selectedHour) => {
        if (parseInt(selectedHour, 10) === 0) {
          setFieldValue(
            `${index}.hours[${SUNDAY_INDEX_FOR_OPERATING_TIME_TABLE}].${timeIndex}`,
            txt,
          );
        } else {
          setFieldValue(
            `${index}.hours[${selectedHour - 1}].${timeIndex}`,
            txt,
          );
        }
      });
    } else {
      setFieldValue(`${index}.hours[${i}].${timeIndex}`, txt);
    }
  } else if (selectedHours.length > 0) {
    selectedHours.forEach((selectedHour) => {
      if (parseInt(selectedHour, 10) === 0) {
        setFieldValue(
          `hours[${SUNDAY_INDEX_FOR_OPERATING_TIME_TABLE}].${timeIndex}`,
          txt,
        );
      } else {
        setFieldValue(`hours[${selectedHour - 1}].${timeIndex}`, txt);
      }
    });
  } else {
    setFieldValue(`hours[${i}].${timeIndex}`, txt);
  }
};

const HoursTable = ({
  index,
  startTitle,
  startIndex,
  endTitle,
  endIndex,
  setFieldValue,
  values,
  errors,
  loading,
  enableToggle = true,
}) => {
  const [startErrorIdxs, setStartErrorIdxs] = useState([]);
  const [endErrorIdxs, setEndErrorIdxs] = useState([]);
  const [selectedHours, setSelectedHours] = useState([]);
  const isStartError = (i) =>
    startErrorIdxs.includes(i) && errors && errors.hours && errors.hours[i];
  const isEndError = (i) =>
    endErrorIdxs.includes(i) && errors && errors.hours && errors.hours[i];

  const setErrors = () => {
    if (errors.hours) {
      const tempStartErrorIdxs = [...startErrorIdxs];
      const tempEndErrorIdxs = [...endErrorIdxs];

      errors.hours.forEach((dayErrors, i) => {
        if (!values.hours[i][startIndex] || !values.hours[i][endIndex]) {
          if (dayErrors && dayErrors[startIndex]) {
            if (!tempStartErrorIdxs.includes(i)) {
              tempStartErrorIdxs.push(i);
            }
          }
          if (dayErrors && dayErrors[endIndex]) {
            if (!tempEndErrorIdxs.includes(i)) {
              tempEndErrorIdxs.push(i);
            }
          }
        }
      });

      setStartErrorIdxs([...tempStartErrorIdxs]);
      setEndErrorIdxs([...tempEndErrorIdxs]);
    }

    endErrorIdxs.forEach((errorIndex) => {
      if (
        values.hours[errorIndex][startIndex] &&
        values.hours[errorIndex][endIndex]
      ) {
        const endErrorIdxsCopy = endErrorIdxs;
        const errorIdx = endErrorIdxsCopy.indexOf(errorIndex);
        endErrorIdxsCopy.splice(errorIdx, 1);
        setEndErrorIdxs([...endErrorIdxsCopy]);
      }
    });
    startErrorIdxs.forEach((errorIndex) => {
      if (
        values.hours[errorIndex][startIndex] &&
        values.hours[errorIndex][endIndex]
      ) {
        const startErrorIdxsCopy = startErrorIdxs;
        const errorIdx = startErrorIdxsCopy.indexOf(errorIndex);
        startErrorIdxsCopy.splice(errorIdx, 1);
        setStartErrorIdxs([...startErrorIdxsCopy]);
      }
    });
  };

  const rowSelection = {
    onChange: (selectedRowKeys) => {
      setSelectedHours(selectedRowKeys);
    },
  };

  useEffect(() => {
    if (errors) {
      setErrors();
    }
  }, [errors, values]);

  return (
    <Table
      loading={loading}
      className="hoursTable"
      size="small"
      dataSource={values.hours}
      rowKey={(record) => `${record.day}`}
      pagination={false}
      rowSelection={enableToggle && rowSelection}
    >
      <Column
        title="Day of Week"
        dataIndex="day"
        key="day"
        render={(text) => toDayOfWeek(text)}
      />
      <Column
        title={startTitle}
        dataIndex={startIndex}
        key={startIndex}
        render={(text, record, i) => (
          <>
            <TimePicker
              className={isStartError(i) && styles.errorBorder}
              size="small"
              value={text ? moment(text, "HH:mm") : null}
              format="HH:mm"
              onChange={(m, txt) =>
                setTime({
                  errors,
                  setErrors,
                  index,
                  selectedHours,
                  setFieldValue,
                  timeIndex: startIndex,
                  i,
                  txt,
                })
              }
            />
            {isStartError(i) && (
              <div className={styles.errorText}>
                {errors.hours[i][startIndex]}
              </div>
            )}
          </>
        )}
      />
      <Column
        title={endTitle}
        dataIndex={endIndex}
        key={endIndex}
        render={(text, record, i) => (
          <>
            <TimePicker
              className={isEndError(i) && styles.errorBorder}
              size="small"
              value={text ? moment(text, "HH:mm") : null}
              format="HH:mm"
              onChange={(m, txt) =>
                setTime({
                  errors,
                  setErrors,
                  index,
                  selectedHours,
                  setFieldValue,
                  timeIndex: endIndex,
                  i,
                  txt,
                })
              }
            />
            {isEndError(i) && (
              <div className={styles.errorText}>
                {errors.hours[i][endIndex]}
              </div>
            )}
          </>
        )}
      />
    </Table>
  );
};

export default HoursTable;
