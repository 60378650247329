import * as React from "react";
import { Col, Row } from "antd";
import { RadioChangeEvent } from "antd/lib/radio/interface";
import { useId } from "@react-aria/utils";

import { useLocationCard } from "./Context";
import Section from "../components/Section";
import { Subheading, Input, InputLabel, Radio } from "../components/FigmaUI";

interface ButtonsProps {
  onPrimaryBgChange: InputChangeType;
  onPrimaryTextChange: InputChangeType;
  onSecondaryBgChange: InputChangeType;
  onSecondaryTextChange: InputChangeType;
  onButtonStyleChange?: (e: RadioChangeEvent) => void;
}

function Buttons({
  onPrimaryBgChange,
  onSecondaryBgChange,
  onPrimaryTextChange,
  onSecondaryTextChange,
  onButtonStyleChange,
}: ButtonsProps) {
  const { componentStyles } = useLocationCard();

  const buttonColorID = useId();
  const buttonTextColorID = useId();
  const buttonStyleID = useId();

  return (
    <Section title="Button" caption="Set primary and secondary button styles.">
      <Subheading id={buttonColorID}> BUTTON COLORS </Subheading>
      <Row
        style={{ marginBottom: "1.5rem" }}
        role="group"
        aria-labelledby={buttonColorID}
      >
        <Col span={12} style={{ paddingRight: "0.5rem" }}>
          <InputLabel htmlFor="primaryButtonLC"> Primary Button </InputLabel>
          <Input
            data-testid="primaryButtonBackgroundColor"
            id="primaryButtonLC"
            name="primaryButtonBackgroundColor"
            onChange={onPrimaryBgChange}
            placeholder={componentStyles.primaryButtonBackgroundColor}
            value={componentStyles.primaryButtonBackgroundColor}
          />
        </Col>
        <Col span={12}>
          <InputLabel htmlFor="secondaryButtonLC">
            {" "}
            Secondary Button{" "}
          </InputLabel>
          <Input
            id="secondaryButtonLC"
            name="secondaryButtonBackgroundColor"
            onChange={onSecondaryBgChange}
            placeholder={componentStyles.secondaryButtonBackgroundColor}
            value={componentStyles.secondaryButtonBackgroundColor}
          />
        </Col>
      </Row>

      <Subheading id={buttonTextColorID}> BUTTON TEXT COLORS </Subheading>
      <Row
        style={{ marginBottom: "1.5rem" }}
        role="group"
        aria-labelledby={buttonTextColorID}
      >
        <Col span={12} style={{ paddingRight: "0.5rem" }}>
          <InputLabel htmlFor="primaryTextLC"> Primary Text </InputLabel>
          <Input
            id="primaryTextLC"
            placeholder={componentStyles.primaryButtonTextColor}
            name="primaryButtonTextColor"
            value={componentStyles.primaryButtonTextColor}
            onChange={onPrimaryTextChange}
          />
        </Col>
        <Col span={12}>
          <InputLabel htmlFor="secondaryTextLC"> Secondary Text </InputLabel>
          <Input
            id="secondaryTextLC"
            placeholder={componentStyles.secondaryButtonTextColor}
            name="secondaryButtonTextColor"
            value={componentStyles.secondaryButtonTextColor}
            onChange={onSecondaryTextChange}
          />
        </Col>
      </Row>

      <Subheading id={buttonStyleID}> BUTTON STYLE </Subheading>
      <Row
        style={{ margin: ".5rem 0" }}
        role="radiogroup"
        aria-labelledby={buttonStyleID}
      >
        <Col span={24}>
          <Radio.Group
            value={componentStyles.buttonStyle}
            onChange={onButtonStyleChange}
            style={{ display: "grid", padding: "0" }}
          >
            <Radio value="rectangular"> Rectangular </Radio>
            <Radio value="rounded"> Rounded </Radio>
            <Radio value="pill"> Pill </Radio>
          </Radio.Group>
        </Col>
      </Row>
    </Section>
  );
}

export default Buttons;
