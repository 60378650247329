import React, {useState} from "react";
import csx from "classnames";
import {Col} from "antd";
import {Row, T} from "components/elements";
import {DetailsInfo} from "./DetailsInfo";
import css from "./details.module.css";

/**
 * Renders Media details for a specific media record. Allows viewing of different version of media record
 * @param {object} props
 * @param {String} props.id - Database Id of the media document
 * @param {String} props.altText - Alternate Text
 * @param {String} props.tags - Tag Keywords for easy searching
 * @param {String} props.title - Title of the media
 * @param {String} props.admin - Db Id of the admin
 * @param {String} props.fileName - File Name
 * @param {String} props.fileType - File Type (jpg, png, etc)
 * @param {Object[]} props.versions - Array of the various versions of this media document (ie different sizes)
 * @param {String} props.versions.isOriginal - Indicator if the specific version is the original media uploaded
 * @param {String} props.versions.identifier - Unique Id for specific version of the media document
 * @param {String} props.versions.fileName - File Name for specific version of the media document
 * @param {String} props.versions.fileSize - File Size for specific version of the media document
 * @param {String} props.versions.path - Publicly accessible URL of the media document
 * @param {String} props.versions.createdAt - Date specific version of media document was created
 * @param {String} props.versions.updatedAt - Date specific version of media document was Update
 * @param {String} props.createdAt - Date the media document was initially created,
 * @param {String} props.updatedAt - Date the media document was updated,
 * @param {Function} props.onDelete - Callback to run when media is deleted
 */
const Details = ({id, versions, onDelete, ...props}) => {
  const [selectedVersionIndex, setSelectedVersionIndex] = useState(0);

  const selectVersion = versions[selectedVersionIndex] ?? {
    isOriginal: false,
    identifier: "",
    fileName: "",
    fileSize: 0,
    path: "",
    createdAt: "",
    updatedAt: "",
  };

  return (
    <Row className={css.detailsRow} gutter={15}>
      <Col xs={24} sm={24} md={24} lg={12}>
        <Row>
          <Col>
            <div className={css.selectImageContainer}>
              <img
                className={css.selectedImage}
                src={selectVersion.path}
                alt={props.altText}
              />
              {/* the replace() is temporary util we get it sent from the backend */}
              <T
                copyable={{
                  text: selectVersion.path.replace("http://", "https://"),
                }}
              >
                URL
              </T>
            </div>
          </Col>
          <Col>
            <T>Versions</T>
            <div className={css.versionContainer}>
              {versions.map((i, index) => {
                const isSelected = index === selectedVersionIndex;
                const onSelect = isSelected
                  ? null
                  : () => setSelectedVersionIndex(index);
                return (
                  <div
                    key={index}
                    className={css.versionItem}
                    onClick={onSelect}
                  >
                    <img
                      className={csx(
                        css.versionImage,
                        isSelected && css.selected,
                      )}
                      src={i.path}
                    />
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12}>
        <DetailsInfo
          id={id}
          altText={props.altText}
          tags={props.tags}
          title={props.title}
          admin={props.admin}
          fileName={props.fileName}
          fileType={props.fileType}
          onDelete={onDelete}
        />
      </Col>
    </Row>
  );
};

export {Details};
export default Details;
