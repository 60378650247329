import React, { useState, useEffect } from "react";
import { Titles } from "components/elements";
import Drawer from "./Drawer";
import { useWindowSize } from "hooks";
import { utils } from "../../../common";
import styles from "./drawers.module.css";

const getWidth = (breakpoint) => {
  switch (breakpoint) {
    case "sm":
      return "80vw";
    case "md":
      return "50vw";
    case "lg":
    case "xl":
    case "xxl":
      return "40vw";
    default:
      return "100vw";
  }
};

/**
 * An extension of our Components.Fragments.Drawer component with responsiveness in mind
 * @memberof Components.Fragments
 * @prop {JSX.Element | JSX.Element[]} props.children - Children components
 * @prop {JSX.Element | JSX.Element[]} props.button - An element that when clicked will open the drawer
 * @prop {string} props.title - The text that will display at the top of the drawer
 * Props are spread and passed down to Components.Fragments.Drawer for more information view that file
 * @returns {React.Element} - Returns a responsive drawer component
 */

const ResDrawer = ({ children, button, title, ...props }) => {
  const { width } = useWindowSize();
  const [drawerWidth, setWidth] = useState();

  const newWidth = (breakpoint) => setWidth(getWidth(breakpoint));

  useEffect(() => {
    const { name: breakpoint } = utils.determineBreakpoint(width);
    newWidth(breakpoint);
  }, [width]);

  return (
    <Drawer
      {...props}
      drawerProps={{
        width: drawerWidth,
        destroyOnClose: true,
      }}
      trigger={button}
      title={
        <Titles.SectionTitle className={styles.drawerTitle}>
          {title}
        </Titles.SectionTitle>
      }
    >
      {children}
    </Drawer>
  );
};

export default ResDrawer;
