import * as React from "react";
import { getIn } from "formik";
import PropTypes from "prop-types";
import { Select, Form } from "antd";
import { T } from "components/elements";
import classnames from "classnames";
import styles from "./field.module.scss";

const SelectField = ({
  field: { onChange, ...field },
  form: { touched, errors, setFieldValue, setFieldTouched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  width,
  selectedName,
  className,
  ...props
}) => {
  const errorMsg = getIn(errors, field.name);

  const Comp = Select;

  return (
    <Form.Item
      label={<T label>{label}</T>}
      help={errorMsg}
      validateStatus={errorMsg ? "error" : undefined}
    >
      <Comp
        {...field}
        {...props}
        style={{ width }}
        className={classnames(styles.select, className)}
        optionLabelProp={selectedName || undefined}
        onChange={(newValue) => setFieldValue(field.name, newValue)}
        onBlur={() => setFieldTouched(field.name)}
      >
        {props.options &&
          props.options.map((i, index) => (
            <Select.Option
              className={styles.selectItem}
              key={index}
              disabled={i.disabled}
              value={i.value}
            >
              {i.name}
            </Select.Option>
          ))}
      </Comp>
    </Form.Item>
  );
};

SelectField.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]).isRequired,
    }),
  ).isRequired,
  size: PropTypes.string,
  width: PropTypes.string,
};

SelectField.defaultProps = {
  size: "default",
  width: "90%",
};

export default SelectField;
