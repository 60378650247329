import { ORDER_TYPE } from "../../../common/constants/strings";

const DELIVERY_SERVICE_CHARGE_LABEL = "Delivery Charge";
const SERVICE_CHARGE_LABEL = "Service Charges";
export const DELIVERY_SERVICE_CHARGE_INFO =
  "Delivery Charge can include both the Delivery Fee as well as any other Service Charges applied to the order.";

export const getServiceChargeLabel = (orderType) => {
  if (orderType === ORDER_TYPE.DELIVERY) {
    return DELIVERY_SERVICE_CHARGE_LABEL;
  }
  return SERVICE_CHARGE_LABEL;
};
