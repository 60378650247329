import React from "react";
import {Upload, Icon, Modal} from "antd";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const mapFile = (data) => ({
  uid: data,
  status: "done",
  url: data,
});

const ImageGallery = ({values, disabled, ...props}) => {
  const [previewVisible, setPreviewVisible] = React.useState(false);
  const [previewImage, setPreviewImage] = React.useState("");

  const onClose = () => {
    setPreviewImage("");
  };

  const onPreview = (file) => {
    setPreviewImage(file.url || file.preview);
  };

  const onChange = async ({file, fileList: newFileList}) => {
    let newList = [...newFileList];
    if (newFileList.length > values.length) {
      file.url = await getBase64(file);
      newList.push(file);
    } else {
      newList = newList.filter((i) => i).filter((i) => i.uid !== file.uid);
    }
    props.onChange(newList.map((i) => i.url).filter((i) => i));
  };

  React.useEffect(() => {
    if (previewImage) {
      setPreviewVisible(true);
    } else {
      setPreviewVisible(false);
    }
  }, [previewImage]);

  return (
    <>
      <Upload
        accept="image/*"
        listType="picture-card"
        fileList={values.map(mapFile)}
        onPreview={onPreview}
        onChange={onChange}
        multiple
        beforeUpload={(file) => false}
        disabled={disabled}
      >
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </Upload>
      <Modal visible={previewVisible} footer={null} onCancel={onClose}>
        <img alt="example" style={{width: "100%"}} src={previewImage} />
      </Modal>
    </>
  );
};
export default ImageGallery;
