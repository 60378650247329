import React, { Fragment, useEffect, useState } from "react";
import { Col, Card, message } from "antd";
import { useSearchQuery } from "hooks";
import { Row, Titles } from "components/elements";
import css from "./ListItems.module.css";
import { Filters } from "../Filters";

const { CardTitle } = Titles;

const paginationObj = (skip, limit, total) => ({
  current: (Number(skip) + Number(limit)) / Number(limit),
  pageSize: Number(limit),
  total: Number(total),
});

const ListItems = ({
  name,
  searchPath,
  defaultQuery,
  extraFilters,
  filters,
  table,
  headerButtons,
  filterHeaderButton,
  subQuery,
  subItem = undefined,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const {
    query,
    resource,
    fetching,
    error,
    updateQuery,
    useEffectFilterUpdate,
    fetch: fetchResults,
  } = useSearchQuery({
    defaults: {
      ...defaultQuery,
      filters: { ...defaultQuery.filters, ...extraFilters },
    },
    path: searchPath,
    subQuery: subQuery || "listItems",
  });

  useEffect(() => {
    if (error) {
      const errorMessage =
        error?.response?.data?.message ?? "An error occurred";
      message.error(errorMessage);
    }
  }, [error]);

  useEffectFilterUpdate(extraFilters ? { ...extraFilters } : {});

  const { total } = resource || { total: 0 };
  let results = [];
  if (resource) {
    if (resource.results) results = resource.results; // gift card endpoint
    if (resource.result) results = resource.result; // payout details endpoint
    if (resource.matchingStoredValueCards)
      results = resource.matchingStoredValueCards;
  }
  // TODO: revert this back to commented code once gift card endpoint, and payout endpoint response
  // is updated to match other responses
  // const { results } = resource || { results: [] };

  const onPageChange = ({ current }) => {
    updateQuery({ ...query, skip: (current - 1) * query.limit });
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      let rowIds;
      if (selectedRows) {
        rowIds = selectedRows.map((row) => row.id);
      }
      setSelectedRows(rowIds);
    },
  };

  let filterValues;
  if (filters) {
    if (typeof filters === "function") filterValues = filters(query);
    else filterValues = filters;
  }

  let nameValue;
  if (name) {
    if (typeof name === "function") nameValue = name(query);
    else nameValue = name;
  }

  return (
    <Fragment>
      {filters && (
        <Filters
          updateQuery={updateQuery}
          title={nameValue}
          filters={filterValues}
          query={query}
          subItem={subItem}
          defaultValues={defaultQuery}
          headerButton={
            filterHeaderButton && filterHeaderButton({ fetchResults })
          }
        />
      )}
      <Row spacing={40}>
        <Card>
          <Row>
            <Col xs={{ span: 22, offset: 1 }} sm={{ span: 24, offset: 0 }}>
              <Col sm={6}>
                <CardTitle>{nameValue}</CardTitle>
              </Col>
              <Col sm={18} className={css.headerButtons}>
                {headerButtons &&
                  headerButtons({ selectedRows, fetchResults, query })}
              </Col>
            </Col>
          </Row>
          <Col>
            {table({
              paginationObj,
              results,
              onPageChange,
              rowSelection,
              total,
              query,
              fetching,
              updateQuery,
              fetchResults,
            })}
          </Col>
        </Card>
      </Row>
    </Fragment>
  );
};

export default ListItems;
