import React from "react";
import {Formik, Field} from "formik";
import {Form, message} from "antd";
import {Fields} from "components";
import {Button} from "components/elements";
import {useAPIContext} from "components/providers/Api";
import {mapAxiosError} from "common/utils";
import {entitiesSchemas} from "common/schemas";
import {LocationSelector} from "./LocationSelector";

const CreateMenu = ({drawerProps, fetchResults}) => {
  const {Menus} = useAPIContext();

  const save = async (formValues, actions) => {
    try {
      actions.setSubmitting(true);
      await Menus.create(formValues);
      message.success("Successfully Saved");
    } catch (e) {
      message.error(mapAxiosError(e));
    } finally {
      actions.setSubmitting(false);
      fetchResults();
      drawerProps.close();
    }
  };

  return (
    <Formik
      initialValues={{
        name: "",
        excludedLocations: [],
      }}
      onSubmit={(formValues, actions) => save(formValues, actions)}
      validationSchema={entitiesSchemas.menuSchema}
    >
      {({handleSubmit, isSubmitting, setFieldValue}) => (
        <Form onSubmit={handleSubmit}>
          <Field
            label="Name"
            name="name"
            placeholder="Name"
            component={Fields.Input}
          />
          <LocationSelector
            title="Excluded Locations"
            addLocations={(locations) =>
              setFieldValue("excludedLocations", locations)
            }
          />
          <Button
            htmlType="submit"
            size="small"
            color="blue"
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Create
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export {CreateMenu};
