import styled from "styled-components";
import { Input as InputBase, Radio as RadioBase } from "antd";
import {
  Button as ButtonComponent,
  Checkbox as CheckboxComponent,
} from "@lunchboxinc/utensils";

/**
 * Generic Style Components from Figma
 * Ideally these styles would exist in a theme context
 */

export const Radio = styled(RadioBase)`
  color: #000;
  font-size: 1rem;
  line-height: 1.25rem;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const Input = styled(InputBase)`
  border-radius: 2px !important;
  border: 1px solid #8f8f8f !important;
  padding: 0.625rem 0.5rem;
  margin-top: 0.5rem;
  color: #000;
  font-size: 1rem;
  line-height: 1.25rem;
  height: auto;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: #000 !important;
  }
`;

export const Subheading = styled.div`
  color: #666666;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.75rem;
  margin-bottom: 1rem;
`;

export const HeadingMedium = styled.h2`
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 24px;
  text-transform: capitalize;
  color: #3d3d3d;
`;

export const DisplayMedium = styled.h2`
  font-weight: normal;
  font-size: 26px;
  line-height: 2rem;
  text-transform: capitalize;
`;

export const DisplayLarge = styled.h1`
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  margin: 0 auto 0 0;
  color: #000;
`;

export const Body = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: #000;
`;

export const ParagraphBody = styled.p`
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.25rem;
  color: #666666;
  margin: 0.5rem 0 0 0;
`;

export const Button = ButtonComponent;

export const Checkbox = CheckboxComponent;

export const CheckboxSpacer = styled.div`
  margin-top: 1rem;
`;

export const InputLabel = styled.label`
  color: #212121;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
`;
