import * as React from "react";

type BannerStates = "warning";

const bannerTheme: Partial<
  Record<
    BannerStates,
    {
      backgroundColor?: string;
      textColor?: string;
      altTextColor?: string;
    }
  >
> = {
  warning: {
    backgroundColor: "#FFFDDF",
    textColor: "#000",
    altTextColor: "#98661B",
  },
};

interface BannerProps {
  type?: BannerStates;
  text?: string;
  altText?: string;
  middot?: boolean;
  children?: React.ReactNode;
}

/**
 * Alert Banner
 * TODO: Remove colors from props and
 */
function Banner({
  type = "warning",
  text,
  altText,
  middot = true,
  children,
}: BannerProps) {
  return (
    <div
      style={{
        backgroundColor: bannerTheme[type]?.backgroundColor || "#fff",
        fontWeight: 400,
        padding: "0.75rem 1rem",
        textAlign: "center",
      }}
    >
      {children || (
        <span style={{ color: bannerTheme[type]?.textColor || "#000" }}>
          {text}
        </span>
      )}
      {altText && middot && `\u00A0\u00B7\u00A0`}
      {altText && (
        <span
          style={{
            color: bannerTheme[type]?.altTextColor || "#666",
            display: "inline-flex",
          }}
        >
          {altText}
        </span>
      )}
    </div>
  );
}

export default Banner;
