import * as React from "react";
import { useUserContext } from "components/providers/User";
import { S } from "./styled";

type Location = {
  id: string;
  isActive: boolean;
  name: string;
  phone: {
    isVerified: boolean;
    value: string;
  };
};

const setLocationKey = (key: string) => (key === "all" ? "" : key);

const LocationDropdown = () => {
  const { locations, onActiveLocationChange } = useUserContext();

  const locationOptions = [
    {
      label: "All Locations",
      value: "all",
    },
    ...locations
      .filter((location: Location) => location.isActive)
      .map((e: Location) => ({
        label: e.name,
        value: e.id,
      })),
  ];

  return (
    <S.StyledSelectSearch
      options={locationOptions}
      placeholder="All Locations"
      onChange={(e: string) => {
        onActiveLocationChange(setLocationKey(e));
      }}
    />
  );
};

export { LocationDropdown };
