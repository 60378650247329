const baseURL = "https://lunchboxsupport.zendesk.com/hc/en-us/articles";
export default {
  AdminDashboardUpdates: `${baseURL}/360040051553-Admin-Dashboard-Updates-`,
  AwardingLoyalty: `${baseURL}/360050970233-Awarding-Loyalty`,
  BanningGuest: `${baseURL}/360044410493-Banning-Guest-`,
  ChattingWithLunchboxSupport: `${baseURL}/360036046734-Chatting-with-Lunchbox-Support`,
  CreatingAdminsForOrganizations: `${baseURL}/360036547973-Creating-Admins-for-your-Organization`,
  CustomizeHelpCenter: `${baseURL}/360024202574-How-do-I-customize-my-Help-Center-`,
  GuestProfiles: `${baseURL}/360036050554-Guest-Profiles`,
  HowToUseAdminPanel: `${baseURL}/360036547953-How-to-use-Lunchbox-Admin-Panel`,
  InsideDiscountDetails: `${baseURL}/360036579513-What-s-inside-Discount-Details-`,
  LeverageKnowledgeToHelpCustomers: `${baseURL}/360024202614-How-can-agents-leverage-knowledge-to-help-customers-`,
  SearchingForAdmins: `${baseURL}/360036046754-Searching-for-Admins-`,
  SearchingForGuests: `${baseURL}/360036050494-Searching-for-Guests`,
  SignInAndForgotPassword: `${baseURL}/360036046594-Sign-in-and-Forgot-Password`,
  UpdatingOrDeletingAdminsFromOrganization: `${baseURL}/360043244173-Updating-or-Deleting-Admins-for-your-Organization-`,
  ViewingUpdatingAdminProfile: `${baseURL}/360036547993-Viewing-and-Updating-your-Admin-Profile`,
  WhatIsAnAdminPanel: `${baseURL}/360042346414-What-is-an-Admin-Panel`,
};
