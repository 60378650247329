import React from "react";
import {Menu, Icon} from "antd";
import {Link} from "react-router-dom";
import {Permissions} from "components/fragments";

const {Permission} = Permissions;
const {Item} = Menu;

/**
 * Permission wrapped nav links for the side menu
 * also allows for external (non react router links)
 *
 * @author Mohammad Afzal
 * @memberof Templates.Templates/SideNav
 * @param {object} props
 * @param {{JSX.Element}} props.children - used to render children of the link such as the name on the link.
 * @param {string} props.link - href url if not using react router.
 * @param {boolean} props.matchOne - whether user only needs to match one of the required permission in order to see the route.
 * @param {function} props.onClickHelper - An additional onClick function used when an external link is clicked. Cannot use onClick field name b/c antd will override it.
 * @param {object} props.requiredPermissions - permissions required for the nav item to render.
 * @param {string} props.to - react router to prop to navigate to different page route.
 * @param {string} props.type - the name of icon to render (see antD docs for names) on the element. only applies to react router links (i.e used in instances with to prop).
 * @returns {React.Element} NavItem on the menu side nav
 */
const NavItem = ({
  children,
  link,
  matchOne,
  onClickHelper,
  requiredPermissions,
  to,
  type,
  ...props
}) => (
  <Permission
    requiredPermissions={requiredPermissions}
    isNav
    matchOne={matchOne}
    yes={() => (
      <Item {...props}>
        {to ? (
          <Link to={to}>
            {type && <Icon type={type} />}
            <span className="nav-text">{children}</span>
          </Link>
        ) : (
          <a
            className="nav-text"
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onClickHelper || null}
          >
            {children}
          </a>
        )}
      </Item>
    )}
  />
);

export default NavItem;
