import React, {useState} from "react";
import {Tables} from "components/fragments";
import {numberHelper, priceHelper} from "./utils";
import {INFO_TYPES} from "./subscriptionConsts";

const {Table} = Tables;

const CategoryTables = ({
  handleRowClick,
  fetching,
  revenueSortedLocations = [],
  totals,
  infoType,
  billingBreakdown = {},
  programBreakdown = {},
}) => {
  const [sortedData, setSortedData] = useState(
    totals ? [...revenueSortedLocations, totals] : [],
  );
  const [sortKey, setSortKey] = useState({
    key: "default",
    count: 1,
  });

  const sortData = (key) => {
    const totalRemoved = sortedData;
    totalRemoved.pop();
    if (sortKey.key !== key) {
      setSortedData([...totalRemoved.sort((a, b) => b[key] - a[key]), totals]);
      setSortKey({key, count: 1});
    } else if (sortKey.key === key && sortKey.count === 1) {
      setSortedData([...totalRemoved.sort((a, b) => a[key] - b[key]), totals]);
      setSortKey({key, count: 2});
    } else if (sortKey.key === key && sortKey.count === 2) {
      setSortedData([...revenueSortedLocations, totals]);
      setSortKey({key: "default", count: 1});
    }
  };

  if (infoType === INFO_TYPES.PROGRAM) {
    return (
      <Table
        horizontalScrollSize="large"
        size="small"
        striped
        dataSource={Object.values(programBreakdown)}
        pagination={false}
        loading={fetching}
        className="sales-reports-table"
        columns={[
          {
            title: "Program",
            dataIndex: "name",
          },
          {
            title: "Subscribers ",
            dataIndex: "subscribers",
            render: numberHelper,
          },
          {
            title: "Avg Quantity",
            dataIndex: "averageTokenQuantity",
            render: numberHelper,
          },
          {
            title: "Total Items",
            dataIndex: "totalTokens",
            render: numberHelper,
          },
          {
            title: "Redeemed Items",
            dataIndex: "totalRedeemedTokens",
            render: numberHelper,
          },
          {
            title: "Unredeemed Items",
            dataIndex: "totalUnredeemedTokens",
            render: numberHelper,
          },
          {
            title: "Subtotal",
            dataIndex: "totalSubtotal",
            render: priceHelper,
          },
          {
            title: "Discounts",
            dataIndex: "totalDiscount",
            render: priceHelper,
          },
          {
            title: "Tax",
            dataIndex: "totalTax",
            render: priceHelper,
          },
          {
            title: "Revenue",
            dataIndex: "totalRevenue",
            render: priceHelper,
          },
        ]}
      />
    );
  }
  if (infoType === INFO_TYPES.BILLING) {
    return (
      <Table
        horizontalScrollSize="large"
        size="small"
        striped
        dataSource={Object.values(billingBreakdown)}
        pagination={false}
        loading={fetching}
        className="sales-reports-table"
        columns={[
          {
            title: "Duration",
            dataIndex: "name",
          },
          {
            title: "Subscribers",
            dataIndex: "subscribers",
            render: numberHelper,
          },
          {
            title: "Avg Quantity",
            dataIndex: "averageTokenQuantity",
            render: numberHelper,
          },
          {
            title: "Subtotal",
            dataIndex: "totalSubtotal",
            render: priceHelper,
          },
          {
            title: "Discount",
            dataIndex: "totalDiscount",
            render: priceHelper,
          },
          {
            title: "Tax",
            dataIndex: "totalTax",
            render: priceHelper,
          },
          {
            title: "Revenue",
            dataIndex: "totalRevenue",
            render: priceHelper,
          },
        ]}
      />
    );
  }
  return (
    <Table
      horizontalScrollSize="large"
      size="small"
      striped
      dataSource={sortedData}
      pagination={false}
      loading={fetching}
      onRow={handleRowClick}
      className="sales-reports-table subscription-analytics-table"
      columns={[
        {
          title: "Location",
          dataIndex: "name",
        },
        {
          title: "Subscribers",
          dataIndex: "activeSubscriptions",
          render: numberHelper,
          sorter: true,
          onHeaderCell: () => ({
            onClick: () => {
              sortData("activeSubscriptions");
            },
          }),
        },
        {
          title: "Cancelled Subscribers",
          dataIndex: "cancelledSubscriptions",
          render: numberHelper,
          sorter: true,
          onHeaderCell: () => ({
            onClick: () => {
              sortData("cancelledSubscriptions");
            },
          }),
        },
        {
          title: "Avg Quantity",
          dataIndex: "averageTokenQuantity",
          render: numberHelper,
          sorter: true,
          onHeaderCell: () => ({
            onClick: () => {
              sortData("averageTokenQuantity");
            },
          }),
        },
        {
          title: "Total Items",
          dataIndex: "totalTokens",
          render: numberHelper,
          sorter: true,
          onHeaderCell: () => ({
            onClick: () => {
              sortData("totalTokens");
            },
          }),
        },
        {
          title: "Redeemed Items",
          dataIndex: "totalRedeemedTokens",
          render: numberHelper,
          sorter: true,
          onHeaderCell: () => ({
            onClick: () => {
              sortData("totalRedeemedTokens");
            },
          }),
        },
        {
          title: "Unredeemed Items",
          dataIndex: "totalUnredeemedTokens",
          render: numberHelper,
          sorter: true,
          onHeaderCell: () => ({
            onClick: () => {
              sortData("totalUnredeemedTokens");
            },
          }),
        },
        {
          title: "Subtotal",
          dataIndex: "totalSubtotal",
          render: priceHelper,
          sorter: true,
          onHeaderCell: () => ({
            onClick: () => {
              sortData("totalSubtotal");
            },
          }),
        },
        {
          title: "Discount",
          dataIndex: "totalDiscount",
          render: priceHelper,
          sorter: true,
          onHeaderCell: () => ({
            onClick: () => {
              sortData("totalDiscount");
            },
          }),
        },
        {
          title: "Tax",
          dataIndex: "totalTax",
          render: priceHelper,
          sorter: true,
          onHeaderCell: () => ({
            onClick: () => {
              sortData("totalTax");
            },
          }),
        },
        {
          title: "Revenue",
          dataIndex: "totalRevenue",
          render: priceHelper,
          sorter: true,
          onHeaderCell: () => ({
            onClick: () => {
              sortData("totalRevenue");
            },
          }),
        },
      ]}
    />
  );
};

export {CategoryTables};
