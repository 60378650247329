import React from "react";
import {Tag} from "components/elements";
import styles from "./table.module.css";

const tagColorData = {
  IN_STOCK: styles.inStock,
  OUT_OF_STOCK: styles.outOfStock,
};
const tagColor = (status) => tagColorData[status] || null;

const tagTextData = {
  IN_STOCK: "In Stock",
  OUT_OF_STOCK: "Out of Stock",
};
const tagText = (status) => tagTextData[status] || null;

const InventoryStatus = ({quantity, status}) => {
  let inventoryStatus = null;

  if (quantity === null) {
    inventoryStatus = <Tag className={tagColor(status)}>{tagText(status)}</Tag>;
  } else {
    inventoryStatus = <span>{quantity}</span>;
  }

  return inventoryStatus;
};

export default InventoryStatus;
