import React, {useRef} from "react";
import {Elements, Fragments} from "components";
import {Col, Table} from "antd";
import globalStyles from "./locationStatus.module.css";

const {
  Tables: {Table: CustomTable},
  Condition,
} = Fragments;

const {
  Titles: {SectionTitle},
  Row,
} = Elements;

const LocationStatus = ({
  fetching,
  id,
  serviceArea,
  dataSource,
  address,
  deliveryRadius,
  hasDeliveryRadius,
  setLocationData,
  serviceAreaButton,
  title,
  ...props
}) => {
  return (
    <>
      <Row spacing={20}>
        <Col xs={12}>
          <SectionTitle>{title}</SectionTitle>
        </Col>
        <Condition.Condition is={serviceAreaButton}>
          <Col xs={12} className={globalStyles.rightAlign}>
            {serviceAreaButton && serviceAreaButton()}
          </Col>
        </Condition.Condition>
      </Row>
      <CustomTable
        size="small"
        dataSource={dataSource}
        rowKey={(i) => i.name}
        pagination={false}
        loading={fetching}
      >
        <Table.Column dataIndex="name" width={300} />
        <Table.Column
          dataIndex="toggle"
          width={20}
          title="Status"
          render={(text, i) => (
            <span className="locationStatusSwitch">{i.toggle}</span>
          )}
        />
      </CustomTable>
    </>
  );
};

export default LocationStatus;
