import React from "react";
import {Col} from "antd";
import moment from "moment";
import {useRefetchableResource, useAnalyticsActions} from "hooks";
import {Elements, Fragments} from "components";
import Header from "../Analytics/Header";
import LineGraph from "../Analytics/LineGraph";
import styles from "./home.module.css";

const {
  Titles: {CardTitle},
  Row,
} = Elements;
const {
  Cards: {BlueCard},
} = Fragments;

const GuestsAnalytics = ({locationId, ...props}) => {
  const {actions, params} = useAnalyticsActions({locationId});
  const {resource} = useRefetchableResource({
    path: "/analytics/registrations",
    data: {filters: params},
    method: "GET",
  });

  const registrationsLineData = (data) => [
    {
      id: "Total Registrations",
      data: data.map((i) => ({
        y: i.count || i.sum,
        x: moment(i._id).format("MMM DD"),
      })),
    },
  ];

  // const orderCountPieData = data => data.map(i => (
  //   {
  //     id: `${i.numberOfOrders} Orders`,
  //     value: i.numberOfUsers,
  //   }
  // ));

  // const averageOrderPieData = data => data.map(i => (
  //   {
  //     id: `${i.numberOfOrders} Orders`,
  //     value: i.totalSpend,
  //   }
  // )); [THESE TWO ARE NEVER USED]

  return (
    <>
      <BlueCard>
        <Header
          actions={actions}
          title="Guests"
          initialDateValues={params.createdAt}
        />
        <Row gutter={60}>
          <Col xs={24} md={24}>
            <Row>
              <CardTitle className={styles.barTitle}>
                Total Registrations
              </CardTitle>
              <LineGraph
                data={registrationsLineData(resource)}
                legendTranslateX={-26}
              />
            </Row>
            {/* <Row>
              <CardTitle className={styles.barTitle}>Number Of Users By Average Orders</CardTitle>
              <PieChart data={orderCountPieData(orderCountResource)} legendTranslateX={-105} label={i => `${i.value} Guests`} />
            </Row>
            <Row>
              <CardTitle className={styles.barTitle}>Number Of Users By Average Orders</CardTitle>
              <PieChart data={averageOrderPieData(orderCountResource)} legendTranslateX={-105} label={i => `$${Number(parseFloat(i.value, 10).toFixed(0)).toLocaleString()}`} />
            </Row> */}
          </Col>
        </Row>
      </BlueCard>
    </>
  );
};

export default GuestsAnalytics;
