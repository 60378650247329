import { utils } from "common";
import { useUserContext } from "../components/providers/User";
import { useAppcuesContext } from "../components/providers/Appcues";

const { appcuesPermissions } = utils;

export const checkPermission = (requiredPermission, adminPermissions) => {
  return adminPermissions.find((adminPermission) => {
    const {
      method: userMethod,
      url: userUrl,
      forbidden: userForbidden,
      access: userAccess,
    } = adminPermission;

    const {
      method: requiredMethod,
      url: requiredUrl,
      field: requiredField,
    } = requiredPermission;

    if (requiredMethod) {
      if (userMethod !== requiredMethod) {
        return false;
      }
    }
    if (requiredUrl) {
      if (userUrl !== requiredUrl) {
        return false;
      }
    }
    if (requiredField) {
      if (userForbidden.includes(requiredField)) {
        if (userAccess.includes(requiredField)) {
          return adminPermission;
        }
      }
      if (userAccess.includes(requiredField)) {
        return adminPermission;
      }
      return false;
    }
    if (userAccess.length) return false;

    return adminPermission;
  });
};

const usePermission = (
  { matchOne, requiredPermissions, isRoute, isNav } = {
    matchOne: false,
    requiredPermissions: [],
  },
) => {
  const { user } = useUserContext();
  const { updatePermissions } = useAppcuesContext();
  const { adminPermissions, role } = user || {};

  // TODO OLD ROLES
  // If the user's legacy role is 0 (developer), bypass all permissions
  if (role === 0) return true;

  if (adminPermissions && requiredPermissions) {
    const matchedPermissions = requiredPermissions
      .map((requiredPermission) =>
        checkPermission(requiredPermission, adminPermissions),
      )
      .filter((permission) => permission);

    const permissionLabels = matchedPermissions.map(
      (permission) => permission.label,
    );

    let hasPermission;
    if (matchOne) {
      hasPermission = matchedPermissions.length;
    } else {
      hasPermission = requiredPermissions.length === matchedPermissions.length;
    }

    const permissions = appcuesPermissions({
      isRoute,
      isNav,
      hasPermission,
      permissionLabels,
    });
    if (permissions) {
      updatePermissions(permissions);
    }

    return hasPermission;
  }

  return false;
};

export default usePermission;
