import * as React from "react";
import { Column, useSortBy, useTable } from "react-table";
import { Caret } from "../Icons/Caret";
import {
  SizeTypes,
  SortArrow,
  StyledTable,
  StyledTableDataCell,
  StyledTableHeaderCellWrapper,
  StyledTableRow,
} from "./Styled";

interface TableShape {
  columns: Array<Column<object>>;
  data: any;
  defaultSortAccessor?: string;
  size?: SizeTypes;
  nonSortableColumns?: string[];
  onRowClick?: Function;
  getRowProps?: Function;
  enableRowHover?: boolean;
}

function Table({
  columns,
  data,
  defaultSortAccessor = "",
  nonSortableColumns = [],
  size = "md",
  onRowClick = () => null,
  getRowProps = () => ({}),
  enableRowHover = false,
}: TableShape) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: { sortBy: [{ id: defaultSortAccessor, desc: true }] },
      },
      useSortBy,
    );

  const [rowHover, setRowHover] = React.useState("");
  const isSortable = (columnName: string) =>
    !nonSortableColumns.includes(columnName);

  return (
    <StyledTable {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(
                  isSortable(column.Header as string) &&
                    column.getSortByToggleProps(),
                )}
              >
                <StyledTableHeaderCellWrapper>
                  {column.render("Header")}
                  {isSortable(column.Header as string) && (
                    <SortArrow isHidden={!column.isSorted}>
                      {
                        <Caret
                          direction={
                            column.isSortedDesc ? "collapse" : "expand"
                          }
                        />
                      }
                    </SortArrow>
                  )}
                </StyledTableHeaderCellWrapper>
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <StyledTableRow
              {...row.getRowProps()}
              onMouseEnter={() => (enableRowHover ? setRowHover(row.id) : null)}
              onClick={() => onRowClick(row.original)}
              style={
                rowHover === row.id
                  ? { backgroundColor: "#F5F5F5" }
                  : getRowProps(row).style
              }
            >
              {row.cells.map((cell) => {
                return (
                  <StyledTableDataCell size={size} {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </StyledTableDataCell>
                );
              })}
            </StyledTableRow>
          );
        })}
      </tbody>
    </StyledTable>
  );
}

export { Table };
