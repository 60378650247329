import React from "react";
import {Card} from "antd";
import classnames from "classnames";
import styles from "./blueCard.module.css";

/**
 * Wrapper for antd Card component applying lunchbox blue styling
 * @memberof Components.Fragments
 * @prop {JSX.Element | JSX.Element[]} children - Children components
 * @prop {string} className - Applies a css class to the button
 */

const BlueCard = ({children, className}) => (
  <Card bordered={false} className={classnames(styles.blueCard, className)}>
    {children}
  </Card>
);

export default BlueCard;
