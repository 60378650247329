/* eslint-disable react/no-unused-prop-types */
import React from "react";
import {Icon} from "antd";
import csx from "classnames";
import PropTypes from "prop-types";
import {Droppable, DragDropContext} from "react-beautiful-dnd";
import CarouselImage from "./CarouselImage";
import css from "./imageCarousel.module.css";

const ImageCarousel = ({
  containerClassName = "",
  images = [],
  onMove,
  onClickDelete,
  onClickAdd,
}) => {
  const moveImage = (dragIndex, hoverIndex) => {
    const nextImages = [...images];
    const draggedImage = nextImages[dragIndex];
    const prevItem = nextImages.splice(hoverIndex, 1, draggedImage);
    nextImages.splice(dragIndex, 1, prevItem[0]);
    if (onMove) onMove(nextImages);
  };

  const onDragEnd = (result) => {
    moveImage(result.source.index, result.destination.index);
  };

  const filteredImage = images.filter((i) => i);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className={csx(css.container, containerClassName)}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <>
              <div className={css.imageContainer} ref={provided.innerRef}>
                {filteredImage.map((i, idx) => {
                  const keyNameId = i?.id ?? idx;
                  const src = i?.src ?? i;
                  return (
                    <CarouselImage
                      index={idx}
                      key={`${keyNameId}-image`}
                      src={src}
                      onClickDelete={() => onClickDelete(idx)}
                      moveImage={moveImage}
                    />
                  );
                })}

                {onClickAdd && (
                  <button
                    type="button"
                    className={css.addItem}
                    onClick={onClickAdd}
                  >
                    <Icon type="plus" className={css.addIcon} />
                    <span className={css.addText}>Add</span>
                  </button>
                )}
              </div>
              {provided.placeholder}
            </>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
};

ImageCarousel.propTypes = {
  containerClassName: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.exact({
        src: PropTypes.string,
        id: PropTypes.string,
      }),
      PropTypes.string,
    ]),
  ),
  onMove: PropTypes.func,
  onClickAdd: PropTypes.func,
  onClickDelete: PropTypes.func,
};
ImageCarousel.defaultProps = {
  containerClassName: "",
  images: [],
  onMove: null,
  onClickAdd: null,
  onClickDelete: () => null,
};

export default ImageCarousel;
