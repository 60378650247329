import React, {useState} from "react";
import {TimePicker} from "antd";
import {T, Row} from "components/elements";
import styles from "./field.module.scss";

const TimeRangeField = ({
  startLabel,
  endLabel,
  onStartChange,
  onEndChange,
  initialStartTime,
  initialEndTime,
  errors,
  disabledHours,
  disabledMinutes,
  startFieldStyle,
  endFieldStyle,
}) => {
  const [startTime, setStartTime] = useState(initialStartTime);
  const [endTime, setEndTime] = useState(initialEndTime);

  const handleStartOpenChange = (open) => {
    if (open) {
      setEndTime(null);
    }
  };

  const handleStartChange = (date) => {
    onStartChange(date);
    setStartTime(date);
  };

  const handleEndChange = (date) => {
    onEndChange(date);
    setEndTime(date);
  };

  return (
    <>
      <div className={styles.startFieldWrapper}>
        {startLabel && (
          <Row spacing={10}>
            <T>{startLabel}</T>
          </Row>
        )}
        <TimePicker
          disabledHours={disabledHours}
          disabledMinutes={disabledMinutes}
          format="h:mm a"
          value={startTime}
          placeholder="Start"
          use12Hours
          onChange={handleStartChange}
          allowClear={false}
          onOpenChange={handleStartOpenChange}
          className={styles.timeRangePicker}
          style={startFieldStyle}
        />
        {errors && errors.startTime && (
          <div className={styles.errorText}>Start Time is required</div>
        )}
      </div>
      <div className={styles.endFieldWrapper}>
        {endLabel && (
          <Row spacing={10}>
            <T>{endLabel}</T>
          </Row>
        )}
        <TimePicker
          disabledHours={() => disabledHours(true)}
          disabledMinutes={(currentHour) => disabledMinutes(currentHour, true)}
          format="h:mm a"
          value={endTime}
          placeholder="End"
          use12Hours
          onChange={handleEndChange}
          allowClear={false}
          className={styles.timeRangePicker}
          style={endFieldStyle}
        />
        {errors && errors.endTime && (
          <div className={styles.errorText}>End Time is required</div>
        )}
      </div>
    </>
  );
};

export default TimeRangeField;
