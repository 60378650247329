const dollars = {
  formatter: (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
  parser: (value) => value.replace(/\$\s?|(,*)/g, ""),
};

const minutes = {
  formatter: (value) => `${value} mins`,
  parser: (value) => value.replace(/[A-Za-z\s]+$/g, ""),
};

const miles = {
  formatter: (value) => `${value} miles`,
  parser: (value) => value.replace(/[A-Za-z\s]+$/g, ""),
};

export default { dollars, minutes, miles };
export { dollars, minutes, miles };
