import React from "react";
import PropTypes from "prop-types";
import {useActive} from "hooks";
import {Icon, Modal} from "antd";

const ImagePreviewModal = ({src}) => {
  const {isActive, activate, deactivate} = useActive(false);

  return (
    <>
      <Icon
        type="eye"
        theme="filled"
        style={{color: "white"}}
        onClick={activate}
      />
      <Modal
        visible={isActive}
        closable
        maskClosable
        onCancel={deactivate}
        footer={null}
      >
        <img src={src} style={{width: "100%"}} />
      </Modal>
    </>
  );
};

ImagePreviewModal.propTypes = {
  src: PropTypes.string,
};

ImagePreviewModal.defaultProps = {
  src: "",
};

export {ImagePreviewModal};
