import * as React from "react";
import { UIContext } from "components/providers";
import { useClickOutside } from "hooks";
import { MenuItem } from "./MenuItem";
import { MenuItemDropdown } from "./MenuItemDropdown";
import { S } from "./styled";
import {
  NAV_MENUS,
  NAV_OPTIONS,
  SETTINGS_NAV,
  SideMenuItem,
} from "../menuConfig";

const Sidebar = ({
  navType,
  minNav,
  setMinNav,
  toggleSidebarRef,
  mobileFooterRef,
  isMobile,
}) => {
  const navTypeMenu: SideMenuItem[] = NAV_MENUS[navType];
  const { label } = NAV_OPTIONS[navType];
  const { setNewUI } = React.useContext(UIContext);
  const mobileSidebarRef = React.useRef(null);
  const [isHoverExpanded, setIsHoverExpanded] = React.useState(false);
  const [menuDropdownOpen, setMenuDropdownOpen] = React.useState("");
  const showMinNav =
    (minNav && navType !== SETTINGS_NAV) || (minNav && isMobile);
  const showOldAdminButton = !minNav && !isMobile;
  const showTitle = isMobile && !minNav;
  const isSettingsMenu = navType === SETTINGS_NAV;

  const handleClickOutside = (e: MouseEvent | TouchEvent) => {
    if (toggleSidebarRef?.current?.contains(e.target)) return;
    if (mobileFooterRef?.current?.contains(e.target)) return;
    if (mobileSidebarRef?.current?.contains(e.target)) return;
    setMinNav(true);
  };

  useClickOutside(mobileSidebarRef, handleClickOutside);

  return (
    <S.SidebarContainer
      aria-label="side"
      isHoverExpanded={isHoverExpanded}
      isMinimized={showMinNav}
      isMobile={isMobile}
      isSettingsMenu={isSettingsMenu}
      onMouseEnter={() => setIsHoverExpanded(true)}
      onMouseLeave={() => setIsHoverExpanded(false)}
      ref={isMobile ? mobileSidebarRef : null}
    >
      {(isSettingsMenu || showTitle) && (
        <S.SidebarTitle component="h1" variant="DisplaySmall">
          {/* TODO: Replace with lb-utensils icon */}
          {isSettingsMenu && <S.SettingsIcon fixedWidth icon={"cog"} />}
          {label}
        </S.SidebarTitle>
      )}

      {navTypeMenu.map((menuItem) => {
        const MenuItemComponent = menuItem.subItems?.length
          ? MenuItemDropdown
          : MenuItem;

        return (
          <MenuItemComponent
            isHoverExpanded={isHoverExpanded}
            isSettingsMenu={isSettingsMenu}
            key={menuItem.label}
            menuDropdownOpen={menuDropdownOpen}
            menuItem={menuItem}
            minNav={minNav}
            setMenuDropdownOpen={setMenuDropdownOpen}
          />
        );
      })}

      {showOldAdminButton && (
        <S.ClassicAdminButton onClick={() => setNewUI(false)}>
          Go back to Classic Admin
        </S.ClassicAdminButton>
      )}
    </S.SidebarContainer>
  );
};

export { Sidebar };
