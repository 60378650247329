import React from "react";
import styled from "styled-components";
import { Svg, SvgProps } from "../Svg";

export type Direction = "expand" | "right" | "collapse" | "left";

type Angle = {
  [key: string]: string;
};
/** Css transform angle (deg) */
const directionTransform: Angle = {
  expand: "0",
  right: "90",
  collapse: "180",
  left: "270",
};

interface CaretProps extends SvgProps {
  /** Defines the direction of the caret. */
  direction?: Direction;
  className?: string;
}

// rotate the Caret based on direction
const StyledCaret = styled(Svg)<CaretProps>`
  transform: ${({ direction }) =>
    `rotate(${directionTransform[direction || "expand"]}deg)`};
  font-style: "normal";
`;

const Caret: React.FC<CaretProps> = ({
  direction = "expand",
  color,
  bold,
  ...rest
}) => {
  return (
    <StyledCaret direction={direction} {...rest}>
      {bold ? (
        <path
          d="M6.375 0.570312C6.16406 0.359375 5.8125 0.359375 5.60156 0.570312L1.03125 5.11719C0.820312 5.35156 0.820312 5.70312 1.03125 5.91406L1.57031 6.45312C1.78125 6.66406 2.13281 6.66406 2.36719 6.45312L6 2.84375L9.60938 6.45312C9.84375 6.66406 10.1953 6.66406 10.4062 6.45312L10.9453 5.91406C11.1562 5.70312 11.1562 5.35156 10.9453 5.11719L6.375 0.570312Z"
          transform-origin="center"
          fill={color || "#2B7C7F"}
          fontStyle="normal"
        />
      ) : (
        <path
          d="M11.1328 0.320312L10.9688 0.15625C10.8516 0.0390625 10.6875 0.0390625 10.5703 0.15625L6 4.72656L1.40625 0.15625C1.28906 0.0390625 1.125 0.0390625 1.00781 0.15625L0.84375 0.320312C0.726562 0.4375 0.726562 0.601562 0.84375 0.71875L5.78906 5.66406C5.90625 5.78125 6.07031 5.78125 6.1875 5.66406L11.1328 0.71875C11.25 0.601562 11.25 0.4375 11.1328 0.320312Z"
          transform-origin="center"
          fill={color || "#2B7C7F"}
        />
      )}
    </StyledCaret>
  );
};

export { Caret };
