import React, { useState, useEffect } from "react";
import { Col, Card } from "antd";
import { LoadingSpinner } from "components/v2/Loading/LoadingSpinner/LoadingSpinner";
import moment from "moment";
import { useAnalyticsActions, useRefetchableResource } from "hooks";
import { Row, Titles } from "components/elements";
import { MonthPicker, Tables, Cards } from "components/fragments";
import classnames from "classnames";
import { numberHelper, priceHelper } from "./utils";
import css from "../../Analytics/analytics.module.css";

const { BlueCardTitle } = Titles;
const { Table } = Tables;
const { BlueCard, StatCard } = Cards;

const SubscriptionDailyReports = ({ locationId }) => {
  const [dateSelected, setDateSelected] = useState(() => moment());

  const { actions, params } = useAnalyticsActions({
    locationId,
    initialDates: {
      startDate: moment().utc().startOf("month"),
      endDate: moment().utc().endOf("month"),
    },
  });
  const { resource = [], fetching } = useRefetchableResource({
    path: "/subscriptions-report",
    data: { filters: params },
    method: "GET",
  });

  const {
    cancelledSubscribers = 0,
    realizedRevenue = 0,
    redeemedTokens = 0,
    subscribers = 0,
    totalRevenue = 0,
    totalTokens = 0,
    unrealizedRevenue = 0,
    unrealizedTokens = 0,
  } = !fetching && (resource?.monthlyOverview ?? {});

  useEffect(() => {
    actions.onCreatedAtChange({
      createdAt: {
        start: moment(dateSelected).utc().clone().startOf("month").format(),
        end: moment(dateSelected).utc().clone().endOf("month").format(),
      },
    });
  }, [dateSelected]);

  if (fetching) {
    return <LoadingSpinner spinning={fetching} />;
  }

  return (
    <>
      <Card>
        <div className={css.headerWrapper}>
          <Col xs={24} sm={24} md={24} lg={8}>
            <BlueCardTitle className={css.title}>Monthly Summary</BlueCardTitle>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={16}
            className={classnames(css.filters, "headerFilters")}
          >
            <MonthPicker
              value={dateSelected}
              onChange={(date) => {
                setDateSelected(date);
              }}
            />
          </Col>
        </div>
        <Row gutter={30}>
          <Col xs={24} sm={6} style={{ marginBottom: "30px" }}>
            <StatCard
              title="Subscribers"
              type="value"
              value={subscribers}
              standardIcon="rollback"
            />
          </Col>

          <Col xs={24} sm={6} style={{ marginBottom: "30px" }}>
            <StatCard
              title="Total Items"
              type="value"
              value={totalTokens}
              standardIcon="arrow-up"
            />
          </Col>

          <Col xs={24} sm={6} style={{ marginBottom: "30px" }}>
            <StatCard
              title="Redeemed Items"
              type="value"
              value={redeemedTokens}
              standardIcon="play-circle"
            />
          </Col>

          <Col xs={24} sm={6} style={{ marginBottom: "30px" }}>
            <StatCard
              title="Unrealized Items"
              type="value"
              value={unrealizedTokens}
              standardIcon="pause-circle"
            />
          </Col>

          <Col xs={24} sm={6} style={{ marginBottom: "30px" }}>
            <StatCard
              title="Cancelled Subscriptions"
              type="value"
              value={cancelledSubscribers}
              standardIcon="close"
            />
          </Col>

          <Col xs={24} sm={6} style={{ marginBottom: "30px" }}>
            <StatCard
              title="Total Revenue"
              type="price"
              value={totalRevenue}
              standardIcon="arrow-up"
            />
          </Col>

          <Col xs={24} sm={6} style={{ marginBottom: "30px" }}>
            <StatCard
              title="Realized Revenue"
              type="price"
              value={realizedRevenue}
              standardIcon="dollar"
            />
          </Col>

          <Col xs={24} sm={6} style={{ marginBottom: "30px" }}>
            <StatCard
              title="Unrealized Revenue"
              type="price"
              value={unrealizedRevenue}
              standardIcon="dollar"
            />
          </Col>
        </Row>
      </Card>
      <BlueCard>
        <BlueCardTitle className={css.title}>Daily Summary</BlueCardTitle>
        <Row spacing={45}>
          <Table
            size="small"
            striped
            dataSource={resource.dailyBreakdown}
            pagination={false}
            loading={fetching}
            className="sales-reports-table"
            columns={[
              {
                title: "Date",
                dataIndex: "date",
                render: (date) => moment(date).format("LL"),
              },
              {
                title: "Subscribers",
                dataIndex: "subscribers",
                render: numberHelper,
              },
              {
                title: "Total Items",
                dataIndex: "totalTokens",
                render: numberHelper,
              },
              {
                title: "Realized Items",
                dataIndex: "realizedTokens",
                render: numberHelper,
              },
              {
                title: "Unrealized Items",
                dataIndex: "unrealizedTokens",
                render: numberHelper,
              },
              {
                title: "Total Revenue",
                dataIndex: "totalRevenue",
                render: priceHelper,
              },
              {
                title: "Realized Revenue",
                dataIndex: "realizedRevenue",
                render: priceHelper,
              },
              {
                title: "Unrealized Revenue",
                dataIndex: "unrealizedRevenue",
                render: priceHelper,
              },
            ]}
          />
        </Row>
      </BlueCard>
    </>
  );
};

export { SubscriptionDailyReports };
