export const getCoordinatesFromOverlay = (overlay) =>
  overlay
    .getPath()
    .getArray()
    .map(({ lat: latitude, lng: longitude }) => ({
      long: longitude(),
      lat: latitude(),
    }));

export default { getCoordinatesFromOverlay };
