import React from "react";
import {Tag} from "components/elements";
import styles from "./availabilityStatus.module.css";

const tagColor = (available) =>
  available ? styles.available : styles.unavailable;
const tagText = (available) => (available ? "Available" : "Unavailable");

/**
 * An extension of the Components.Elements.Tag component for displaying availability
 * @memberof Components.Fragments
 * @prop {boolean} props.available - Applies corresponding css and text to tag
 */

const AvailabilityStatus = ({available}) => (
  <Tag className={tagColor(available)}>{tagText(available)}</Tag>
);

export default AvailabilityStatus;
