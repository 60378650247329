import React from "react";

type CondidtionProps = {
  children: React.ReactNode;
  is: boolean;
  condition?: boolean;
};

type ChildType = {
  type: React.ReactNode;
};

const True = ({ children }) => children;
const False = ({ children }) => children;

const Condition = (props: CondidtionProps) => {
  const { children, is, condition = false } = props;

  const finalCondition = is !== undefined ? is : condition;

  const trueRenders: React.ReactNode[] = [];
  const falseRenders: React.ReactNode[] = [null];

  React.Children.forEach(children, (child: ChildType) => {
    switch (child.type) {
      case True: {
        trueRenders.push(child);
        break;
      }
      case False: {
        falseRenders.push(child);
        break;
      }
      default: {
        if (finalCondition) trueRenders.push(child);
      }
    }
  });
  return <>{finalCondition ? trueRenders : falseRenders}</>;
};

export { Condition, True, False };
