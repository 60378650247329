import * as React from "react";
import { S } from "./styled";
import { NAV_OPTIONS, NAV_MENU_ORDER } from "../menuConfig";

interface MenuButtonsProps {
  handleClick: (menuType: string) => void;
  navType: string;
}

const MenuButtons = ({ handleClick, navType }: MenuButtonsProps) => {
  return (
    <>
      {NAV_MENU_ORDER.map((menu) => {
        const { label, menuType, path } = NAV_OPTIONS[menu];
        const isActive = navType === menuType;
        return (
          <S.MenuButton $isActive={isActive} key={label}>
            <S.MenuLink
              aria-current={isActive}
              to={path}
              onClick={() => {
                handleClick(menuType);
              }}
            >
              {label}
            </S.MenuLink>
          </S.MenuButton>
        );
      })}
    </>
  );
};

export { MenuButtons };
