import { useHistory } from "react-router-dom";
import qs from "qs";
import cloneDeep from "lodash/cloneDeep";
import useDeepEffect from "./useDeepEffect";

const cleanQuery = (queryValue, defaultQuery) => {
  const query = { ...queryValue };
  Object.keys(query).forEach((key) => {
    if (query[key] === defaultQuery[key]) {
      delete query[key];
    } else if (typeof query[key] === "object") {
      cleanQuery(query[key], defaultQuery[key]);
    }
  });
};

const useQueryString = ({ query, defaults }) => {
  const history = useHistory();
  useDeepEffect(() => {
    const queryClone = cloneDeep(query);
    cleanQuery(queryClone, defaults);
    history.replace({
      search: qs.stringify(queryClone),
    });
  }, [query]);
};

export default useQueryString;
