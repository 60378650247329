import * as React from "react";
import { LoadingSpinner } from "components/v2/Loading/LoadingSpinner/LoadingSpinner";
import Banner from "../components/Banner";
import Header from "../components/Header";
import PreviewComponent from "./Preview";
import ButtonSection from "./Buttons";
import LocationDetails from "./LocationDetails";
import OpeningHours from "./OpeningHours";
import WaitTimes from "./WaitTimes";

import { useLocationCard } from "./Context";
import Dialog from "../components/Dialog";
import { Prompt } from "react-router-dom";

const LocationCardPage: React.FC<{}> = ({}) => {
  const {
    dialog,
    discard,
    isLoading,
    save,
    handleConfigChange,
    handleStylesChange,
    publish,
    updateButtonStyle,
    publishData,
  } = useLocationCard();

  const publishText = `${publishData?.remainder} publish actions left today`;
  const handleButtonStyleChange = (e) => updateButtonStyle(e.target.value);

  React.useEffect(() => {
    const unloadHandler = (e) => {
      e.preventDefault();
      e.returnValue = "";
    };
    if (publishData?.dirty) {
      window.addEventListener("beforeunload", unloadHandler);
    }
    return () => window.removeEventListener("beforeunload", unloadHandler);
  }, [publishData]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <Banner
        type="warning"
        text={publishText}
        altText={publishData?.dirty && "There are unpublished changes"}
      />
      <Prompt
        when={publishData.dirty === true}
        message="Changes you made may not be saved"
      />
      <Dialog isOpen={Boolean(Object.keys(dialog).length)} {...dialog} />
      <div style={{ padding: "1.5rem" }}>
        <Header
          title="Location Cards"
          onDiscard={() => discard()}
          onSave={() => save()}
          onPublish={() => publish()}
          publishData={publishData}
        />
        <PreviewComponent />
        <ButtonSection
          onPrimaryBgChange={handleStylesChange}
          onSecondaryBgChange={handleStylesChange}
          onPrimaryTextChange={handleStylesChange}
          onSecondaryTextChange={handleStylesChange}
          onButtonStyleChange={handleButtonStyleChange}
        />
        <LocationDetails
          onAddressChange={handleConfigChange}
          onPhoneNumberChange={handleConfigChange}
        />
        <WaitTimes onChange={handleConfigChange} />
        <OpeningHours
          onDeliveryHoursChange={handleConfigChange}
          onPickupHoursChange={handleConfigChange}
          onStoreHoursChange={handleConfigChange}
        />
      </div>
    </div>
  );
};

export default LocationCardPage;
