import React from "react";
import PropTypes from "prop-types";
import {useSuperAdminCheck} from "hooks";
import {Condition} from "../Condition";

const {Condition: If} = Condition;
/**
 * Renders out children if the user is a super admin
 * @param {object} props
 * @param {object} props.children - React children to render if super admin
 */
const SuperAdminOnly = ({children}) => {
  const isSuperAdmin = useSuperAdminCheck();

  return <If is={isSuperAdmin}>{children}</If>;
};

SuperAdminOnly.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default SuperAdminOnly;
