import React from "react";
import {Table as AntTable} from "antd";
import Table from "./Table";

const LocationSelectionTable = ({
  excludedLocations,
  locations,
  height,
  setFieldValue,
  fieldName,
  loading,
  selectedLocations,
  onChange,
  rowSelection: additionalRowSelection,
}) => {
  excludedLocations = excludedLocations.map((location) => {
    if (typeof location === "object") {
      return location.id;
    }
    return location;
  });

  /* Get list of locations that aren't in excludedLocations */
  const availableLocations = locations.filter(
    (location) => !excludedLocations.includes(location.id),
  );

  const rowSelection = {
    ...additionalRowSelection,
    selectedRowKeys: selectedLocations,
    onChange: (selectedRowKeys, selectedRows) => {
      if (setFieldValue) {
        setFieldValue(fieldName, selectedRowKeys);
      }
      if (onChange) {
        onChange(selectedRowKeys, selectedRows);
      }
    },
  };

  return (
    <Table
      loading={loading}
      dataSource={availableLocations}
      scroll={{y: height}}
      className="locationToggleTable"
      rowSelection={rowSelection}
    >
      <AntTable.Column title="Location" dataIndex="name" width={550} />
    </Table>
  );
};

LocationSelectionTable.defaultProps = {
  disabledLocations: [],
  excludedLocations: [],
  locations: [],
};

export default LocationSelectionTable;
