import React from "react";
import { Tooltip } from "@lunchboxinc/utensils";
import { ConditionalWrapper } from "components/elements";
import { usePermission } from "hooks";

const popoverContent = "You do not have permission to edit this field";

const withPermissionField =
  (Component, requiredPermissions, matchOne) =>
  ({ disabled, ...props }) => {
    const hasRequiredPermission = usePermission({
      requiredPermissions,
      matchOne,
    });

    return (
      <ConditionalWrapper
        condition={!hasRequiredPermission}
        wrapper={(children) => (
          <Tooltip placement="left" popupLabel={popoverContent}>
            {children}
          </Tooltip>
        )}
      >
        <Component disabled={!hasRequiredPermission || disabled} {...props} />
      </ConditionalWrapper>
    );
  };

export default withPermissionField;
