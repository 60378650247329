import * as React from "react";
import { Button, DisplayLarge } from "./FigmaUI";

interface HeaderProps {
  title?: string;
  onDiscard?: (e: React.MouseEvent) => void;
  onSave?: (e: React.MouseEvent) => void;
  onPublish?: (e: React.MouseEvent) => void;
  publishData?: {
    date?: string;
    author?: string;
    time?: string;
  };
}

/**
 * Page header component with title and two buttons (Discard Changes, Save)
 */
function Header({
  title,
  onDiscard,
  onSave,
  onPublish,
  publishData = {},
}: HeaderProps) {
  const { date = "", time = "", author = "" } = publishData;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "2.5rem",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {title && <DisplayLarge>{title}</DisplayLarge>}
        {onPublish && (
          <Button
            variant="secondary"
            size="large"
            onClick={onPublish}
            style={{ marginRight: "0.5rem" }}
          >
            Publish
          </Button>
        )}
        {onDiscard && (
          <Button
            variant="secondary"
            size="large"
            onClick={onDiscard}
            style={{ marginRight: "0.5rem" }}
          >
            Discard Changes
          </Button>
        )}
        {onSave && (
          <Button size="large" onClick={onSave}>
            Save
          </Button>
        )}
      </div>
      {date && time && author && (
        <div
          style={{
            color: "#666",
            marginTop: "0.5rem",
            textAlign: "right",
            fontSize: "0.875rem",
          }}
        >
          Last published: {date} {time} by&nbsp;
          {author}
        </div>
      )}
    </div>
  );
}

export default Header;
