import React, {useState, useEffect} from "react";
import {DatePicker, Dropdown, Icon} from "antd";
import {T} from "components/elements";
import {strings} from "common/constants";
import {useDeepEffect} from "hooks";
import moment from "moment";
import classnames from "classnames";
import {Condition} from "../Condition";
import styles from "./dropdowns.module.css";

const initialState = {
  startValue: null,
  endValue: null,
  endOpen: false,
  visible: false,
  interval: "weeks",
};

/**
 * A component for selecting a date range
 * @memberof Components.Fragments
 * @param {"days" | "weeks" | "months"} props.initialInterval - The initial interval to swap from with the arrow buttons
 * @param {moment} props.initialDateValues - The initial date range displayed
 * @param {string} props.className - The css class to apply to the container
 * @param {function} props.onChange -
 * @param {object} props.form - If DateRangeDropdown is being used in a form, the form object to pull it's initial date values from
 * @param {object} props.field - The form field object to update when date changes
 * @param {string} props.label - The text to display on top of the component
 * @returns {React.Element} - Returns a component to select a date range
 */

const DateRangeDropdown = ({
  initialInterval,
  initialDateValues,
  className,
  onChange,
  form,
  field,
  label,
}) => {
  const getStateFromInitialValues = () => {
    if (
      initialDateValues?.createdAt.start &&
      initialDateValues?.createdAt.end
    ) {
      return {
        ...initialState,
        startValue: initialDateValues?.createdAt.start,
        endValue: initialDateValues?.createdAt.end,
        interval: initialInterval || initialState.interval,
      };
    }
    if (
      form?.values.filters.createdAt?.start &&
      form?.values.filters.createdAt?.start
    ) {
      return {
        ...initialState,
        startValue: moment(form?.values.filters.createdAt.start),
        endValue: moment(form?.values.filters.createdAt.end),
      };
    }
    if (
      form?.values.filters.scheduledAt?.start &&
      form?.values.filters.scheduledAt?.start
    ) {
      return {
        ...initialState,
        startValue: moment(form?.values.filters.scheduledAt.start),
        endValue: moment(form?.values.filters.scheduledAt.end),
        interval: initialInterval || initialState.interval,
      };
    }
    return initialState;
  };

  const [dropdownState, setDropdownState] = useState(getStateFromInitialValues);

  const {startValue, endValue, endOpen, visible, interval} = dropdownState;
  const formattedStart = startValue && startValue.format();
  const formattedEnd = endValue && endValue.format();
  useEffect(() => {
    if (startValue && endValue) {
      if (form) {
        form.setFieldValue(field.name, {
          start: startValue.startOf("day").format(),
          end: endValue.endOf("day").format(),
        });
      } else {
        onChange({
          createdAt: {
            start: startValue.startOf("day").format(),
            end: endValue.endOf("day").format(),
          },
        });
      }
    }
  }, [startValue, endValue, formattedStart, formattedEnd]);

  useDeepEffect(() => {
    setDropdownState(getStateFromInitialValues());
  }, [form?.values.filters.createdAt]);

  const disabledEndDate = (nextEndValue) => {
    const {startValue} = dropdownState;
    if (!nextEndValue || !startValue) {
      return false;
    }
    return nextEndValue.valueOf() <= startValue.valueOf();
  };

  const onStartChange = (value) => {
    setDropdownState((prevState) => ({
      ...prevState,
      startValue: value,
      interval: null,
      endOpen: true,
    }));
  };

  const onEndChange = (value) => {
    setDropdownState((prevState) => ({
      ...prevState,
      endValue: value,
      interval: null,
      visible: false,
      endOpen: false,
    }));
  };

  const handleStartOpenChange = (open) => {
    if (!open) {
      setDropdownState((prevState) => ({
        ...prevState,
        endOpen: true,
      }));
    } else {
      setDropdownState({
        endOpen: false,
        endValue: null,
      });
    }
  };

  const handleEndOpenChange = () => {
    setDropdownState((prevState) => ({
      ...prevState,
      endOpen: true,
    }));
  };

  const handleVisibleChange = (flag) => {
    setDropdownState((prevState) => ({
      ...prevState,
      visible: flag,
      endOpen: false,
    }));
  };

  const setPresetRange = (start, end, interval) => {
    setDropdownState({
      startValue: start,
      endValue: end,
      interval,
      visible: false,
      endOpen: false,
    });
  };

  const handleThisWeek = () => {
    setPresetRange(
      moment().startOf("isoWeek"),
      moment().endOf("isoWeek"),
      "weeks",
    );
  };

  const handleThisMonth = () => {
    setPresetRange(
      moment().utc().startOf("month"),
      moment().utc().endOf("month"),
      "months",
    );
  };

  const handleYesterday = () => {
    setPresetRange(
      moment().subtract(1, "day").startOf("day"),
      moment().subtract(1, "day").endOf("day"),
      "days",
    );
  };

  const handleLastWeek = () => {
    setPresetRange(
      moment().subtract(1, "week").startOf("isoWeek"),
      moment().subtract(1, "week").endOf("isoWeek"),
      "weeks",
    );
  };

  const handleLastMonth = () => {
    setPresetRange(
      moment().utc().subtract(1, "month").startOf("month"),
      moment().utc().subtract(1, "month").endOf("month"),
      "months",
    );
  };

  const handleToday = () => {
    setPresetRange(moment().startOf("day"), moment().endOf("day"), "days");
  };

  const onStep = (direction) => {
    if (startValue && endValue) {
      setDropdownState((prevState) => ({
        ...prevState,
        startValue:
          direction === strings.DATE_RANGE.INCREMENT
            ? interval === strings.DATE_RANGE.QUARTERS
              ? startValue.add(3, "months")
              : startValue.add(1, interval)
            : interval === strings.DATE_RANGE.QUARTERS
            ? startValue.subtract(3, "months")
            : startValue.subtract(1, interval),
      }));
      setDropdownState((prevState) => ({
        ...prevState,
        endValue:
          direction === strings.DATE_RANGE.INCREMENT
            ? interval === strings.DATE_RANGE.QUARTERS
              ? endValue.add(3, "months")
              : endValue.add(1, interval)
            : interval === strings.DATE_RANGE.QUARTERS
            ? endValue.subtract(3, "months")
            : endValue.subtract(1, interval),
      }));
    }
  };

  const DatePickerInputs = (
    <div className={classnames(styles.dateDropdown, "dateDropdown")}>
      <div className={styles.dateIntervals}>
        <div
          role="button"
          onClick={handleToday}
          className={styles.intervalItems}
        >
          Today
        </div>
        <div
          role="button"
          onClick={handleYesterday}
          className={styles.intervalItems}
        >
          Yesterday
        </div>
        <div
          role="button"
          onClick={handleThisWeek}
          className={styles.intervalItems}
        >
          This Week
        </div>
        <div
          role="button"
          onClick={handleLastWeek}
          className={styles.intervalItems}
        >
          Last Week
        </div>
        <div
          role="button"
          onClick={handleThisMonth}
          className={styles.intervalItems}
        >
          This Month
        </div>
        <div
          role="button"
          onClick={handleLastMonth}
          className={styles.intervalItems}
        >
          Last Month
        </div>
      </div>

      <div className="analyticsDatePickers">
        <div className={styles.customRangeTitle}>Custom Range</div>
        <DatePicker
          size="small"
          format="M/D/YY"
          value={startValue}
          placeholder="Start"
          onChange={onStartChange}
          onOpenChange={handleStartOpenChange}
          allowClear={false}
        />
        <DatePicker
          disabledDate={disabledEndDate}
          size="small"
          format="M/D/YY"
          value={endValue}
          placeholder="End"
          onChange={onEndChange}
          open={endOpen}
          onOpenChange={handleEndOpenChange}
          allowClear={false}
        />
      </div>
    </div>
  );

  return (
    <>
      {label && <T label>{label}</T>}
      <div className={classnames(styles.buttonWrapper, className)}>
        <div
          onClick={() => onStep("decrement")}
          className={
            interval ? styles.decrementButton : styles.hideDecrementButton
          }
        >
          <Condition.Condition is={interval}>
            <Icon type="left" />
          </Condition.Condition>
        </div>
        <Dropdown
          placement="bottomCenter"
          trigger={["click"]}
          overlay={DatePickerInputs}
          onVisibleChange={handleVisibleChange}
          visible={visible}
        >
          <div className={interval ? styles.dateButton : styles.hideDateButton}>
            {startValue && endValue && (
              <span>
                {startValue && startValue.utc().format("M/D/YY")} -{" "}
                {endValue && endValue.utc().format("M/D/YY")}
              </span>
            )}
            {!startValue && !endValue && <span>Select a Date Range</span>}
          </div>
        </Dropdown>
        <div
          onClick={() => onStep("increment")}
          className={
            interval ? styles.incrementButton : styles.hideIncrementButton
          }
        >
          <Condition.Condition is={interval}>
            <Icon type="right" />
          </Condition.Condition>
        </div>
      </div>
    </>
  );
};

export default DateRangeDropdown;
