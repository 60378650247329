import * as React from "react";
import moment from "moment";
import { message } from "antd";
import { useMutation, useQuery } from "react-query";
import { ButtonStyle } from "@lunchboxinc/dynamic-web/dist/types";
import {
  ComponentConfig,
  ComponentStyle,
  defaultConfig,
  defaultStyles,
} from "@lunchboxinc/dynamic-web/dist/LocationCard";
import { useUserContext } from "components/providers/User";
import { useAPIContext } from "components/providers/Api";
import { DialogProps } from "../components/Dialog";

interface LocationCardContext {
  componentConfig: ComponentConfig;
  componentStyles: ComponentStyle;
  dialog: DialogProps;
  discard(): void;
  handleConfigChange: InputChangeType;
  handleStylesChange: InputChangeType;
  isLoading: boolean;
  publish(): void;
  publishData: {
    dirty: Boolean;
    date: string;
    author: string;
    time: string;
    remainder: number;
  };
  save(): void;
  updateButtonStyle(buttonStyle: ButtonStyle): void;
}

export const DEFAULT_CONTEXT = {
  componentConfig: defaultConfig,
  componentStyles: defaultStyles,
  dialog: {},
  discard: () => null,
  handleConfigChange: () => null,
  handleStylesChange: () => null,
  isLoading: true,
  publish: () => null,
  publishData: {
    dirty: false,
    date: "",
    author: "",
    time: "",
    remainder: 3,
  },
  save: () => null,
  updateButtonStyle: () => null,
};

export const LocationCardContext =
  React.createContext<LocationCardContext>(DEFAULT_CONTEXT);

/**
 * A function that take in 2 params, which will update a state when the value is an object.
 * This will work for radio buttons and checkbox
 */
const handleChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  setStateFn: React.Dispatch<React.SetStateAction<unknown>>,
  type: "input" | "checkbox" | "radio",
) => {
  const { name, checked, value } = e.target;

  let newState: unknown = value;

  if (type === "checkbox") {
    newState = checked;
  }

  if (name && typeof setStateFn === "function") {
    setStateFn((prevState: any) => ({
      ...prevState,
      [name]: newState,
    }));
  }

  return null;
};

export const useLocationCard = () => {
  const ctx = React.useContext(LocationCardContext);

  if (!ctx)
    throw new Error(
      "Cannot use LocationCard Context outside of a LocationCard Provider.",
    );

  return ctx;
};

export function LocationCardProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { user } = useUserContext();
  const { AppConfigurations } = useAPIContext();
  const { getLocationCardPreview, saveLocationCard, publishLocationCard } =
    AppConfigurations;

  const [dialog, setDialog] = React.useState<DialogProps>({});

  const closeDialog = () => setDialog({});

  const openDialog = ({ secondaryCancel = false, ...args }) =>
    setDialog({
      ...args,
      onOpenChange: (isOpen) => !isOpen && closeDialog(),
      ...(secondaryCancel && {
        secondaryButtonProps: {
          children: "Cancel",
          onClick: () => closeDialog(),
        },
      }),
    });

  const [componentConfig, setComponentConfig] =
    React.useState<ComponentConfig>(defaultConfig);
  const [componentStyles, setComponentStyles] =
    React.useState<ComponentStyle>(defaultStyles);

  const [publishData, setPublishData] = React.useState({
    dirty: false,
    date: "",
    author: "",
    time: "",
    remainder: 3,
  });

  const cleanDialog = () =>
    openDialog({
      title: "Nice Try",
      content:
        "There are no changes. We're sometimes tempted to push buttons to see what might happen too.",
      primaryButtonProps: {
        children: "You Got Me",
        onClick: () => closeDialog(),
      },
    });

  const updateButtonStyle = (buttonStyle: ButtonStyle) => {
    setComponentStyles((prev) => ({
      ...prev,
      buttonStyle,
    }));
    setPublishData((prev) => ({
      ...prev,
      dirty: true,
    }));
  };

  const handleStylesChange = (e) => {
    handleChange(e, setComponentStyles, "input");
    setPublishData((prev) => ({
      ...prev,
      dirty: true,
    }));
  };

  const handleConfigChange = (e) => {
    handleChange(e, setComponentConfig, "checkbox");
    setPublishData((prev) => ({
      ...prev,
      dirty: true,
    }));
  };

  const { isLoading, refetch } = useQuery(
    "getLocationCardPreview",
    async () => await getLocationCardPreview(user.id),
    {
      refetchOnWindowFocus: false,
      onSuccess: ({ data }) => {
        // if components return null read the default values
        if (!data?.components) {
          setComponentStyles(defaultStyles);
          setComponentConfig(defaultConfig);
        }
        const {
          buttonStyle,
          primaryButtonBackgroundColor,
          primaryButtonTextColor,
          secondaryButtonBackgroundColor,
          secondaryButtonTextColor,
          showAddress,
          showDeliveryHours,
          showPhoneNumber,
          showPickUpHours,
          showStoreHours,
          showWaitTime,
        } = data?.components?.componentConfigs;

        setComponentConfig({
          showAddress,
          showDeliveryHours,
          showPhoneNumber,
          showPickUpHours,
          showStoreHours,
          showWaitTime,
        });
        setComponentStyles({
          buttonStyle,
          primaryButtonBackgroundColor,
          primaryButtonTextColor,
          secondaryButtonBackgroundColor,
          secondaryButtonTextColor,
        });

        const lastDeploymentDate = data?.recentDeploymentAudits?.[0];
        if (!lastDeploymentDate) return;
        const { createdAt = new Date(), admin = null } = lastDeploymentDate;
        const authorName = `${admin?.firstName} ${admin?.lastName}`;
        const date = new Date(createdAt);
        const monthName = moment().month(date.getUTCMonth()).format("MMM");
        const day = moment(date).date();
        const year = moment(date).year();

        const lastPublishedTime = date.toLocaleString();
        setPublishData((prev) => ({
          ...prev,
          date: `${monthName} ${day}, ${year}`,
          author: authorName,
          time: lastPublishedTime,
          remainder: data?.dailyDeploymentsCount,
        }));
      },
    },
  );

  const discard = () => {
    if (!publishData?.dirty) {
      return cleanDialog();
    }
    return openDialog({
      content: "Do you want to discard your changes?",
      primaryButtonProps: {
        children: "Discard",
        // TODO: Use state management to restore prior state
        onClick: () => {
          setPublishData((prev) => ({
            ...prev,
            dirty: false,
          }));
          refetch();
          closeDialog();
        },
      },
      secondaryCancel: true,
    });
  };

  const { mutate: publishMutation } = useMutation(
    async () =>
      await publishLocationCard({
        emailAddress: user?.email?.value || "",
        env: process.env.REACT_APP_ENV,
        branchName: "master",
      }),
    {
      onMutate: () => message.loading("Publishing..."),
      onError: (err) => {
        console.error(err);
        message.error("Publish failed! Please try again or contact support.");
      },
      onSuccess: ({ data }) => {
        if (data?.state === "setup-pending") {
          message.loading("Please wait...");
        } else {
          message.error(`Publish returned ${data?.state} state.`);
        }
      },
    },
  );

  const publish = () => {
    if (publishData?.remainder) {
      return openDialog({
        title: "Publish Changes",
        content: `Publishing will make all changes public. ${publishData?.remainder} publish actions are left before today's limit is reached. Do you want to proceed?`,
        primaryButtonProps: {
          children: "Publish",
          onClick: () => {
            closeDialog();
            saveMutation();
            publishMutation();
          },
        },
        secondaryCancel: true,
      });
    }
    return openDialog({
      title: "Daily Publish Limit Reached",
      content:
        "The limit of 3 publish actions per day has been reached. Please try again tomorrow.",
      primaryButtonProps: {
        children: "OK",
        onClick: () => closeDialog(),
      },
    });
  };

  const { mutate: saveMutation } = useMutation(
    async () =>
      await saveLocationCard({
        source: "figma",
        type: "LocationCard",
        fileId: "aQQJJ4ER0429QIHjAkoG7n",
        componentConfigs: { ...componentStyles, ...componentConfig },
        structureData: {
          LocationCard: "",
        },
      }),
    {
      onMutate: () => message.loading("Saving..."),
      onError: (err) => {
        console.error(err);
        message.error("Saving failed! Please try again or contact support.");
      },
      onSuccess: () => {
        setPublishData((prev) => ({
          ...prev,
          dirty: false,
        }));
        message.success("Configurations saved.");
      },
    },
  );

  const save = () => {
    if (publishData?.dirty) {
      return saveMutation();
    }
    return cleanDialog();
  };

  return (
    <LocationCardContext.Provider
      value={{
        componentConfig,
        componentStyles,
        dialog,
        discard,
        handleConfigChange,
        handleStylesChange,
        isLoading,
        publish,
        publishData,
        save,
        updateButtonStyle,
      }}
    >
      {children}
    </LocationCardContext.Provider>
  );
}
