import React from "react";
import {Helmet} from "react-helmet";

export default (Component, title) => (props) =>
  (
    <>
      <Helmet>
        <title>{`${title} | Lunchbox Admin`}</title>
      </Helmet>
      <Component {...props} />
    </>
  );
