import React from "react";
import {Tables} from "components/fragments";
import {Price, Number, Row} from "components/elements";

const priceHelper = (val = 0) => <Price align="right" value={val || 0} />;
const numberHelper = (val = 0) => <Number align="right" value={val || 0} />;

const {Table} = Tables;

const LocationDetailsTables = ({fetching, locationObj}) => {
  return (
    <>
      <Row spacing={45}>
        <Table
          horizontalScrollSize="large"
          size="small"
          striped
          dataSource={[locationObj]}
          pagination={false}
          loading={fetching}
          className="sales-reports-table"
          columns={[
            {
              title: "Location",
              dataIndex: "name",
            },
            {
              title: "Subscribers",
              dataIndex: "activeSubscriptions",
              render: numberHelper,
            },
            {
              title: "Cancelled Subscribers",
              dataIndex: "cancelledSubscriptions",
              render: numberHelper,
            },
            {
              title: "Avg Quantity",
              dataIndex: "averageTokenQuantity",
              render: numberHelper,
            },
            {
              title: "Total Items",
              dataIndex: "totalTokens",
              render: numberHelper,
            },
            {
              title: "Redeemed Itmes",
              dataIndex: "totalRedeemedTokens",
              render: numberHelper,
            },
            {
              title: "Unredeemed Items",
              dataIndex: "totalUnredeemedTokens",
              render: numberHelper,
            },
            {
              title: "Subtotal",
              dataIndex: "totalSubtotal",
              render: priceHelper,
            },
            {
              title: "Tax",
              dataIndex: "totalTax",
              render: priceHelper,
            },
            {
              title: "Revenue",
              dataIndex: "totalRevenue",
              render: priceHelper,
            },
            {
              title: "Delivery Orders",
              dataIndex: "totalDeliveryOrders",
              render: numberHelper,
            },
            {
              title: "Pick Up Orders",
              dataIndex: "totalPickupOrders",
              render: numberHelper,
            },
          ]}
        />
      </Row>
      <Row spacing={45}>
        <Table
          horizontalScrollSize="large"
          size="small"
          striped
          dataSource={locationObj.programAnalytics}
          pagination={false}
          loading={fetching}
          className="sales-reports-table"
          columns={[
            {
              title: "Program",
              dataIndex: "name",
            },
            {
              title: "Subscribers",
              dataIndex: "subscribers",
              render: numberHelper,
            },
            {
              title: "Avg Quantity",
              dataIndex: "averageTokenQuantity",
              render: numberHelper,
            },
            {
              title: "Total Items",
              dataIndex: "totalTokens",
              render: numberHelper,
            },
            {
              title: "Redeemed Items",
              dataIndex: "totalRedeemedTokens",
              render: numberHelper,
            },
            {
              title: "Unredeemed Items",
              dataIndex: "totalUnredeemedTokens",
              render: numberHelper,
            },
            {
              title: "Subtotal",
              dataIndex: "totalSubtotal",
              render: priceHelper,
            },
            {
              title: "Tax",
              dataIndex: "totalTax",
              render: priceHelper,
            },
            {
              title: "Revenue",
              dataIndex: "totalRevenue",
              render: priceHelper,
            },
          ]}
        />
      </Row>
      <Row spacing={45}>
        <Table
          horizontalScrollSize="large"
          size="small"
          striped
          dataSource={locationObj.billingDurationAnalytics}
          pagination={false}
          loading={fetching}
          className="sales-reports-table"
          columns={[
            {
              title: "Duration",
              dataIndex: "name",
            },
            {
              title: "Subscribers",
              dataIndex: "subscribers",
              render: numberHelper,
            },
            {
              title: "Avg Quantity",
              dataIndex: "averageTokenQuantity",
              render: numberHelper,
            },
            {
              title: "Total Items",
              dataIndex: "totalTokens",
              render: numberHelper,
            },
            {
              title: "Redeemed Items",
              dataIndex: "totalRedeemedTokens",
              render: numberHelper,
            },
            {
              title: "Unredeemed Items",
              dataIndex: "totalUnredeemedTokens",
              render: numberHelper,
            },
            {
              title: "Subtotal",
              dataIndex: "totalSubtotal",
              render: priceHelper,
            },
            {
              title: "Tax",
              dataIndex: "totalTax",
              render: priceHelper,
            },
            {
              title: "Revenue",
              dataIndex: "totalRevenue",
              render: priceHelper,
            },
          ]}
        />
      </Row>
    </>
  );
};

export {LocationDetailsTables};
