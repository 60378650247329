import React from "react";
import { Link } from "react-router-dom";
import { List } from "antd";
import { Price } from "components/elements";
import css from "./orderItems.module.css";

const renderItem = (mod) => (
  <List.Item key={mod.itemName}>
    <Link to={`/item/${mod.item}`}>{mod.itemName}</Link>
    <Price className={css.orderItemPrice} inline value={mod.price} />
    {mod.quantity > 1 && (
      <div>
        <span>x {mod.quantity}</span>
        <Price
          className={css.orderItemPrice}
          inline
          value={mod.price * mod.quantity}
        />
      </div>
    )}
    {mod.modifications.length > 0 && (
      <div className={css.nestedModifications}>
        <List
          dataSource={mod.modifications}
          size="small"
          itemLayout="vertical"
          renderItem={renderItem}
        />
      </div>
    )}
  </List.Item>
);

export const modPrice = (mods) =>
  mods.reduce((acc, curr) => {
    return acc + (curr.price + modPrice(curr.modifications)) * curr.quantity;
  }, 0);

const OrderItem = ({
  description,
  item,
  modifications,
  name,
  notes,
  quantity,
  unitPrice,
}) => {
  return (
    <List.Item key={name} className={css.orderListItem}>
      <List.Item.Meta
        title={
          <div>
            {item ? (
              <Link to={`/item/${item.id}`}>{name}</Link>
            ) : (
              <span>{name}</span>
            )}
            <div className={css.itemPrice}>
              <Price value={(unitPrice + modPrice(modifications)) * quantity} />
            </div>
          </div>
        }
        description={
          <>
            <div className={css.itemDetails}>
              <span>{`Quantity: ${quantity}`}</span>
            </div>
            <div className={css.itemDetails}>
              <span>{`Unit Price: $${unitPrice}`}</span>
            </div>
            <div className={css.itemDescription}>
              <span>{description}</span>
            </div>
          </>
        }
      />
      {modifications.length > 0 && (
        <>
          <p>Modifications</p>
          <List
            dataSource={modifications}
            size="small"
            itemLayout="vertical"
            renderItem={renderItem}
          />
        </>
      )}
      {notes && (
        <div className={css.itemNotes}>
          <span className={css.itemNotesLabel}>Notes: </span>
          <span>{notes}</span>
        </div>
      )}
    </List.Item>
  );
};

OrderItem.defaultProps = {
  description: "",
  item: {},
  modifications: [],
  name: "",
  notes: "",
  quantity: 1,
  unitPrice: 0,
};

export default OrderItem;
