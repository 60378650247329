import { permissions } from "common/constants";
import { usePermission } from "hooks";

const {
  methods: { GET },
} = permissions;

const Permission = ({
  requiredPermissions,
  matchOne,
  yes,
  no,
  isRoute = undefined,
  isNav,
}) => {
  let requiredPermissionsArray;
  if (typeof requiredPermissions === "object") {
    if (requiredPermissions.length) {
      requiredPermissionsArray = requiredPermissions.map((permission) => {
        if (typeof permission === "object") return permission;
        return {
          method: GET,
          url: permission,
        };
      });
    } else if (Object.entries(requiredPermissions).length) {
      requiredPermissionsArray = [requiredPermissions];
    }
  } else {
    requiredPermissionsArray = [
      {
        method: GET,
        url: requiredPermissions,
      },
    ];
  }

  const hasRequiredPermission = usePermission({
    requiredPermissions: requiredPermissionsArray,
    matchOne,
    isRoute,
    isNav,
  });

  return hasRequiredPermission ? yes() : no();
};

Permission.defaultProps = {
  yes: () => null,
  no: () => null,
};

export { Permission };

export default { Permission };
