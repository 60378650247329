import React from "react";
import {Col} from "antd";
import {Row, Price} from "components/elements";

/**
 * Renders the label and the price of an item in a row
 * @author Htin Linn Aung
 * @memberof Templates.Templates/PaymentSummary
 * @param {Object} props
 * @param {string} props.label - Field label
 * @param {string} props.value - Field value
 * @param {string} props.className - Field style classname
 * @param {React.Element} props.info - Any jsx to include to the right of the label
 * @returns {React.Element} - A row with a label and a price amount of an item
 */

const PaymentSummaryRow = ({label, value, className, promo, info}) => (
  <Row spacing={10} className={className}>
    <Col md={18} sm={16} xs={14}>
      {label}
      &nbsp;
      {info}
    </Col>
    <Col md={6} sm={8} xs={10} style={{textAlign: "right"}}>
      <em>{promo}</em>
      &nbsp;
      <Price inline value={value} />
    </Col>
  </Row>
);

export default PaymentSummaryRow;
