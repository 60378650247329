import React from "react";
import {SaveToggle} from "../Toggle";

const EntityToggle = (props) => (
  <SaveToggle
    className="largeToggle"
    checkedChildren="Enabled Everywhere"
    unCheckedChildren="Disabled Everywhere"
    field="isActive"
    {...props}
  />
);
export default EntityToggle;
