import styled from "styled-components";
import {Card} from "antd";

const Grid = styled(Card.Grid)`
  &&& {
    text-align: center;
    width: 25%;
    cursor: pointer;
  }
`;

export default Grid;
