import React from "react";
import classnames from "classnames";
import {Tag} from "components/elements";

import css from "./styles.module.css";

const CANCELLED = "cancelled";
const CONFIRMED = "confirmed";
const IN_PROGRESS = "in_progress";
const SCHEDULED = "scheduled";
const PENDING = "pending";
const UNSCHEDULED = "unscheduled";
const SUSPENDED = "suspended";

const STATUS_COPY = {
  [IN_PROGRESS]: "In Progress",
  [CANCELLED]: "Cancelled",
  [PENDING]: "Pending",
  [UNSCHEDULED]: "Unscheduled",
  [CONFIRMED]: "Confirmed",
  [SCHEDULED]: "Scheduled",
  [SUSPENDED]: "Suspended",
};

const STATUS_CSS = {
  [IN_PROGRESS]: css.inProgress,
  [SCHEDULED]: css.inProgress,
  [CANCELLED]: css.cancelled,
  [PENDING]: css.pending,
  [UNSCHEDULED]: css.pending,
  [SUSPENDED]: css.pending,
};

const availabilityStatusSelector = (status) => {
  const returnedCSS = STATUS_CSS[status];

  if (returnedCSS) return returnedCSS;

  if (status === true) {
    return css.inProgress;
  }

  return css.completed;
};

const availabilityStatusTextSelector = (status) => {
  const returnedCopy = STATUS_COPY[status];

  if (returnedCopy) return returnedCopy;

  if (status === true) {
    return STATUS_COPY[IN_PROGRESS];
  }

  return "Completed";
};

const SubscriptionTag = ({status, right}) => {
  const statusCSS = availabilityStatusSelector(status);
  const classes = right ? classnames(statusCSS, css.right) : statusCSS;
  return (
    <Tag className={classes}>{availabilityStatusTextSelector(status)}</Tag>
  );
};

export default SubscriptionTag;
