import React, {useState, useEffect} from "react";
import {Col} from "antd";
import moment from "moment";
import {DateRangeDropdown} from "components/fragments";
import {Button, Titles} from "components/elements";
import classnames from "classnames";
import {DEFAULT_TITLE} from "./subscriptionConsts";

import css from "../../Analytics/analytics.module.css";

const {BlueCardTitle} = Titles;

const SubscriptionHeader = ({
  actions,
  title,
  exportCSV,
  submitting,
  params,
}) => {
  const {start, end} = params.createdAt || {};
  return (
    <div className={css.headerWrapper}>
      <Col xs={24} sm={24} md={24} lg={8}>
        <BlueCardTitle className={css.title}>{title}</BlueCardTitle>
      </Col>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={16}
        className={classnames(css.filters, "headerFilters")}
      >
        <DateRangeDropdown
          className={css.datePicker}
          onChange={actions.onCreatedAtChange}
          initialDateValues={{
            createdAt: {
              start: moment(start).utc(),
              end: moment(end).utc(),
            },
          }}
          initialInterval="months"
        />
        {title === DEFAULT_TITLE && (
          <Button
            className={css.filterButton}
            onClick={exportCSV}
            disabled={submitting}
            color="blue"
            size="small"
            height="40px"
          >
            Export CSV
          </Button>
        )}
      </Col>
    </div>
  );
};

export default SubscriptionHeader;
