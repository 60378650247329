import React from "react";
import {Card} from "antd";
import {Titles} from "components/elements";
import {ResponsivePie} from "@nivo/pie";

const {CardTitle} = Titles;

const SubscriptionGraphBilling = ({data = []}) => {
  return (
    <Card>
      <div style={{height: "400px"}}>
        <CardTitle>Subscription Data</CardTitle>
        <ResponsivePie
          data={data}
          margin={{top: 40, right: 80, bottom: 80, left: 80}}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          colors={[
            "var(--button-blue)",
            "#43B9F7",
            "#8CE3DA",
            "#108dff",
            "#7678ad",
          ]}
          borderWidth={1}
          borderColor={{from: "color", modifiers: [["darker", 0.2]]}}
          radialLabelsSkipAngle={10}
          radialLabelsTextColor="#333333"
          radialLabelsLinkColor={{from: "color"}}
          sliceLabelsSkipAngle={10}
          sliceLabelsTextColor="#333333"
          defs={[
            {
              id: "dots",
              type: "patternDots",
              background: "inherit",
              color: "rgba(255, 255, 255, 0.3)",
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: "lines",
              type: "patternLines",
              background: "inherit",
              color: "rgba(255, 255, 255, 0.3)",
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          legends={[
            {
              anchor: "bottom-left",
              direction: "column",
              justify: false,
              translateX: -50,
              translateY: 25,
              itemsSpacing: 5,
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: "#999",
              itemDirection: "left-to-right",
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: "circle",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: "#000",
                  },
                },
              ],
            },
          ]}
        />
      </div>
    </Card>
  );
};

export {SubscriptionGraphBilling};
