import React from "react";
import { Button as AntButton } from "antd";
import classnames from "classnames";
import styles from "./button.module.css";

const colorClass = (color: string) => {
  switch (color) {
    case "transparentRed":
      return styles.transparentRed;
    case "transparent":
      return styles.transparent;
    case "blue":
      return styles.blue;
    case "white":
      return styles.white;
    case "green":
      return styles.green;
    case "red":
      return styles.red;
    case "yellow":
      return styles.yellow;
    default:
      return "";
  }
};

const sizeClass = (size: string) => {
  switch (size) {
    case "extraSmall":
      return styles.extraSmall;
    case "small":
      return styles.small;
    case "medium":
      return styles.medium;
    case "large":
      return styles.large;
    default:
      return "";
  }
};

interface ButtonParam {
  children?: any;
  color?: string;
  className?: string;
  size?: string;
  marginLeft?: string;
  borderless?: string;
  width?: string;
  height?: string;
  float?: string;
  onClick(e: React.MouseEvent<HTMLButtonElement>): void;
}

/**
 * Wrapper for the antd button component with props to apply inhouse styling
 * @memberof Components.Elements
 * @prop {JSX.Element | JSX.Element[]} props.children - Children components
 * @prop {"transparentRed" | "transparent" | "blue" | "white" | "green" | "red"} props.color - Applies css color styles
 * @prop {string} props.className - Applies a css class to the button
 * @prop {"extraSmall" | "small" | "medium" | "large"} props.size - Applies css size styles
 * @prop {boolean} props.marginLeft - Will give button a 5px left margin
 * @prop {boolean} props.borderless - Will remove button border
 * @prop {integer} props.width - Will apply pixel width to button
 * @prop {integer} props.height - Will apply pixel height to button
 * @prop {"left" | "right"} props.float - Will apply a flot to button
 * Props are spread, all antd 3.x button props will work
 * @returns {React.Element} - Returns a button element
 */

function Button({
  children,
  color,
  className = "",
  size,
  marginLeft = undefined,
  borderless = undefined,
  width = undefined,
  height = undefined,
  float = undefined,
  ...props
}: ButtonParam) {
  const classes = classnames(
    colorClass(color!),
    sizeClass(size!),
    marginLeft && styles.marginLeft,
    borderless && styles.borderless,
    styles.button,
    float === "right" ? styles.floatRight : null,
    float === "left" ? styles.floatLeft : null,
    className,
  );

  return (
    <AntButton
      {...props}
      type="default"
      className={classes}
      style={{ width, height }}
    >
      {children}
    </AntButton>
  );
}

Button.defaultProps = {
  color: "transparent",
};

export default Button;
