import React, {useState} from "react";
import {Icon, Dropdown, Menu, message} from "antd";
import {Button} from "components/elements";
import {ConfirmationButton} from "components/fragments/ConfirmationButton";
import {useAPIContext} from "components/providers/Api";

const EntityAvailabilityDropdown = ({
  selectedIds,
  locationId,
  listType,
  onSuccess,
  requiredPermissions,
}) => {
  const {MenuEntities} = useAPIContext();

  const [fetching, setFetching] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(true);

  const postAvailability = async (available) => {
    setFetching(true);
    try {
      const data = {
        availability: available,
        ...(locationId !== undefined && {location: locationId}),
        [listType]: selectedIds,
      };
      await MenuEntities.updateAvailability(data);
      onSuccess();
      message.success("Successfully Saved!");
    } catch (e) {
      message.error("An error occurred");
    } finally {
      setFetching(false);
    }
  };

  const onTrigger = () => {
    if (isDropdownVisible) {
      setIsConfirmationVisible(false);
      setIsDropdownVisible(false);
    } else {
      setIsConfirmationVisible(true);
    }
  };

  const onConfirm = () => {
    setIsDropdownVisible(true);
  };

  const onMakeAvailable = () => {
    if (!fetching) {
      postAvailability(true);
    }
  };
  const onMakeUnavailable = () => {
    if (!fetching) {
      postAvailability(false);
    }
  };

  function handleMenuClick(e) {
    if (e.key === "1") {
      onMakeAvailable();
      setIsDropdownVisible(false);
    } else {
      onMakeUnavailable();
      setIsDropdownVisible(false);
    }
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">Make Available</Menu.Item>
      <Menu.Item key="2">Make Unavailable</Menu.Item>
    </Menu>
  );

  if (!requiredPermissions) return null;
  return (
    <ConfirmationButton
      placement="bottom"
      title="This effect will affect all locations. Are you sure?"
      okText="Yes"
      isConditional={!locationId}
      onConfirm={onConfirm}
      visible={isConfirmationVisible}
    >
      <Dropdown
        visible={isDropdownVisible}
        disabled={!selectedIds[0] || fetching}
        overlay={menu}
        trigger="click"
      >
        <Button size="small" onClick={onTrigger}>
          Actions <Icon type="down" />
        </Button>
      </Dropdown>
    </ConfirmationButton>
  );
};

export default EntityAvailabilityDropdown;
