import { useLocation } from "react-router-dom";

/**
 * Returns whether the URL includes a feature flag.
 *
 * Behaviors:
 * -
 */
export default function useFeatureFlag(flag: string) {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  return params.get("flags")?.includes(flag);
}
