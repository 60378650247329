import React, { useEffect, useState } from "react";
import { Col, Form } from "antd";
import { Formik, Field } from "formik";
import { useAPI } from "hooks";
import { Row, Button, Titles, InfoPopoverIcon } from "components/elements";
import { Tables, Drawers } from "components/fragments";
import { Fields } from "components";
import remove from "lodash/remove";
import uniqBy from "lodash/uniqBy";

import css from "./entities.module.css";

const { Table } = Tables;
const { ResDrawer } = Drawers;

const paginationObj = (skip, limit, total) => ({
  current: (Number(skip) + Number(limit)) / Number(limit),
  pageSize: Number(limit),
  total: Number(total),
});

const defaultQuery = {
  filters: {
    name: "",
    city: "",
    state: "",
    isActive: "true",
  },
  skip: 0,
  limit: 20,
};

const AddLocations = ({ addEntities, onSuccess }) => {
  const [selectedIds, setselectedIds] = useState([]);
  const {
    resource,
    fetching = true,
    data,
    setData,
  } = useAPI("Locations.readMany", defaultQuery);

  let total = 0;
  let results;
  if (resource) {
    if (resource.total) {
      ({ total } = resource);
    }
    ({ results } = resource);
  }

  const onPageChange = ({ current }) => {
    setData({ ...data, skip: (current - 1) * data.limit });
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedItems) => {
      setselectedIds(selectedItems);
    },
  };

  return (
    <>
      <Row spacing={40}>
        <Formik
          initialValues={data}
          onSubmit={(values, formActions) => {
            setData({ ...data, ...values });
            formActions.setSubmitting(false);
          }}
          render={(subFormik) => (
            <Form
              colon={false}
              onSubmit={(e) => {
                e.stopPropagation();
                subFormik.handleSubmit(e);
              }}
            >
              <Row gutter={15} spacing={15}>
                <Col xs={24} md={12}>
                  <Field
                    label="Name"
                    name="filters.name"
                    placeholder="Name"
                    component={Fields.Input}
                  />
                </Col>
              </Row>

              <Row spacing={15} gutter={15}>
                <Col xs={12} lg={6}>
                  <Button
                    htmlType="submit"
                    disabled={subFormik.isSubmitting}
                    color="blue"
                  >
                    Search
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        />
      </Row>

      <Row spacing={40}>
        <Row>
          <Col xs={6}>
            <Titles.CardTitle>
              <span className={css.capitalize}>Locations</span>
            </Titles.CardTitle>
          </Col>
          <Col xs={18} className={css.rightAlign}>
            <Formik
              onSubmit={async () => {
                addEntities(selectedIds);
                onSuccess();
              }}
              render={(subFormik) => (
                <Form
                  colon={false}
                  onSubmit={(e) => {
                    e.stopPropagation();
                    subFormik.handleSubmit(e);
                  }}
                >
                  <Button
                    size="small"
                    htmlType="submit"
                    disabled={!selectedIds[0] || subFormik.isSubmitting}
                    color="blue"
                  >
                    <span className={css.upperCase}>Add Locations</span>
                  </Button>
                </Form>
              )}
            />
          </Col>
        </Row>
        <Col>
          <Tables.Table
            rowSelection={rowSelection}
            dataSource={results}
            pagination={paginationObj(data.skip, data.limit, total)}
            onChange={onPageChange}
            loading={fetching}
          >
            <Tables.Table.Column title="Name" dataIndex="name" />
            <Tables.Table.Column title="GUID" dataIndex="externalId" />
          </Tables.Table>
        </Col>
      </Row>
    </>
  );
};

const LocationSelector = ({
  locations: initialLocations = [],
  addLocations,
  title,
  description,
  button,
  loading,
  setLocations,
}) => {
  const [locations, setLocation] = useState([...initialLocations]);
  const [selectedIds, setSelectedIds] = useState([]);

  const removeItems = () => {
    const arr = [...locations];
    for (let i = 0; i < selectedIds.length; i++) {
      remove(arr, { id: selectedIds[i] });
    }
    setLocation(arr);
    setLocations(arr);
    setSelectedIds([]);
  };

  const addItems = (newLocations) => {
    setLocation(uniqBy([...locations, ...newLocations], "id"));
    setLocations(uniqBy([...locations, ...newLocations], "id"));
  };

  const rowSelection = {
    onChange: (selectedRowKeys, newSelectedIds) => {
      let rowIds;
      if (newSelectedIds) {
        rowIds = newSelectedIds.map((row) => row.id);
      }
      setSelectedIds(rowIds);
    },
  };

  useEffect(() => {
    addLocations(
      locations.map((location) => location.id),
      locations,
    );
  }, [locations]);

  return (
    <>
      <Row spacing={15}>
        <Col xs={6}>
          <Titles.SectionTitle>
            {title || "Locations"}
            {description && <InfoPopoverIcon popoverContent={description} />}
          </Titles.SectionTitle>
        </Col>
        <Col xs={18} className={css.rightAlign}>
          <Button
            color="red"
            size="extraSmall"
            disabled={!selectedIds[0]}
            onClick={removeItems}
          >
            Remove
          </Button>
          <ResDrawer
            title="Search for Location"
            placement="right"
            width="50vw"
            button={
              <Button marginLeft color="blue" size="small">
                Add Locations
              </Button>
            }
          >
            {(drawerProps) => (
              <AddLocations
                addEntities={addItems}
                onSuccess={drawerProps.close}
              />
            )}
          </ResDrawer>
        </Col>
      </Row>
      <Row spacing={40}>
        <Table
          loading={loading}
          rowSelection={rowSelection}
          dataSource={locations}
        >
          <Table.Column title="Name" dataIndex="name" />
          <Table.Column title="GUID" dataIndex="externalId" />
        </Table>
      </Row>
      {button && button()}
    </>
  );
};

export { LocationSelector };
