import * as React from "react";
import { S } from "./styled";

type ToggleSidebarProps = {
  isDisabled: boolean;
  handleClick: () => void;
  minNav: boolean;
  toggleSidebarRef?: React.Ref<HTMLButtonElement>;
};

const ToggleSidebar = ({
  isDisabled,
  handleClick,
  minNav,
  toggleSidebarRef,
}: ToggleSidebarProps) => {
  return (
    <S.ToggleSidebarButton
      aria-label="Show side navigation"
      aria-expanded={!minNav}
      disabled={isDisabled}
      onClick={handleClick}
      ref={toggleSidebarRef}
    >
      <S.Icon icon="bars" fixedWidth />
    </S.ToggleSidebarButton>
  );
};

export { ToggleSidebar };
