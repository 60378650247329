import * as React from "react";

interface LogoProps extends React.HTMLAttributes<SVGElement> {
  fillColor?: string;
}

/**
 * Official Lunchbox Logo Vector
 */
function Logo({ fillColor, ...props }: LogoProps) {
  const fill = fillColor || "#000";

  return (
    <svg
      width="221"
      height="28"
      viewBox="0 0 221 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M91.8786 0.421173C92.0466 0.421173 92.8997 0.421173 93.2413 0.43176C94.0085 0.458227 94.7738 0.553509 95.5261 0.685845C98.5426 1.21695 101.277 2.27917 103.525 4.3489C104.803 5.52581 105.621 6.9074 106.039 8.50955C106.276 9.41649 106.243 10.3517 106.118 11.3009H98.3317C98.3317 10.8263 98.3783 10.357 98.3242 9.89995C98.0965 8.01725 96.4613 6.40805 94.5237 5.93693C92.9053 5.54345 91.3205 5.58227 89.7805 6.08515C88.2965 6.57038 87.1522 7.52496 86.3887 8.83597C85.3751 10.5722 84.8693 12.4373 85.0597 14.4153C85.3005 16.9014 86.0994 19.1811 88.3898 20.6933C90.1015 21.8225 92.0298 22.0202 94.0551 21.6179C95.8247 21.2667 97.0885 20.3121 97.8183 18.7806C98.0927 18.2054 98.1693 17.5454 98.3093 16.9173C98.3559 16.7073 98.3167 16.4797 98.3167 16.2291H106.093C106.272 17.1326 106.194 18.0342 105.951 18.9217C105.615 20.1604 105.061 21.3179 104.169 22.2813C102.806 23.7511 101.204 24.9492 99.2837 25.7326C96.3735 26.9201 93.3253 27.2977 90.1967 27.0136C87.1373 26.7366 84.2831 25.902 81.8135 24.0634C79.1274 22.0625 77.6994 19.444 77.1562 16.298C76.7978 14.2194 76.878 12.1744 77.3615 10.1435C77.8562 8.06666 78.7951 6.16279 80.3948 4.63828C81.8956 3.20729 83.6279 2.07625 85.6999 1.43222C87.4285 0.894053 89.1719 0.507633 90.9938 0.465285"
        {...{ fill }}
      />
      <path
        d="M177.441 27.1795C174.656 27.1336 171.985 26.6625 169.444 25.5633C167.171 24.5787 165.369 23.1053 163.939 21.1679C161.533 17.9107 161.306 14.3253 162.02 10.5863C162.259 9.33886 162.709 8.13195 163.45 7.0768C164.798 5.16057 166.4 3.47726 168.653 2.42739C169.902 1.84512 171.163 1.32636 172.515 1.01052C174.964 0.437061 177.456 0.322369 179.95 0.585276C182.291 0.830539 184.519 1.46752 186.593 2.56855C188.538 3.60077 190.011 5.04765 191.254 6.77684C192.867 9.01773 193.347 11.5286 193.334 14.1382C193.323 16.2203 192.871 18.2724 191.771 20.0951C190.866 21.5985 189.735 22.9818 188.228 24.0317C185.964 25.6091 183.427 26.5619 180.637 26.8972C179.578 27.0242 178.507 27.0878 177.441 27.1795ZM177.611 21.8278C179.076 21.8508 180.541 21.4326 181.923 20.6245C183.285 19.8269 184.153 18.6871 184.743 17.3372C185.389 15.8657 185.539 14.3165 185.361 12.739C185.079 10.2387 184.086 8.13725 181.701 6.76272C178.835 5.11117 175.358 5.30703 172.672 7.10856C171.283 8.04021 170.428 9.39179 169.965 10.9375C169.534 12.3791 169.392 13.8365 169.666 15.3328C170.081 17.5878 171.061 19.4687 173.22 20.7092C174.536 21.4661 175.933 21.8331 177.611 21.8278Z"
        {...{ fill }}
      />
      <path
        d="M57.1566 26.3714C54.0729 26.3714 51.1124 26.3714 48.1089 26.3714C48.1089 17.9865 48.1089 9.61938 48.1089 1.22046C50.6121 1.22046 53.1059 1.22046 55.6782 1.22046C59.9977 7.42084 64.3507 13.6671 68.6982 19.9063C69.2582 19.9063 69.7715 19.9063 70.3969 19.9063C68.8531 13.6371 67.1209 7.45789 65.3905 1.22222C68.4555 1.22222 71.431 1.22222 74.4401 1.22222C74.4401 9.60527 74.4401 17.9724 74.4401 26.3678C71.9033 26.3678 69.3945 26.3678 66.8297 26.3678C62.5308 20.201 58.2169 14.0094 53.8825 7.78962C53.315 7.78962 52.7364 7.78962 52.0812 7.78962C53.7761 13.9971 55.4598 20.1586 57.1566 26.3714Z"
        {...{ fill }}
      />
      <path
        d="M136.808 26.3679C136.808 17.9637 136.808 9.61062 136.808 1.21699C136.994 1.20641 137.157 1.18876 137.319 1.18876C142.014 1.18876 146.71 1.15877 151.405 1.20641C153.423 1.22758 155.431 1.51166 157.195 2.51918C159.01 3.55669 160.242 4.98239 160.249 7.13153C160.253 8.20433 160.01 9.1942 159.249 9.97763C158.282 10.9728 157.046 11.6115 155.64 11.9009C154.679 12.0985 153.703 12.2221 152.732 12.3826C152.603 12.4038 152.478 12.4426 152.329 12.4779C152.329 13.0143 152.329 13.5278 152.329 14.0818C153.01 14.1753 153.671 14.2441 154.323 14.3606C156.042 14.6711 157.574 15.3328 158.93 16.4109C160.255 17.4661 160.819 18.8106 160.727 20.3704C160.604 22.4737 159.471 24.0352 157.533 25.0851C155.769 26.0415 153.835 26.3767 151.844 26.3891C146.981 26.4191 142.118 26.3996 137.254 26.3979C137.127 26.3996 136.996 26.382 136.808 26.3679ZM144.312 15.7404C144.312 17.8225 144.312 19.8622 144.312 21.932C144.537 21.932 144.719 21.932 144.898 21.932C146.507 21.932 148.118 21.939 149.727 21.9267C150.108 21.9232 150.494 21.879 150.869 21.8155C153.488 21.3709 153.865 18.9235 153.031 17.2932C152.43 16.1216 151.259 15.7298 149.928 15.7298C148.235 15.7298 146.54 15.7281 144.847 15.7316C144.685 15.7316 144.849 15.7316 144.312 15.7404ZM144.304 11.578C146.223 11.578 148.099 11.7421 149.928 11.5374C152.432 11.2586 153.025 9.42888 152.628 7.4456C152.417 6.38868 151.108 5.57349 149.751 5.55055C148.043 5.52232 146.335 5.53644 144.625 5.53644C144.53 5.53644 144.625 5.53644 144.302 5.53291C144.304 7.52853 144.304 9.56651 144.304 11.578Z"
        {...{ fill }}
      />
      <path
        d="M108.904 1.22931C111.424 1.22931 113.914 1.22931 116.459 1.22931C116.459 4.52006 116.459 7.76847 116.459 11.0592C119.621 11.0592 122.727 11.0592 125.893 11.0592C125.893 7.78435 125.893 4.53418 125.893 1.23813C128.502 1.23813 131.054 1.23813 133.634 1.23813C133.634 9.61059 133.634 17.9672 133.634 26.3608C131.065 26.3608 128.515 26.3608 125.908 26.3608C125.908 23.0242 125.908 19.7281 125.908 16.3933C122.746 16.3933 119.638 16.3933 116.455 16.3933C116.455 19.7193 116.455 23.0277 116.455 26.3679C113.905 26.3679 111.432 26.3679 108.904 26.3679C108.904 18.0007 108.904 9.63353 108.904 1.22931Z"
        {...{ fill }}
      />
      <path
        d="M37.344 1.22573C39.8715 1.22573 42.343 1.22573 44.8704 1.22573C44.8798 1.40218 44.8984 1.56804 44.8984 1.7339C44.8928 6.46094 44.8966 11.1862 44.8723 15.9133C44.8648 17.3584 44.6203 18.8017 44.0174 20.118C43.0672 22.1895 41.7979 24.0863 39.64 25.2579C38.0198 26.1384 36.2875 26.7666 34.4022 26.8883C33.0358 26.9765 31.6507 27.0983 30.2918 26.9907C27.0195 26.7313 24.0515 25.7661 21.8358 23.2994C20.4302 21.7343 19.551 19.9433 19.2579 17.9177C19.1086 16.8908 19.0022 15.848 18.9947 14.8122C18.9667 10.4804 18.9835 6.14863 18.9835 1.81859C18.9835 1.63156 18.9835 1.44452 18.9835 1.2222C21.5035 1.2222 23.975 1.2222 26.5062 1.2222C26.5174 1.43217 26.536 1.63156 26.536 1.82918C26.5379 6.09746 26.5286 10.3657 26.5416 14.634C26.5472 16.065 26.7787 17.4589 27.4843 18.7541C28.315 20.2768 29.6347 21.0673 31.4491 21.1767C32.6512 21.249 33.7936 21.0832 34.8352 20.5027C35.5726 20.0915 36.099 19.4652 36.476 18.7541C37.1238 17.5331 37.3496 16.2185 37.3478 14.8599C37.344 10.5122 37.3459 6.16451 37.3459 1.81859C37.344 1.63332 37.344 1.44805 37.344 1.22573Z"
        {...{ fill }}
      />
      <path
        d="M219.572 26.3803C216.495 26.3803 213.514 26.3803 210.408 26.3803C208.396 23.3489 206.354 20.2716 204.325 17.2155C203.72 17.2155 203.186 17.2155 202.568 17.2155C202.568 20.2699 202.568 23.3083 202.568 26.3732C198.751 26.3732 195.019 26.3732 191.223 26.3732C194.764 22.1702 198.273 18.006 201.814 13.8048C198.393 9.64768 194.967 5.48703 191.463 1.2311C194.725 1.2311 197.833 1.2311 201.023 1.2311C202.985 4.18661 204.982 7.19681 206.996 10.2335C207.554 10.2335 208.114 10.2335 208.73 10.2335C208.73 7.22504 208.73 4.24483 208.73 1.22757C212.531 1.22757 216.273 1.22757 220.104 1.22757C216.523 5.45527 212.992 9.6265 209.458 13.8013C212.813 17.9725 216.163 22.1402 219.572 26.3803Z"
        {...{ fill }}
      />
      <path
        d="M0.14502 1.21695C2.67622 1.21695 5.18502 1.21695 7.75169 1.21695C7.75169 7.76317 7.75169 14.2829 7.75169 20.8715C11.3525 20.8715 14.8935 20.8715 18.4776 20.8715C18.4776 22.7313 18.4776 24.5222 18.4776 26.3573C12.3754 26.3573 6.28075 26.3573 0.14502 26.3573C0.14502 18.0007 0.14502 9.63352 0.14502 1.21695Z"
        {...{ fill }}
      />
    </svg>
  );
}

export default Logo;
