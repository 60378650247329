import * as React from "react";
import {Tag} from "components/elements";
import {Condition} from "../Condition";
import styles from "./paymentStatus.module.css";

// ERRORED (UNPAID): Unable to request action from payment processor (ie unable
// to void or capture transaction. Action is required)
// FAILED / NULL (UNPAID): Payment Processor unable to complete transaction (see
// processor for what this means on their end)
// VOIDED (VOIDED): Payment was voided. No money transferred, prior hold released.
// CAPTURED (PAID): Payment was charged. Money transfer occurred.
// AUTHORIZED (shouldn't ever view) Payment Funds are held pending capture. No
// money transfer occurred

const tagColor = (type, finalAmount, externalTransactionId, orderType) => {
  switch (type) {
    case "captured":
      if (externalTransactionId) {
        return {statusColor: styles.success, detailsColor: styles.success};
      }
      return {statusColor: styles.success};
    case "voided":
      return {statusColor: styles.error, detailsColor: styles.error};
    case "failed":
    case null: {
      switch (orderType) {
        case "dinein": {
          return {statusColor: null, detailsColor: styles.authorized};
        }
        default: {
          return {statusColor: null, detailsColor: styles.error};
        }
      }
    }
    case "authorized":
      return {statusColor: null, detailsColor: styles.authorized};
    case "errored":
      return {statusColor: null, detailsColor: styles.error};
    default:
      if (finalAmount === 0) {
        return {statusColor: styles.success, detailsColor: null};
      }
      return {statusColor: styles.error, detailsColor: null};
  }
};

const tagText = (type, finalAmount, externalTransactionId, orderType) => {
  switch (type) {
    case "captured":
      if (externalTransactionId) {
        return {statusText: "Paid", detailsText: "Captured"};
      }
      return {statusText: "Cash"};
    case "voided":
      return {statusText: "Voided", detailsText: "POS"};
    case "failed":
    case null:
      switch (orderType) {
        case "dinein": {
          return {statusText: null, detailsText: "Loyalty"};
        }
        default: {
          return {statusText: null, detailsText: "Declined"};
        }
      }
    case "authorized":
      return {statusText: null, detailsText: "Authorized"};
    case "errored":
      return {statusText: null, detailsText: "Payment Processor"};
    default:
      if (finalAmount === 0) {
        return {statusText: "Paid", detailsText: null};
      }
      return {statusText: "Unpaid", detailsText: null};
  }
};

const PaymentStatus = ({
  paymentStatus,
  finalAmount,
  externalTransactionId,
  orderType,
}) => {
  const {statusColor, detailsColor} = tagColor(
    paymentStatus,
    finalAmount,
    externalTransactionId,
    orderType,
  );
  const {statusText, detailsText} = tagText(
    paymentStatus,
    finalAmount,
    externalTransactionId,
    orderType,
  );

  return (
    <>
      <Condition.Condition is={statusColor}>
        <Tag className={statusColor}>{statusText}</Tag>
      </Condition.Condition>
      <Condition.Condition is={detailsColor}>
        <Tag className={detailsColor}>{detailsText}</Tag>
      </Condition.Condition>
    </>
  );
};
export default PaymentStatus;
