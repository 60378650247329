import React, {useState} from "react";
import {Input, Form} from "antd";
import {T} from "components/elements";
import classnames from "classnames";
import styles from "./field.module.scss";

const LiveSearch = ({
  field: {...field},
  form: {errors, setFieldValue},
  width,
  onSearch,
  inputProps,
  disabled,
  ...props
}) => {
  const [text, setText] = useState("");
  const [value, setValue] = useState("");
  const [visible, setVisible] = useState(false);

  const errorMsg = errors[field.name];

  React.useEffect(() => {
    if (text.trim().length) {
      onSearch(text.trim());
    }
  }, [text]);

  const handleSelect = (company) => {
    props.onSelect(company);
    setValue(company.text);
    setVisible(false);
  };

  const handleChange = ({target: {value}}) => {
    setValue(value);
    setText(value);
  };

  const handleFocus = () => {
    setVisible(true);
    setFieldValue(field.name, "");
  };

  const handleBlur = () => {
    setVisible(false);
  };

  const searchResultsClasses = classnames(
    styles.searchResults,
    visible ? styles.visible : undefined,
  );

  return (
    <div className={styles.searchContainer}>
      <div className={styles.searchDropdown}>
        <Form.Item
          label={<T label>{props.label}</T>}
          help={errorMsg}
          validateStatus={errorMsg ? "error" : undefined}
        >
          <Input
            {...inputProps}
            value={value}
            style={{width}}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            disabled={disabled}
          />
        </Form.Item>

        <div className={searchResultsClasses}>
          {props.children.map((child) => (
            <SearchResult
              type={props.resultType}
              key={child.text}
              onClick={() => handleSelect(child)}
            >
              {child.text}
            </SearchResult>
          ))}
        </div>
      </div>
    </div>
  );
};

const SearchResult = ({className, type, children, onClick, ...props}) => {
  const classes = classnames(styles.searchResult, className);
  return (
    <>
      <div
        type={type}
        className={classes}
        role="button"
        tabIndex={0}
        onMouseDown={onClick}
      >
        {children}
      </div>
      <hr />
    </>
  );
};

export {LiveSearch};
export default LiveSearch;
