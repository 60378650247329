import { RefObject, useEffect } from "react";

// I re-write this hook using typescript
// and also handle touch event

const useClickOutside = (
  ref: RefObject<HTMLElement>,
  onClickOutside?: (e: MouseEvent | TouchEvent) => void,
) => {
  // check if the click is outside of the ref element
  const isOutside = (e: MouseEvent | TouchEvent) => {
    return !!ref.current && !ref.current.contains(e.target as HTMLElement);
  };

  // when click or touch
  const onMouseClicked = (e: MouseEvent | TouchEvent) => {
    if (isOutside(e) && onClickOutside) {
      onClickOutside(e);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", onMouseClicked);
    document.addEventListener("touchstart", onMouseClicked);

    return () => {
      document.removeEventListener("mousedown", onMouseClicked);
      document.removeEventListener("touchstart", onMouseClicked);
    };
  }, [onClickOutside]);
};

export { useClickOutside };
