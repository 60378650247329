import React, { useState, useMemo, useRef } from "react";
import classnames from "classnames";
import moment from "moment";
import { message, Col, Card } from "antd";
import { Route, Switch, useHistory } from "react-router-dom";
import { useAnalyticsActions, useRefetchableResource } from "hooks";
import { PageSpinner, Row } from "components/elements";
import { Cards, Toggles } from "components/fragments";
import { useAPIContext } from "components/providers/Api";
import cloneDeep from "lodash/cloneDeep";
import { downloadCsv } from "common/utils";
import SubscriptionHeader from "./SubscriptionHeader";
import { LocationDetailsTables } from "./LocationDetailsTables";
import { CategoryTables } from "./CategoryTables";
import { SubscriptionGraphBilling } from "./SubscriptionGraphBilling";
import { SubscriptionGraphProgram } from "./SubscriptionGraphProgram";
import { INFO_TYPES, DEFAULT_TITLE } from "./subscriptionConsts";
import { calculateSumsOfKeys } from "./utils";

import css from "../../Analytics/analytics.module.css";

const { BlueCard } = Cards;

const { TriToggle } = Toggles;

const OPTIONS_ORDER = [
  INFO_TYPES.OVERVIEW,
  INFO_TYPES.PROGRAM,
  INFO_TYPES.BILLING,
];

const ensureNumber = (value) => {
  return Number(value || 0);
};

const SubscriptionAnalytics = ({ locationId }) => {
  const history = useHistory();
  const { Analytics } = useAPIContext();
  const [submitting, setSubmitting] = useState(false);
  const [infoType, setInfoType] = useState("Overview");
  const { actions, params } = useAnalyticsActions({
    locationId,
    initialDates: {
      startDate: moment().utc().startOf("month"),
      endDate: moment().utc().endOf("month"),
    },
  });
  const { resource = [], fetching } = useRefetchableResource({
    path: "/subscriptions-general-report",
    data: { filters: params },
    method: "GET",
  });
  const revenueSortedLocations = useRef([]);
  const totals = useMemo(() => {
    let totalsValue;
    if (resource.length > 0) {
      revenueSortedLocations.current = resource.sort(
        (a, b) => b.totalRevenue - a.totalRevenue,
      );

      if (resource.length === 1) {
        totalsValue = cloneDeep(revenueSortedLocations.current[0]);

        totalsValue.name = "Total";
        delete totalsValue.id;
      } else {
        totalsValue = revenueSortedLocations.current.reduce((accu, i) => ({
          name: "Total",
          id: "total",
          activeSubscriptions:
            accu.activeSubscriptions + ensureNumber(i.activeSubscriptions),
          totalRevenue: accu.totalRevenue + ensureNumber(i.totalRevenue),
          totalSubtotal: accu.totalSubtotal + ensureNumber(i.totalSubtotal),
          totalTax: accu.totalTax + ensureNumber(i.totalTax),
          totalDeliveryOrders:
            accu.totalDeliveryOrders + ensureNumber(i.totalDeliveryOrders),
          totalPickupOrders:
            accu.totalPickupOrders + ensureNumber(i.totalPickupOrders),
          averageTokenQuantity:
            accu.averageTokenQuantity + ensureNumber(i.averageTokenQuantity),
          totalTokens: accu.totalTokens + ensureNumber(i.totalTokens),
          totalRedeemedTokens:
            accu.totalRedeemedTokens + ensureNumber(i.totalRedeemedTokens),
          totalUnredeemedTokens:
            accu.totalUnredeemedTokens + ensureNumber(i.totalUnredeemedTokens),
          cancelledSubscriptions:
            accu.cancelledSubscriptions +
            ensureNumber(i.cancelledSubscriptions),
        }));
      }
      totalsValue.averageTokenQuantity /=
        revenueSortedLocations.current.length + 1;
    }
    return totalsValue;
  }, [resource]);

  const dataSums = useMemo(
    () =>
      calculateSumsOfKeys(revenueSortedLocations.current, [
        "programAnalytics",
        "billingDurationAnalytics",
      ]),
    [revenueSortedLocations.current],
  );

  const onExportCSV = async () => {
    setSubmitting(true);
    try {
      const { data } = await Analytics.getSubscriptionCSV({ filters: params });
      downloadCsv(data);
    } catch (e) {
      message.error("An error occurred");
    } finally {
      setSubmitting(false);
    }
  };

  const programPieData = [];
  const billingPieData = [];

  const programBreakdown = dataSums.programAnalytics || [];
  const billingBreakdown = dataSums.billingDurationAnalytics || [];

  Object.keys(programBreakdown).forEach((key) => {
    programPieData.push({
      id: key,
      label: key,
      value: programBreakdown[key].subscribers,
      color: "hsl(231, 92%, 62%)",
    });
  });

  Object.keys(billingBreakdown).forEach((key) => {
    billingPieData.push({
      id: key,
      label: key,
      value: billingBreakdown[key].subscribers,
      color: "hsl(231, 92%, 62%)",
    });
  });

  let locationObj;

  if (locationId) {
    locationObj = revenueSortedLocations.current.find(
      (element) => element.id === locationId,
    );
  }

  const handleRowClick = (record) => ({
    onClick: () => {
      if (record?.id && record.id !== "total") {
        history.push(`/subscription-reports/${record.id}`);
      }
    },
  });

  const handleChange = (value) => {
    setInfoType(value);
  };

  return (
    <>
      <BlueCard>
        <SubscriptionHeader
          title={locationObj?.name || DEFAULT_TITLE}
          actions={actions}
          activeTab={infoType}
          exportCSV={onExportCSV}
          submitting={submitting}
          displayToggle={!locationObj}
          params={params}
        />

        <Route exact path="/subscription-reports/">
          <Row gutter={15}>
            <Col md={12}>
              <SubscriptionGraphBilling data={billingPieData} />
            </Col>
            <Col md={12}>
              <SubscriptionGraphProgram data={programPieData} />
            </Col>
          </Row>
        </Route>
      </BlueCard>
      <Row spacing={45}>
        <Col xs={24}>
          <Card bordered={false}>
            {!fetching && (
              <Switch>
                <Route path="/subscription-reports/:locationId">
                  {locationObj && (
                    <LocationDetailsTables
                      handleRowClick={handleRowClick}
                      fetching={fetching}
                      locationObj={locationObj}
                    />
                  )}
                </Route>
                <Route>
                  <TriToggle
                    className={classnames(
                      css.triToggle,
                      css.triToggleSubscriptionAnalytics,
                    )}
                    value={OPTIONS_ORDER.indexOf(INFO_TYPES.OVERVIEW)}
                    options={OPTIONS_ORDER}
                    onChange={handleChange}
                  />
                  <CategoryTables
                    handleRowClick={handleRowClick}
                    fetching={fetching}
                    revenueSortedLocations={revenueSortedLocations.current}
                    totals={totals}
                    infoType={infoType}
                    billingBreakdown={billingBreakdown}
                    programBreakdown={programBreakdown}
                  />
                </Route>
              </Switch>
            )}
            {fetching && <PageSpinner />}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export { SubscriptionAnalytics };
