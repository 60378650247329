import { useEffect, useRef } from "react";
import isEqual from "lodash/isEqual";

const useDeepCompareMemoize = (val) => {
  const ref = useRef();

  if (!isEqual(val, ref.current)) {
    ref.current = val;
  }

  return ref.current;
};

const useDeepEffect = (callback, inputs) => {
  useEffect(() => {
    callback();
  }, [useDeepCompareMemoize(inputs)]);
};

export default useDeepEffect;
