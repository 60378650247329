import React, { useState } from "react";
import { Formik, Field } from "formik";
import { Col, Form, List, Popconfirm } from "antd";
import { Tab, Tabs, TabList, TabPanel, TabPanels } from "@lunchboxinc/utensils";
import { Fields, Elements } from "components";
import { schemas } from "common";
import { useReceiptEmails } from "./useReceiptEmails";
import styles from "./receiptEmails.module.css";

const { Item } = List;
const { Button, Row } = Elements;

const listProps = (onRemove) => ({
  size: "small",
  bordered: true,
  renderItem: (item) => (
    <Item>
      <Row>
        <span className={styles["remove-button"]}>
          <Popconfirm
            placement="bottom"
            title={<strong>Are you sure?</strong>}
            onConfirm={() => onRemove(item)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger" icon="close" />
          </Popconfirm>
        </span>
        &nbsp;
        <span>{item}</span>
      </Row>
    </Item>
  ),
});

const ReceiptEmails = ({ emails: initialEmails, save }) => {
  const [activeTab, setActiveTab] = useState("pickup");
  const [emails, emailSetters] = useReceiptEmails(initialEmails);
  const tabList = [
    { key: "Pickup" },
    { key: "Delivery" },
    { key: "Dine in" },
    { key: "Catering" },
    { key: "Subscription" },
  ];
  const onSave = async () => {
    await save({ receiptEmails: emails });
  };

  const onTabChanged = (index) => {
    let tabTitle = tabList[index].key.toLowerCase();
    if (tabTitle.indexOf(" ") !== -1) {
      tabTitle = tabTitle.split(" ").join("");
    }
    setActiveTab(tabTitle);
  };
  return (
    <>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={schemas.ReceiptEmails}
        validateOnChange={false}
        onSubmit={(values) => {
          emailSetters.onAdd({ type: activeTab, ...values });
        }}
        render={(formProps) => (
          <Row gutter={15} spacing={20}>
            <Col xs={20}>
              <Field
                label="Email"
                name="email"
                placeholder="Email Address"
                component={Fields.Input}
              />
            </Col>
            <Col xs={4}>
              <Form.Item>
                <Button
                  htmlType="button"
                  type="primary"
                  onClick={formProps.submitForm}
                  disabled={formProps.submitting}
                  loading={formProps.submitting}
                  className={styles.addEmail}
                >
                  Add
                </Button>
              </Form.Item>
            </Col>
          </Row>
        )}
      />
      <Row spacing={40}>
        <Tabs onChange={onTabChanged}>
          <TabList>
            {tabList.map((tabItem) => (
              <Tab style={{ paddingRight: 30 }}>{tabItem.key}</Tab>
            ))}
          </TabList>
          <TabPanels>
            <TabPanel style={{ paddingTop: 15 }} key="pickup">
              <List
                {...listProps(emailSetters.onRemove("pickup"))}
                dataSource={emails.pickup}
              />
            </TabPanel>
            <TabPanel style={{ paddingTop: 15 }} key="delivery">
              <List
                {...listProps(emailSetters.onRemove("delivery"))}
                dataSource={emails.delivery}
              />
            </TabPanel>
            <TabPanel style={{ paddingTop: 15 }} key="dinein">
              <List
                {...listProps(emailSetters.onRemove("dinein"))}
                dataSource={emails.dinein}
              />
            </TabPanel>
            <TabPanel style={{ paddingTop: 15 }} key="catering">
              <List
                {...listProps(emailSetters.onRemove("catering"))}
                dataSource={emails.catering}
              />
            </TabPanel>
            <TabPanel style={{ paddingTop: 15 }} key="subscription">
              <List
                {...listProps(emailSetters.onRemove("subscription"))}
                dataSource={emails.subscription}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Row>
      <Row>
        <Button
          style={{ float: "right" }}
          size="small"
          color="blue"
          onClick={onSave}
        >
          Save
        </Button>
      </Row>
    </>
  );
};

export default ReceiptEmails;
