import React from "react";
import csx from "classnames";
import PropTypes from "prop-types";
import css from "./image2.module.css";

interface Image2Params {
  src: string;
  onClick: any;
  imageClassName: string;
}

function Image2({ src, onClick }: Image2Params) {
  const containerClass = csx(css.container, onClick && css.clickable);
  return (
    <div className={containerClass} onClick={onClick && onClick}>
      <div className={css.item}>
        <div className={css.itemInfo}>
          <div className={css.itemThumbnail}>
            <img src={src} alt="" className={css.itemImage} />
          </div>
        </div>
      </div>
    </div>
  );
}

Image2.propTypes = {
  src: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

Image2.defaultProps = {
  onClick: null,
};

export default Image2;
