import React, {useEffect} from "react";
import {
  BrinkIntegration,
  DeliverectIntegration,
  LunchboxPosIntegration,
  OrdermarkIntegration,
  ToastIntegration,
  ChowlyIntegration,
} from "./Pos";

// Create and import the following components from IntegrationNamesMap
const IntegrationNamesMap = {
  // POS
  brink: BrinkIntegration,
  deliverect: DeliverectIntegration,
  ordermark: OrdermarkIntegration,
  toast: ToastIntegration,
  lunchboxpos: LunchboxPosIntegration,
  chowly: ChowlyIntegration,
};

const DefaultWrapper = ({setFetching, children}) => {
  useEffect(() => {
    setFetching(false);
  }, []);

  return children;
};

const NameManager = ({
  name,
  locationId,
  setFetching,
  inputOptions,
  setInputOptions,
  activeIntegration,
  children,
}) => {
  const Component = IntegrationNamesMap[name] ?? DefaultWrapper;

  return (
    <Component
      locationId={locationId}
      setFetching={setFetching}
      inputOptions={inputOptions}
      setInputOptions={setInputOptions}
      activeIntegration={activeIntegration}
    >
      {children}
    </Component>
  );
};

export {NameManager};
