import * as Yup from "yup";

const contentHub = Yup.object({
  callToActionTitle: Yup.string()
    .nullable(true)
    .default(null)
    .transform((val) => (val === "" ? null : val)),
  callToActionUrl: Yup.string()
    .nullable(true)
    .default(null)
    .transform((val) => (val === "" ? null : val)),
  description: Yup.string()
    .nullable(true)
    .default(null)
    .transform((val) => (val === "" ? null : val)),
  imageUrl: Yup.string()
    .nullable(true)
    .default(null)
    .transform((val) => (val === "" ? null : val)),
  title: Yup.string()
    .nullable(true)
    .default(null)
    .transform((val) => (val === "" ? null : val)),
});

export { contentHub };
