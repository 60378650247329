import * as React from "react";
import { Checkbox, CheckboxSpacer } from "../components/FigmaUI";
import Section from "../components/Section";
import { useLocationCard } from "./Context";

interface OpeningHours {
  onStoreHoursChange: InputChangeType;
  onPickupHoursChange: InputChangeType;
  onDeliveryHoursChange: InputChangeType;
}

function OpeningHours({
  onStoreHoursChange,
  onPickupHoursChange,
  onDeliveryHoursChange,
}: OpeningHours) {
  const { componentConfig } = useLocationCard();
  return (
    <Section
      title="Opening Hours"
      caption="Choose the type of hours to display."
      disableDivider
    >
      <Checkbox
        label="Show store hours"
        checked={componentConfig.showStoreHours}
        name="showStoreHours"
        onChange={onStoreHoursChange}
      />
      <CheckboxSpacer />
      <Checkbox
        label="Show pickup hours"
        checked={componentConfig.showPickUpHours}
        name="showPickUpHours"
        onChange={onPickupHoursChange}
      />
      <CheckboxSpacer />
      <Checkbox
        label="Show delivery hours"
        checked={componentConfig.showDeliveryHours}
        name="showDeliveryHours"
        onChange={onDeliveryHoursChange}
      />
    </Section>
  );
}

export default OpeningHours;
