import {Table as AntTable, Col, Switch} from "antd";
import {Field, FieldArray} from "formik";
import React, {useEffect, useState} from "react";
import {Row, Titles} from "components/elements";
import {SuperAdminOnly} from "components/fragments";
import Availability from "components/templates/AvailabilityButtons";
import {Checkbox} from "components/fields";
import {constants} from "common";
import {Condition} from "../Condition";
import InventoryStatus from "./InventoryStatus";
import {Permission} from "../Permission";
import Table from "./Table";
import styles from "./table.module.css";

const {permissions} = constants;
const {
  methods: {PUT},
  routes: {MENU_ITEMS_ITEM},
} = permissions;

const LocationToggleTable = ({
  disabledLocations,
  excludedLocations,
  locations,
  height,
  onSelection,
  setFieldValue,
  inventory,
  save,
  isSubmitting,
  loading,
  dropdownOnly,
  enableToggle,
  autosave,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [isInventoryChecked, setIsInventoryChecked] = useState();
  const {isTrackingInventory, inventories} = inventory || {};

  useEffect(() => {
    setIsInventoryChecked(isTrackingInventory);
  });

  excludedLocations = excludedLocations.map((location) => {
    if (typeof location === "object") {
      return location.id;
    }
    return location;
  });

  let disabledLocationsID = [];
  disabledLocationsID = disabledLocations.map((location) => {
    if (typeof location === "object") {
      return location.id;
    }
    return location;
  });

  /* Get list of locations that aren't in excludedLocations */
  let availableLocations = locations.filter(
    (location) => !excludedLocations.includes(location.id),
  );

  if (isTrackingInventory) {
    availableLocations = availableLocations.map((location) => {
      const inventoryIdx = inventories.findIndex(
        (i) => i.location === location.id,
      );
      if (inventoryIdx > -1) {
        Object.assign(location, {
          status: inventories[inventoryIdx].status,
          quantity: inventories[inventoryIdx].quantity,
        });
      }
      return location;
    });
  }

  const isChecked = (id) => disabledLocationsID.includes(id);

  const handleSelection = (ids, availability) => {
    const nextDisabledLocations = disabledLocationsID.filter(
      (id) => !ids.includes(id),
    );
    if (availability) {
      setFieldValue("disabledLocations", nextDisabledLocations);
      onSelection({disabledLocations: nextDisabledLocations});
    } else {
      nextDisabledLocations.push(...ids);
      setFieldValue("disabledLocations", nextDisabledLocations);
      onSelection({disabledLocations: nextDisabledLocations});
    }
  };

  const onClick = (index, id, arrayFuncs) => (checked) => {
    if (autosave) {
      return handleSelection([id], isChecked(id));
    }
    return checked
      ? arrayFuncs.remove(disabledLocationsID.indexOf(id))
      : arrayFuncs.push(availableLocations[index]);
  };

  const rowSelection = {
    onChange: (selectedRowKeys) => {
      setSelectedRows(selectedRowKeys);
    },
  };

  return (
    <>
      <Row>
        <Col xs={10}>
          <Titles.SectionTitle>Location Availability</Titles.SectionTitle>
        </Col>
        <Col xs={14} className={styles.rightAlign}>
          {selectedRows[0] && (
            <Availability.LocationAvailability
              selectedIds={selectedRows}
              onSelection={handleSelection}
              listType="menus"
              onSuccess={fetch}
            />
          )}
        </Col>
      </Row>

      <Condition.Condition is={inventory}>
        <Permission
          requiredPermissions={{
            method: PUT,
            url: MENU_ITEMS_ITEM,
            field: "isTrackingInventory",
          }}
          yes={() => (
            <Row spacing={25}>
              <Field
                disabled={isSubmitting}
                label="Based On Inventory"
                checked={isInventoryChecked}
                component={Checkbox}
                onChange={(e) => {
                  save({isTrackingInventory: e.target.checked});
                }}
              />
            </Row>
          )}
        />
      </Condition.Condition>
      <FieldArray
        name="disabledLocations"
        render={(arrayHelpers) => (
          <Table
            loading={loading}
            dataSource={availableLocations}
            pagination={false}
            scroll={{y: height}}
            className="locationToggleTable"
            rowSelection={enableToggle && rowSelection}
          >
            <AntTable.Column title="Location" dataIndex="name" width={150} />
            {isInventoryChecked && (
              <AntTable.Column
                title="Inventory Level"
                width={150}
                render={(txt, {quantity, status}) => (
                  <InventoryStatus quantity={quantity} status={status} />
                )}
              />
            )}
            {enableToggle && (
              <AntTable.Column
                title="Availability"
                width={150}
                render={(text, {id}, index) => (
                  <Switch
                    onClick={onClick(index, id, arrayHelpers)}
                    checked={!isChecked(id)}
                  />
                )}
              />
            )}
          </Table>
        )}
      />
    </>
  );
};

LocationToggleTable.defaultProps = {
  disabledLocations: [],
  excludedLocations: [],
  locations: [],
  enableToggle: true,
};

export default LocationToggleTable;
