import { useEffect, useReducer } from "react";
import moment from "moment";

const reducer = (state, action) => {
  switch (action.type) {
    case "LOCATION_CHANGE":
      return { ...state, location: action.payload.location };
    case "DATE_CHANGE":
      return { ...state, ...action.payload };
    case "PLATFORM_CHANGE":
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

const useAnalyticsActions = ({ locationId, initialDates = {} }) => {
  const initialStartDate =
    initialDates.startDate || moment().utc().subtract(8, "days").startOf("day");
  const initialEndDate =
    initialDates.endDate || moment().utc().subtract(1, "days").endOf("day");

  const filters = (location) => ({
    location,
    createdAt: {
      start: initialStartDate.format(),
      end: initialEndDate.format(),
    },
  });

  const [params, dispatch] = useReducer(reducer, filters(locationId));

  const onLocationChange = (id) => {
    dispatch({
      type: "LOCATION_CHANGE",
      payload: {
        location: id,
      },
    });
  };
  const onCreatedAtChange = (dates) => {
    dispatch({
      type: "DATE_CHANGE",
      payload: {
        ...dates,
      },
    });
  };
  const onPlatformChange = (platform) => {
    dispatch({
      type: "PLATFORM_CHANGE",
      payload: {
        platform,
      },
    });
  };

  useEffect(() => {
    onLocationChange(locationId);
  }, [locationId]);

  return {
    actions: {
      onCreatedAtChange,
      onPlatformChange,
    },
    params,
    initialDateValues: {
      initialStartDate,
      initialEndDate,
    },
  };
};

export default useAnalyticsActions;
