import * as Yup from "yup";

const schema = Yup.object().shape({
  externalId: Yup.string().matches(
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
  ),
  name: Yup.string().required(),
  description: Yup.string(),
  visibility: Yup.string().oneOf(["ALL", "NONE", "POS_ONLY"]),
  images: Yup.array().of(Yup.string()),
  items: Yup.array().of(Yup.string().matches(/^[a-f\d]{24}$/)),
  subgroups: Yup.array().of(Yup.string().matches(/^[a-f\d]{24}$/)),
  isOrderableOnline: Yup.boolean(),
  // excludedLocations: Yup.array().of(Yup.string().matches(/^[a-f\d]{24}$/)),
  isActive: Yup.boolean(),
  isDeleted: Yup.boolean(),
  priority: Yup.number()
    .typeError("")
    .min(0, "You must specify a value from 0 to 10,000")
    .max(10000, "You must specify a value from 0 to 10,000"),
});

export default schema;
