import React from "react";
import {Icon} from "antd";

const IconText = ({type, text, color, className}) => (
  <span style={{color, display: "flex", alignItems: "center"}}>
    <Icon className={className} type={type} style={{color, marginRight: 8}} />
    {text}
  </span>
);

export default IconText;
