import {useReducer} from "react";

const ADD = "ADD";
const REMOVE = "REMOVE";
const CLEAR = "CLEAR";

const reducer = (state, {action, payload}) => {
  switch (action) {
    case ADD:
      if (!state[payload.type].includes(payload.email)) {
        return {
          ...state,
          [payload.type]: [...state[payload.type], payload.email],
        };
      }
      break;
    case REMOVE:
      return {
        ...state,
        [payload.type]: state[payload.type].filter((i) => i !== payload.email),
      };
    case CLEAR:
      break;
    default:
  }
  return state;
};

const useReceiptEmails = (initialEmails) => {
  const [emails, dispatch] = useReducer(reducer, initialEmails);

  const onAdd = async ({type, email}) => {
    dispatch({
      action: ADD,
      payload: {
        type,
        email,
      },
    });
  };
  const onRemove = (type) => async (email) => {
    dispatch({
      action: REMOVE,
      payload: {
        type,
        email,
      },
    });
  };

  return [
    emails,
    {
      onAdd,
      onRemove,
    },
  ];
};

export default useReceiptEmails;
