import * as Yup from "yup";

const scheduleAvailabilitySchema = Yup.object({
  availability: Yup.string()
    .typeError("Availability selection is required")
    .required("Availability selection is required"),
  availabilityDates: Yup.object().shape({
    startDate: Yup.date("Start Date is required").required(
      "Start Date is required",
    ),
    endDate: Yup.date("End Date is required")
      .min(Yup.ref("startDate"))
      .required("End Date is required"),
  }),
});

export default scheduleAvailabilitySchema;
