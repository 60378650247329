/* eslint-disable react/jsx-filename-extension */
import React from "react";

import { createRoot } from "react-dom/client";

import * as FullStory from "@fullstory/browser";
import { constants } from "common";

import * as Sentry from "@sentry/browser";
import "@lunchboxinc/utensils/style.css";
import "antd/dist/antd.css";
import "./index.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import App from "./App";

// import registerServiceWorker from './registerServiceWorker';

const {
  fullstoryCredentials: { fullstoryID },
} = constants;

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://5ca01407629c455fa7535e7ef11eee7d@o360216.ingest.sentry.io/4777069",
    release: `LunchboxAdmin@${process.env.REACT_APP_VERSION}`,
  });
}

if (
  process.env.NODE_ENV === "production" &&
  window.location.origin.includes("lunchbox.io")
) {
  FullStory.init({ orgId: fullstoryID });
}

if (process.env.NODE_ENV === "production") {
  if (window.Appcues) {
    window.Appcues = undefined;
  }
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App className="fs-unmask" />);

// registerServiceWorker();
