import React from "react";

export type FontWeight = "normal" | "bold";

export interface SvgProps extends React.SVGAttributes<HTMLOrSVGElement> {
  /** CSS classname to apply custom styles. */
  className?: string;
  /** Defines the position and dimension of an SVG viewport. viewBox attribute is a list of four numbers: min-x, min-y, width and height. */
  viewBox?: string;
  /** The horizontal length of a SVG component. */
  width?: string;
  /** The vertical length of a SVG component. */
  height?: string;
  /** The title of a SVG component. */
  title?: string;
  /** Optional styling via styled component string. */
  css?: string;
  /** weight of the SVG  font */
  bold?: boolean;
  /** color of the SVG  font */
  color?: string;
}

const Svg: React.FC<SvgProps> = ({
  className,
  children,
  viewBox = "0 0 12 7",
  xmlns = "http://www.w3.org/2000/svg",
  width = "1rem",
  height = "0.75rem",
  title,
  color,
  ...rest
}) => {
  // This is necessary because some versions of Firefox would not use rems as values
  const styles = {
    width,
    height,
  };
  return (
    <svg
      xmlns={xmlns}
      className={`Svg ${className || ""}`}
      height={height}
      style={styles}
      viewBox={viewBox}
      width={width}
      aria-hidden="true"
      aria-label={title}
      {...rest}
    >
      {title && <title>{title}</title>}
      <g fillRule="evenodd" fill="currentColor">
        {children}
      </g>
    </svg>
  );
};

export { Svg };
