/**
 * Takes in an array of objects & returns a mapped object using key parameter as a key(for each input array item)
 * @author Htin Linn Aung
 * @memberof Utils
 * @param {Array} arr - An input array of objects
 * @param {string} key - A property name of an object(an array item)
 * @returns {Object} A mapped object(using key parameter as a key)
 */
const convertArray2Object = (arr, key) => {
  const initialValue = {};
  return arr.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

export default convertArray2Object;
