import React from "react";
import classnames from "classnames";
import {Table} from "antd";
import styles from "./table.module.css";

const hiddenClassGenerator = (hiddenObj) => {
  const hiddenClass = ["xs", "sm", "md", "lg", "xl", "xxl"].reduce(
    (accu, size) => {
      if (hiddenObj[size] !== undefined) {
        if (hiddenObj[size]) {
          accu = {...accu, [styles[`hidden-${size}`]]: true};
        } else {
          accu = {...accu, [styles[`show-${size}`]]: true};
        }
      }
      return accu;
    },
    {},
  );
  return classnames(hiddenClass);
};

const Column = ({hidden, className, ...props}) => {
  const hiddenClass = ["xs", "sm", "md", "lg", "xl", "xxl"].reduce(
    (accu, size) => {
      if (hidden[size]) {
        accu = {...accu, [styles[`hidden-${size}`]]: true};
      } else {
        accu = {...accu, [styles[`show-${size}`]]: true};
      }
      return accu;
    },
    {},
  );

  const columnClasses = classnames(hiddenClass, className);

  return <Table.Column className={columnClasses} {...props} />;
};

export {hiddenClassGenerator};
export default {
  Column,
  hiddenClassGenerator,
};
