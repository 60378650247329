import { useCallback, useEffect, useReducer, Reducer } from "react";
import isEqual from "lodash/isEqual";
import usePrevious from "./usePrevious";

const SET = "SET";
const RESET = "RESET";

type Action<T> = {
  type: typeof SET | typeof RESET;
  payload: Partial<T> | string;
  field?: string;
};

const formReducer = <T, A extends Action<T>>(state: T, action: A) => {
  if (action.type === SET) {
    return {
      ...state,
      [action.field]: action.payload,
    };
  }

  if (action.type === RESET) {
    return action.payload as Partial<T>;
  }

  return state;
};

const useForm = <T>({
  initialValues = {},
  enableReintialization = false,
}: {
  initialValues: Partial<T>;
  enableReintialization?: Boolean;
}) => {
  const previousInitialValue = usePrevious(initialValues);
  const [values, dispatchValues] = useReducer<Reducer<Partial<T>, Action<T>>>(
    formReducer,
    Object.entries(initialValues).reduce((accu, i) => {
      accu[i[0]] = i[1];
      return accu;
    }, {}),
  );

  const [errors, dispatchErrors] = useReducer(
    formReducer,
    Object.entries(initialValues).reduce((accu, i) => {
      accu[i[0]] = null;
      return accu;
    }, {}),
  );

  const updateField = (name: string, value: string | Partial<T>) =>
    dispatchValues({
      type: SET,
      field: name,
      payload: value,
    });

  const updateError = (name: string, value: string) =>
    dispatchErrors({
      type: SET,
      field: name,
      payload: value,
    });

  const resetForm = useCallback(() => {
    dispatchValues({
      type: RESET,
      payload: initialValues,
    });
  }, [initialValues]);

  useEffect(() => {
    if (!enableReintialization) return;
    const areIntialValuesEqual = isEqual(initialValues, previousInitialValue);
    if (!areIntialValuesEqual) {
      resetForm();
    }
  }, [initialValues, enableReintialization, resetForm, previousInitialValue]);
  return {
    values,
    errors,
    updateField,
    updateError,
    resetForm,
  };
};

export default useForm;
