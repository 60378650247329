import * as React from "react";
import {T} from "components/elements";

const OneLineAddress = ({street1, street2, city, state, zip}) => (
  <T>
    {" "}
    {street1} {street2}, {city}, {state} {zip}
  </T>
);

export default OneLineAddress;
