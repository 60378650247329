import * as React from "react";
import { useLocation, useHistory } from "react-router-dom";
import qs from "qs";

import useRefetchableResource from "./useRefetchableResource";

type Query = {
  filters: Record<string, string>;
  sort?: Record<string, string>;
  skip?: string | number;
  limit?: string | number;
};

// If two components are being rendered simultaneously they need to be given their own unique subQueries
const useSearchQuery = ({
  path,
  defaults = { filters: {} },
  subQuery,
}: {
  /** API path */
  path: string;
  /** Default search query parameters */
  defaults?: Query;
  /** Control only part of the search query */
  subQuery?: string;
}) => {
  const history = useHistory();
  const { search } = useLocation();

  const params = React.useMemo<Partial<Query>>(
    () => qs.parse(search.substr(1)),
    [search],
  );

  const query = React.useMemo<Query>(() => {
    const queryParams = (subQuery ? params[subQuery] : params) || {};
    return { ...defaults, ...queryParams };
  }, [params, subQuery, defaults]);

  const updateQuery = React.useCallback(
    (newQuery: Query) => {
      const newQueryParams = subQuery
        ? { ...params, [subQuery]: newQuery }
        : newQuery;
      const newQueryString = `?${qs.stringify(newQueryParams)}`;
      history.replace({ search: newQueryString });
    },
    [subQuery, params, history],
  );

  // project wide search for "updateQuery({...query" and replace with useEffectFilterUpdate
  const useEffectFilterUpdate = React.useCallback(
    (newFilters: Query["filters"]) => {
      const filtersShouldUpdate = Object.entries(newFilters).some(
        ([key, value]) => query.filters[key] !== value,
      );

      if (filtersShouldUpdate) {
        const newQuery: Query = {
          ...query,
          filters: { ...query.filters, ...newFilters },
        };
        updateQuery(newQuery);
      }
    },
    [query, updateQuery],
  );

  const data = useRefetchableResource({ path, data: query });

  return {
    query,
    updateQuery,
    useEffectFilterUpdate,
    ...data,
  };
};

export default useSearchQuery;
