import * as React from "react";
import { Spinner } from "./Spinner";
import css from "./loadingspinner.module.css";
interface LoadingSpinnerProps {
  spinning?: boolean;
  children?: JSX.Element | JSX.Element[] | React.ReactNode;
  tip?: string;
  style?: React.CSSProperties;
}

const LoadingSpinner = ({
  spinning = true,
  tip,
  children,
  style,
}: LoadingSpinnerProps) => {
  if (spinning) {
    const defaultStyle: React.CSSProperties | boolean = children
      ? { position: "absolute" }
      : null;
    return (
      <>
        <div className={css.loading} style={style ? style : defaultStyle}>
          <div className={css.loadingContent}>
            <div className={css.loadingSpinner}>
              <Spinner />
            </div>
            <p className={css.loadingSpinnerTip}>
              {tip ? tip : "Retrieving Data"}
            </p>
          </div>
        </div>
        {children || null}
      </>
    );
  }
  return <>{children || null}</>;
};

export { LoadingSpinner };
