import styled from "styled-components";

const OverflowText = styled.span`
  && {
    white-space: nowrap;
    overflow: hidden;
    max-width: ${({minWidth}) => minWidth};
    text-overflow: ${({type}) => type || "ellipsis"};
  }
`;

export default OverflowText;
