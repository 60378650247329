import React, { Fragment, useState } from "react";
import { Formik, Field } from "formik";
import { Col, Form } from "antd";
import { useSearchQuery } from "hooks";
import { strings } from "common/constants";
import { Titles, Button, Row } from "../../elements";
import { Tables } from "../../fragments";
import { Fields } from "../..";
import css from "./addEntities.module.css";

const paginationObj = (skip, limit, total) => ({
  current: (Number(skip) + Number(limit)) / Number(limit),
  pageSize: Number(limit),
  total: Number(total),
});

const AddEntities = ({
  locationId,
  location,
  history,
  onSuccess,
  entities,
  entity,
  ...props
}) => {
  const [selectedIds, setSelectedIds] = useState([]);
  const defaultQuery = {
    filters: {
      name: "",
      externalId: "",
    },
    skip: 0,
    limit: 10,
  };
  const { query, resource, fetching, updateQuery, useEffectFilterUpdate } =
    useSearchQuery({
      defaults: defaultQuery,
      path: entity === strings.ENTITY_TYPE.MENU ? "/menus" : `/menu-${entity}s`,
      subQuery: "addEntities",
    });

  useEffectFilterUpdate({ locationId });

  let total = 0;
  let results;
  if (resource) {
    if (resource.total) {
      ({ total } = resource);
    }
    ({ results } = resource);
  }

  const onPageChange = ({ current }) => {
    updateQuery({ ...query, skip: (current - 1) * query.limit });
  };

  const rowSelection = {
    onChange: (selectedRowKeys, newSelectedIds) => {
      setSelectedIds(newSelectedIds);
    },
  };

  return (
    <Fragment>
      <Row spacing={40}>
        <Formik
          initialValues={query}
          onSubmit={(values, formActions) => {
            updateQuery(values);
            formActions.setSubmitting(false);
          }}
          render={(subFormik) => (
            <Form
              colon={false}
              onSubmit={(e) => {
                e.stopPropagation();
                subFormik.handleSubmit(e);
              }}
              className="form-container"
            >
              <Row gutter={15} spacing={15}>
                <Col xs={24} md={12}>
                  <Field
                    label="Name"
                    name="filters.name"
                    placeholder="Name"
                    component={Fields.Input}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <Field
                    label="POS GUID"
                    name="filters.externalId"
                    placeholder="POS GUID"
                    component={Fields.Input}
                  />
                </Col>
              </Row>

              <Row spacing={15} gutter={15}>
                <Col xs={12} lg={6}>
                  <Button
                    htmlType="submit"
                    disabled={subFormik.isSubmitting}
                    color="blue"
                  >
                    Search
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        />
      </Row>

      <Row spacing={40}>
        <Row>
          <Col xs={6}>
            <Titles.CardTitle>
              <span className={css.capitalize}>{entity}</span>
            </Titles.CardTitle>
          </Col>
          <Col xs={18} className={css.rightAlign}>
            <Formik
              onSubmit={async () => {
                props.addEntities(selectedIds);
                onSuccess();
              }}
              render={(subFormik) => (
                <Form
                  colon={false}
                  onSubmit={(e) => {
                    e.stopPropagation();
                    subFormik.handleSubmit(e);
                  }}
                >
                  <Button
                    size="small"
                    htmlType="submit"
                    disabled={!selectedIds[0] || subFormik.isSubmitting}
                    color="blue"
                  >
                    <span className={css.upperCase}>{`Add ${entity}`}</span>
                  </Button>
                </Form>
              )}
            />
          </Col>
        </Row>
        <Col>
          <Tables.Table
            rowSelection={rowSelection}
            dataSource={results}
            pagination={paginationObj(query.skip, query.limit, total)}
            onChange={onPageChange}
            loading={fetching}
          >
            <Tables.Table.Column title="Name" dataIndex="name" />
            <Tables.Table.Column title="GUID" dataIndex="externalId" />
          </Tables.Table>
        </Col>
      </Row>
    </Fragment>
  );
};

export default AddEntities;
