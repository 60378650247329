import React from "react";
import classnames from "classnames";

const withCustomClass =
  (Component, customClass) =>
  ({className, ...props}) => {
    const classes = classnames(customClass, className);
    return <Component {...props} className={classes} />;
  };

export default withCustomClass;
