import React, {useState, useContext} from "react";

const PagesVisited = React.createContext();

const Provider = ({children}) => {
  const [pages, setPages] = useState([]);

  const pushPage = (url) => {
    setPages([...pages, url]);
  };

  const clearPages = () => {
    setPages([]);
  };

  const contextValues = {
    pages,
    pushPage,
    clearPages,
  };

  return (
    <PagesVisited.Provider value={contextValues}>
      {typeof children === "function"
        ? React.Children.only(children(contextValues))
        : children}
    </PagesVisited.Provider>
  );
};

const usePagesVisited = () => {
  const contextValues = useContext(PagesVisited);
  if (!contextValues) {
    throw new Error("usePagesVisited must be used within PagesVisitedProvider");
  }
  return contextValues;
};

export {usePagesVisited};
export default {
  Provider,
  PagesVisited,
};
