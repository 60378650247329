import * as Yup from "yup";

const roleSchema = Yup.object({
  permissions: Yup.array()
    .of(Yup.string())
    .min(1, "At least one permission must be selected"),
});

const newRoleSchema = Yup.object({
  role: Yup.string().required("Role name is required"),
  permissions: Yup.array()
    .of(Yup.string())
    .min(1, "At least one permission must be selected"),
});

export default {
  RoleSchema: roleSchema,
  NewRoleSchema: newRoleSchema,
};
