/**
 * Takes in a string & returns a camelized version of the string
 * @author Htin Linn Aung
 * @memberof Utils
 * @param {string} string - A string that might have hyphens, underscores, white-spaces or commas in it
 * @returns {string} - A camelized string
 */
const camelizeString = (string) =>
  string.replace(/^([A-Z])|[\s-_]+(\w)/g, (match, p1, p2, offset) => {
    if (p2) return p2.toUpperCase();
    return p1.toLowerCase();
  });

export default camelizeString;
