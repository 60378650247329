import * as Yup from "yup";

const menuSchema = Yup.object({
  name: Yup.string().required(),
  excludedLocations: Yup.array(),
});

const groupSchema = Yup.object({
  name: Yup.string().required(),
  excludedLocations: Yup.array(),
});

const itemSchema = Yup.object({
  name: Yup.string().required(),
  price: Yup.number()
    .moreThan(-1)
    .required()
    .typeError("Please enter a number"),
  alternatePrices: Yup.array().of(
    Yup.object({
      price: Yup.number().moreThan(-1).required(),
      location: Yup.string().required(),
    }),
  ),
  excludedLocations: Yup.array(),
});

const optionSchema = Yup.object({
  name: Yup.string().required(),
  min: Yup.number().typeError("Please enter a number").nullable(),
  max: Yup.number().typeError("Please enter a number").nullable(),
  excludedLocations: Yup.array(),
});

const entitiesSchemas = {
  menuSchema,
  groupSchema,
  itemSchema,
  optionSchema,
};

export { entitiesSchemas };
