import * as React from "react";
import { Breadcrumb } from "@lunchboxinc/utensils";
import { Link } from "react-router-dom";

import type { BreadcrumbProps } from "@lunchboxinc/utensils";

function BreadcrumbNav({ pages }: BreadcrumbProps) {
  return (
    <Breadcrumb
      pages={pages}
      linkComponent={{
        Component: Link,
        hrefPropName: "to",
      }}
    />
  );
}

export { BreadcrumbNav };
