import * as React from "react";
import {utils} from "common";
import classnames from "classnames";
import PropTypes from "prop-types";
import css from "./price.module.css";

const {formatSubtractingPrice} = utils;

const alignClass = (align) => {
  switch (align) {
    case "right":
      return css.right;
    case "left":
      return css.left;
    case "center":
      return css.center;
    default:
      return null;
  }
};

/**
 * Number formatter for displaying prices
 * @memberof Components.Elements
 * @prop {integer} props.value - The number in cents that will be converted to a dollar amount
 * @prop {"right" | "left" | "center"} props.align - Applies the respective align style
 * @prop {boolean} props.inline - Whether the display should be inline/true or block/false
 * @prop {string} props.className - Applies a css class to the div
 * Props are spread, all DOM props will be applied
 * @returns {React.Element} - Returns a formatted number for a price
 */

const Price = ({value, align, inline, className, ...props}) => {
  const price = value || 0;
  const classes = classnames(
    alignClass(align),
    inline && css.inline,
    className,
  );

  const isNegative = value < 0;
  let formattedPrice = formatSubtractingPrice(price);
  if (isNegative) formattedPrice = `(${formattedPrice})`;

  return (
    <div {...props} className={classes}>
      {formattedPrice}
    </div>
  );
};

Price.propTypes = {
  align: PropTypes.string,
  className: PropTypes.string,
  inline: PropTypes.bool,
  value: PropTypes.number,
};

Price.defaultProps = {
  align: "right",
  className: "",
  inline: false,
};

export default Price;
