import React from "react";
import {Select, Form} from "antd";
import {T} from "components/elements";

const AutoSelect = ({
  updateFilters,
  options,
  label,
  form,
  field,
  initialValues,
  ...props
}) => {
  const {setFieldValue, resetForm} = form;

  const handleChange = (val) => {
    setFieldValue(field.name, val);

    const updatedFilters = {
      ...initialValues,
      filters: {
        ...initialValues.filters,
        type: val,
      },
    };

    resetForm(updatedFilters);
    updateFilters(updatedFilters);
  };

  return (
    <Form.Item label={<T label>{label}</T>}>
      <Select {...field} style={{width: "90%"}} onChange={handleChange}>
        {options &&
          options.map((i, index) => (
            <Select.Option key={index} value={i.value}>
              {i.name}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );
};

export default AutoSelect;
