import AnalyticsOverview from "./AnalyticsOverview";
import GuestsAnalytics from "./GuestsAnalytics";
import SalesReportsAnalytics from "./SalesReportsAnalytics";
import GiftCardAnalytics from "./GiftCardAnalytics";
import {
  SubscriptionAnalytics,
  SubscriptionDailyReports,
} from "./SubscriptionAnalytics";

export default {
  AnalyticsOverview,
  GuestsAnalytics,
  SalesReportsAnalytics,
  GiftCardAnalytics,
  SubscriptionAnalytics,
  SubscriptionDailyReports,
};
export {
  AnalyticsOverview,
  GuestsAnalytics,
  SalesReportsAnalytics,
  GiftCardAnalytics,
  SubscriptionAnalytics,
  SubscriptionDailyReports,
};
