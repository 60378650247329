import React from "react";
import PropTypes from "prop-types";
import {Modal} from "antd";
import {useActive} from "hooks";
import {Button} from "components/elements";
import {ImageCarousel, SingleImageSelection} from "components/fragments";
import Media from "./Media";

const MediaSelection = ({isSingleSelection = false, onSelect, ...props}) => {
  const {isActive, activate, deactivate} = useActive(false);
  const onSelectMedia = (i) => {
    onSelect(i.versions[0].path);
    deactivate();
  };
  return (
    <>
      {isSingleSelection ? (
        <SingleImageSelection {...props} onClickAdd={activate} />
      ) : (
        <ImageCarousel {...props} onClickAdd={activate} />
      )}
      <Modal
        closable
        style={{top: "1rem"}}
        visible={isActive}
        width="80vw"
        onCancel={deactivate}
        footer={[
          <Button color="blue" size="medium" onClick={deactivate}>
            Close
          </Button>,
        ]}
      >
        <Media onSelectMedia={onSelectMedia} />
      </Modal>
    </>
  );
};

MediaSelection.propTypes = {
  onSelect: PropTypes.func,
};

MediaSelection.defaultProps = {
  onSelect: (i) => null,
};

export default MediaSelection;
export {MediaSelection};
