import * as Yup from "yup";

const audienceSchema = Yup.object({
  name: Yup.string().required("Audience name is required."),
  query: Yup.object({
    children: Yup.array().of(
      Yup.object({
        rOperands: Yup.array()
          .of(Yup.string().required("Search criteria is missing."))
          .min(1, "Search criteria is missing."),
      }),
    ),
  }),
});

export { audienceSchema };
