import { IconProp } from "@fortawesome/fontawesome-svg-core";

export const OPERATE_NAV = "operate";
export const ENGAGE_NAV = "engage";
export const ANALYZE_NAV = "analyze";
export const SETTINGS_NAV = "settings";

export interface NavOptionProps {
  label: string;
  icon?: IconProp | null;
  menuType: string;
  path?: string;
}

export interface SideMenuItem {
  disabled: boolean;
  label: string;
  icon?: IconProp;
  path?: string;
  restrictProduction?: boolean;
  subItems?: SideMenuItem[];
  superAdminOnly?: boolean;
}

export type SideNavItem = {
  isHoverExpanded?: boolean;
  isSettingsMenu: boolean;
  isSubmenu?: boolean;
  menuDropdownOpen?: string;
  menuItem: SideMenuItem;
  minNav: boolean;
  setMenuDropdownOpen: Function;
};

export const NAV_OPTIONS = {
  [ANALYZE_NAV]: {
    label: "Analyze",
    icon: "tachometer-alt",
    menuType: ANALYZE_NAV,
    path: "/analyze",
  },
  [ENGAGE_NAV]: {
    label: "Engage",
    icon: "volume-up",
    menuType: ENGAGE_NAV,
    path: "/engage",
  },
  [OPERATE_NAV]: {
    label: "Operate",
    icon: "pizza-slice",
    menuType: OPERATE_NAV,
    path: "/operate",
  },
  [SETTINGS_NAV]: {
    label: "Settings",
    icon: "cog",
    menuType: SETTINGS_NAV,
    path: "/settings",
  },
};

export const NAV_MENU_ORDER = [OPERATE_NAV, ENGAGE_NAV, ANALYZE_NAV];

export const NAV_MENUS = {
  [ANALYZE_NAV]: [
    { label: "Dashboards", icon: "home", path: "/analyze/dashboards" },
    {
      label: "Reports",
      icon: "file-invoice-dollar",
      path: "/analyze/reports",
    },
    {
      label: "Exports",
      path: "/exports",
      icon: "download",
      restrictProduction: true,
      disabled: true,
    },
  ],
  [ENGAGE_NAV]: [
    { label: "All Guests", icon: "users", path: "/all-guests" },
    { label: "Audiences", icon: "sliders-h", path: "/audiences" },
    {
      label: "Push Notifications",
      icon: "volume-up",
      path: "/push-notifications",
    },
    {
      label: "Loyalty",
      icon: "shopping-bag",
      path: "#",
      subItems: [
        { label: "Loyalty Triggers", path: "/loyalty-triggers" },
        {
          label: "Loyalty Wizard",
          path: "/loyalty-wizard",
          superAdminOnly: true,
        },
      ],
    },
    { label: "Discounts", icon: "dollar-sign", path: "/discounts" },
  ],
  [OPERATE_NAV]: [
    {
      label: "Orders",
      icon: "shopping-cart",
      path: "#",
      subItems: [
        { label: "All Orders", path: "/orders" },
        { label: "Catering", path: "/catering" },
        { label: "Catering Groups", path: "/catering-groups" },
      ],
    },
    {
      label: "Menus",
      icon: "list",
      path: "#",
      subItems: [
        { label: "Menus", path: "/menus" },
        { label: "Groups", path: "/groups" },
        { label: "Items", path: "/items" },
        { label: "Options", path: "/options" },
        { label: "Upsells", path: "/upsells" },
      ],
    },
    {
      label: "Hours",
      icon: "clock",
      path: "#",
      subItems: [
        { label: "Dining Hours", path: "/dining-hours" },
        { label: "Holiday Hours", path: "/holiday-hours" },
      ],
    },
    { label: "Locations", icon: "map-marker-alt", path: "/locations" },
    {
      label: "Subscriptions",
      icon: "bell",
      path: "#",
      subItems: [
        { label: "Schedule", path: "/subscriptions" },
        { label: "Subscribers", path: "/subscribers" },
        { label: "Daily Summary", path: "/subscription-daily-reports" },
      ],
    },
    { label: "Gift Cards", icon: "gift", path: "/gift-cards" },
  ],
  [SETTINGS_NAV]: [
    { label: "Integrations", path: "/integrations-editor" },
    { label: "Payments", path: "/payments" },
    { label: "Taxes", path: "/taxes" },
    { label: "Service Charges", path: "/service-charges" },
    { label: "Company Settings", path: "/company-settings" },
    { label: "Roles", path: "/roles" },
    { label: "Email Templates", path: "/email-templates" },
    { label: "Media", path: "/media" },
    { label: "Home Content", path: "/app-home-content" },
    {
      label: "Files",
      path: "/files",
      restrictProduction: true,
    },
    { label: "Marketplaces", path: "/marketplaces" },
    { label: "Pincodes", path: "/pincodes" },
    { label: "Cron Actions", path: "/cron-actions", superAdminOnly: true },
    { label: "Apps", path: "/apps", restrictProduction: true, disabled: true },
    { label: "DNS Setup", path: "/dns-setup", superAdminOnly: true },
    { label: "Restaurant Group", path: "/restaurant-group" },
    { label: "Admins", path: "/admins" },
    {
      label: "App Config",
      path: "#",
      subItems: [
        { label: "Configurations", path: "/app-configuration" },
        { label: "Themes", path: "/themes" },
      ],
    },
  ],
};
