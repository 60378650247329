import * as Yup from "yup";

const baseLocationSchema = Yup.object().shape({
  deliveryHours: Yup.object().shape({
    hours: Yup.array()
      .of(
        Yup.object().shape(
          {
            day: Yup.number().required(),
            deliveryOpen: Yup.string().when("deliveryClose", {
              is: (deliveryClose) => deliveryClose,
              then: Yup.string().typeError("Required").required("Required"),
            }),
            deliveryClose: Yup.string().when("deliveryOpen", {
              is: (deliveryOpen) => deliveryOpen,
              then: Yup.string().typeError("Required").required("Required"),
            }),
          },
          ["deliveryOpen", "deliveryClose"],
        ),
      )
      .min(7),
  }),
  pickupHours: Yup.object().shape({
    hours: Yup.array()
      .of(
        Yup.object().shape(
          {
            day: Yup.number().required(),
            pickupOpen: Yup.string().when("pickupClose", {
              is: (pickupClose) => pickupClose,
              then: Yup.string().typeError("Required").required("Required"),
            }),
            pickupClose: Yup.string().when("pickupOpen", {
              is: (pickupOpen) => pickupOpen,
              then: Yup.string().typeError("Required").required("Required"),
            }),
          },
          ["pickupOpen", "pickupClose"],
        ),
      )
      .min(7),
  }),
  dineInHours: Yup.object().shape({
    hours: Yup.array()
      .of(
        Yup.object().shape(
          {
            day: Yup.number().required(),
            dineInOpen: Yup.string().when("dineInClose", {
              is: (dineInClose) => dineInClose,
              then: Yup.string().typeError("Required").required("Required"),
            }),
            dineInClose: Yup.string().when("dineInOpen", {
              is: (dineInOpen) => dineInOpen,
              then: Yup.string().typeError("Required").required("Required"),
            }),
          },
          ["dineInOpen", "dineInClose"],
        ),
      )
      .min(7),
  }),
  isCashAccepted: Yup.boolean(),
  timeZone: Yup.string().required(),
  serviceEstimates: Yup.object().shape({
    prep: Yup.number()
      .typeError("Prep Estimate must be a number")
      .min(0, "Prep Estimate must be 0 or greater")
      .nullable(true),
    delivery: Yup.number()
      .typeError("Delivery Estimate must be a number")
      .min(0, "Delivery Estimate must be 0 or greater")
      .nullable(),
    threshold: Yup.number()
      .typeError("Estimate Threshold must be a number")
      .min(1, "Estimate Threshold must be 1 or greater")
      .nullable(),
    increment: Yup.number()
      .typeError("Estimate Increment must be a number")
      .min(0, "Estimate Increment must be 0 or greater")
      .nullable(),
  }),
});

const locationSchema = baseLocationSchema.shape({
  cateringDeliveryMinimum: Yup.number()
    .typeError("Catering Delivery Minimum must be a number")
    .min(0, "Catering Delivery Minimum must be 0 or greater")
    .nullable(true),
  standardDeliveryMinimum: Yup.number()
    .typeError("Standard Delivery Minimum must be a number")
    .min(0, "Standard Delivery Minimum must be 0 or greater")
    .nullable(true),
  orderThrottling: Yup.object({
    standard: Yup.object({
      deliver: Yup.object({
        count: Yup.number()
          .transform((value) => (isNaN(value) ? null : value))
          .nullable(),
      }),
      pickup: Yup.object({
        count: Yup.number()
          .transform((value) => (isNaN(value) ? null : value))
          .nullable(),
      }),
    }),
    catering: Yup.object({
      deliver: Yup.object({
        count: Yup.number()
          .transform((value) => (isNaN(value) ? null : value))
          .nullable(),
      }),
      pickup: Yup.object({
        count: Yup.number()
          .transform((value) => (isNaN(value) ? null : value))
          .nullable(),
      }),
    }),
  }),
});

const newLocationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  externalId: Yup.string().required("External ID is required"),
  placeId: Yup.string().required("Address is required"),
});

const newLocationOutpostSchema = Yup.object().shape({
  placeId: Yup.string().required("Address selection is required"),
  slug: Yup.string().required("URL slug is required"),
  street2: Yup.string(),
  phone: Yup.string()
    .typeError("Phone number is required")
    .matches(/^[0-9 ()+-]{10,}$/i, "Phone number is not valid")
    .required("Phone number is required"),
  name: Yup.string().required("Name is required"),
});

export {
  baseLocationSchema,
  locationSchema,
  newLocationOutpostSchema,
  newLocationSchema,
};

export default {
  baseLocationSchema,
  locationSchema,
  newLocationOutpostSchema,
  newLocationSchema,
};
