import React from "react";
import {Formik, Field} from "formik";
import {Form, message} from "antd";
import {Fields} from "components";
import {Button} from "components/elements";
import {useAPIContext} from "components/providers/Api";
import {mapAxiosError} from "common/utils";
import {entitiesSchemas} from "common/schemas";
import {LocationSelector} from "./LocationSelector";

const CreateGroup = ({drawerProps, menuId, groupId, fetchResults}) => {
  const {MenuGroups} = useAPIContext();

  const save = async (formValues, actions) => {
    const body = {
      ...formValues,
    };
    if (menuId) body.menu = menuId;
    if (groupId) body.group = groupId;

    try {
      actions.setSubmitting(true);
      await MenuGroups.create(body);
      message.success("Successfully Saved");
      fetchResults();
    } catch (e) {
      message.error(mapAxiosError(e));
    } finally {
      actions.setSubmitting(false);
      drawerProps.close();
    }
  };
  return (
    <Formik
      initialValues={{
        name: "",
        excludedLocations: [],
      }}
      validationSchema={entitiesSchemas.groupSchema}
      onSubmit={(formValues, actions) => save(formValues, actions)}
    >
      {({handleSubmit, isSubmitting, setFieldValue}) => (
        <Form onSubmit={handleSubmit}>
          <Field
            label="Name"
            name="name"
            placeholder="Name"
            component={Fields.Input}
          />
          <LocationSelector
            title="Excluded Locations"
            addLocations={(locations) =>
              setFieldValue("excludedLocations", locations)
            }
          />
          <Button
            htmlType="submit"
            size="small"
            color="blue"
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Create
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export {CreateGroup};
