import React from "react";
import {Select} from "antd";
import {useResource} from "hooks";
import {useUserContext} from "components/providers/User";

const findAppId = (arr = [], client) =>
  arr.findIndex((item) => item.appId === client);

const SelectRestaurantDropdown = (props) => {
  const user = useUserContext();
  const {isSuperAdmin} = user;

  const componentProps = {
    ...props,
    admin: user,
  };
  if (isSuperAdmin) {
    return <SuperAdminRestaurantDropdown {...componentProps} />;
  }

  return <AdminRestaurantDropdown {...componentProps} />;
};

const SuperAdminRestaurantDropdown = (props) => {
  const {resource: restaurantGroupsResource, fetchingRestaurantGroups} =
    useResource({
      path: "/restaurant-groups",
      data: {filters: {name: ""}, limit: 10000},
    });
  const {resource: marketplacesResource, fetchingMarketplaces} = useResource({
    path: "/marketplaces",
    data: {filters: {name: ""}},
  });
  const restaurantGroups = restaurantGroupsResource.results || [];

  // Optional chaining temporarily so we're backwards compatible with the backend
  // remove the optional chain and middle condition when backend is updated
  const marketplaces =
    marketplacesResource?.results || marketplacesResource || [];

  if (
    fetchingRestaurantGroups ||
    fetchingMarketplaces ||
    !restaurantGroups.length
  ) {
    return null;
  }

  return (
    <RestaurantDropdown
      {...props}
      restaurantGroups={restaurantGroups}
      marketplaces={marketplaces}
    />
  );
};

const AdminRestaurantDropdown = (props) => {
  const {
    admin: {user},
  } = props;
  const restaurantGroups = user.restaurantGroups || [];
  const marketplaces = user.marketplaces || [];

  if (restaurantGroups.length + marketplaces.length <= 1) {
    return null;
  }

  return (
    <RestaurantDropdown
      {...props}
      restaurantGroups={restaurantGroups}
      marketplaces={marketplaces}
    />
  );
};

/**
 * Returns Select component with RG/Marketplaces listed for the admin logged in to select from
 * @param {object} props
 * @param {string} props.className - an optional class name to apply to the select component
 * @param {string} props.restaurantGroups - array of RGs to show
 * @param {string} props.marketplaces - an optional class name to apply to the select component
 * @param {string} props.restaurantGroups - Fallback src used for placeholders if src is not provided
 * @param {string} props.alt - alt text of image
 */
const RestaurantDropdown = ({
  admin,
  className,
  restaurantGroups = [],
  marketplaces = [],
}) => {
  const {user, client, onChangePlatform, setRgConfiguration} = admin;
  const restaurantGroupMatch = findAppId(restaurantGroups, client);
  const marketplaceMatch = findAppId(marketplaces, client);

  let index;
  if (restaurantGroupMatch !== -1) {
    index = restaurantGroupMatch;
    setRgConfiguration(restaurantGroups[index]?.configurations);
  } else if (marketplaceMatch !== -1) {
    index = marketplaceMatch * -1 - 1;
  } else {
    index = restaurantGroups.findIndex(
      (item) => item.id === user.restaurantGroup,
    );
    setRgConfiguration(restaurantGroups[index]?.configurations ?? {});
  }

  return (
    <Select
      showSearch
      className={className}
      value={index}
      placeholder=""
      onChange={async (idx) => {
        if (idx < 0) {
          const marketplaceIndex = (idx + 1) * -1;
          const {appId, id} = marketplaces[marketplaceIndex];
          onChangePlatform(appId, "marketplace", id);
          setRgConfiguration({});
        } else {
          const {appId, configurations, id} = restaurantGroups[idx];
          onChangePlatform(appId, "admin", id);
          setRgConfiguration(configurations);
        }
      }}
      filterOption={(input, option) => {
        if (typeof option.props.children === "string") {
          return (
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          );
        }
        return false;
      }}
    >
      <Select.OptGroup label="Marketplaces">
        {marketplaces.map((i, idx) => (
          <Select.Option key={i.id} value={idx * -1 - 1}>
            {i.name}
          </Select.Option>
        ))}
      </Select.OptGroup>
      <Select.OptGroup label="Restaurant Groups">
        {restaurantGroups.map((i, idx) => (
          <Select.Option key={i.id} value={idx}>
            {i.name}
          </Select.Option>
        ))}
      </Select.OptGroup>
    </Select>
  );
};

RestaurantDropdown.defaultProps = {
  user: {},
  className: "",
  restaurantGroups: [],
  marketplaces: [],
  fetching: false,
};

export default SelectRestaurantDropdown;
