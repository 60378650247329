const { sessionStorage } = window;

const getAdminSession = () => {
  // read from session storage to get last selected RG and platform
  let storageDateInSession = sessionStorage?.getItem("platform");

  // initiliaze these values to the sessionStorage
  let storageData = {
    client: "empty",
    platform: "admin",
    restaurantGroupId: "empty",
  };

  if (storageDateInSession) {
    storageData = JSON.parse(storageDateInSession);
  }
  return storageData;
};

export default getAdminSession;
