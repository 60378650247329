import * as React from "react";
import { MenuItem } from "./MenuItem";
import { SideNavItem } from "../menuConfig";
import { useAPIContext } from "components/providers/Api";
import { S } from "./styled";

const MenuItemDropdown = ({
  isHoverExpanded,
  isSettingsMenu,
  menuItem,
  menuDropdownOpen,
  minNav,
  setMenuDropdownOpen,
}: SideNavItem) => {
  const { isProduction } = useAPIContext();
  const { icon, label, path, restrictProduction, subItems } = menuItem;
  const hasSubItems = subItems?.length;
  const isOpen = menuDropdownOpen === label;
  const showLabel = isSettingsMenu || !minNav || isHoverExpanded;
  const showSubItems = isOpen && hasSubItems && showLabel;
  const formattedLabel = `sidebar_${label.replace(/ /g, "_").toLowerCase()}`;

  if (isProduction && restrictProduction) return null;

  return (
    <>
      <S.MenuDropdownContainer id={formattedLabel}>
        <S.MenuDropdownButton
          to={path}
          role="button"
          aria-expanded={isOpen}
          aria-label={label}
          key={label}
          onClick={() => {
            setMenuDropdownOpen(isOpen ? "" : label);
          }}
          aria-controls={isOpen ? `${formattedLabel}_region` : undefined}
        >
          <S.MenuButtonLabel>
            {isSettingsMenu && <S.SubMenuSpan padNumber={30} />}
            {icon && (
              <S.MenuIconContainer isActive={false}>
                <S.MenuIcon icon={icon} fixedWidth />
              </S.MenuIconContainer>
            )}

            {showLabel ? label : ""}
          </S.MenuButtonLabel>
          {hasSubItems && showLabel && (
            <S.MenuArrowIcon icon={isOpen ? "chevron-up" : "chevron-down"} />
          )}
        </S.MenuDropdownButton>
      </S.MenuDropdownContainer>
      {showSubItems && (
        <div
          id={`${formattedLabel}_region`}
          aria-labelledby={formattedLabel}
          role="region"
        >
          {subItems.map((subItem) => (
            <MenuItem
              isHoverExpanded={isHoverExpanded}
              isSettingsMenu={isSettingsMenu}
              isSubmenu={true}
              key={subItem.label}
              menuItem={subItem}
              minNav={minNav}
              setMenuDropdownOpen={setMenuDropdownOpen}
            />
          ))}
        </div>
      )}
    </>
  );
};

export { MenuItemDropdown };
