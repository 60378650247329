import React from "react";
import { Select } from "antd";
import { useUserContext } from "components/providers/User";
import { utils } from "common";
import classnames from "classnames";
import { T } from "../../elements";

const { fetchParentNode } = utils;

const LocationDropDown = ({
  onLocationChange,
  route,
  name,
  value,
  disabled,
  label,
  className,
}) => {
  const { locations } = useUserContext();

  const classes = classnames(className);

  if (/^\/locations/i.test(route)) return null;

  return (
    <>
      <>
        {label}
        <Select
          className={classes}
          disabled={disabled}
          name={name}
          value={value}
          showSearch
          placeholder={<T>All Locations</T>}
          onSelect={(newValue, option) =>
            onLocationChange(
              option.key,
              locations.find((i) => i.id === newValue),
            )
          }
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          getPopupContainer={fetchParentNode}
        >
          <Select.Option key="all">All Locations</Select.Option>
          {locations
            .filter((location) => location.isActive)
            .map((location) => (
              <Select.Option key={location.id} value={location.id}>
                {location.name}
              </Select.Option>
            ))}
        </Select>
      </>
    </>
  );
};

export default LocationDropDown;
