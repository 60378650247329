import React from "react";
import classnames from "classnames";
import {Typography} from "antd";
import styles from "./text.module.css";

/**
 * All in one text utitlity, can be used for text snippets, blocks, editable text, etc. based off of antd's typography components
 * @memberof Components.Elements
 * @prop {JSX.Element | JSX.Element[]} children - Children components
 * @prop {string} className - Applies a css clas to wrapping div
 * @prop {boolean} copyable - Displays a copy icon which when clicked, will copy text to user clipboard
 * @prop {boolean | Object} ellipsis - Will add ellipsis to the end of text, if container boarder is reached. Refer to antd 3.x documentation for more customizability
 * @prop {boolean} underline - Will display an underline
 * @prop {boolean} inline - Will display text as inline instead of block
 * @prop {boolean} label - Will treat text as label and apply appropriate style
 * Props are spread, all DOM props will be applied
 * @returns {React.Element} - Returns a text element
 */

const T = ({
  children,
  className = "",
  copyable = undefined,
  ellipsis = undefined,
  underline = undefined,
  inline = undefined,
  label,
  ...props
}) => {
  const classes = classnames(
    inline ? styles.inlineText : styles.text,
    label && styles.label,
    styles.text,
    className,
  );
  return (
    <div className={classes} {...props}>
      <Typography.Text
        className={className}
        copyable={copyable}
        ellipsis={ellipsis}
        underline={underline}
      >
        {children}
      </Typography.Text>
    </div>
  );
};

T.defaultProps = {
  className: null,
  copyable: false,
  ellipsis: false,
  underline: false,
};

export default T;
