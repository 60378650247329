import { useReducer } from "react";

import {
  UPLOAD_LIMIT,
  UPLOAD_LIMIT_MESSAGE,
  UPLOAD_DUPLICATE_MESSAGE,
  UPLOAD_FILE_SIZE_LIMIT,
  UPLOAD_FILE_SIZE_LIMIT_MESSAGE,
} from "./utils";

const CLEAR = "CLEAR";
const ADD = "ADD";
const REMOVE = "REMOVE";
const UPDATE = "UPDATE";

const NEW_FILE_DEFAULTS = {
  name: "",
  altText: "",
  caption: "",
  description: "",
  fileName: "",
  tags: [],
  title: "",
  base64: "",
  isUploading: false,
  isUploaded: false,
  isOpen: true,
};

const hasFileName = (fileList, fileToCheck) => {
  return fileList.findIndex((i) => i.fileName === fileToCheck.name) !== -1;
};

const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const mapForUpload = (file) => {
  return {
    name: file.name,
    altText: file.altText,
    caption: file.caption,
    description: file.description,
    fileName: file.fileName,
    tags: file.tags,
    title: file.title,
    media: file.base64,
  };
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case CLEAR: {
      return [];
    }
    case ADD: {
      const { name, type: fileType, base64 } = payload;
      const newItem = {
        ...NEW_FILE_DEFAULTS,
        fileName: name,
        type: fileType,
        base64,
      };
      return [...state, newItem];
    }
    case REMOVE: {
      return state.filter((i, index) => index !== payload);
    }
    case UPDATE: {
      const { index, value } = payload;
      const newState = [...state];
      newState[index] = {
        ...newState[index],
        ...value,
      };
      return newState;
    }
    default:
      return state;
  }
};

const useFileList = ({ initState, uploadLimit, UploadAPI }) => {
  const [fileList, dispatch] = useReducer(reducer, initState ?? []);

  const clear = () => {
    dispatch({
      type: CLEAR,
    });
  };

  const add = (file) => {
    if (file.size > UPLOAD_FILE_SIZE_LIMIT) {
      throw new Error(`${file.name} - ${UPLOAD_FILE_SIZE_LIMIT_MESSAGE}`);
    }

    if (uploadLimit && fileList.length > uploadLimit) {
      throw new Error(UPLOAD_LIMIT_MESSAGE);
    }

    const isDuplicate = hasFileName(fileList, file);
    if (isDuplicate) {
      throw new Error(UPLOAD_DUPLICATE_MESSAGE);
    }
    fileToBase64(file).then((base64) => {
      file.base64 = base64;
      dispatch({
        type: ADD,
        payload: file,
      });
    });
  };

  const removeAtIndex = (removeIndex) => {
    dispatch({
      type: REMOVE,
      payload: removeIndex,
    });
  };

  const updateAtIndex = (index, value) => {
    dispatch({
      type: UPDATE,
      payload: {
        index,
        value,
      },
    });
  };

  const uploadAtIndex = async (index) => {
    const fileData = fileList[index];

    if (fileData.isUploaded || fileData.isUploading) return;
    const file = mapForUpload(fileData);
    updateAtIndex(index, { isUploading: true });
    try {
      UploadAPI && (await UploadAPI(file));
      updateAtIndex(index, { isUploaded: true });
    } catch (e) {
      console.error(e);
    } finally {
      updateAtIndex(index, { isUploading: false });
    }
  };

  const getUploadedCounts = () => {
    return fileList.reduce(
      (accu, i) => {
        accu[i.isUploaded ? "uploadedCount" : "notUploadedCount"] += 1;
        return accu;
      },
      { uploadedCount: 0, notUploadedCount: 0 },
    );
  };

  return [
    fileList,
    {
      clear,
      add,
      removeAtIndex,
      updateAtIndex,
      uploadAtIndex,
    },
    {
      getUploadedCounts,
    },
  ];
};

export { useFileList };
