import React, {useState, useEffect, useContext} from "react";
import {useLocation} from "react-router-dom";
import isEqual from "lodash/isEqual";
import debounce from "lodash/debounce";
import {useUserContext} from "./User";

const AppcuesContext = React.createContext();

const update = debounce((permissions, id) => {
  if (window.Appcues && window.Appcues.identify) {
    window.Appcues.identify(id, permissions);
  }
}, 250);

const Provider = ({children, ...props}) => {
  const {user} = useUserContext();
  const [permissions, setPermissions] = useState({});
  const location = useLocation();

  useEffect(() => {
    setPermissions({});
  }, [location]);

  const updatePermissions = (newPermissions) => {
    setPermissions((oldPermissions) => {
      const updatedPermission = {
        ...oldPermissions,
        ...newPermissions,
      };

      if (!isEqual(oldPermissions, updatedPermission)) {
        return updatedPermission;
      }
      return oldPermissions;
    });
  };

  useEffect(() => {
    if (user && user.id) {
      update(permissions, user.id);
    }
  }, [permissions, user]);

  const contextValues = {updatePermissions};

  return (
    <AppcuesContext.Provider value={contextValues}>
      {typeof children === "function"
        ? React.Children.only(children(contextValues))
        : children}
    </AppcuesContext.Provider>
  );
};

export const useAppcuesContext = () => {
  const contextValues = useContext(AppcuesContext);
  if (!contextValues) {
    throw new Error(
      "useAppcuesContext must be used within AppcuesContextProvider",
    );
  }
  return contextValues;
};

export {AppcuesContext};
export default {
  Provider,
};
