const sortValue = (query, sortKey) => {
  if (query.sort && query.sort[sortKey]) {
    if (Number(query.sort[sortKey]) === 1) {
      return { ...query, sort: { [sortKey]: -1 } };
    }
    const { sort, ...withoutSort } = query;
    return withoutSort;
  }

  return { ...query, sort: { [sortKey]: 1 } };
};

export { sortValue };
