import React from "react";
import {ReactComponent as NoResultsIcon} from "assets/images/no_results_icon.svg";
import {T} from "../../elements";
import css from "./noResults.module.css";

const NoResults = () => (
  <div className={css.container}>
    <NoResultsIcon className="noResultsIcon" />
    <T>No Results</T>
  </div>
);

export default NoResults;
export {NoResults};
