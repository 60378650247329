import { useEffect, useState, useRef } from "react";
import { utils } from "common";
import isEqual from "lodash/isEqual";
import usePrevious from "./usePrevious";

const { mapAxiosError } = utils;

const usePaginatedResource = (method, params) => {
  const [resource, setResource] = useState({});
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const prevParams = usePrevious(params);
  const firstPage = useRef(null);
  const hadMore = useRef(false);

  const fetch = async ({ prevId, nextId }) => {
    setFetching(true);
    try {
      const { data } = await method({
        ...params,
        startingAfter: nextId,
        endingBefore: prevId,
      });
      setResource(data);
    } catch (e) {
      const mappedErr = mapAxiosError(e);
      setError(mappedErr);
    } finally {
      setFetching();
    }
  };

  const { result: results = [], hasMore } = resource;
  const nextId = results.length ? results[results.length - 1].id : null;
  const prevId = results.length ? results[0].id : null;

  const previousPage = () => {
    if (!prevId) return;
    fetch({ prevId });
  };
  const nextPage = () => {
    if (!nextId) return;
    fetch({ nextId });
  };

  useEffect(() => {
    if (!firstPage.current) {
      firstPage.current = prevId;
      hadMore.current = hasMore;
    }
  }, [prevId, hasMore]);

  useEffect(() => {
    if (!isEqual(prevParams, params)) {
      fetch({});
    }
  }, [params]);

  return {
    fetch,
    resource,
    fetching,
    error,
    previousPage,
    nextPage,
    hasMore: prevId === firstPage.current ? hadMore.current : hasMore,
    isFirstPage: prevId === firstPage.current,
  };
};

export default usePaginatedResource;
