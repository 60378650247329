import * as React from "react";
import { Checkbox } from "../components/FigmaUI";
import Section from "../components/Section";
import { useLocationCard } from "./Context";

interface WaitTimesProps {
  onChange: InputChangeType;
}

function WaitTimes({ onChange }: WaitTimesProps) {
  const { componentConfig } = useLocationCard();
  return (
    <Section
      title="Wait Times"
      caption="Choose whether wait time is displayed when we know a customer's location."
    >
      <Checkbox
        label="Show wait time"
        checked={componentConfig.showWaitTime}
        name="showWaitTime"
        onChange={onChange}
      />
    </Section>
  );
}

export default WaitTimes;
