import React from "react";
import EditableTextField from "components/fields/EditableTextField";
import {EditableTitleSchema} from "common/schemas";
import styles from "./title.module.css";

const EditableTitle = ({name: initName, placeholder, save, ...props}) => {
  const onSave = (name) => save({name});

  return (
    <EditableTextField
      initialText={placeholder}
      className={styles.blueCardTitle}
      schema={EditableTitleSchema}
      save={onSave}
      {...props}
    >
      {initName}
    </EditableTextField>
  );
};

export default EditableTitle;
