import * as React from "react";

const ALERT_TIME = 5000;
const initialState = {
  autoDismiss: true,
  onDismiss: () => {},
  text: "",
  type: "",
};

type AlertContextProps = {
  autoDismiss?: boolean;
  onDismiss?: () => void;
  text: string;
  type: string;
  dismissAlert: () => void;
  setAlert: (a: string, b: string, c?: boolean, d?: Function) => void;
};

const AlertContext = React.createContext<AlertContextProps>({
  ...initialState,
  dismissAlert: () => {},
  setAlert: () => {},
});

export const AlertProvider = ({ children }) => {
  const [autoDismiss, setAutoDismiss] = React.useState(true);
  const [onDismiss, setOnDismiss] = React.useState(() => {});
  const [text, setText] = React.useState("");
  const [type, setType] = React.useState("");

  const dismissAlert = () => {
    setAutoDismiss(true);
    setOnDismiss(() => {});
    setText("");
    setType("");
  };

  const setAlert = (
    text: string,
    type: string,
    autoDismiss: boolean = true,
    onDismiss?: Function,
  ) => {
    setAutoDismiss(autoDismiss);
    if (onDismiss) setOnDismiss(() => onDismiss());
    setText(text);
    setType(type);

    if (autoDismiss) {
      setTimeout(() => {
        dismissAlert();
      }, ALERT_TIME);
    }
  };

  return (
    <AlertContext.Provider
      value={{
        autoDismiss,
        onDismiss: () => onDismiss,
        text,
        type,
        dismissAlert,
        setAlert,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContext;
