import * as Yup from "yup";

const adminSessionSchema = Yup.object({
  platform: Yup.string().lowercase().required("Platform is required"),
  client: Yup.string().lowercase().required("Client Id is required"),
});

export { adminSessionSchema };

export default adminSessionSchema;
