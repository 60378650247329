import StatCardIcon from "./StatCardIcon";
import IconText from "./IconText";
import CustomNavIcon from "./CustomNavIcon";
import OrderIcon from "./OrderIcon";

export default {
  StatCardIcon,
  IconText,
  CustomNavIcon,
  OrderIcon,
};
export { StatCardIcon, IconText, CustomNavIcon, OrderIcon };
