import * as React from "react";
import { S } from "./styled";

interface SettingsButtonProps {
  handleClick: () => void;
  isActive: boolean;
}

const SettingsButton = ({ handleClick, isActive }: SettingsButtonProps) => {
  return (
    <S.SettingsLink
      role="button"
      aria-label="Show settings"
      aria-selected={isActive}
      $isActive={isActive}
      onClick={handleClick}
      to="/integrations-editor"
    >
      {/* TODO: Replace with lb-utensils icon */}
      <S.SettingsIcon fixedWidth icon={"cog"} />
    </S.SettingsLink>
  );
};

export { SettingsButton };
