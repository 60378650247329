import * as React from "react";
import PropTypes from "prop-types";
import {TimePicker, Form} from "antd";

const TimePickerField = ({
  field: {onChange, ...field},
  form: {touched, errors, setFieldValue, setFieldTouched},
  label,
  useNumberComponent = false,
  ...props
}) => {
  const errorMsg = touched[field.name] && errors[field.name];

  const Comp = TimePicker;
  return (
    <Form.Item
      label={label}
      help={errorMsg}
      validateStatus={errorMsg ? "error" : undefined}
    >
      <Comp
        {...field}
        onBlur={undefined}
        use12Hours
        {...props}
        onChange={(time, timeString) => {
          setFieldValue(field.name.start, timeString);
          setFieldValue(field.name.end, timeString);
        }}
        onOpenChange={() => {
          setFieldTouched(field.name);
        }}
      />
    </Form.Item>
  );
};

TimePickerField.propTypes = {
  size: PropTypes.string,
  label: PropTypes.string,
  format: PropTypes.string.isRequired,
};

TimePickerField.defaultProps = {
  size: "small",
};

export default TimePickerField;
