import {Row as RowAnt} from "antd";
import styled from "styled-components";

/**
 * Wrapper for the antd row component
 * @memberof Components.Elements
 * @prop {integer} props.spacing - Adds a pixel amount to the bottom margin
 * One of the rare times we use styled components in the codebase
 * @returns {React.Element} - Returns a container for Col components
 */

const Row = styled(RowAnt)`
  && {
    margin-bottom: ${({spacing}) => spacing || 0}px;
  }
`;

export default Row;
