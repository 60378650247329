import * as Yup from "yup";

const complimentaryQuantityMessage =
  "You must specify a quantity greater than or equal to 0 and less than or equal to this option's Max quantity";

const schema = Yup.object().shape({
  externalId: Yup.string().matches(
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
  ),
  name: Yup.string().min(3).required(),
  min: Yup.number().min(0).nullable(),
  max: Yup.number().min(0).nullable(),
  items: Yup.array().of(Yup.string().matches(/^[a-f\d]{24}$/)),
  modifier: Yup.number().oneOf([1, 2]),
  visibility: Yup.string().oneOf(["ALL", "NONE", "POS_ONLY"]),
  // excludedLocations: Yup.array().of(Yup.string().matches(/^[a-f\d]{24}$/)),
  isActive: Yup.boolean(),
  isDeleted: Yup.boolean(),
  priority: Yup.number()
    .typeError("")
    .min(0, "You must specify a value from 0 to 10,000")
    .max(10000, "You must specify a value from 0 to 10,000"),
  complimentaryQuantity: Yup.number().when("max", {
    is: (val) => val > 1,
    then: Yup.number()
      .typeError(complimentaryQuantityMessage)
      .min(0, complimentaryQuantityMessage)
      .max(Yup.ref("max"), complimentaryQuantityMessage)
      .nullable(),
    otherwise: Yup.number().typeError(complimentaryQuantityMessage).nullable(),
  }),
});

export default schema;
