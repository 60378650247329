import React, {useEffect} from "react";
import {Col} from "antd";
import moment from "moment";
import {strings} from "common/constants";
import {Button, Titles} from "components/elements";
import {Condition, Toggles, DateRangeDropdown} from "components/fragments";
import classnames from "classnames";

import css from "./analytics.module.css";

const {BlueCardTitle} = Titles;
const {TriToggle} = Toggles;

const Header = ({actions, title, exportCSV, initialDateValues, submitting}) => {
  const {start, end} = initialDateValues || {};

  useEffect(() => {
    if (exportCSV) {
      actions.onPlatformChange(["web", "android", "ios", "catering", "other"]);
    }
  }, []);

  const handlePlatformChange = (platform) => {
    if (platform === strings.PLATFORM_TYPE.STANDARD) {
      actions.onPlatformChange(["web", "android", "ios", "other"]);
    } else if (platform === strings.PLATFORM_TYPE.COMBINED) {
      actions.onPlatformChange(["web", "android", "ios", "catering", "other"]);
    } else {
      actions.onPlatformChange(["catering"]);
    }
  };

  return (
    <div className={css.headerWrapper}>
      <Col xs={24} sm={24} md={24} lg={8}>
        <BlueCardTitle className={css.title}>{title}</BlueCardTitle>
      </Col>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={16}
        className={classnames(css.filters, "headerFilters")}
      >
        <Condition.Condition is={!exportCSV}>
          <DateRangeDropdown
            className={css.datePicker}
            onChange={actions.onCreatedAtChange}
            initialDateValues={{
              createdAt: {
                start: moment(start).utc(),
                end: moment(end).utc(),
              },
            }}
          />
        </Condition.Condition>
        <Condition.Condition is={exportCSV}>
          <TriToggle
            className={css.triToggle}
            options={["Standard", "Combined", "Catering"]}
            onChange={handlePlatformChange}
          />
          <DateRangeDropdown
            className={css.datePicker}
            onChange={actions.onCreatedAtChange}
            initialDateValues={{
              createdAt: {
                start: moment(start).utc(),
                end: moment(end).utc(),
              },
            }}
          />
          <Button
            className={css.filterButton}
            onClick={exportCSV}
            disabled={submitting}
            color="blue"
            size="small"
            height="40px"
          >
            Export CSV
          </Button>
        </Condition.Condition>
      </Col>
    </div>
  );
};

export default Header;
