/**
 * Takes in an array of objects & returns a mapped object
 * Using 'key' parameter as a key and an array of objects as a value (for each input array item)
 * @author Htin Linn Aung
 * @memberof Utils
 * @param {Array} arr - An input array of objects
 * @param {string} key - A property name of an object(an array item)
 * @returns {Object} A mapped object(using key parameter as a key & an array of objects as a value)
 */
const convertArray2ObjectOfArrays = (arr, key) => {
  const obj = {};
  arr.forEach((item) => {
    if (obj[item[key]]) {
      obj[item[key]].push(item);
    } else {
      obj[item[key]] = [item];
    }
  });
  return obj;
};

export default convertArray2ObjectOfArrays;
