import React from "react";
import {Field} from "formik";
import {Col} from "antd";
import {Input} from "components/fields";
import {utils} from "common";
import {Row} from "../../elements";

const {dollars: dollarsFormatter} = utils.inputFormatters;

const fieldProps = {
  type: "number",
  placeholder: "0",
  component: Input,
  ...dollarsFormatter,
};
const DeliveryOrderMinimums = ({className, ...props}) => (
  <Row gutter={15} className={className} {...props}>
    <Col xs={24} sm={12}>
      <Field
        label="Standard Delivery"
        name="orderMinimum.standardDelivery"
        {...fieldProps}
      />
    </Col>
    <Col xs={24} sm={12}>
      <Field
        label="Catering Delivery"
        name="orderMinimum.cateringDelivery"
        {...fieldProps}
      />
    </Col>
  </Row>
);

export default DeliveryOrderMinimums;
