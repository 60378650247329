import * as React from "react";
import { LocationCard } from "@lunchboxinc/dynamic-web";

import { useLocationCard } from "./Context";
import Section from "../components/Section";

function PreviewComponent() {
  const { componentConfig, componentStyles } = useLocationCard();

  return (
    <Section
      title="Preview"
      caption="Preview how your location card will appear based on parameters set."
      disableCard
    >
      <LocationCard
        sx={componentStyles}
        config={componentConfig}
        onOrderClick={() => null}
        onScheduleClick={() => null}
      />
    </Section>
  );
}

export default PreviewComponent;
