import React from "react";
import PropTypes from "prop-types";
import {Icon} from "antd";
import csx from "classnames";
import {ImagePreviewModal} from "components/elements";
import {Condition} from "../Condition";
import {ConfirmationButton} from "../ConfirmationButton";
import css from "./singleImageSelection.module.css";

const SingleImageSelection = ({
  src,
  onClickAdd,
  onClickDelete,
  containerClassName,
}) => {
  return (
    <div className={csx(css.singleImageSelectionContainer, containerClassName)}>
      <div className={css.imageContainer}>
        <Condition.Condition is={src}>
          <div className={css.container}>
            <span>
              <div className={css.item}>
                <div className={css.itemInfo}>
                  <span>
                    <a
                      className={css.itemThumbnail}
                      href={src}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={src} alt="" className={css.itemImage} />
                    </a>
                  </span>
                </div>
                <span className={css.itemActions}>
                  <ImagePreviewModal src={src} />
                  &nbsp; &nbsp;
                  <Icon
                    type="edit"
                    theme="filled"
                    style={{color: "white"}}
                    onClick={onClickAdd}
                  />
                  &nbsp; &nbsp;
                  {onClickDelete && (
                    <ConfirmationButton
                      title="Are you sure you want to delete this?"
                      onConfirm={onClickDelete}
                    >
                      <Icon
                        type="delete"
                        theme="filled"
                        style={{color: "white"}}
                      />
                    </ConfirmationButton>
                  )}
                </span>
              </div>
            </span>
          </div>
        </Condition.Condition>
        {onClickAdd && !src && (
          <button type="button" className={css.addItem} onClick={onClickAdd}>
            <Icon type="plus" className={css.addIcon} />
            <span className={css.addText}>Add</span>
          </button>
        )}
      </div>
    </div>
  );
};

SingleImageSelection.propTypes = {
  src: PropTypes.string,
  onClickAdd: PropTypes.func,
  containerClassName: PropTypes.string,
};
SingleImageSelection.defaultProps = {
  src: "",
  onClickAdd: null,
  containerClassName: "",
};

export default SingleImageSelection;
