import * as React from "react";
import { useLocation } from "react-router-dom";
import { SideNavItem } from "../menuConfig";
import { useAPIContext } from "components/providers/Api";
import { useSuperAdminCheck } from "hooks";
import { S } from "./styled";

const MenuItem = ({
  isHoverExpanded,
  isSettingsMenu,
  isSubmenu,
  menuItem,
  minNav,
  setMenuDropdownOpen,
}: SideNavItem) => {
  const { isProduction } = useAPIContext();
  const isSuperAdmin = useSuperAdminCheck();
  const { disabled, icon, label, path, restrictProduction, superAdminOnly } =
    menuItem;
  const { pathname } = useLocation();
  const isActive = path === pathname;
  const isDisabled = !path || path.length === 0 || disabled;
  const showLabel = isSettingsMenu || !minNav || isHoverExpanded;
  const padNumber = isSettingsMenu && !isSubmenu ? 30 : 60;
  const shouldHideProduction = isProduction && restrictProduction;
  const shouldHideNonSuperAdmin = superAdminOnly && !isSuperAdmin;

  if (shouldHideProduction || shouldHideNonSuperAdmin) return null;

  const handleButtonClick = () => {
    if (!isSubmenu) setMenuDropdownOpen("");
  };

  return (
    <>
      <S.MenuItemButton
        to={path}
        aria-label={label}
        disabled={isDisabled}
        $isActive={isActive}
        $isSettingsMenu={isSettingsMenu}
        key={label}
        onClick={handleButtonClick}
        role="button"
      >
        {(isSubmenu || isSettingsMenu) && (
          <S.SubMenuSpan padNumber={padNumber} />
        )}
        {icon && (
          <S.MenuIconContainer isActive={isActive}>
            <S.MenuIcon icon={icon} fixedWidth />
          </S.MenuIconContainer>
        )}
        {showLabel ? label : ""}
      </S.MenuItemButton>
    </>
  );
};

export { MenuItem };
