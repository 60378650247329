const sections = {
  welcome: {
    images: ["header", "main", "title"],
    links: ["order", "contactEmail"],
    text: ["main", "contactPhone"],
  },
  forgot_password: {
    images: ["header", "main", "title"],
    links: ["contactEmail"],
    text: ["contactPhone"],
  },
  refund: {
    images: ["header", "main", "title", "body"],
    links: ["contactEmail"],
    text: ["contactPhone"],
  },
  loyalty_credit_rewarded: {
    images: ["header", "main", "title", "body"],
    links: ["contactEmail"],
    text: ["credit", "prompt", "contactPhone"],
  },
  order_confirmation: {
    images: ["header"],
    links: ["contactEmail"],
    text: ["contactPhone"],
  },
  gift_card: {
    images: ["header"],
    links: ["idNumber", "contactEmail"],
    text: [
      "redeemInstructions",
      "details",
      "sendingPatron",
      "receivingPatron",
      "message",
      "contactPhone",
    ],
  },
  catering_pending: {
    images: ["header"],
    links: ["contactEmail"],
    text: ["contactPhone"],
  },
  catering_confirm: {
    images: ["header"],
    links: ["contactEmail"],
    text: ["contactPhone"],
  },
  catering_decline: {
    images: ["header", "main", "title"],
    links: ["contactEmail"],
    text: ["contactPhone"],
  },
  catering_invoice: {
    images: ["header"],
    links: ["contactEmail"],
    text: ["contactPhone"],
  },
  admin_catering_pending: {
    images: ["header"],
    links: ["contactEmail"],
    text: ["contactPhone"],
  },
  admin_catering_confirm: {
    images: ["header"],
    links: ["contactEmail"],
    text: ["contactPhone"],
  },
};

const socialMediaTypes = ["instagram", "facebook", "twitter", "brand"];

export default { sections, socialMediaTypes };
