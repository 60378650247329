import React from "react";
import PropTypes from "prop-types";
import {useUserContext} from "components/providers/User";
import {InfoPopoverIcon} from "components/elements";
import styles from "./orderMinimum.module.css";

const orderTypes = {
  standardDelivery: "Standard Delivery",
  cateringDelivery: "catering Delivery",
  standardPickup: "Standard Pickup",
  cateringPickup: "Catering Pickup",
};

const PopoverIconTitle = ({orderType}) => {
  const {restaurantGroup} = useUserContext();
  const orderTypeLabel = orderTypes[orderType];
  const orderTypeText = orderTypeLabel.toLowerCase();

  const popoverContent = (
    <div className={styles.popoverContent}>
      <div>
        {`This field overrides the ${orderTypeText} minimum set for the restaurant for this location only.
        The current ${orderTypeText} minimum set for the restaurant is:`}
      </div>
      <div className={styles.deliveryMinimumValue}>
        {`$${restaurantGroup.orderMinimum[orderType]}`}
      </div>
    </div>
  );

  return (
    <span>
      {orderTypeLabel}
      <InfoPopoverIcon popoverContent={popoverContent} />
    </span>
  );
};

export default PopoverIconTitle;

PopoverIconTitle.propTypes = {
  orderType: PropTypes.oneOf([
    "standardDelivery",
    "cateringDelivery",
    "standardPickup",
    "cateringPickup",
  ]).isRequired,
};
