import NavKeys from "./NavKeys";

const NavKeySearch = (pathname) => {
  const trimmedPathname =
    pathname.split("/")[1] === "analytics"
      ? `${pathname.split("/")[2]}-analytics`
      : pathname.split("/")[1];
  const key = NavKeys[trimmedPathname] || trimmedPathname;
  return key;
};

export default NavKeySearch;
