/* eslint-disable no-bitwise */
import appcuesPermissions from "./appcuesPermissions";
import arrayChunk from "./arrayChunk";
import axios from "./axios";
import camelizeString from "./camelizeString";
import clearAdminSession from "./clearAdminSession";
import convert2NegativeValue from "./convert2NegativeValue";
import convertArray2Object from "./convertArray2Object";
import convertArray2ObjectOfArrays from "./convertArray2ObjectOfArrays";
import decamelizeString from "./decamelizeString";
import downloadCsv from "./downloadCsv";
import formatSubtractingPrice from "./formatSubtractingPrice";
import getAdminSession from "./getAdminSession";
import inputFormatters from "./inputFormatters";
import locationHours from "./locationHours";
import holidayHours from "./holidayHours";
import pickerTimes from "./pickerTimes";
import uppercaseTitle from "./uppercaseTitle";
import zendeskArticles from "./zendeskArticles";
import { sortValue } from "./sortValue";
import { spacing } from "./spacing";

/**
 * @namespace Utils
 */

const toDayOfWeek = (number) => {
  let toReturn = "";
  switch (number) {
    case 1:
      toReturn = "Monday";
      break;
    case 2:
      toReturn = "Tuesday";
      break;
    case 3:
      toReturn = "Wednesday";
      break;
    case 4:
      toReturn = "Thursday";
      break;
    case 5:
      toReturn = "Friday";
      break;
    case 6:
      toReturn = "Saturday";
      break;
    default:
      toReturn = "Sunday";
  }
  return toReturn;
};

const dayToNumber = (day) => {
  let toReturn = "";
  switch (day) {
    case "Monday":
      toReturn = 1;
      break;
    case "Tuesday":
      toReturn = 2;
      break;
    case "Wednesday":
      toReturn = 3;
      break;
    case "Thursday":
      toReturn = 4;
      break;
    case "Friday":
      toReturn = 5;
      break;
    case "Saturday":
      toReturn = 6;
      break;
    default:
      toReturn = 0;
  }
  return toReturn;
};
const numberToDay = (number) => {
  let toReturn = "";
  switch (number) {
    case 1:
      toReturn = "monday";
      break;
    case 2:
      toReturn = "tuesday";
      break;
    case 3:
      toReturn = "wednesday";
      break;
    case 4:
      toReturn = "thursday";
      break;
    case 5:
      toReturn = "friday";
      break;
    case 6:
      toReturn = "saturday";
      break;
    default:
      toReturn = "sunday";
  }
  return toReturn;
};
const mapAxiosError = (e) => {
  if (e.response) {
    return `${e.response.status} ${e.response.data.message}`;
  }
  if (e.request) {
    return "Uh oh, an error occurred!";
  }
  return e.message;
};

const roundFloat = (value) => Math.round((value + 0.00001) * 100) / 100;

const determineBreakpoint = (width) => {
  const breakpoints = [
    { width: 576, name: "sm" },
    { width: 768, name: "md" },
    { width: 992, name: "lg" },
    { width: 1200, name: "xl" },
    { width: 1600, name: "xxl" },
  ];
  return breakpoints.reduce((accu, i) => (width < i.width ? accu : i), {
    width: 0,
    name: "xs",
  });
};

const roleConversion = (role) => {
  let roleName = "";
  switch (role) {
    case 0:
      roleName = "Developer";
      break;
    case 1:
      roleName = "Owner";
      break;
    case 2:
      roleName = "District Manager";
      break;
    case 3:
      roleName = "Manager";
      break;
    case 4:
      roleName = "Technical";
      break;
    case 5:
      roleName = "Marketing";
      break;
    default:
      roleName = "N/A";
  }

  return roleName;
};

// use for obsecure destructuring where we don't know if ht nested object key will be the same
const getPropValue = (obj, key) =>
  key.split(".").reduce((o, x) => (o === undefined ? o : o[x]), obj);

// for assigning parentNode as container to an antd dropdown
const fetchParentNode = (trigger) => trigger.parentNode;

const formatAddressToOneLine = ({ street1, street2, city, state, zip }) =>
  `${street1}${street2 ? ` ${street2}` : ""}, ${city}, ${state} ${zip}`;

const ensureArray = (val) => (Array.isArray(val) ? val : []);

const getDisplayName = (WrappedComponent) => {
  return WrappedComponent.displayName ?? WrappedComponent.name ?? "Component";
};

const uuid = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  );
};

export {
  appcuesPermissions,
  arrayChunk,
  axios,
  camelizeString,
  clearAdminSession,
  convert2NegativeValue,
  convertArray2Object,
  convertArray2ObjectOfArrays,
  dayToNumber,
  decamelizeString,
  determineBreakpoint,
  downloadCsv,
  ensureArray,
  fetchParentNode,
  formatAddressToOneLine,
  formatSubtractingPrice,
  getAdminSession,
  getDisplayName,
  getPropValue,
  holidayHours,
  inputFormatters,
  locationHours,
  mapAxiosError,
  numberToDay,
  pickerTimes,
  roleConversion,
  roundFloat,
  toDayOfWeek,
  uppercaseTitle,
  zendeskArticles,
  spacing,
  sortValue,
  uuid,
};
export default {
  appcuesPermissions,
  arrayChunk,
  axios,
  camelizeString,
  clearAdminSession,
  convert2NegativeValue,
  convertArray2Object,
  convertArray2ObjectOfArrays,
  dayToNumber,
  decamelizeString,
  determineBreakpoint,
  downloadCsv,
  ensureArray,
  fetchParentNode,
  formatAddressToOneLine,
  formatSubtractingPrice,
  getAdminSession,
  getDisplayName,
  getPropValue,
  holidayHours,
  inputFormatters,
  locationHours,
  mapAxiosError,
  numberToDay,
  pickerTimes,
  roleConversion,
  roundFloat,
  toDayOfWeek,
  uppercaseTitle,
  zendeskArticles,
  spacing,
  sortValue,
  uuid,
};
