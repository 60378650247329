import {useEffect} from "react";
import {message} from "antd";
import {mapAxiosError} from "common/utils";
import {useAPIContext} from "components/providers/Api";

/**
 * Fetch required input options for input fields of Chowly integration form(both restaurant-level &
 * location-level), set them to inputOptions state
 * @returns {Array} - Returns children component array
 */
const ChowlyIntegration = ({
  children,
  inputOptions,
  setInputOptions,
  locationId,
  setFetching,
}) => {
  const {Locations} = useAPIContext();

  useEffect(() => {
    const fetchInputOptions = async () => {
      try {
        let serviceCharges = [];

        if (locationId) {
          const serviceChargesRes = await Locations.getServiceCharges(
            locationId,
          );
          serviceCharges = [...serviceChargesRes.data];
        }

        setInputOptions({
          ...inputOptions,
          serviceCharges,
        });
      } catch (e) {
        message.error(mapAxiosError(e));
      } finally {
        setFetching(false);
      }
    };

    fetchInputOptions();
  }, [setInputOptions, locationId]);

  return children;
};

export default ChowlyIntegration;
