import React from "react";
import { Icon } from "antd";
import { Tooltip } from "@lunchboxinc/utensils";
import { TooltipPlacement } from "antd/lib/tooltip";
import styles from "./infoPopoverIcon.module.css";

interface InfoPopover {
  popoverContent?: string;
  popoverPlacement?: TooltipPlacement;
  popoverWidth?: number | string;
}

function InfoPopoverIcon({
  popoverContent = "",
  popoverWidth = 300,
}: InfoPopover) {
  return (
    <Tooltip placement="left" popupLabel={popoverContent}>
      <Icon className={styles.infoIcon} type="info-circle" />
    </Tooltip>
  );
}

export default InfoPopoverIcon;
