import * as React from "react";
import { HeadingMedium, ParagraphBody } from "./FigmaUI";

interface SectionProps {
  title?: string;
  caption?: string;
  sideContent?: React.ReactNode;
  disableCard?: boolean;
  disableDivider?: boolean;
  children: React.ReactNode;
}

function Section({
  title,
  caption,
  sideContent,
  disableCard,
  disableDivider,
  children,
}: SectionProps) {
  return (
    <section
      style={{
        display: "flex",
        alignItems: "flex-start",
        marginBottom: "2.5rem",
        ...(!disableDivider && {
          paddingBottom: "2.5rem",
          borderBottom: "1px solid #e0e0e0",
        }),
      }}
    >
      {(title || caption || sideContent) && (
        <div style={{ width: "100%", maxWidth: "18rem", marginRight: "3rem" }}>
          {title && <HeadingMedium>{title}</HeadingMedium>}
          {caption && (
            <ParagraphBody style={{ marginBottom: "1rem" }}>
              {caption}
            </ParagraphBody>
          )}
          {sideContent && <div>{sideContent}</div>}
        </div>
      )}
      <div
        style={{
          width: "100%",
          ...(!disableCard && {
            background: "#fff",
            boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.1)",
            padding: "1.5rem",
          }),
        }}
      >
        {children}
      </div>
    </section>
  );
}

export default Section;
