import * as React from "react";
import useAlert from "hooks/useAlert";
import { Alert } from "@reach/alert";
import { getColor } from "common/constants/theme";

const ALERT_DETAILS = {
  discard: {
    border: getColor("yellow", 700),
    color: getColor("yellow", 50),
  },
  error: {
    border: getColor("critical", "red"),
    color: getColor("critical", "base"),
  },
  success: {
    border: getColor("teal", 500),
    color: getColor("teal", 50),
  },
};

const AlertPopup = () => {
  const { text, type } = useAlert();
  const { border, color } = ALERT_DETAILS[type] || {};

  if (text.length && type.length) {
    return (
      <div style={{ position: "relative", top: "80px" }}>
        <Alert
          style={{
            padding: "8px",
            position: "absolute",
            left: "50%",
            zIndex: 9999,
            display: "flex",
            flexDirection: "row",
            backgroundColor: color,
            borderColor: border,
            border: "2px solid " + border,
            borderRadius: "6px",
          }}
        >
          {text}
        </Alert>
      </div>
    );
  } else {
    return null;
  }
};

export default AlertPopup;
