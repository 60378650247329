import React from "react";
import {Col} from "antd";
import DeliveryOrderMinimums from "./DeliveryOrderMinimums";
import PickupOrderMinimums from "./PickupOrderMinimums";
import {Row} from "../../elements";

const OrderMinimums = ({className}) => (
  <Row gutter={15} className={className}>
    <Col md={24} lg={12}>
      <DeliveryOrderMinimums />
    </Col>
    <Col md={24} lg={12}>
      <PickupOrderMinimums />
    </Col>
  </Row>
);

export default OrderMinimums;
