import React from "react";
import { Col } from "antd";
import { Field } from "formik";
import { Row, T } from "components/elements";
import { Checkbox } from "components/fields";
import { strings } from "common/constants";

const OrderTypeAvailability = ({
  field: { value },
  form: { values, setFieldValue },
  disabled,
}) => {
  const filterOrderType = (value, checked, toAdd) =>
    checked ? [...value, toAdd] : value.filter((i) => i !== toAdd);

  const { isPickup, isDelivery, isDinein, isKiosk } = value.reduce(
    (accu, i) => {
      if (i === strings.ORDER_TYPE.PICKUP) accu.isPickup = true;
      if (i === strings.ORDER_TYPE.DELIVERY) accu.isDelivery = true;
      if (i === strings.ORDER_TYPE.DINEIN) accu.isDinein = true;
      if (i === strings.ORDER_TYPE.KIOSK) accu.isKiosk = true;
      return accu;
    },
    {
      isPickup: false,
      isDelivery: false,
      isDinein: false,
      isKiosk: false,
    },
  );

  return (
    <>
      <Row spacing={10}>
        <T label>Order Type Availability</T>
      </Row>
      <Row spacing={10}>
        <Col xs={6}>
          <Field
            disabled={disabled}
            label="Pickup"
            checked={isPickup}
            component={Checkbox}
            onChange={(e) => {
              setFieldValue(
                "orderTypeAvailability",
                filterOrderType(value, e.target.checked, "pickup"),
              );
            }}
          />
        </Col>
        <Col xs={6}>
          <Field
            disabled={disabled}
            label="Delivery"
            checked={isDelivery}
            component={Checkbox}
            onChange={(e) => {
              setFieldValue(
                "orderTypeAvailability",
                filterOrderType(value, e.target.checked, "delivery"),
              );
            }}
          />
        </Col>
        <Col xs={6}>
          <Field
            disabled={disabled}
            label="Dine In"
            checked={isDinein}
            component={Checkbox}
            onChange={(e) => {
              setFieldValue(
                "orderTypeAvailability",
                filterOrderType(value, e.target.checked, "dinein"),
              );
            }}
          />
        </Col>
        <Col xs={6}>
          <Field
            disabled={disabled}
            label="Pocket Kiosk"
            checked={isKiosk}
            component={Checkbox}
            onChange={(e) => {
              setFieldValue(
                "orderTypeAvailability",
                filterOrderType(value, e.target.checked, "kiosk"),
              );
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default OrderTypeAvailability;
