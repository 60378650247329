import styled, { css } from "styled-components";

export type SizeTypes = "sm" | "md" | "lg" | "xl";

const sizeMap = {
  sm: "30px",
  md: "40px",
  lg: "50px",
  xl: "70px",
};

const tableCellStyles = css`
  font-family: Open Sans, sans-serif;
  font-style: normal;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 20px;
  color: #3d3d3d;
  text-align: left;
`;

export const StyledTable = styled.table`
  width: 100%;
  height: fit-content;
  overflow-x: auto;
`;

export const StyledTableRow = styled.tr`
  border-top: solid 1px #e0e0e0;
  border-bottom: solid 1px #e0e0e0;
  :nth-child(odd) {
    background-color: #fbfbfb;
  }
`;

export const StyledTableHeaderCellWrapper = styled.span`
  ${tableCellStyles}
  font-weight: 600;
  height: 50px;
  display: flex;
  align-items: center;
  :hover i {
    visibility: visible;
  }
`;

export const StyledTableDataCell = styled.td<{ size?: SizeTypes }>`
  ${tableCellStyles}
  height: ${(props) => sizeMap[props.size]}
  font-weight: normal;
`;

export const SortArrow = styled.i<{ isHidden?: boolean }>`
  ${(props) =>
    props.isHidden
      ? {
          visibility: "hidden",
          fontSize: "0.75rem",
          paddingLeft: "0.5rem",
        }
      : {
          fontSize: "0.75rem",
          verticalAlign: "middle",
          paddingLeft: "0.5rem",
          color: "#2b7c7f",
        }}
`;

export const TableWrapper = styled.div`
  overflow-x: auto;
`;
