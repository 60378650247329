import * as Yup from "yup";

const holidayHourSchema = Yup.object({
  name: Yup.string().typeError("Name is required").required("Name is required"),
  // startDate: Yup.date().required("Start Date is required"),
  // endDate: Yup.date()
  //   .min(Yup.ref("startDate"))
  //   .required("End Date is required"),
  hours: Yup.array()
    .of(
      Yup.object().shape(
        {
          orderType: Yup.string().required(),
          open: Yup.string()
            .nullable()
            .when("close", {
              is: (close) => close,
              then: Yup.string().typeError("Required").required("Required"),
            }),
          close: Yup.string()
            .nullable()
            .when("open", {
              is: (open) => open,
              then: Yup.string().typeError("Required").required("Required"),
            }),
        },
        ["open", "close"],
      ),
    )
    .min(3),
  selectedLocations: Yup.array().of(Yup.string().matches(/^[a-f\d]{24}$/)),
});

export default holidayHourSchema;
