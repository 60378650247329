import * as Yup from "yup";

const email = Yup.object().shape({
  email: Yup.string()
    .email("Valid email address is required")
    .required("Valid email address is required"),
});
const pinCode = Yup.object().shape({
  pinCode: Yup.string()
    .min(6, "Valid pin code is required")
    .required("Valid pin code is required"),
});

export default {
  PinCode: pinCode,
  Email: email,
};
