import { useEffect, useState } from "react";
import axios from "axios";
import { get } from "common/utils/axios";
interface useResource<T> {
  path: string;
  data?: T;
}
const useResource = <T>({ path, data }: useResource<T>) => {
  const [resource, setResource] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState(null);

  const getResponse = async () => {
    setFetching(true);
    try {
      const res = await get(path, { params: data });
      setResource(res.data);
    } catch (e) {
      setError(e);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    getResponse();
  }, []);

  return {
    fetch: getResponse,
    resource,
    fetching,
    error,
  };
};

export default useResource;
