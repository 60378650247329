import React, {useState} from "react";
import {message, Popconfirm} from "antd";
import {mapAxiosError} from "common/utils";
import {useAPIContext} from "components/providers/Api";
import {Button} from "components/elements";

import css from "./SyncLocationButton.module.css";

// Todo move this to templates folder since its too opinionated
const SyncLocationButton = ({locationId, onComplete}) => {
  const {Locations} = useAPIContext();

  const [syncing, setSyncing] = useState(false);

  const sync = async () => {
    setSyncing(true);
    try {
      if (locationId) {
        await Locations.syncOne(locationId);
      } else {
        await Locations.syncAll();
      }

      message.success("Sync Complete");
      onComplete();
    } catch (e) {
      message.error(mapAxiosError(e));
    } finally {
      setSyncing(false);
    }
  };

  return (
    <Popconfirm
      placement="left"
      title={
        <div>
          This action will overwrite guid, name, address, service area, geo,
          phone, hours with POS data.
        </div>
      }
      onConfirm={sync}
    >
      <Button className={css.width} loading={syncing} color="blue">
        {locationId ? "Sync Location" : "Sync Locations"}
      </Button>
    </Popconfirm>
  );
};

export default SyncLocationButton;
