import * as Yup from "yup";

const schema = Yup.object().shape({
  externalId: Yup.string().matches(
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
  ),
  name: Yup.string().min(3).required(),
  description: Yup.string(),
  price: Yup.number().min(0),
  visibility: Yup.string().nullable().oneOf(["ALL", "NONE", "POS_ONLY", null]),
  images: Yup.array().of(Yup.string()),
  dietaryRestrictions: Yup.array().of(Yup.string()),
  alternatePrices: Yup.array().of(
    Yup.object({
      price: Yup.number().min(0),
      location: Yup.string().matches(/^[a-f\d]{24}$/),
    }),
  ),
  calories: Yup.number()
    .typeError("Calories must be a number")
    .min(0, "Calories must be a number greater than 0")
    .nullable("Calories must be a number greater than 0"),
  caloriesMax: Yup.number()
    .typeError("Calories must be a number")
    .min(0, "Calories must be a number greater than 0")
    .nullable("Calories must be a number greater than 0"),
  coverSize: Yup.number()
    .typeError("Cover size must be a number")
    .min(0, "Cover size must be a number greater than or equal to 0")
    .nullable("Cover size must be a number greater than or equal to 0"),
  options: Yup.array().of(Yup.string().matches(/^[a-f\d]{24}$/)),
  isOrderableOnline: Yup.boolean(),
  sku: Yup.string().nullable(),
  // excludedLocations: Yup.array().of(Yup.string().matches(/^[a-f\d]{24}$/)),
  isActive: Yup.boolean(),
  isDeleted: Yup.boolean(),
  priority: Yup.number()
    .typeError("")
    .min(0, "You must specify a value from 0 to 10,000")
    .max(10000, "You must specify a value from 0 to 10,000"),
});

export default schema;
