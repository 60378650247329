const methods = {
  GET: "GET",
  PUT: "PUT",
  POST: "POST",
  DELETE: "DELETE",
};

export const ADMINS_ITEM = "/admin/:id";
export const ADMINS_LIST = "/admins";
export const ANALYTICS_CATERING_CSV = "/analytics/lunchbox-sales-catering/csv";
export const ANALYTICS_CSV = "/analytics/lunchbox-sales/csv";
export const ANALYTICS_LOCATIONS = "/analytics/locations";
export const ANALYTICS_LOCATIONS_SIMPLE = "/analytics/locations-simple";
export const ANALYTICS_MENU = "/analytics/menu";
export const ANALYTICS_ORDERS_REPORT = "/analytics/orders-report";
export const ANALYTICS_OVERVIEW = "/analytics/overview";
export const ANALYTICS_PATRON_FREQUENCY = "/analytics/patron-frequency";
export const ANALYTICS_PATRON_ORDER_COUNT = "/analytics/patron-order-count";
export const ANALYTICS_RECONCILIATION_REPORT =
  "/analytics/reconciliation-report";
export const ANALYTICS_REGISTRATIONS = "/analytics/registrations";
export const ANALYTICS_SALES = "/analytics/sales";
export const APP_HOME_CONTENT = "/home-content";
export const AUDITS_ITEM = "/audits/:auditId";
export const AUDITS_LIST = "/audits";
export const AUTOMATION = "/lunchcrew/config";
export const CAMPAIGNER = "/fe/campaigner";
export const CARDS_LIST = "/cards";
export const CATERING_ACCEPT = "/catering/:id/accept";
export const CATERING_DECLINE = "/catering/:id/decline";
export const CATERING_GROUPS_ITEM = "/catering-groups/:id";
export const CATERING_GROUPS_LIST = "/catering-groups";
export const CATERING_ITEM = "/catering/:id/";
export const CATERING_LIST = "/catering";
export const CATERING_LOCATION = "/catering/catering-location";
export const CATERING_ORDER_ORDER = "/catering/:id/order";
export const CATERING_RESEND_EMAIL = "/catering/:id/resend-email";
export const CRON_ACTIONS = "/cron-actions";
export const DINING_HOURS_ITEM = "/dining-hours/:id";
export const DINING_HOURS_LIST = "/dining-hours";
export const DINING_OPTIONS = "/pos/:id/dining-options";
export const DISCOUNTS_ITEM = "/discounts/:id";
export const DISCOUNTS_LIST = "/discounts";
export const DISPUTES_CSV = "restaurant-groups/getDisputeList/csv";
export const EMAIL_TEMPLATES_ITEM = "/email-templates/:id";
export const EXPORTS_LIST = "/data-exports";
export const FORGOT_PASSWORD = "/admins/forgot-password";
export const GHOST_KITCHEN_ITEM = "/ghost-kitchens/:id";
export const GHOST_KITCHEN_LIST = "/ghost-kitchens";
export const GUEST_LOYALTY_REPORTS = "/analytics/guest-loyalty-reports";
export const HOLIDAY_HOURS_ITEM = "/holiday-hours/:id";
export const HOLIDAY_HOURS_LIST = "/holiday-hours";
export const HOME_CONTENT_ITEM = "/home-content";
export const ITEM_AVAILABILITY_ITEM = "/item-availabilities/:id";
export const ITEM_AVAILABILITY_LIST = "/item-availabilities";
export const LINKS_SEND = "/links/send";
export const LINKS_VERIFY = "/links/verify";
export const LOCATIONS_DELIVERY_RADIUS =
  "/locations/:locationId/create-delivery-radius";
export const LOCATIONS_ESTIMATES = "/locations/:id/post-estimates";
export const LOCATIONS_ITEM = "/locations/:locationId";
export const LOCATIONS_LIST = "/locations";
export const LOCATIONS_OUTPOST = "/locations/:id/outpost";
export const LOYALTIES = "/loyalties";
export const LOYALTY_TRIGGER = "/loyalty-trigger";
export const MARKETPLACE_LIST = "/marketplaces";
export const MARKETPLACE_ORDERS_LIST = "/marketplaces/orders";
export const MEDIA_ITEM = "/media/:id";
export const MEDIA_LIST = "/media";
export const MENU_ENTITIES_AVAILABILITY = "/menu-entities/availability";
export const MENU_GROUPS_ITEM = "/menu-groups/:id";
export const MENU_GROUPS_LIST = "/menu-groups";
export const MENU_ITEMS_ITEM = "/menu-items/:id";
export const MENU_ITEMS_LIST = "/menu-items";
export const MENU_OPTIONS_ITEM = "/menu-options/:id";
export const MENU_OPTIONS_LIST = "/menu-options";
export const MENUS_ITEM = "/menus/:id";
export const MENUS_LIST = "/menus";
export const ORDERS_CSV = "/orders-csv";
export const ORDERS_ISSUE_REFUND = "/orders/issue-refund";
export const ORDERS_ISSUE_VOID = "/orders/:id/void";
export const ORDERS_ITEM = "/orders/:orderId";
export const ORDERS_LIST = "/orders";
export const ORDERS_RESEND_EMAIL = "/orders/resend-email";
export const ORDERS_UPDATE_COVER_SIZES = "/orders/update-cover-sizes";
export const ORDERS_VALIDATE_REFUND = "/orders/validate-refund";
export const PATRONS_BAN = "/patrons/:patronId/ban";
export const PATRONS_ITEM = "/patrons/:patronId";
export const PATRONS_LIST = "/patrons";
export const PATRONS_LOYALTY = "/patrons/:patronId/loyalty";
export const PAYMENTS = "/onboarding";
export const PAYMENTS_EXTERNAL_ACCOUNTS =
  "/locations/:locationId/external-account";
export const PAYMENTS_ONBOARD = "/locations/:locationId/onboard";
export const PAYOUTS_CSV = "/locations/:id/payouts/csv";
export const PERMISSION_GROUPS_ITEM = "/permission-groups/:id";
export const PERMISSION_GROUPS_LIST = "/permission-groups";
export const PERMISSIONS_ITEM = "/permissions/:id";
export const PERMISSIONS_LIST = "/permissions";
export const PINCODE_VERIFY = "/pincode/verify";
export const PINCODES_ITEM = "/pincodes/:id";
export const PINCODES_LIST = "/pincodes";
export const PROMOTION_CODES_LIST = "/promotion-codes";
export const PROMOTIONS_ITEM = "/promotions/:id";
export const PROMOTIONS_LIST = "/promotions";
export const PUSH_NOTIFICATIONS_ITEM = "/push-notifications/:notificationId";
export const PUSH_NOTIFICATIONS_LIST = "/push-notifications";
export const REFUNDS_CSV = "/restaurant-groups/getrefundlist/csv";
export const REFUNDS_LIST = "/refunds";
export const RESTAURANT_GROUPS_CURRENT = "/restaurant-group";
export const RESTAURANT_GROUPS_ITEM = "/restaurant-groups/:id";
export const RESTAURANT_GROUPS_LIST = "/restaurant-groups";
export const SERVICES = "/services";
export const SERVICE_CHARGES_LIST = "/pos/service-charges";
export const SIGN_IN = "/admins/sign-in";
export const SIGN_OUT = "/admins/sign-out";
export const SIGN_UP = "/admins/sign-up";
export const STORED_VALUE_CARDS_ITEM = "/stored-value-cards/:id";
export const STORED_VALUE_CARDS_LIST = "/stored-value-cards";
export const STORED_VALUE_CARDS_REPORTS = "/stored-value-cards/reports";
export const STORED_VALUE_CARDS_TRANSACTIONS =
  "/stored-value-card-transactions";
export const SUBSCRIPTION_AWARD = "/subscription-ledgers/:id/awards";
export const SUBSCRIPTION_LIST = "/scheduled-tokens";
export const SUBSCRIPTION_PATRON = "/subscription-periods";
export const SUBSCRIPTIONS_LIST = "/subscriptions";
export const SUBSCRIPTION_ANALYTICS = "/subscriptions-general-report";
export const SYNC_DISCOUNTS_ALL = "/pos/discounts/all";
export const SYNC_DISCOUNTS_LOCATION = "/pos/discounts";
export const SYNC_LOCATIONS_ITEM = "/pos/locations/:locationId";
export const SYNC_LOCATIONS_LIST = "/pos/locations";
export const SYNC_MENUS_ITEM = "/pos/menus/:id";
export const SYNC_MENUS_LIST = "/pos/menus";
export const SYNC_TABLES_LIST = "/pos/tables/:locationid?/sync";
export const TABLES_ITEM = "/tables/:id";
export const TABLES_LIST = "/tables";
export const TAXES_ITEMS_LIST = "/item-taxes";
export const TAXES_LOCATIONS_LIST = "/location-taxes";
export const THEMES = "/fe/themes";
export const TRANSACTIONS_CSV = "locations/:id/transactions/csv";
export const UNBOXED = `/unboxed`;
export const UPSELLS_ITEM = "/upsells/:id";
export const UPSELLS_LIST = "/upsells";
export const VALIDATE_ADMIN = "/admin";

// Constants corresponding to each endpoint route for use as url parameter in permissions
const routes = {
  ADMINS_ITEM,
  ADMINS_LIST,
  ANALYTICS_CATERING_CSV,
  ANALYTICS_CSV,
  ANALYTICS_LOCATIONS_SIMPLE,
  ANALYTICS_LOCATIONS,
  ANALYTICS_MENU,
  ANALYTICS_ORDERS_REPORT,
  ANALYTICS_OVERVIEW,
  ANALYTICS_PATRON_FREQUENCY,
  ANALYTICS_PATRON_ORDER_COUNT,
  ANALYTICS_RECONCILIATION_REPORT,
  ANALYTICS_REGISTRATIONS,
  ANALYTICS_SALES,
  APP_HOME_CONTENT,
  AUDITS_ITEM,
  AUDITS_LIST,
  AUTOMATION,
  CAMPAIGNER,
  CARDS_LIST,
  CATERING_ACCEPT,
  CATERING_DECLINE,
  CATERING_GROUPS_ITEM,
  CATERING_GROUPS_LIST,
  CATERING_ITEM,
  CATERING_LIST,
  CATERING_LOCATION,
  CATERING_ORDER_ORDER,
  CATERING_RESEND_EMAIL,
  CRON_ACTIONS,
  DINING_HOURS_ITEM,
  DINING_HOURS_LIST,
  DINING_OPTIONS,
  DISCOUNTS_ITEM,
  DISCOUNTS_LIST,
  DISPUTES_CSV,
  EMAIL_TEMPLATES_ITEM,
  EXPORTS_LIST,
  FORGOT_PASSWORD,
  GHOST_KITCHEN_ITEM,
  GHOST_KITCHEN_LIST,
  GUEST_LOYALTY_REPORTS,
  HOLIDAY_HOURS_ITEM,
  HOLIDAY_HOURS_LIST,
  ITEM_AVAILABILITY_ITEM,
  ITEM_AVAILABILITY_LIST,
  LINKS_SEND,
  LINKS_VERIFY,
  LOCATIONS_DELIVERY_RADIUS,
  LOCATIONS_ESTIMATES,
  LOCATIONS_ITEM,
  LOCATIONS_LIST,
  LOCATIONS_OUTPOST,
  LOYALTIES,
  LOYALTY_TRIGGER,
  MARKETPLACE_LIST,
  MARKETPLACE_ORDERS_LIST,
  MEDIA_ITEM,
  MEDIA_LIST,
  MENU_ENTITIES_AVAILABILITY,
  MENU_GROUPS_ITEM,
  MENU_GROUPS_LIST,
  MENU_ITEMS_ITEM,
  MENU_ITEMS_LIST,
  MENU_OPTIONS_ITEM,
  MENU_OPTIONS_LIST,
  MENUS_ITEM,
  MENUS_LIST,
  ORDERS_CSV,
  ORDERS_ISSUE_REFUND,
  ORDERS_ISSUE_VOID,
  ORDERS_ITEM,
  ORDERS_LIST,
  ORDERS_RESEND_EMAIL,
  ORDERS_UPDATE_COVER_SIZES,
  ORDERS_VALIDATE_REFUND,
  PATRONS_BAN,
  PATRONS_ITEM,
  PATRONS_LIST,
  PATRONS_LOYALTY,
  PAYMENTS_EXTERNAL_ACCOUNTS,
  PAYMENTS_ONBOARD,
  PAYMENTS,
  PAYOUTS_CSV,
  PERMISSION_GROUPS_ITEM,
  PERMISSION_GROUPS_LIST,
  PERMISSIONS_ITEM,
  PERMISSIONS_LIST,
  PINCODE_VERIFY,
  PINCODES_ITEM,
  PINCODES_LIST,
  PROMOTION_CODES_LIST,
  PROMOTIONS_ITEM,
  PROMOTIONS_LIST,
  PUSH_NOTIFICATIONS_ITEM,
  PUSH_NOTIFICATIONS_LIST,
  REFUNDS_CSV,
  REFUNDS_LIST,
  RESTAURANT_GROUPS_CURRENT,
  RESTAURANT_GROUPS_ITEM,
  RESTAURANT_GROUPS_LIST,
  SERVICES,
  SIGN_IN,
  SIGN_OUT,
  SIGN_UP,
  STORED_VALUE_CARDS_ITEM,
  STORED_VALUE_CARDS_LIST,
  STORED_VALUE_CARDS_REPORTS,
  STORED_VALUE_CARDS_TRANSACTIONS,
  SUBSCRIPTION_ANALYTICS,
  SUBSCRIPTION_AWARD,
  SUBSCRIPTION_LIST,
  SUBSCRIPTION_PATRON,
  SUBSCRIPTIONS_LIST,
  SYNC_DISCOUNTS_ALL,
  SYNC_DISCOUNTS_LOCATION,
  SYNC_LOCATIONS_ITEM,
  SYNC_LOCATIONS_LIST,
  SYNC_MENUS_ITEM,
  SYNC_MENUS_LIST,
  SYNC_TABLES_LIST,
  TABLES_ITEM,
  TABLES_LIST,
  THEMES,
  TRANSACTIONS_CSV,
  UNBOXED,
  UPSELLS_ITEM,
  UPSELLS_LIST,
  VALIDATE_ADMIN,
};

export default { methods, routes };
