import getAdminSession from "common/utils/getAdminSession";
import permissions, { LOCATIONS_LIST } from "./permissions";

const platform = getAdminSession();

const {
  methods: { GET, PUT, POST, DELETE },
  routes: {
    // MENU_GROUPS_ITEM,
    // MENU_ITEMS_ITEM,
    // MENU_OPTIONS_ITEM,
    // MENUS_ITEM,
    ADMINS_ITEM,
    ADMINS_LIST,
    ANALYTICS_CATERING_CSV,
    ANALYTICS_CSV,
    ANALYTICS_LOCATIONS_SIMPLE,
    ANALYTICS_OVERVIEW,
    ANALYTICS_REGISTRATIONS,
    APP_HOME_CONTENT,
    AUDITS_ITEM,
    AUDITS_LIST,
    CATERING_ACCEPT,
    CATERING_DECLINE,
    CATERING_GROUPS_ITEM,
    CATERING_ITEM,
    CRON_ACTIONS,
    DINING_HOURS_ITEM,
    DISCOUNTS_ITEM,
    EMAIL_TEMPLATES_ITEM,
    GHOST_KITCHEN_ITEM,
    GHOST_KITCHEN_LIST,
    GUEST_LOYALTY_REPORTS,
    HOLIDAY_HOURS_ITEM,
    HOLIDAY_HOURS_LIST,
    ITEM_AVAILABILITY_ITEM,
    ITEM_AVAILABILITY_LIST,
    LOCATIONS_ITEM,
    LOYALTIES,
    LOYALTY_TRIGGER,
    MARKETPLACE_LIST,
    MEDIA_ITEM,
    MEDIA_LIST,
    ORDERS_ITEM,
    ORDERS_RESEND_EMAIL,
    PATRONS_ITEM,
    PATRONS_LOYALTY,
    PAYMENTS_EXTERNAL_ACCOUNTS,
    PAYMENTS_ONBOARD,
    PERMISSION_GROUPS_ITEM,
    PERMISSIONS_LIST,
    PINCODES_ITEM,
    PINCODES_LIST,
    PROMOTION_CODES_LIST,
    PROMOTIONS_ITEM,
    PROMOTIONS_LIST,
    PUSH_NOTIFICATIONS_ITEM,
    PUSH_NOTIFICATIONS_LIST,
    RESTAURANT_GROUPS_LIST,
    STORED_VALUE_CARDS_ITEM,
    STORED_VALUE_CARDS_TRANSACTIONS,
    TABLES_ITEM,
    TABLES_LIST,
    UPSELLS_ITEM,
    UPSELLS_LIST,
  },
} = permissions;

const adminPermissions = {
  audit: {
    permissions: [
      { method: GET, url: AUDITS_ITEM },
      { method: GET, url: AUDITS_LIST },
    ],
    label: "View Audit",
    type: "Audit",
  },
  cateringDetails: {
    permissions: [
      { method: GET, url: CATERING_ITEM },
      { method: PUT, url: CATERING_ITEM },
      { method: POST, url: CATERING_ACCEPT },
      { method: POST, url: CATERING_DECLINE },
    ],
    label: "View/Update/Accept/Decline a catering order",
    type: "Catering",
  },
  cateringGroup: {
    permissions: [
      { method: GET, url: CATERING_GROUPS_ITEM },
      { method: PUT, url: CATERING_GROUPS_ITEM },
    ],
    label: "View/Update the details for a catering group",
    type: "Catering",
  },
  // cronActions: {
  //   permissions: [
  //     { method: GET, url: CRON_ACTIONS },
  //     { method: PUT, url: CRON_ACTIONS },
  //   ],
  //   label: "View/Update failed cron actions",
  //   type: "cron action",
  // },
  emailTemplates: {
    permissions: [
      { method: GET, url: EMAIL_TEMPLATES_ITEM },
      { method: PUT, url: EMAIL_TEMPLATES_ITEM },
    ],
    label: "View/Update email templates",
    type: "Developer",
  },
  guest: {
    permissions: [
      { method: GET, url: LOYALTIES },
      { method: GET, url: PATRONS_LOYALTY },
      { method: GET, url: PATRONS_ITEM },
      { method: PUT, url: PATRONS_ITEM },
    ],
    label: "View a guest profile",
    type: "Guests",
  },
  upsell: {
    permissions: [
      { method: GET, url: UPSELLS_ITEM },
      { method: PUT, url: UPSELLS_ITEM },
      { method: DELETE, url: UPSELLS_ITEM },
      { method: POST, url: UPSELLS_LIST },
    ],
    label: "View/Update/Create an upsell",
    type: "Marketing",
  },
  discount: {
    permissions: [
      { method: GET, url: DISCOUNTS_ITEM },
      { method: PUT, url: DISCOUNTS_ITEM },
    ],
    label: "View/Update the details for a discount",
    type: "Marketing",
  },
  pushNotification: {
    permissions: [
      { method: GET, url: PUSH_NOTIFICATIONS_ITEM },
      { method: PUT, url: PUSH_NOTIFICATIONS_ITEM },
      { method: GET, url: PUSH_NOTIFICATIONS_LIST },
      { method: POST, url: PUSH_NOTIFICATIONS_LIST },
    ],
    label: "View/Update/Create push notifications",
    type: "Marketing",
  },
  appHomeContent: {
    permissions: [
      { method: GET, url: APP_HOME_CONTENT },
      { method: PUT, url: APP_HOME_CONTENT },
    ],
    label: "View/Update images for app home content",
    type: "Marketing",
  },
  itemAvailability: {
    permissions: [
      { method: GET, url: ITEM_AVAILABILITY_LIST },
      { method: POST, url: ITEM_AVAILABILITY_LIST },
      { method: GET, url: ITEM_AVAILABILITY_ITEM },
      { method: PUT, url: ITEM_AVAILABILITY_ITEM },
      { method: DELETE, url: ITEM_AVAILABILITY_ITEM },
    ],
    label: "View/Update/Create/Delete the scheduled availability for items",
    type: "Menu Item",
  },
  diningHour: {
    permissions: [
      { method: GET, url: DINING_HOURS_ITEM },
      { method: PUT, url: DINING_HOURS_ITEM },
      { method: DELETE, url: DINING_HOURS_ITEM },
    ],
    label: "View/Update/Delete a dining hour",
    type: "Dining Hours",
  },
  holidayHour: {
    permissions: [
      { method: GET, url: HOLIDAY_HOURS_LIST },
      { method: POST, url: HOLIDAY_HOURS_LIST },
      { method: GET, url: HOLIDAY_HOURS_ITEM },
      { method: PUT, url: HOLIDAY_HOURS_ITEM },
      { method: DELETE, url: HOLIDAY_HOURS_ITEM },
    ],
    label: "View/Update/Create a holiday hour",
    type: "Holiday Hours",
  },
  order: {
    permissions: [
      { method: GET, url: ORDERS_ITEM },
      { method: PUT, url: ORDERS_ITEM },
      { method: POST, url: ORDERS_RESEND_EMAIL },
    ],
    label: "View/Update an order",
    type: "Orders",
  },
  role: {
    permissions: [
      { method: GET, url: PERMISSIONS_LIST },
      { method: GET, url: PERMISSION_GROUPS_ITEM },
      { method: PUT, url: PERMISSION_GROUPS_ITEM },
      { method: DELETE, url: PERMISSION_GROUPS_ITEM },
    ],
    label: "View/Update a role",
    type: "Developer",
  },
  salesReports: {
    permissions: [
      { method: GET, url: ANALYTICS_LOCATIONS_SIMPLE },
      { method: GET, url: ANALYTICS_CATERING_CSV },
      { method: GET, url: ANALYTICS_CSV },
    ],
    label: "View sales reports",
    type: "Analytics",
  },
  analyticsOverview: {
    permissions: [
      { method: GET, url: ANALYTICS_OVERVIEW },
      { method: GET, url: ANALYTICS_REGISTRATIONS },
    ],
    label: "View the analytics overview",
    type: "Analytics",
  },
  guestAndLoyaltyReports: {
    permissions: [{ method: GET, url: GUEST_LOYALTY_REPORTS }],
    label: "View guest and loyalty reports",
    type: "Analytics",
  },
  location: {
    permissions: [
      { method: GET, url: LOCATIONS_ITEM },
      { method: PUT, url: LOCATIONS_ITEM },
    ],
    label: "View/Update the details for a location",
    type: "Locations",
  },
  admin: {
    permissions: [
      { method: GET, url: ADMINS_ITEM },
      { method: PUT, url: ADMINS_ITEM },
      { method: POST, url: ADMINS_LIST },
    ],
    label: "View/Update/Create an admin",
    type: "Administration",
  },
  payment: {
    permissions: [
      { method: POST, url: PAYMENTS_ONBOARD },
      { method: POST, url: PAYMENTS_EXTERNAL_ACCOUNTS },
    ],
    label: "Onboard Lunch Money",
    type: "Configuration",
  },
  pincode: {
    permissions: [
      { method: GET, url: PINCODES_LIST },
      { method: GET, url: PINCODES_ITEM },
    ],
    label: "View/Get Pincodes",
    type: "Developer",
  },
  promotion: {
    permissions: [
      { method: POST, url: PROMOTIONS_LIST },
      { method: GET, url: PROMOTIONS_LIST },
      { method: GET, url: PROMOTIONS_ITEM },
      { method: GET, url: PROMOTION_CODES_LIST },
      { method: PUT, url: PROMOTIONS_ITEM },
    ],
    label: "View/Update/Create a promotion",
    type: "Marketing",
  },
  giftCard: {
    permissions: [
      { method: GET, url: STORED_VALUE_CARDS_ITEM },
      { method: GET, url: STORED_VALUE_CARDS_TRANSACTIONS },
    ],
    label: "View the details and transactions for a gift card",
    type: "Gift Cards",
  },
  tables: {
    permissions: [
      { method: GET, url: TABLES_ITEM },
      { method: PUT, url: TABLES_ITEM },
      { method: GET, url: TABLES_LIST },
    ],
    label: "View/Update tables",
    type: "Locations",
  },
  media: {
    permissions: [
      { method: GET, url: MEDIA_LIST },
      { method: GET, url: MEDIA_ITEM },
      { method: PUT, url: MEDIA_ITEM },
      { method: POST, url: MEDIA_LIST },
      { method: DELETE, url: MEDIA_ITEM },
    ],
    label: "View/Update/Create media",
    type: "Media",
  },
  apps: {
    permissions: [{ method: GET }],
    label: "View Client Apps",
    type: "Developer",
  },
  marketplaces: {
    permissions: [
      { method: GET, url: RESTAURANT_GROUPS_LIST },
      { method: POST, url: MARKETPLACE_LIST },
    ],
    label: "Create a marketplace ",
    type: "Marketplace",
  },
};

const marketplacePermissions = {
  ghostKitchen: {
    permissions: [
      { method: GET, url: GHOST_KITCHEN_ITEM },
      { method: PUT, url: GHOST_KITCHEN_ITEM },
    ],
    label: "View/Update ghost kitchen details",
    type: "Marketplace",
  },
  ghostKitchens: {
    permissions: [
      { method: GET, url: GHOST_KITCHEN_LIST },
      { method: POST, url: GHOST_KITCHEN_LIST },
    ],
    label: "View/Create ghost kitchens",
    type: "Marketplace",
  },
};

const pagePermissions =
  platform.platform === "admin"
    ? { ...adminPermissions }
    : { ...adminPermissions, ...marketplacePermissions };
export default pagePermissions;
