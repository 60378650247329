import {useEffect} from "react";
import {message} from "antd";
import {mapAxiosError} from "common/utils";
import {useAPIContext} from "components/providers/Api";

/**
 * This whole set of integrations code modules(including this code) are legacy code and
 * we need to do a major refactor very soon. Otherwise, this will quickly become a bottleneck soon.
 *
 * Fetch required input options for input fields of Ordermark integration form(both restaurant-level &
 * location-level), set them to inputOptions state
 * @returns {Array} - Returns children component array
 */
const OrdermarkIntegration = ({
  children,
  inputOptions,
  setInputOptions,
  locationId,
  setFetching,
}) => {
  const {Locations} = useAPIContext();

  useEffect(() => {
    const fetchInputOptions = async () => {
      try {
        let diningOptions = [];
        let serviceCharges = [];
        let paymentOptions = [];

        if (locationId) {
          const [diningOptionsRes, serviceChargesRes, paymentOptionsRes] =
            await Promise.all([
              Locations.getDiningOptions(locationId),
              Locations.getServiceCharges(locationId),
              Locations.getPaymentOptions(locationId),
            ]);
          diningOptions = [...diningOptionsRes.data];
          serviceCharges = [...serviceChargesRes.data];
          paymentOptions = [...paymentOptionsRes.data];
        } else {
          const paymentOptionsRes = await Locations.getPaymentOptions();
          paymentOptions = paymentOptionsRes.data || [];
        }

        setInputOptions({
          ...inputOptions,
          diningOptions,
          paymentOptions,
          serviceCharges,
          sections: [],
        });
      } catch (e) {
        message.error(mapAxiosError(e));
      } finally {
        setFetching(false);
      }
    };

    fetchInputOptions();
  }, [setInputOptions, locationId]);

  return children;
};

export default OrdermarkIntegration;
