const OVERVIEWKEYS = [
  [
    {
      key: "totalOrders",
      title: "Orders",
      type: "value",
      description: "",
    },
    {
      key: "totalItemsOrder",
      title: "Total Items Ordered",
      type: "value",
      description: "",
    },
    {
      key: "averageOrderPrice",
      title: "Average Order (Gross)",
      type: "price",
      description: "",
    },
    {
      key: "totalRevenue",
      title: "Total Revenue (Gross)",
      type: "price",
      description: "",
    },
  ],

  [
    {
      key: "totalOrderWithRefunds",
      title: "Orders w/ Refund",
      type: "value",
      description: "",
    },
    {
      key: "totalRefund",
      title: "Refunded Amount",
      type: "price",
      description: "",
    },
    {
      key: "totalAppliedCredit",
      title: "Credits Redeemed",
      type: "price",
      description: "",
    },
  ],
];

const DETAILKEYS = [
  [
    {
      key: "totalOrdersWithAppliedCredit",
      title: "Credited Orders",
      type: "value",
      description: "Orders that have credit applied to the final price",
    },
    {
      key: "totalAppliedCredit",
      title: "Credits Redeemed",
      type: "price",
      description: "",
    },
    {
      key: "totalRevenueWithAppliedCredit",
      title: "Total Revenue from credited orders",
      type: "price",
      description: "",
    },
  ],

  [
    {
      key: "revenueYieldPerLoyaltySpent",
      title: "Revenue/Credit Yield",
      type: "value",
      description: "Yield of $ revenue generated per $ loyalty",
    },
    {
      key: "revenueYieldPerLoyaltyOrder",
      title: "Revenue/Credited Order Yield",
      type: "value",
      description: "",
    },
  ],

  [
    {
      key: "totalOrdersWithDiscount",
      title: "Discounted Orders",
      type: "value",
      description: "Order with discounts applied",
    },
    {
      key: "totalDiscountApplied",
      title: "Discount Redeemed",
      type: "price",
      description: "",
    },
    {
      key: "totalRevenueWithDiscount",
      title: "Total Revenue from discounted orders",
      type: "price",
      description: "",
    },
  ],

  [
    {
      key: "revenueYieldPerDiscountSpent",
      title: "Revenue/Discount Yield",
      type: "value",
      description: "Yield of $ revenue generated per $ discount",
    },
    {
      key: "revenueYieldPerDiscountOrder",
      title: "Revenue/Discounted Order Yield",
      type: "value",
      description: "",
    },
  ],

  [
    {
      key: "averageDailyOrders",
      title: "Avg Daily Orders",
      type: "value",
      description: "Average orders over the last 30 days",
    },
    {
      key: "averageDailyRevenue",
      title: "Avg Daily Revenue",
      type: "price",
      description: "Daily revenue over the last 30 days",
    },
    {
      key: "totalPatrons",
      title: "Total Guests",
      type: "value",
      description: "",
    },
  ],
  // {
  //   key: 'totalTip',
  //   title: 'Total Tip',
  //   type: 'price',
  //   description: '',
  // },
  // {
  //   key: 'averageTip',
  //   title: 'Average Tip',
  //   type: 'price',
  //   description: '',
  // },
  // {
  //   key: 'averageRefund',
  //   title: 'Average Refund',
  //   type: 'price',
  //   description: '',
  // },
  // {
  //   key: 'averageItemsPerOrder',
  //   title: 'Avg Items/Order',
  //   type: 'value',
  //   description: '',
  // },
];

const SUMMARYKEYS = [
  [
    {
      key: "totalRevenueGross",
      title: "Revenue (Gross)",
      type: "price",
      description: "",
    },
    {
      key: "totalOrders",
      title: "# of Orders",
      type: "value",
      description: "",
    },
    {
      key: "averageOrderPrice",
      title: "Check Avg (Gross)",
      type: "price",
      description: "",
    },
    {
      key: "totalRevenueWithAppliedCredit",
      title: "Revenue From Loyalty",
      type: "price",
      description: "",
    },
  ],

  [
    {
      key: "averageCustomerVisit",
      title: "Average Customer Visit",
      type: "value",
      description: "",
    },
    {
      key: "deliveryOrderPercentage",
      title: "Delivery Order %",
      type: "value",
      description: "",
    },
    {
      key: "netAverageOrderPrice",
      title: "Check Avg (Net)",
      type: "price",
      description: "",
    },
    {
      key: "totalAppliedCredit",
      title: "Loyalty Redeemed",
      type: "price",
      description: "",
    },
  ],
];

export { OVERVIEWKEYS, DETAILKEYS, SUMMARYKEYS };
export default {
  OVERVIEWKEYS,
  DETAILKEYS,
  SUMMARYKEYS,
};
