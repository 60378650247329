import * as React from "react";
import {Tag} from "antd";
import styles from "./loyaltyType.module.css";

const tagColorDataV1 = {
  credit: styles.credit,
  gift: styles.credit,
  order: styles.order,
  refund: styles.refund,
  recredit: styles.recredit,
  spent: styles.spent,
  "spent-loyalty": styles.spent,
  coupon: styles.coupon,
  redeemed: styles.redeemed,
  "gift-redeemed": styles.redeemed,
  voided: styles.voided,
};

const tagColorDataV2 = {
  "credit-awarded": styles.credit,
  points: styles.credit,
  "points-awarded": styles.credit,
  reward: styles.credit,
  "points-refunded": styles.refund,
  "credit-refunded": styles.refund,
  "points-removed": styles.refund,
  "credit-removed": styles.refund,
  "points-reverted": styles.refund,
  "credit-reverted": styles.refund,
  "points-spent": styles.spent,
  "spent-loyalty": styles.spent,
  discount: styles.coupon,
  "credit-converted": styles.redeemed,
  void: styles.voided,
};

const tagColorData = {...tagColorDataV1, ...tagColorDataV2};
const tagColor = (type) => tagColorData[type] || null;

const tagTextDataV1 = {
  credit: "Credit",
  gift: "Gift",
  order: "Order",
  refund: "Refund",
  recredit: "Recredit",
  spent: "Spent",
  "spent-loyalty": "Spent Loyalty",
  coupon: "Coupon",
  redeemed: "Redeemed",
  "gift-redeemed": "Gift Redeemed",
  voided: "Voided",
};

const tagTextDataV2 = {
  "credit-awarded": "Credit Awarded",
  points: "Points",
  "points-awarded": "Points Awarded",
  reward: "Reward",
  "points-refunded": "Points Refunded",
  "credit-refunded": "Credit Refunded",
  "points-removed": "Points Removed",
  "credit-removed": "Credit Removed",
  "points-reverted": "Points Reverted",
  "credit-reverted": "Credit Reverted",
  "points-spent": "Points Spent",
  "spent-loyalty": "Spent Loyalty",
  discount: "Discount",
  "credit-converted": "Credit Converted",
  void: "Void",
};

const tagTextData = {...tagTextDataV1, ...tagTextDataV2};
const tagText = (type) => tagTextData[type] || null;

const LoyaltyType = ({type}) => (
  <Tag className={tagColor(type)}>{tagText(type)}</Tag>
);

export default LoyaltyType;
