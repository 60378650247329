import React from "react";
import {Menu} from "antd";
import {Permissions} from "components/fragments";

const {Permission} = Permissions;
const {SubMenu} = Menu;

const NavSubMenu = ({requiredPermissions, matchOne, ...props}) => (
  <Permission
    requiredPermissions={requiredPermissions}
    isNav
    matchOne={matchOne}
    yes={() => <SubMenu {...props} />}
  />
);

export default NavSubMenu;
