import React from "react";
import PropTypes from "prop-types";
import { Col, Input, Icon, Select } from "antd";
import { LoadingSpinner } from "components/v2/Loading/LoadingSpinner/LoadingSpinner";
import Image from "../Image";
import { T, Row } from "../../../elements";
import { ConfirmationButton } from "../../../fragments";
import css from "./uploader.module.css";

const rowProps = {
  gutter: 16,
  spacing: 16,
};
const iconSize = {
  fontSize: "1.2rem",
};
const uploadedIconProps = {
  type: "check-circle",
  theme: "twoTone",
  twoToneColor: "#52c41a",
  title: "Image has been uploaded",
  style: iconSize,
};
const pendingIconProps = {
  type: "warning",
  theme: "twoTone",
  twoToneColor: "#faad14",
  title: "Image has not been uploaded yet",
  style: iconSize,
};

const loadingIconProps = {
  type: "loading",
  style: iconSize,
};
const uploadIconProps = {
  type: "cloud-upload",
  style: iconSize,
};

const cssRed = window
  .getComputedStyle(document.documentElement)
  .getPropertyValue("--error-red");

const UploadItem = ({
  altText = "",
  caption = "",
  description = "",
  fileName = "",
  tags = [],
  title = "",
  base64 = "",
  isUploading = false,
  isUploaded = false,
  isOpen = false,
  onChange = () => null,
  onRemove = () => null,
  onUpload = () => null,
}) => {
  const handleChange = ({ target: { name, value } }) => {
    onChange({ [name]: value });
  };
  const handleTagChange = (value) => {
    onChange({ tags: value });
  };
  const handleToggleIsOpen = () => {
    onChange({ isOpen: !isOpen });
  };

  let iconProps = pendingIconProps;
  if (isUploaded) {
    iconProps = uploadedIconProps;
  } else if (isUploading) {
    iconProps = loadingIconProps;
  } else {
    iconProps = { ...uploadIconProps, onClick: onUpload };
  }

  return (
    <div style={{ width: "100%" }}>
      <Row type="flex" justify="space-between">
        <Col>
          <T>
            <Icon
              type={isOpen ? "caret-down" : "caret-right"}
              theme="filled"
              onClick={handleToggleIsOpen}
            />
            {fileName}
          </T>
        </Col>
        <Col>
          <Row type="flex" justify="space-between" {...rowProps}>
            <Col>
              <ConfirmationButton
                placement="bottom"
                title="Are you sure?"
                okText="Yes"
                onConfirm={onRemove}
              >
                {!isUploaded && (
                  <Icon
                    type="delete"
                    theme="twoTone"
                    twoToneColor={cssRed.trim()}
                    style={iconSize}
                  />
                )}
              </ConfirmationButton>
            </Col>
            <Col>
              <Icon {...iconProps} />
            </Col>
          </Row>
        </Col>
      </Row>
      {isOpen && (
        <div className={css.detailsContainer}>
          <div className={css.imagePreview}>
            <Image src={base64} />
          </div>
          <div className={css.infoPreview}>
            <LoadingSpinner spinning={isUploading}>
              <Row {...rowProps}>
                <Col xs={24} sm={12}>
                  <Input
                    value={title}
                    name="title"
                    disabled={isUploaded}
                    onChange={handleChange}
                    placeholder="Title"
                  />
                </Col>
                <Col xs={24} sm={12}>
                  <Input
                    value={altText}
                    name="altText"
                    disabled={isUploaded}
                    onChange={handleChange}
                    placeholder="Alt Text"
                  />
                </Col>
              </Row>
              <Row {...rowProps}>
                <Col xs={24} sm={12}>
                  <Input
                    value={caption}
                    name="caption"
                    disabled={isUploaded}
                    onChange={handleChange}
                    placeholder="Caption"
                  />
                </Col>
                <Col xs={24} sm={12}>
                  <Input
                    value={description}
                    name="description"
                    disabled={isUploaded}
                    onChange={handleChange}
                    placeholder="Description"
                  />
                </Col>
              </Row>
              <Row {...rowProps}>
                <Col xs={24} sm={12}>
                  <Select
                    style={{ width: "100%" }}
                    value={tags}
                    mode="tags"
                    name="tags"
                    disabled={isUploaded}
                    onChange={handleTagChange}
                    placeholder="Tags"
                  />
                </Col>
              </Row>
            </LoadingSpinner>
          </div>
        </div>
      )}
    </div>
  );
};

UploadItem.propType = {
  altText: PropTypes.string,
  caption: PropTypes.string,
  description: PropTypes.string,
  fileName: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  isUploading: PropTypes.bool,
  isUploaded: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default UploadItem;
