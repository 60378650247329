import React, {useState} from "react";
import classnames from "classnames";
import css from "./toggle.module.css";

const selectorClass = (selected) => {
  switch (selected) {
    case 0:
      return css.triSelectorLeft;
    case 1:
      return css.triSelectorMiddle;
    case 2:
      return css.triSelectorRight;
    default:
      return "";
  }
};

// TODO refactor this so that the initialSelected is controlled by the parent only like other inputs
const TriToggle = ({options, onChange, className, value = 1}) => {
  const [selected, setSelected] = useState(value);

  const handleClick = (id) => {
    if (id !== selected) {
      setSelected(id);
      onChange(options[id]);
    }
  };

  const selectorClasses = classnames(
    css.triToggleSelector,
    selectorClass(selected),
  );
  const selectedLeft = selected === 0 && css.selected;
  const selectedMiddle = selected === 1 && css.selected;
  const selectedRight = selected === 2 && css.selected;

  return (
    <div className={classnames(css.triToggleWrapper, className)}>
      <div className={css.sliderWrapper}>
        <div
          onClick={() => handleClick(0)}
          className={classnames(css.triToggleSelection, selectedLeft)}
        >
          <span onClick={() => handleClick(0)}>{options[0]}</span>
        </div>
        <div
          id="1"
          onClick={() => handleClick(1)}
          className={classnames(css.triToggleSelection, selectedMiddle)}
        >
          <span onClick={() => handleClick(1)}>{options[1]}</span>
        </div>
        <div
          id="2"
          onClick={() => handleClick(2)}
          className={classnames(css.triToggleSelection, selectedRight)}
        >
          <span onClick={() => handleClick(2)}>{options[2]}</span>
        </div>
        <div className={selectorClasses} />
      </div>
    </div>
  );
};

export default TriToggle;
