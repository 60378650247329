import * as Yup from "yup";

const BirthdaySettingEnums = ["dayMonthYear", "dayMonth"];
const TipEnablementOptionsEnums = ["always", "delivery", "pickup", "none"];
const DefaultDeliveryTipOptionsEnums = [
  "zero",
  "ten",
  "fifteen",
  "twenty",
  "twentyfive",
];
const DefaultPickupTipOptionsEnums = [
  "zero",
  "ten",
  "fifteen",
  "twenty",
  "twentyfive",
];
const StoredValueEnablementOptionsEnums = [
  "all",
  "preload",
  "giftcard",
  "none",
];
const SocialLinkTypeOptionsEnums = [
  "facebook",
  "instagram",
  "twitter",
  "tiktok",
  "other",
];

const SocialLinkSchema = Yup.object().shape({
  url: Yup.string().url(),
  imageURL: Yup.string().url(),
  type: Yup.string().oneOf(SocialLinkTypeOptionsEnums).nullable(),
});

const WelcomeMessageSectionSchema = Yup.object().shape({
  message: Yup.string().nullable(),
  startTime: Yup.number().nullable(),
  endTime: Yup.number().nullable(),
});

const MobileAppConfigurationSchema = Yup.object().shape({
  browserOrderUrl: Yup.string().url().required(),
  birthdaySetting: Yup.string().oneOf(BirthdaySettingEnums).required(),
  tipEnablement: Yup.string().oneOf(TipEnablementOptionsEnums).required(),
  defaultDeliveryTip: Yup.string()
    .oneOf(DefaultDeliveryTipOptionsEnums)
    .required(),
  defaultPickupTip: Yup.string().oneOf(DefaultPickupTipOptionsEnums).required(),
  storedValueEnablement: Yup.string()
    .oneOf(StoredValueEnablementOptionsEnums)
    .required(),
  bankLoyaltyEnablement: Yup.boolean().required(),
  locationNotificationEnablement: Yup.boolean().required(),
  deliverySelectionEnablement: Yup.boolean().required(),
  googlePayEnabled: Yup.boolean().required(),
  applePayEnabled: Yup.boolean().required(),
  zendeskEmailCheckEnabled: Yup.boolean().required(),
  facebookSSOEnabled: Yup.boolean().required(),
  googleSSOEnabled: Yup.boolean().required(),
  appleSSOEnabled: Yup.boolean().required(),
  beamEnabled: Yup.boolean().required(),
  giftcardAddCheckout: Yup.boolean().required(),
  welcomeMessage: Yup.object().shape({
    morning: WelcomeMessageSectionSchema,
    afternoon: WelcomeMessageSectionSchema,
    evening: WelcomeMessageSectionSchema,
  }),
  socialLinks: Yup.array().of(SocialLinkSchema),
});

export {
  MobileAppConfigurationSchema,
  BirthdaySettingEnums,
  TipEnablementOptionsEnums,
  DefaultDeliveryTipOptionsEnums,
  DefaultPickupTipOptionsEnums,
  StoredValueEnablementOptionsEnums,
  SocialLinkTypeOptionsEnums,
};
