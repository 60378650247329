import React, { useEffect, useState } from "react";
import moment from "moment";
import { Col, Card, message } from "antd";
import { LoadingSpinner } from "components/v2/Loading/LoadingSpinner/LoadingSpinner";
import { useRefetchableResource, useAnalyticsActions } from "hooks";
import { Elements, Fragments } from "components";
import { utils } from "common";
import { useAPIContext } from "components/providers/Api";
import Header from "../Analytics/Header";
import LineGraph from "../Analytics/LineGraph";
import { SUMMARYKEYS } from "./utils";
import css from "./home.module.css";

const { roundFloat, mapAxiosError } = utils;
const {
  Titles: { BlueCardTitle, CardTitle },
  Row,
} = Elements;
const {
  Cards: { StatCard, BlueCard },
  Sales,
} = Fragments;

const iconSelection = (idx) => {
  switch (idx) {
    case 3:
      return "desktop-total-icon";
    case 2:
      return "desktop-orders-icon";
    case 1:
      return "mobile-total-icon";
    default:
      return "mobile-orders-icon";
  }
};

const getPercentage = (part, whole) => {
  if (part === 0) return 0;
  if (whole === 0) return Infinity;
  return roundFloat((part / whole) * 100);
};

const getPercentageChange = (val, prevVal) => {
  if (typeof val !== "number" || typeof prevVal !== "number") return null;

  const diff = val - prevVal;
  return getPercentage(diff, prevVal);
};

const getDeliveryOrderPercentage = (totalDeliveryOrders, totalOrders) =>
  totalDeliveryOrders && totalOrders
    ? getPercentage(totalDeliveryOrders, totalOrders)
    : undefined;

const getNetAverageOrderPrice = (netRevenue, orders) => {
  if (netRevenue && orders) {
    if (orders === 0) return 0;
    return netRevenue / orders;
  }
  return undefined;
};

const renderStatCards = (stats, overview, prevOverview) =>
  stats.map((stat, idx) => (
    <Row key={idx}>
      {stat.map((statItem, i) => {
        const isMissingPresentWeek = overview[statItem.key] === undefined;
        const value = isMissingPresentWeek ? 0 : overview[statItem.key];

        return (
          <Col
            xs={24}
            sm={12}
            lg={6}
            key={statItem.key}
            style={{ marginBottom: "30px" }}
          >
            <StatCard
              {...statItem}
              value={value}
              percentageChange={getPercentageChange(
                overview[statItem.key],
                prevOverview[statItem.key],
              )}
              icon={iconSelection(i)}
            />
          </Col>
        );
      })}
    </Row>
  ));

const AnalyticsOverview = ({ locationId }) => {
  const { Analytics } = useAPIContext();

  const { actions, params } = useAnalyticsActions({ locationId });
  const { resource: overview, fetching: fetchingOverview } =
    useRefetchableResource({
      path: "/analytics/overview",
      data: { filters: params },
      method: "GET",
    });
  const { resource: registrationsResource } = useRefetchableResource({
    path: "/analytics/registrations",
    data: { filters: params },
    method: "GET",
  });
  const [prevOverview, setPrevOverview] = useState([]);
  const [currOverview, setCurrOverview] = useState({});

  useEffect(() => {
    if (!fetchingOverview) {
      const getAnalyticsDifference = async () => {
        const {
          createdAt: { start, end },
        } = params;

        const startObj = moment(start);
        const endObj = moment(end);
        const duration = endObj.diff(startObj, "minutes", true);

        const prevStart = moment(start)
          .utc()
          .subtract(duration, "minutes")
          .format();
        const prevEnd = moment(end)
          .utc()
          .subtract(duration, "minutes")
          .format();

        try {
          const { data } = await Analytics.getOverview({
            filters: { createdAt: { start: prevStart, end: prevEnd } },
          });
          const { totalDeliveryOrders, totalOrders, totalRevenueNet } = data;
          data.deliveryOrderPercentage = getDeliveryOrderPercentage(
            totalDeliveryOrders,
            totalOrders,
          );
          data.netAverageOrderPrice = getNetAverageOrderPrice(
            totalRevenueNet,
            totalOrders,
          );
          setPrevOverview(data);
        } catch (e) {
          if (e) message.error(mapAxiosError(e));
        }
      };

      getAnalyticsDifference();

      const { totalDeliveryOrders, totalOrders, totalRevenueNet } = overview;
      overview.deliveryOrderPercentage = getDeliveryOrderPercentage(
        totalDeliveryOrders,
        totalOrders,
      );
      overview.netAverageOrderPrice = getNetAverageOrderPrice(
        totalRevenueNet,
        totalOrders,
      );
      setCurrOverview(overview);
    }
  }, [fetchingOverview, overview]);

  const totalRevenueLineData = (data) => [
    {
      id: "Total Sales",
      data: data.map((i) => ({
        y: i.totalRevenue,
        x: moment(i._id).format("MMM DD"),
      })),
    },
  ];

  const totalOrdersLineData = (data) => [
    {
      id: "Total Orders",
      data: data.map((i) => ({
        y: i.totalOrders,
        x: moment(i._id).format("MMM DD"),
      })),
    },
  ];

  const platformSalesLineData = (data) => {
    const webData =
      data &&
      data.map((i) => ({
        y: +i.totalWebRevenue.toFixed(2),
        x: moment(i._id).format("MMM DD"),
      }));
    const appData =
      data &&
      data.map((i) => ({
        y: +(i.totalAppleRevenue + i.totalAndroidRevenue).toFixed(2),
        x: moment(i._id).format("MMM DD"),
      }));

    return [
      {
        id: "Web Sales",
        data: webData,
      },
      {
        id: "App Sales",
        data: appData,
      },
    ];
  };

  const orderTypeLineData = (data) => {
    const deliveryData =
      data &&
      data.map((i) => ({
        y: i.totalDeliveryOrders,
        x: moment(i._id).format("MMM DD"),
      }));
    const pickupData =
      data &&
      data.map((i) => ({
        y: i.totalPickupOrders,
        x: moment(i._id).format("MMM DD"),
      }));

    return [
      {
        id: "Delivery Orders",
        data: deliveryData,
      },
      {
        id: "Pickup Orders",
        data: pickupData,
      },
    ];
  };

  const registrationsLineData = (data) => [
    {
      id: "Registrations",
      data: data.map((i) => ({
        y: i.count || i.sum,
        x: moment(i._id).format("MMM DD"),
      })),
    },
  ];

  return (
    <>
      <Card bordered={false} loading={fetchingOverview}>
        <Header
          actions={actions}
          title="Analytics Breakdown"
          initialDateValues={params.createdAt}
        />

        <Row>
          <LoadingSpinner spinning={fetchingOverview}>
            {renderStatCards(SUMMARYKEYS, currOverview, prevOverview)}
          </LoadingSpinner>
        </Row>
      </Card>

      <BlueCard>
        <Sales locationId={locationId} params={params}>
          {({ ...salesData }) => (
            <>
              <BlueCardTitle className={css.homeTitle}>Home</BlueCardTitle>
              <Row gutter={60}>
                <Col xs={24} md={24}>
                  <Row>
                    <Col xs={24} sm={12}>
                      <CardTitle className={css.barTitle}>
                        Total Sales
                      </CardTitle>
                      <LineGraph
                        data={totalRevenueLineData(salesData.resource)}
                        unit="currency"
                      />
                    </Col>
                    <Col xs={24} sm={12}>
                      <CardTitle className={css.barTitle}>
                        Platform Sales
                      </CardTitle>
                      <LineGraph
                        data={platformSalesLineData(salesData.resource)}
                        legendTranslateX={-67}
                        unit="currency"
                      />
                    </Col>
                  </Row>
                  <Row spacing={60}>
                    <Col xs={24} sm={12}>
                      <CardTitle className={css.barTitle}>
                        Total Orders
                      </CardTitle>
                      <LineGraph
                        data={totalOrdersLineData(salesData.resource)}
                        legendTranslateX={-57}
                      />
                    </Col>
                    <Col xs={24} sm={12}>
                      <CardTitle className={css.barTitle}>Order Type</CardTitle>
                      <LineGraph
                        data={orderTypeLineData(salesData.resource)}
                        legendTranslateX={-47}
                      />
                    </Col>
                  </Row>
                  <Row spacing={30}>
                    <CardTitle className={css.barTitle}>
                      Daily Registrations
                    </CardTitle>
                    <LineGraph
                      data={registrationsLineData(registrationsResource)}
                      legendTranslateX={-46}
                    />
                  </Row>
                </Col>
              </Row>
            </>
          )}
        </Sales>
      </BlueCard>
    </>
  );
};

export default AnalyticsOverview;
