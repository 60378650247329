import React from "react";
import {Formik, Field} from "formik";
import {message, Form} from "antd";
import {useAsyncState} from "hooks";
import {MediaUpdate} from "common/schemas/media";
import {useAPIContext} from "components/providers/Api";
import {T, Button} from "../../../elements";
import {ConfirmationButton} from "../../../fragments";
import {Input, TagsSelect} from "../../../fields";
import css from "./details.module.css";

/**
 * Renders Media details information for a specific media record. With form to edit
 * @param {object} props
 * @param {String} props.id - Database Id of the media document
 * @param {String} props.altText - Indicator if the specific version is the original media uploaded
 * @param {String} props.tags - Unique Id for specific version of the media document
 * @param {String} props.title - File Name for specific version of the media document
 * @param {String} props.admin - File Size for specific version of the media document
 * @param {String} props.fileName - Publicly accessible URL of the media document
 * @param {String} props.fileType - Date specific version of media document was created
 */
const DetailsInfo = ({
  id = "",
  altText = "",
  tags = [],
  title = "",
  fileName = "",
  fileType = "",
  onDelete = () => null,
}) => {
  const [deleteState, deleteSetters] = useAsyncState({fetching: false});
  const {Media} = useAPIContext();
  const handleSave = async (values, actions) => {
    actions.setSubmitting(true);
    try {
      await Media.update(id, {
        title: values.title,
        altText: values.altText,
        tags: values.tags,
      });
      message.success("Media Saved");
    } catch (e) {
      message.error(e);
    } finally {
      actions.setSubmitting(false);
    }
  };
  const handleDelete = async () => {
    deleteSetters.setIsFetching();
    try {
      await Media.delete(id);
      message.success("Media Deleted");
      onDelete();
      return;
    } catch (e) {
      message.error(e);
    } finally {
      deleteSetters.setNotFetching();
    }
  };

  return (
    <div className={css.detailsInfo}>
      <T>
        File Name: &nbsp;
        {fileName}
      </T>
      <T>
        File Type: &nbsp;
        {fileType}
      </T>

      <Formik
        initialValues={{altText, tags, title}}
        validationSchema={MediaUpdate}
        onSubmit={handleSave}
      >
        {({isSubmitting, handleSubmit}) => (
          <Form onSubmit={handleSubmit}>
            <Field
              label="Title"
              name="title"
              placeholder="Title of this media"
              component={Input}
            />
            <Field
              label="Alt Text"
              name="altText"
              placeholder="Alt text"
              type="textarea"
              component={Input}
            />
            <Field
              label="Tags/Keywords"
              name="tags"
              placeholder="Tags/Keywords for easy searching"
              component={TagsSelect}
            />

            <div className={css.buttonWrapper}>
              <ConfirmationButton
                placement="bottom"
                title="Are you sure?"
                okText="Yes"
                onConfirm={handleDelete}
              >
                <Button
                  color="red"
                  htmlType="button"
                  size="medium"
                  loading={deleteState.fetching}
                >
                  Delete
                </Button>
              </ConfirmationButton>
              <Button
                htmlType="submit"
                disabled={isSubmitting}
                color="blue"
                size="medium"
              >
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export {DetailsInfo};
export default DetailsInfo;
