import InteractiveTable from "./InteractiveTable";
import PersistentTable from "./PersistentTable";
import LocationToggleTable from "./LocationToggleTable";
import LocationSelectionTable from "./LocationSelectionTable";
import Table from "./Table";

export {
  InteractiveTable,
  Table,
  PersistentTable,
  LocationToggleTable,
  LocationSelectionTable,
};
export default {
  InteractiveTable,
  Table,
  PersistentTable,
  LocationToggleTable,
  LocationSelectionTable,
};
