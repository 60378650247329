/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {useState} from "react";
import classnames from "classnames";
import css from "./toggle.module.css";

const selectorClass = (selected) => {
  switch (selected) {
    case 0:
      return css.selectorLeft;
    case 1:
      return css.selectorRight;
    default:
      return "";
  }
};

const DuoToggle = ({className, options, onChange, value = 0}) => {
  const [selected, setSelected] = useState(value);

  const handleClick = (id) => {
    setSelected(id);
    onChange(options[id]);
  };

  const selectedLeft = selected === 0 && css.selected;
  const selectedRight = selected === 1 && css.selected;

  const selectorClasses = classnames(
    css.duoSelectorToggle,
    selectorClass(selected),
  );

  return (
    <div className={classnames(css.duoToggleWrapper, className)}>
      <div className={css.sliderWrapper}>
        <div
          onClick={() => handleClick(0)}
          className={classnames(css.selection, selectedLeft)}
        >
          <span onClick={() => handleClick(0)}>{options[0]}</span>
        </div>
        <div
          onClick={() => handleClick(1)}
          className={classnames(css.selection, selectedRight)}
        >
          <span onClick={() => handleClick(1)}>{options[1]}</span>
        </div>
        <div className={selectorClasses} />
      </div>
    </div>
  );
};

export {DuoToggle};
