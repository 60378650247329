import * as React from "react";
import styled from "styled-components";
import { useOnClickOutside } from "hooks";
import { v4 as uuid } from "uuid";
import { theme } from "common/constants/theme";
import { Marketplace } from "pages/Marketplaces/Marketplaces.types";
import { RestaurantGroup } from "pages/RestaurantGroups/RestaurantGroups.types";

type SelectSearchSizes = "sm" | "lg";

const SearchSelectContainer = styled.div`
  position: relative;
`;

const Search = styled.input<{
  size?: SelectSearchSizes;
}>`
  padding: 5px 12px;
  border: 1px solid ${theme.colors.neutral[500]};
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 6px;
  ${({ size }) => {
    if (size === "sm") {
      return "padding: 8px 4px";
    }
    if (size === "lg") {
      return "padding: 16px 12px";
    }
  }}
  width: 100%;
`;

const SearchResults = styled.div`
  background-color: ${theme.colors.powder};
  position: absolute;
  z-index: 100;
  width: 100%;
  border: 0.5px solid ${theme.colors.neutral[200]};
  max-height: 200px;
  overflow-y: auto;
`;

const SelectOption = styled.button`
  background-color: transparent;
  border: none;
  width: 100%;
  text-align: left;
  &:hover {
    background-color: ${theme.colors.teal[700]};
    color: ${theme.colors.powder};
  }
  border-radius: 2px;
`;

interface SelectShape {
  onChange?: Function;
  onSearch?: Function;
  options: OptionsArrayShape[];
  placeholder?: string;
  id?: string;
  size?: SelectSearchSizes;
  className?: string;
}

interface OptionShape {
  value?: any;
  children: string | number;
}

interface OptionsArrayShape {
  label?: string;
  value: string | number | RestaurantGroup | Marketplace;
}

function SelectSearch({
  onChange = () => {},
  onSearch = () => {},
  options = [],
  size = "sm",
  placeholder: defaultPlaceholder = "",
  className,
  id,
}: SelectShape) {
  const [showOptions, setShowOptions] = React.useState(false);
  const [value, setValue] = React.useState<string | number | null>(null);
  const [searchValue, setSearchValue] = React.useState<
    (string | number | readonly string[]) & string
  >("");
  const [placeholder, setPlaceholder] = React.useState<string | number>(
    defaultPlaceholder,
  );
  const onFocus = () => setShowOptions(true);
  const containerRef = React.useRef(null);

  useOnClickOutside(() => {
    setShowOptions(false);
    setSearchValue("");
  }, containerRef);

  React.useEffect(() => {
    if (value) {
      onChange(value);
    }
  }, [value]);

  React.useEffect(() => {
    onSearch(searchValue);
  }, [searchValue]);

  function Option({ value, children }: OptionShape) {
    const handleOptionSelected = () => {
      setShowOptions(false);
      setValue(value ?? children);
      setSearchValue("");
      setPlaceholder(children);
    };

    return (
      <SelectOption onClick={handleOptionSelected}>{children}</SelectOption>
    );
  }

  const renderOptions = (options: any) => {
    return options.map((e: any) => {
      const key = uuid();
      return (
        <Option value={e.value} key={key}>
          {e.label ?? e.value}
        </Option>
      );
    });
  };

  return (
    <SearchSelectContainer ref={containerRef}>
      <Search
        onFocus={onFocus}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        // FIXME: We shouldn't cast types here
        placeholder={placeholder as string}
        // FIXME: We shouldn't cast types here
        size={size as never}
        className={className}
        id={id}
      />
      {showOptions && options.length > 0 && (
        <SearchResults>
          {renderOptions(
            // Check if user has inputted a value
            searchValue === ""
              ? // if not display all available options
                options
              : // if a value is inputted then filter options by that value
                /// make everything lower case so it's search isn't case sensitive
                options.filter((e) =>
                  e.label
                    ?.toLowerCase()
                    ?.includes(searchValue.toString().toLowerCase()),
                ),
          )}
        </SearchResults>
      )}
    </SearchSelectContainer>
  );
}

export { SelectSearch };
