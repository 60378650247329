import React from "react";
import {Typography} from "antd";
import css from "./formErrorsCard.module.css";

const {Text} = Typography;

const renderErrorsRecursively = (errors) => {
  if (typeof errors === "object") {
    return Object.entries(errors).map(([key, val]) => (
      <div key={`errors.${key}`} className={css.errorSection}>
        {renderErrorsRecursively(val)}
      </div>
    ));
  }
  if (Array.isArray(errors)) {
    return errors.map((error, index) => (
      <div key={`error.${index}`} className={css.errorSection}>
        {renderErrorsRecursively(error)}
      </div>
    ));
  }
  return <Text type="danger">{errors}</Text>;
};

const FormErrorsCard = ({errors}) => {
  return Object.keys(errors).length > 0 ? (
    <div className={css.errorContainer}>{renderErrorsRecursively(errors)}</div>
  ) : null;
};

export default FormErrorsCard;
