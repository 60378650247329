import React from "react";
import {Switch, useLocation} from "react-router-dom";
import {AnimatePresence} from "framer-motion";

/**
 * Sliding up/down left/right animation wrapper for React Router Switch
 * @author Htin Linn Aung
 * @memberof Elements.Elements/AnimatedRoutes
 * @prop {JSX.Element | JSX.Element[]} props.children - Children components
 * @prop {boolean} props.exitBeforeEnter - Exit before enter animation flag
 * @prop {boolean} props.initial - Initial animation flag
 * @returns {React.Element} - Sliding up/down left/right animation wrapper for React Router Routes
 */
const AnimateRoutes = ({children, exitBeforeEnter = true, initial = false}) => {
  const location = useLocation();
  return (
    <AnimatePresence exitBeforeEnter={exitBeforeEnter} initial={initial}>
      <Switch location={location} key={location.pathname}>
        {children}
      </Switch>
    </AnimatePresence>
  );
};

export default AnimateRoutes;
