import React, { useEffect, useContext, useState, useRef } from "react";
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import { Layout } from "antd";
import { ErrorFallback, Permissions } from "components/fragments";
import { SideNav } from "components/templates";
import { PageSpinner } from "components/elements";
import { UIContext } from "components/providers";
import { useUserContext } from "components/providers/User";
import { useAPIContext } from "components/providers/Api";
import { theme } from "common/constants/theme";
import { useFeatureFlag, useWindowSize } from "hooks";
import Pages from "pages";
import { OPERATE_NAV } from "./menuConfig";
import Header from "./Header";
import sitemap from "./sitemap";
import { Topbar } from "./Topbar/Topbar";
import { Sidebar } from "./Sidebar/Sidebar";
import { MobileFooter } from "./MobileFooter/MobileFooter";
import AutomationDashboard from "pages/Automation";

const { Content } = Layout;
const { Permission } = Permissions;

const PermissionRoute = ({ requiredPermissions, ...props }) => (
  <Permission
    requiredPermissions={requiredPermissions}
    isRoute
    yes={() => <Route {...props} />}
    no={() => <ErrorFallback title="You do not have access to this page." />}
  />
);

const Routes = () => {
  const history = useHistory();
  const location = useLocation();
  const isEnabled = useFeatureFlag("admin_redesign");
  const { fetching, platform } = useAPIContext();
  const { width } = useWindowSize();
  const { user, activeLocationID, onActiveLocationChange, logout, locations } =
    useUserContext();
  const { newUI, setNewUI } = useContext(UIContext);
  const [minNav, setMinNav] = useState(width < theme.breakpoints.xl.px);
  const [navType, setNavType] = useState(currentPageType);

  const toggleSidebarRef = useRef(null);
  const mobileFooterRef = useRef(null);

  const isMobile = width < theme.breakpoints.lg.px;
  const automationEnabled = location?.pathname === "/lunchcrew/config";
  const restoreUrl = location.pathname !== "/login" ? location.pathname : "";
  const currentPageType =
    Object.values(sitemap(platform, newUI)).find(
      (e) => e.path === location.pathname,
    )?.navType ?? OPERATE_NAV;

  useEffect(() => {
    setNavType(currentPageType);
  }, [currentPageType]);

  useEffect(() => {
    if (isMobile) setMinNav(true);
  }, [isMobile]);

  useEffect(() => {
    if (window.Appcues && window.Appcues.page) {
      window.Appcues.page();
    }
  }, [location]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });

    if (isEnabled) {
      setNewUI(true);
    }

    return () => {
      unlisten();
    };
  }, []);

  const routes = Object.values(sitemap(platform, newUI)).map((route) => {
    const { component: Component, path, exact, requiredPermissions } = route;
    return (
      <PermissionRoute
        key={path}
        path={path}
        exact={exact}
        requiredPermissions={requiredPermissions}
      >
        <Component
          locations={locations}
          locationId={activeLocationID}
          user={user}
        />
      </PermissionRoute>
    );
  });

  if (fetching) {
    return <PageSpinner />;
  }

  const layout = (
    <Layout style={{ minHeight: "100vh" }}>
      <SideNav onLogout={logout} location={location} />
      <Layout>
        <Header
          onActiveLocationChange={onActiveLocationChange}
          activeLocationID={activeLocationID}
          currentUrl={location.pathname}
        />
        <Content
          style={{
            height: "100%",
            display: "flex",
          }}
        >
          {/* <ScrollToTop /> */}
          <Switch>
            {routes}
            <Redirect from="*" to="/operate" />
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );

  const newLayout = (
    <>
      <Topbar
        navType={navType}
        setNavType={setNavType}
        minNav={minNav}
        setMinNav={setMinNav}
        onLogout={logout}
        toggleSidebarRef={toggleSidebarRef}
        isMobile={isMobile}
      />
      <div style={{ display: "flex" }}>
        <Sidebar
          navType={navType}
          minNav={minNav}
          isMobile={isMobile}
          setMinNav={setMinNav}
          mobileFooterRef={mobileFooterRef}
          toggleSidebarRef={toggleSidebarRef}
        />
        <Switch>
          {routes}
          <Redirect from="*" to="/operate" />
        </Switch>
        {isMobile && (
          <MobileFooter
            navType={navType}
            setNavType={setNavType}
            mobileFooterRef={mobileFooterRef}
          />
        )}
      </div>
    </>
  );

  if (user) {
    window.userGuiding.identify(user?.id, {
      ...user,
      email: user?.email?.value,
    });

    if (newUI) return newLayout;
    if (automationEnabled) return <AutomationDashboard />;
    return layout;
  }
  return (
    <Switch>
      <Route path="/login" component={Pages.Login} />
      <Redirect from="*" to={{ pathname: "/login", state: { restoreUrl } }} />
    </Switch>
  );
};

export default Routes;
