import {useEffect} from "react";
import {message} from "antd";
import {mapAxiosError} from "common/utils";
import {useAPIContext} from "components/providers/Api";

const mapGenericBrinkArray = ({data = []}) => {
  return data.map((i) => {
    return {
      name: i?.name,
      value: `${i?.externalId ?? ""}`,
    };
  });
};

const BrinkIntegration = ({
  children,
  inputOptions,
  setInputOptions,
  locationId,
  setFetching,
}) => {
  const {Locations} = useAPIContext();

  useEffect(() => {
    const fetchInputOptions = async () => {
      try {
        let diningOptions = [];
        let serviceCharges = [];
        let paymentOptions = [];
        let sections = [];

        if (locationId) {
          const [
            diningOptionsRes,
            serviceChargesRes,
            paymentOptionsRes,
            sectionRes,
          ] = await Promise.all([
            Locations.getDiningOptions(locationId).then(mapGenericBrinkArray),
            Locations.getServiceCharges(locationId).then(mapGenericBrinkArray),
            Locations.getPaymentOptions(locationId).then(mapGenericBrinkArray),
            Locations.getSections(locationId).catch((e) => []),
          ]);
          diningOptions = diningOptionsRes;
          serviceCharges = serviceChargesRes;
          paymentOptions = paymentOptionsRes;
          sections = sectionRes.data;
        }

        setInputOptions({
          ...inputOptions,
          diningOptions,
          paymentOptions,
          serviceCharges,
          sections,
        });
      } catch (e) {
        message.error(mapAxiosError(e));
      } finally {
        setFetching(false);
      }
    };

    fetchInputOptions();
  }, [setInputOptions, locationId]);

  return children;
};

export default BrinkIntegration;
