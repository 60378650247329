import React, {useState} from "react";
import {message} from "antd";
import {Button} from "components/elements";
import {ConfirmationButton} from "components/fragments/ConfirmationButton";
import {useAPIContext} from "components/providers/Api";
import styles from "../availabilityButtons.module.css";

const EntityAvailabilityButtons = ({
  selectedIds,
  locationId,
  listType,
  onSuccess,
  requiredPermissions,
}) => {
  const {MenuEntities} = useAPIContext();

  const [fetching, setFetching] = useState(false);

  const postAvailability = async (available) => {
    setFetching(true);
    try {
      const data = {
        availability: available,
        ...(locationId !== undefined && {location: locationId}),
        [listType]: selectedIds,
      };
      await MenuEntities.updateAvailability(data);
      onSuccess();
      message.success("Successfully Saved!");
    } catch (e) {
      console.log(e);
      message.error("An error occurred");
    } finally {
      setFetching(false);
    }
  };

  const onMakeAvailable = () => {
    if (!fetching) {
      postAvailability(true);
    }
  };
  const onMakeUnavailable = () => {
    if (!fetching) {
      postAvailability(false);
    }
  };

  if (!requiredPermissions && !locationId) return null;
  return (
    <div className={styles.buttonWrapper}>
      <ConfirmationButton
        placement="bottom"
        title="This will affect all locations. Are you sure?"
        okText="Yes"
        isConditional={!locationId}
        onConfirm={onMakeAvailable}
        visible={selectedIds[0]}
      >
        <Button
          disabled={!selectedIds[0] || fetching}
          color="blue"
          size="medium"
        >
          Make Available
        </Button>
      </ConfirmationButton>
      &nbsp;
      <ConfirmationButton
        placement="bottom"
        title="This will affect all locations. Are you sure?"
        okText="Yes"
        isConditional={!locationId}
        onConfirm={onMakeUnavailable}
        visible={selectedIds[0]}
      >
        <Button
          disabled={!selectedIds[0] || fetching}
          className={styles.unavailableButton}
          color="red"
          size="medium"
        >
          Make Unavailable
        </Button>
      </ConfirmationButton>
    </div>
  );
};

export default EntityAvailabilityButtons;
