import React, { useReducer, useEffect } from "react";
import moment from "moment";
import { Col, Card } from "antd";
import { LoadingSpinner } from "components/v2/Loading/LoadingSpinner/LoadingSpinner";
import { useRefetchableResource } from "hooks";
import cloneDeep from "lodash/cloneDeep";
import { Elements, Fragments } from "components";
import Header from "./Header";
import styles from "./analytics.module.css";

const {
  Titles: { BlueCardTitle, CardTitle },
  Row,
} = Elements;
const {
  Cards: { StatCard, BlueCard },
  Tables: { Table },
} = Fragments;

const reducer = (state, action) => {
  switch (action.type) {
    case "LOCATION_CHANGE":
      return { ...state, location: action.payload.location };
    case "DATE_CHANGE":
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

const filters = ({ location = "" }) => ({
  location,
  createdAt: {
    start: moment().subtract(8, "days").startOf("day").format(),
    end: moment().subtract(1, "days").endOf("day").format(),
  },
});

const ItemAnalytics = ({ history, locationId, ...props }) => {
  const [params, dispatch] = useReducer(
    reducer,
    filters({ location: locationId }),
  );
  const { resource, fetching } = useRefetchableResource({
    path: "/analytics/menu",
    data: { filters: params },
  });
  let total = 0;
  let bestSellingItem = "N/A";
  let bestSellingGroup = "N/A";

  if (resource) {
    if (resource.bestSellingItems && resource.bestSellingItems[0]) {
      bestSellingItem = resource.bestSellingItems[0].name;
    }
    if (resource.bestSellingGroups && resource.bestSellingGroups[0]) {
      bestSellingGroup = resource.bestSellingGroups[0].name;
    }
    if (resource.totalItemsOrdered && resource.totalItemsOrdered[0]) {
      total = resource.totalItemsOrdered[0].total;
    }
  }

  const onLocationChange = (id) => {
    dispatch({
      type: "LOCATION_CHANGE",
      payload: {
        location: id,
      },
    });
  };
  const onCreatedAtChange = (dates) => {
    dispatch({
      type: "DATE_CHANGE",
      payload: {
        ...dates,
      },
    });
  };

  useEffect(() => {
    onLocationChange(locationId);
  }, [locationId]);

  const itemsCopy =
    resource.bestSellingItems && cloneDeep(resource.bestSellingItems);
  const groupsCopy =
    resource.bestSellingGroups && cloneDeep(resource.bestSellingGroups);
  const combineDuplicates = (list) => {
    let reducedList = [];
    let duplicateIndex;
    if (list) {
      reducedList = list.reduce((accu, item) => {
        duplicateIndex = accu.findIndex(
          (filteredItem) => filteredItem.name === item.name,
        );
        if (duplicateIndex < 0) {
          accu.push(item);
        } else {
          accu[duplicateIndex].total += item.total;
        }
        return accu;
      }, []);
    }
    reducedList.sort((a, b) => b.total - a.total);
    return reducedList;
  };

  return (
    <LoadingSpinner spinning={fetching}>
      <Card bordered={false}>
        <Header
          actions={{ onCreatedAtChange }}
          title="Item Analytics Breakdown"
          initialDateValues={params.createdAt}
        />
        <Row gutter={30}>
          <Col xs={24} sm={8} style={{ marginBottom: "30px" }}>
            <StatCard
              title="Total Items Sold"
              type="value"
              value={total}
              icon="desktop-total-icon"
            />
          </Col>

          <Col xs={24} sm={8} style={{ marginBottom: "30px" }}>
            <StatCard
              title="Top Selling Item"
              type="text"
              value={bestSellingItem}
              icon="desktop-total-icon"
            />
          </Col>

          <Col xs={24} sm={8} style={{ marginBottom: "30px" }}>
            <StatCard
              title="Best Category"
              type="text"
              value={bestSellingGroup}
              icon="desktop-total-icon"
            />
          </Col>
        </Row>
      </Card>

      <BlueCard>
        <Col xs={24}>
          <BlueCardTitle className={styles.breakdownTitle}>
            Item Analytics
          </BlueCardTitle>

          <Col xs={24} sm={{ span: 22, offset: 1 }}>
            <Row gutter={30} spacing={30}>
              <Col xs={24} md={12}>
                <CardTitle>Popular Items</CardTitle>
                <Table
                  loading={fetching}
                  dataSource={combineDuplicates(itemsCopy)}
                  history={history}
                  path="/option/"
                >
                  <Table.Column title="Item Name" dataIndex="name" />
                  <Table.Column
                    title="Sold"
                    dataIndex="total"
                    render={(value) =>
                      Number(value.toFixed(2)).toLocaleString()
                    }
                  />
                </Table>
              </Col>
              <Col xs={24} md={12}>
                <CardTitle>Popular Groups</CardTitle>
                <Table
                  loading={fetching}
                  dataSource={combineDuplicates(groupsCopy)}
                  history={history}
                  path="/group/"
                >
                  <Table.Column title="Group Name" dataIndex="name" />
                  <Table.Column
                    title="Count"
                    dataIndex="total"
                    render={(value) =>
                      Number(value.toFixed(2)).toLocaleString()
                    }
                  />
                </Table>
              </Col>
            </Row>
          </Col>
        </Col>
      </BlueCard>
    </LoadingSpinner>
  );
};

export default ItemAnalytics;
