import * as Yup from "yup";

const newGhostKitchen = Yup.object({
  name: Yup.string().required("Name is required"),
  externalId: Yup.string().required("External ID is required"),
  appId: Yup.string().lowercase().required("App Id field is required"),
  restaurantGroups: Yup.array()
    .of(Yup.string())
    .min(
      1,
      "At least one restaurant group must be assigned to the ghost kitchen",
    )
    .required("List of restaurant groups is required"),
  source: Yup.string().required("Source Restaurant Group is required"),
});

export { newGhostKitchen };

export default {
  newGhostKitchen,
};
