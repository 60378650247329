import React, {useState, useEffect, Fragment} from "react";
import {get as _get, update as _update} from "lodash";
import PropTypes from "prop-types";
import {message, Switch} from "antd";
import {utils} from "common";
import axios from "common/utils/axios";
import styles from "index.module.css";

const {mapAxiosError} = utils;
const {put} = axios;

const SaveToggle = (props) => {
  const {
    path,
    field,
    parentObjVal = {},
    parentObjKey = "",
    title,
    unCheckedChildren,
    checkedChildren,
    checked: initChecked,
    className,
  } = props;

  const [saving, setSaving] = useState();
  const [checked, setChecked] = useState(initChecked);

  const onChange = async (check, e) => {
    e.stopPropagation();
    setSaving(true);
    try {
      const data = _update(parentObjVal, field, () => check);
      const res = await put(path, parentObjKey ? {[parentObjKey]: data} : data);
      setChecked(
        parentObjKey
          ? _get(res.data, `${parentObjKey}.${field}`)
          : res.data[field],
      );
      setSaving(false);
      message.success("Successfully Saved!");
    } catch (error) {
      message.error(mapAxiosError(error));
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    setChecked(initChecked);
  }, [initChecked, path]);

  return (
    <Fragment>
      <span className={styles.boldBlack}>{title}</span>
      <Switch
        className={className}
        checkedChildren={checkedChildren}
        unCheckedChildren={unCheckedChildren}
        onClick={(check, e) => onChange(check, e)}
        checked={checked}
        loading={saving}
        disabled={saving}
      />
      &nbsp;&nbsp;
    </Fragment>
  );
};

SaveToggle.propTypes = {
  title: PropTypes.string,
  unCheckedChildren: PropTypes.string,
  checkedChildren: PropTypes.string,
  path: PropTypes.string,
  field: PropTypes.string,
  checked: PropTypes.bool,
};

SaveToggle.defaultProps = {
  title: "",
  unCheckedChildren: "Inactive",
  checkedChildren: "Active",
  path: "",
  field: "",
};

export default SaveToggle;
