import * as Yup from "yup";

const webOrderAppConfigSchema = Yup.object({
  id: Yup.string().required(),
  restaurant: Yup.string().required(),
  directory: Yup.string().required(),
  local_storage_key: Yup.string().required(),
  apple_app_store_link: Yup.string()
    .url()
    .nullable(true)
    .default(null)
    .transform((val) => (val === "" ? null : val)),
  google_play_store_link: Yup.string()
    .url()
    .nullable(true)
    .default(null)
    .transform((val) => (val === "" ? null : val)),
  tab_order: Yup.array()
    .of(Yup.string())
    .default(["delivery", "pickup", "kiosk"]),
  order_history: Yup.object({
    date_format: Yup.string().default("MM/dd/yyyy"),
  }),
  cookie_consent: Yup.boolean().required().default(false),
  enableImageOptimization: Yup.boolean().required().default(false),
  flatten_subgroups: Yup.boolean().required().default(false),
  not_replace_mod: Yup.boolean().required().default(false),
  option_weighted_sort: Yup.boolean().required().default(false),
  market_place: Yup.object({
    enabled: Yup.boolean().required().default(false),
  }),
  loyalty: Yup.object({
    enabled: Yup.boolean().required().default(true),
  }),
  routes: Yup.object({
    gift_cards: Yup.boolean().required().default(false),
  }),

  // Pick Related
  pickup: Yup.object({
    enabled: Yup.boolean().required().default(true),
    tip: Yup.boolean().required().default(true),
    guests: Yup.boolean().required().default(false),
    notes: Yup.boolean().required().default(false),
    tip_default: Yup.number().min(0).default(0),
    tip_available_options: Yup.array()
      .of(Yup.number())
      .default([0, 0.15, 0.2, 0.25, 0.3]),
  }),

  // Delivery Related
  delivery: Yup.object({
    enabled: Yup.boolean().required().default(true),
    tip: Yup.boolean().required().default(true),
    guests: Yup.boolean().required().default(false),
    notes: Yup.boolean().required().default(false),
    tip_default: Yup.number().min(0).default(0.15),
    tip_available_options: Yup.array()
      .of(Yup.number())
      .default([0, 0.15, 0.2, 0.25, 0.3]),
  }),

  // Kiosk Related
  kiosk: Yup.object({
    enabled: Yup.boolean().required().default(true),
    tip: Yup.boolean().required().default(true),
    guests: Yup.boolean().required().default(true),
    notes: Yup.boolean().required().default(false),
    tip_default: Yup.number().min(0).default(0.15),
    tip_available_options: Yup.array()
      .of(Yup.number())
      .default([0, 0.15, 0.2, 0.25, 0.3]),
  }),

  // Ordering & Payment
  ordering: Yup.object({
    notes: Yup.boolean().required().default(false),
    scheduled: Yup.boolean().required().default(true),
    cash: Yup.boolean().required().default(false),
    polling: Yup.boolean().required().default(true),
  }),
  payment_processor: Yup.string().default("stripe"),

  // Social Auth
  auth: Yup.object({
    facebook: Yup.object({
      enabled: Yup.boolean().required().default(true),
      app_id: Yup.string().default(""),
    }),
    apple: Yup.object({
      enabled: Yup.boolean().required().default(true),
      client_id: Yup.string().default(""),
    }),
    google: Yup.object({
      enabled: Yup.boolean().required().default(true),
      client_id: Yup.string().default(""),
    }),
  }),

  // Website
  website: Yup.object({
    name: Yup.string().default(""),
    description: Yup.string().default(""),
    url: Yup.string().url().default(""),
    social: Yup.object({
      facebook: Yup.object({
        site_name: Yup.string().default(""),
        title: Yup.string().default(""),
        description: Yup.string().default(""),
        image: Yup.string().url().default(""),
        username: Yup.string().default(""),
      }),
      instagram: Yup.object({
        username: Yup.string().default(""),
      }),
      twitter: Yup.object({
        title: Yup.string().default(""),
        description: Yup.string().default(""),
        image: Yup.string().url().default(""),
        alt: Yup.string().default(""),
        card: Yup.string().default(""),
        username: Yup.string().default(""),
      }),
    }),
    favicons: Yup.array().of(
      Yup.object({
        rel: Yup.string().default(""),
        src: Yup.string().url().default(""),
        sizes: Yup.string().default(""),
        type: Yup.string().default(""),
      }),
    ),
  }),

  // Components &
  components: Yup.object({
    tab: Yup.string().default(""),
    empty: Yup.string().default(""),
  }),
  login_flow: Yup.string().default(""),

  // Diet Order and Hard Codes
  // diet_order: ["vegan", "glutenFree", "nuts"],
  // hardcodes: {
  //   groupOrder: [
  //     "shares",
  //     "greens",
  //     "wiches",
  //     "burgers",
  //     "sides",
  //     "cubby meal",
  //     "milkshakes",
  //     "vegan shakes",
  //     "sweets",
  //     "beverages",
  //   ],
  //   optionsTabs: [
  //     {
  //       "tabName": "Modify",
  //       "type": 0,
  //       "optionNames": ["side"]
  //     },
  //     {
  //       "tabName": "Sides",
  //       "type": 1,
  //       "optionNames": ["side"]
  //     }
  //   ],
  // },

  // Theme
  theme: Yup.object({
    color: Yup.string().default(""),
    // fonts: [
    //   // {
    //   //   "source": "",
    //   //   "href": ""
    //   // },
    // ],
    icons: Yup.object({
      source: Yup.string().default(""),
      social: Yup.number().default(0),
    }),
    header: Yup.object({
      layout: Yup.string().default("Type1"),
      theme: Yup.string().default(""),
      nav_icon: Yup.string().default(""),
    }),
    location: Yup.object({
      layout: Yup.string().default(""),
      card: Yup.string().default("Type1"),
      footer: Yup.string().default("sticky"),
      map_zoom: Yup.number().default(0),
      map_style: Yup.boolean().default(false),
      map_geo: Yup.object({
        lat: Yup.number().default(40.7831),
        long: Yup.number().default(-73.9712),
      }),
      search_image: Yup.boolean().default(false),
    }),
    menu: Yup.object({
      layout: Yup.string().default(""),
      banner: Yup.string().default(""),
      nav: Yup.string().default(""),
      nav_theme: Yup.string().default(""),
      info: Yup.string().default(""),
      group_container: Yup.string().default(""),
      item_card: Yup.string().default(""),
      item_card_clamped: Yup.boolean().default(false),
      footer: Yup.string().default("sticky"),
      group_item_breakpoints: {
        xs: Yup.string().default("1"),
        sm: Yup.string().default("1-2"),
        md: Yup.string().default("1-3"),
        lg: Yup.string().default("1-4"),
        xl: Yup.string().default(""),
        xxl: Yup.string().default(""),
      },
      table_info: Yup.string().default(""),
    }),
    market: Yup.object({
      layout: Yup.string().default("Type1"),
      item_card: Yup.string().default("Type9"),
      footer: Yup.string().default("sticky"),
      group_container: Yup.string().default("Type4"),
      banner: Yup.string().default("Type2"),
      banner_info: Yup.string().default("Type1"),
    }),
    checkout: Yup.object({
      version: Yup.string().default("Type2"),
      confirmation: Yup.string().default(""),
      cart_item: Yup.string().default("Type6"),
      guests: Yup.string().default("Type1"),
      confirm_item: Yup.string().default(""),
      upsell_layout: Yup.string().default("Type1"),
      upsell_item: Yup.string().default(""),
      scheduled_date: Yup.string().default("Type2"),
      table_number: Yup.string().default(""),
      layout: Yup.string().default("Type1"),
      gift_card: Yup.boolean().default(false),
    }),
    packing_instructions: Yup.object({
      menu: Yup.string().default(""),
      group: Yup.string().default(""),
      option: Yup.string().default(""),
      item: Yup.string().default(""),
    }),
    item_details: Yup.object({
      group: Yup.string().default("Type1"),
      header: Yup.string().default(""),
      tabs: Yup.string().default("Type1"),
      modifier_items: Yup.string().default("Type1"),
      footer: Yup.string().default(""),
      notes: Yup.string().default(""),
      layout: Yup.string().default(""),
    }),
    login_signup: Yup.object({
      version: Yup.string().default("Type1"),
      header: Yup.string().default("Type2"),
      nav_item: Yup.string().default("Type1"),
    }),
    profile: Yup.object({
      empty: Yup.string().default(""),
    }),
    order_history: Yup.object({
      history_card: Yup.string().default("Type6"),
      order_item: Yup.string().default("Type6"),
    }),
    loyalty: Yup.array().of(
      Yup.object({
        system: Yup.string().default("credit"),
        card: Yup.string().default("progress"),
        layout: Yup.string().default("Type1"),
      }),
    ),
    footer: Yup.object({
      toggleDelay: Yup.string().default(""),
    }),
  }),

  // Apps
  apps: Yup.object({
    google_analytics: Yup.string().default(""),
    google_tag_manager: Yup.string().default(""),
    google_maps: Yup.string().default(""),
    facebook_pixel: Yup.string().default(""),
    facebook_verification: Yup.string().default(""),
    segment: Yup.string().default(""),
    appsflyer: Yup.string().default(""),
  }),
});

export default webOrderAppConfigSchema;
