import * as Yup from "yup";

const taxSchema = Yup.object({
  name: Yup.string().required(),
  baseRate: Yup.number().nullable(),
  baseAmount: Yup.number().nullable(),
});

export default {
  taxSchema,
};
