import * as React from "react";
import styled from "styled-components";
import { RestaurantDropdown } from "components/fragments";
import Logo from "./Logo";

const DropdownWrapper = styled.div`
  width: 100%;
  max-width: 17rem;
  margin-left: 3rem;
  & .ant-select {
    width: 100%;
  }
`;

/**
 * Application bar is at the top of the screen with the logo and restaurants dropdown
 */
function AppBar() {
  return (
    <header
      style={{
        background: "#fff",
        borderBottom: "1px solid #e0e0e0",
        maxHeight: "4rem",
        overflow: "hidden",
        position: "fixed",
        width: "100%",
        zIndex: 99,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          maxWidth: 1280,
          margin: "0 auto",
          padding: "1rem 2rem",
        }}
      >
        <Logo />
        <DropdownWrapper>
          <RestaurantDropdown />
        </DropdownWrapper>
      </div>
    </header>
  );
}

export default AppBar;
