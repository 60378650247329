import * as Yup from "yup";

const dnsSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  appId: Yup.string().lowercase().required("Client ID field is required"),
  domainName: Yup.string()
    .matches(
      /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/,
      "Not a valid Domain Name",
    )
    .required("Domain Name field is required"),
});

export { dnsSchema };
