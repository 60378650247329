export const hoursToInitialFormValues = (hours) => ({
  pickupHours: {
    hours: hours.map((i) => ({
      day: i.day,
      pickupOpen: i.pickupOpen,
      pickupClose: i.pickupClose,
    })),
  },
  deliveryHours: {
    hours: hours.map((i) => ({
      day: i.day,
      deliveryOpen: i.deliveryOpen,
      deliveryClose: i.deliveryClose,
    })),
  },
  dineInHours: {
    hours: hours.map((i) => ({
      day: i.day,
      dineInOpen: i.dineInOpen,
      dineInClose: i.dineInClose,
    })),
  },
});

const days = [1, 2, 3, 4, 5, 6, 0];

export const toHoursArray = ({ pickupHours, deliveryHours, dineInHours }) =>
  days.map((day, i) => ({
    day,
    pickupOpen: pickupHours.hours[i].pickupOpen,
    pickupClose: pickupHours.hours[i].pickupClose,
    deliveryOpen: deliveryHours.hours[i].deliveryOpen,
    deliveryClose: deliveryHours.hours[i].deliveryClose,
    dineInOpen: dineInHours.hours[i].dineInOpen,
    dineInClose: dineInHours.hours[i].dineInClose,
  }));

export default {
  toHoursArray,
  hoursToInitialFormValues,
};
