import * as Yup from "yup";

const phoneRegExp =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

const schema = Yup.object().shape({
  orderType: Yup.string(),
  patronCompany: Yup.string().required("Please specify a company"),
  scheduledAt: Yup.string()
    .typeError("Please specify a scheduled time")
    .required("Please specify a scheduled time"),
  numberOfGuests: Yup.number()
    .typeError("Please specify a number of guests")
    .min(0, "Please specify a greater than 0")
    .required("Please specify a number of guests"),
  deliveryInfo: Yup.object()
    .shape({
      street1: Yup.string().required("Please specify a street"),
      street2: Yup.string(),
      state: Yup.string().required("Please specify a state"),
      city: Yup.string().required("Please specify a city"),
      zip: Yup.number()
        .typeError("Please specify a zipcode")
        .required("Please specify a zipcode"),
    })
    .nullable()
    .when("orderType", (orderType, field) =>
      orderType === "delivery" ? field.required() : field,
    ),
  deliveryContactName: Yup.string().when("orderType", {
    is: "delivery",
    then: Yup.string()
      .typeError("Please specify a delivery contact name")
      .required("Please specify a delivery contact name"),
    otherWise: Yup.string(),
  }),
  deliveryContactPhone: Yup.string().when("orderType", {
    is: "delivery",
    then: Yup.string()
      .typeError("Please specify a phone number")
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Please specify a phone number"),
    otherWise: Yup.string(),
  }),
});

export default schema;
