import * as React from "react";
import { axios } from "common/utils";
import { useAPIContext } from "components/providers/Api";
import { PLATFORM_TYPE } from "common/constants/strings";
import { useUserContext } from "components/providers/User";

const EXPLO_URL = "https://api.explo.co/embed/create_user_group/";
const BearToken =
  "0e4cb058b50eff4354b610d4e1abe35c252371aa09eb7e7b0ec1f719cb293a40";

export interface Location {
  name: string;
  id: string;
  isActive: boolean;
  phone: Phone;
}
export interface Phone {
  isVerified: boolean;
  value: string;
}

export interface Explo {
  userGroupToken: string;
  allLocations: string;
  token: string;
  client?: string;
}

const useExploApi = (restaurantGroup: string, marketplace: string): Explo => {
  const { locations } = useUserContext();
  const { restaurantGroupId, client, platform } = useAPIContext();
  const [token, setToken] = React.useState<string>("");
  const { ADMIN, MARKETPLACE } = PLATFORM_TYPE;

  // includes Locations
  const allLocations =
    locations?.map((location: Location) => location.id) ?? [];

  React.useEffect(() => {
    const fetchExploData = async () => {
      const { data } = await axios.post(
        EXPLO_URL,
        {
          name: `${client}`,
          user_group_id: `${restaurantGroupId}`,
        },
        {
          /**
           * This will remove the client and platform
           * from the headers .
           *
           */
          transformRequest: (data, headers) => {
            delete headers.common.client;
            delete headers.common.platform;
            return JSON.stringify(data);
          },
          headers: {
            "Explo-Authorization": `Token ${BearToken}`,
            "Content-Type": "application/json",
          },
        },
      );

      const { user_group } = data;
      const { token } = user_group;
      setToken(token);
    };

    fetchExploData();
  }, [client, restaurantGroupId]);

  // switch userToken based on platform
  let userGroupToken;

  switch (platform) {
    case ADMIN:
      userGroupToken = `${restaurantGroup}:${token}`;
      break;
    case MARKETPLACE:
      userGroupToken = `${marketplace}:${token}`;
      break;
    // no need of default case
  }

  return {
    userGroupToken,
    allLocations: `'${allLocations.join("','")}'`,
    token,
    client,
  };
};

export { useExploApi };
