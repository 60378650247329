import * as React from "react";
import { useAPIContext } from "../components/providers/Api";

const getPropValue = (obj, key: string) =>
  key.split(".").reduce((o, x) => (o === undefined ? o : o[x]), obj);

const useAPI = (api: string, filters?: object) => {
 const [resource, setResource] = React.useState([]);
  const [fetching, setFetching] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [data, setData] = React.useState(filters);
  const services = useAPIContext();

  const method = getPropValue(services, api);
  if (!method) {
    throw new Error("API method not found ");
  }

  const getResponse = async () => {
    setFetching(true);
    try {
      const res = await method(data);
      setResource(res.data);
    } catch (e) {
      setError(e);
    } finally {
      setFetching(false);
    }
  };

  React.useEffect(() => {
    getResponse();
  }, [data, api]);

  return {
    fetch: getResponse,
    resource,
    fetching,
    error,
    data,
    setData,
  };
};

export default useAPI;
