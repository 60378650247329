import * as React from "react";
import styled from "styled-components";
import { theme } from "common/constants/theme";
import "./utensils-overrides.css";

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;

  @media (max-width: ${theme.breakpoints.lg.rem}rem) {
    padding-bottom: 4.25rem;
  }
`;

const withLayoutV2 = (Component: React.JSXElementConstructor<any>) =>
  function (props: any) {
    return (
      <LayoutWrapper>
        <Component {...props} />
      </LayoutWrapper>
    );
  };

export { withLayoutV2 };
