import React from "react";
import {Modal} from "antd";
import {useActive} from "hooks";
import {Button} from "components/elements";
import Media from "./Media";

const MediaModal = ({
  onSelect = (i) => {
    console.log(i);
  },
}) => {
  const {isActive, activate, deactivate} = useActive(false);
  return (
    <>
      <Button onClick={activate} color="blue" size="extraSmall">
        Media
      </Button>
      <Modal
        closable
        style={{top: "1rem"}}
        visible={isActive}
        width="80vw"
        onCancel={deactivate}
        footer={[
          <Button color="blue" size="medium" onClick={deactivate}>
            Close
          </Button>,
        ]}
      >
        <Media onSelectMedia={onSelect} />
      </Modal>
    </>
  );
};

export default MediaModal;
export {MediaModal};
