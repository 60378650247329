import * as Yup from "yup";

const rgSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  appId: Yup.string().lowercase().required("Client ID field is required"),
  domainName: Yup.string()
    .matches(
      /^(?!www\.|http:\/\/|https:\/\/).+[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/,
      "Don't include www or https",
    )
    .required("Domain Name field is required"),
});

export default {
  NewRgSchema: rgSchema,
};
