import React, {useState, useEffect} from "react";
import {Icon, Dropdown} from "antd";
import moment from "moment";
import classnames from "classnames";

import css from "./monthpicker.module.css";

const formatSelector = (type) => {
  switch (type) {
    case "years":
      return "YYYY";
    default:
      return "MMMM-YYYY";
  }
};

/**
 * A component for selecting months
 * @memberof Components.Fragments
 * @param {string | moment | date} props.value - The default date that should be selected
 * @param {function} props.onChange - The function that will be run when date is changed
 * @param {"months" | "years" |"select"} props.type - Unused prop, initally made with the intention of allowing users to toggle through years in addition to months
 * @returns {React.Element} - Returns a component to select a month
 */

const MonthPicker = ({value, onChange = () => {}, type = "months"}) => {
  const [date, setDate] = useState(moment(value));
  const [dateType, setDateType] = useState(type === "select" ? "months" : type);

  useEffect(() => {
    onChange(date, dateType);
  }, [date, dateType]);

  const decrementDate = () => {
    setDate(moment(date.subtract(1, type === "select" ? dateType : "months")));
  };

  const incrementDate = () => {
    setDate(moment(date.add(1, type === "select" ? dateType : "months")));
  };

  const DatePickerInputs = (
    <div className={classnames(css.dateDropdown, "dateDropdown")}>
      <div className={css.dateIntervals}>
        <button
          type="button"
          onClick={() => setDateType("months")}
          className={css.intervalItems}
        >
          Month
        </button>
        <button
          type="button"
          onClick={() => setDateType("years")}
          className={css.intervalItems}
        >
          Year
        </button>
      </div>
    </div>
  );

  const formattedDate = date.format(formatSelector(dateType));

  return (
    <div className={css.buttonWrapper}>
      <button
        type="button"
        onClick={decrementDate}
        className={css.decrementButton}
      >
        <Icon type="left" />
      </button>
      {type !== "select" && (
        <div className={css.dateButton}>{formattedDate}</div>
      )}
      {type === "select" && (
        <Dropdown
          placement="bottomCenter"
          trigger={["click"]}
          overlay={DatePickerInputs}
        >
          <div className={css.dateButton}>{formattedDate}</div>
        </Dropdown>
      )}

      <button
        type="button"
        onClick={incrementDate}
        className={css.incrementButton}
      >
        <Icon type="right" />
      </button>
    </div>
  );
};

export {MonthPicker};
