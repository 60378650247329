const theme = {
  colors: {
    bobblack: "#000000",
    powder: "#FFFFFF",
    url: "#177374",
    critical: {
      red: "#B91C1C",
      lightred: "#FEF2F2",
      base: "#FCECE8",
    },
    gray: {
      10: "#3D3D3D",
    },
    yellow: {
      50: "#FFFEE9",
      100: "#FFFDD5",
      200: "#FFF9A7",
      300: "#FFF567",
      400: "#FEED01",
      500: "#F8E113",
      600: "#EAC91C",
      700: "#CCAC05",
      800: "#A88816",
      900: "#8F7107",
    },
    teal: {
      50: "#EEFFFF",
      100: "#D9FDFE",
      200: "#B6EFF0",
      300: "#92DCDE",
      400: "#6CCECF",
      500: "#07B8BA",
      600: "#158D8F",
      700: "#177374",
      800: "#185E5F",
      900: "#174D4E",
    },
    neutral: {
      50: "#FAFAFA",
      100: "#F5F5F5",
      200: "#E5E5E5",
      300: "#D4D4D4",
      400: "#A3A3A3",
      500: "#737373",
      600: "#525252",
      700: "#404040",
      800: "#262626",
      900: "#171717",
    },
    emerald: {
      50: "#ECFDF5",
      100: "#D1FAE5",
      200: "#A7F3D0",
      300: "#6EE7B7",
      400: "#34D399",
      500: "#10B981",
      600: "#059669",
      700: "#047857",
      800: "#065F46",
      900: "#064E3B",
    },
    red: {
      50: "#FEF2F2",
      100: "#FEE2E2",
      200: "#FECACA",
      300: "#FCA5A5",
      400: "#F87171",
      500: "#EF4444",
      600: "#DC2626",
      700: "#B91C1C",
      800: "#991B1B",
      900: "#7F1D1D",
    },
  },
  breakpoints: {
    sm: {
      px: 0,
      rem: 0,
    },
    md: {
      px: 641,
      rem: 40.063,
    },
    lg: {
      px: 1008,
      rem: 63,
    },
    xl: {
      px: 1366,
      rem: 85.375,
    },
  },
};

type ThemeColorsType = keyof typeof theme.colors;

const getColor = (color: ThemeColorsType, n?: number | string) => {
  return n ? theme.colors[color][n] : theme.colors[color];
};

export { getColor, theme };

export type { ThemeColorsType };
