/**
 * Timezones for select options
 * must be a valid moment js timezone
 * refer to https://github.com/moment/moment-timezone/blob/develop/data/packed/latest.json
 */
export default [
  { name: "America/New_York", value: "America/New_York" },
  { name: "America/Chicago", value: "America/Chicago" },
  { name: "America/Denver", value: "America/Denver" },
  { name: "America/Phoenix", value: "America/Phoenix" },
  { name: "America/Los_Angeles", value: "America/Los_Angeles" },
  { name: "America/Anchorage", value: "America/Anchorage" },
  { name: "America/Adak", value: "America/Adak" },
  { name: "America/Puerto_Rico", value: "America/Puerto_Rico" },
  { name: "Pacific/Honolulu", value: "Pacific/Honolulu" },
];
