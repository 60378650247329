import React from "react";
import { Table as AntTable } from "antd";
import classnames from "classnames";
import css from "./table.module.css";
import { hiddenClassGenerator } from "../../elements/Table/Column";
import { LoadingSpinner } from "components/v2/Loading/LoadingSpinner/LoadingSpinner";

const { Column } = AntTable;

const tableScrollMap = {
  small: { x: 400 },
  medium: { x: 600 },
  large: { x: 800 },
};

const Table = ({
  children,
  columns,
  striped,
  clickable,
  rowClassName: additionalRowClassNames,
  className,
  requiredPermissions,
  horizontalScrollSize,
  scroll,
  loading,
  ...props
}) => {
  const rowClassName = (text, index) => {
    const additionalClassNames =
      typeof additionalRowClassNames === "function"
        ? additionalRowClassNames(text, index, css)
        : additionalRowClassNames;

    return classnames(additionalClassNames, css.row);
  };

  const tableClassName = classnames(
    striped ? css.striped : null,
    clickable ? css.click : null,
    css.table,
    className,
  );

  const mappedChildren = () => {
    if (columns) {
      return columns.reduce((accu, column, index) => {
        const { hidden, breakpoints, className, ...rest } = column;
        let finalClassName = className;
        if (Object.keys(breakpoints ?? {}).length) {
          const breakpointClassName = hiddenClassGenerator(breakpoints);
          finalClassName = classnames(finalClassName, breakpointClassName);
        }
        if (hidden !== undefined) {
          if (!hidden) {
            accu.push(
              <Column key={index} className={finalClassName} {...rest} />,
            );
          }
        } else {
          accu.push(
            <Column key={index} className={finalClassName} {...column} />,
          );
        }

        return accu;
      }, []);
    }
    return React.Children.toArray(children).reduce((accu, child, index) => {
      const { requiredPermissions, yes, ...rest } = child.props;
      if (child.type === Column && requiredPermissions !== undefined) {
        if (requiredPermissions) {
          accu.push(
            <Column key={index} {...rest}>
              {yes()}
            </Column>,
          );
        }
      } else {
        accu.push(child);
      }

      return accu;
    }, []);
  };

  if (!horizontalScrollSize && !scroll) {
    return (
      <AntTable
        rowKey={(i) => i._id || i.id}
        {...props}
        className={tableClassName}
        rowClassName={rowClassName}
        childrenColumnName={["randall"]}
        loading={{
          indicator: <LoadingSpinner style={{ position: "absolute" }} />,
          spinning: loading,
        }}
      >
        {mappedChildren()}
      </AntTable>
    );
  }

  return (
    <AntTable
      rowKey={(i) => i._id || i.id}
      {...props}
      className={tableClassName}
      rowClassName={rowClassName}
      childrenColumnName={["randall"]}
      scroll={scroll ?? tableScrollMap[horizontalScrollSize]}
      loading={{
        indicator: <LoadingSpinner style={{ position: "absolute" }} />,
        spinning: loading,
      }}
    >
      {mappedChildren()}
    </AntTable>
  );
};

Table.Column = Column;

Table.defaultProps = {
  size: "small",
};

export default Table;
