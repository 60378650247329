import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Subheading, DisplayMedium, ParagraphBody } from "./FigmaUI";

type NavigationType = Record<string, Record<string, string>>;

interface SidebarProps {
  title?: string;
  caption?: string;
  navigation?: NavigationType;
}

const NavigationLink = styled(Link)`
  font-size: 1rem;
  line-height: 1.25rem;
  text-decoration: underline;
  color: #000;

  &:hover {
    color: #000;
    text-decoration: underline;
  }
`;

/**
 * Array of sidebar navigation lists with link items
 */
const NavigationList = (links: NavigationType) =>
  Object.keys(links).map((category) => (
    <ul style={{ listStyle: "none", padding: 0, marginTop: "1.75rem" }}>
      <li
        style={{
          marginBottom: "1rem",
        }}
      >
        <Subheading>{category}</Subheading>
      </li>
      {Object.keys(links[category]).map((child, i) => (
        <li
          style={{
            marginBottom: "0.5rem",
          }}
        >
          <NavigationLink to={`/styles/${child}`}>
            {links[category][child]}
          </NavigationLink>
        </li>
      ))}
    </ul>
  ));

/**
 * Page side content including title, caption and navigation list
 */
function Sidebar({ title, caption, navigation }: SidebarProps) {
  return (
    <aside
      style={{
        width: "100%",
        maxWidth: "17rem",
        padding: "1.5rem",
        borderRight: "1px solid #e0e0e0",
      }}
    >
      {title && <DisplayMedium>{title}</DisplayMedium>}
      {caption && <ParagraphBody>{caption}</ParagraphBody>}
      {navigation && NavigationList(navigation)}
    </aside>
  );
}

export default Sidebar;
