/* eslint-disable no-use-before-define */
import { useState, useEffect } from "react";
import isEqual from "lodash/isEqual";
import usePrevious from "./usePrevious";

interface useSessionStore<T> {
  key: string;
  initial: T;
}

const initialItems = (key, initial) => {
  const keyItem = window.sessionStorage.getItem(key);
  return keyItem ? JSON.parse(window.sessionStorage.getItem(keyItem)) : initial;
};

const useSessionStore = <T>({ key, initial }: useSessionStore<T>) => {
  const [value, setValue] = useState(() => initialItems(key, initial));

  const onChange = (nextValue: T) => {
    setValue(nextValue);
  };
  const onClear = () => {
    setValue({});
  };

  useEffect(() => {
    if (!isEqual(value, prevValue)) {
      window.sessionStorage.setItem(key, JSON.stringify(value));
    }
  });

  const prevValue = usePrevious(value);

  return {
    value,
    actions: {
      change: onChange,
      clear: onClear,
    },
  };
};

export default useSessionStore;
