import * as React from "react";
import LogoImg from "assets/images/Lunchbox-Logo-White.svg";
import MobileLogo from "assets/images/LB-Icon-yellow.svg";
import { S } from "./styled";

type LogoProps = {
  isMobile?: boolean;
};

const Logo = ({ isMobile }: LogoProps) => {
  const ImageSrc = isMobile ? MobileLogo : LogoImg;

  return (
    <S.LogoContainer>
      <S.LogoImage isMobile={isMobile} src={ImageSrc} alt="Lunchbox" />
    </S.LogoContainer>
  );
};

export { Logo };
