import { object, string } from "yup";

export const bankAccountSchema = object().shape({
  accountNumber: string().required("Required"),
  routingNumber: string().required("Required"),
  accountHolderName: string().required("Required"),
  accountHolderType: string().required("Required"),
});

export default {
  bankAccountSchema,
};
