const downloadCsv = (data) => {
  const linkSource = `data:text/csv;base64,${data.file}`;
  const downloadLink = document.createElement("a");
  const fileName = data.name;

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export default downloadCsv;
