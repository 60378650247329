import React from "react";
import cloneDeep from "lodash/cloneDeep";
import {Price, Number} from "components/elements";

const calculateSumsOfKeys = (array, keys) => {
  const response = {};
  // replace with for of
  for (let i = 0; i < array.length; i++) {
    for (let j = 0; j < keys.length; j++) {
      if (!response[keys[j]]) {
        response[keys[j]] = {};
      }
      if (array[i][keys[j]]) {
        for (let y = 0; y < array[i][keys[j]].length; y++) {
          if (!response[keys[j]][array[i][keys[j]][y].name]) {
            response[keys[j]][array[i][keys[j]][y].name] = cloneDeep(
              array[i][keys[j]][y],
            );
          } else {
            Object.keys(response[keys[j]][array[i][keys[j]][y].name]).forEach(
              (key) => {
                if (key !== "name") {
                  response[keys[j]][array[i][keys[j]][y].name][key] +=
                    array[i][keys[j]][y][key];
                }
              },
            );
          }
        }
      }
    }
  }

  return response;
};

export {calculateSumsOfKeys};
export const priceHelper = (val = 0) => (
  <Price align="right" value={val || 0} />
);
export const numberHelper = (val = 0) => (
  <Number align="right" value={val || 0} />
);
