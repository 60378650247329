import React, {useEffect} from "react";
import {NameManager} from "./NameManager";
// Create and import the following components from IntegrationTypesMap

const IntegrationTypesMap = {
  // TODO add these specific integration-type component
  // "aggregator": AggregatorIntegration,
  // "cateringDelivery": CateringDeliveryIntegration,
  // "delivery": DeliveryIntegration,
  // "giftCard": GiftCardIntegration,
  // "import": ImportIntegration,
  // "kioskPaymentService": KioskPaymentServiceIntegration,
  // "log": LogIntegration,
  // "loyalty": LoyaltyIntegration,
  // "marketing": MarketingIntegration,
  // "notification": NotificationIntegration,
  // "paymentService": PaymentServiceIntegration,
  // "pos": PosIntegration,
  // "referral": ReferralIntegration,
  // "webhook": WebhookIntegration
};

const DefaultWrapper = ({setFetching, children}) => {
  useEffect(() => {
    setFetching(false);
  }, []);

  return children;
};

const TypeManager = ({
  type,
  name,
  locationId,
  setFetching,
  inputOptions,
  setInputOptions,
  activeIntegration,
  children,
}) => {
  const Component = IntegrationTypesMap[type] ?? DefaultWrapper;

  return (
    <Component setFetching={setFetching}>
      <NameManager
        name={name}
        locationId={locationId}
        setFetching={setFetching}
        inputOptions={inputOptions}
        setInputOptions={setInputOptions}
        activeIntegration={activeIntegration}
      >
        {children}
      </NameManager>
    </Component>
  );
};

export {TypeManager};
