import * as Yup from "yup";

const schema = Yup.object().shape({
  codes: Yup.array().of(
    Yup.string().matches(/^[a-zA-Z0-9]*$/, {
      message: "Discount codes should only contain alphanumeric characters",
    }),
  ),
  orderMinimum: Yup.number()
    .typeError("Order Minimum must be a number")
    .min(0, "Order Minimum must be 0 or greater"),
});

const createSchema = Yup.object({
  name: Yup.string().required(),
  selectionType: Yup.string()
    .matches(/^(CHECK|ITEM)$/, "Must be Check or Item")
    .required(),
  type: Yup.string()
    .when("selectionType", {
      is: "CHECK",
      then: Yup.string().matches(
        /^(FIXED|OPENFIXED|PERCENT|BOGO)$/,
        "When Selection Type is Check type must be one of Fixed, Open Fixed, Percent, or Bogo",
      ),
    })
    .when("selectionType", {
      is: "ITEM",
      then: Yup.string().matches(
        /^(FIXED|PERCENT)$/,
        "When Selection Type is Item type must be one of Fixed, or Percent",
      ),
    }),
  amount: Yup.number().min(1),
});

export default { schema, createSchema };
