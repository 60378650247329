import * as React from "react";
import { theme } from "common/constants/theme";

function Spinner() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 32.5C26.9036 32.5 32.5 26.9036 32.5 20C32.5 13.0964 26.9036 7.5 20 7.5C13.0964 7.5 7.5 13.0964 7.5 20C7.5 26.9036 13.0964 32.5 20 32.5Z"
        fill="#CCCCCC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8514 7.4996C21.9224 7.4996 23.6014 5.82067 23.6014 3.7496C23.6014 2.00361 22.4081 0.536316 20.7926 0.118714C20.3563 0.0711576 19.9157 0.0376378 19.4714 0.0186157C17.5788 0.209081 16.1014 1.80681 16.1014 3.7496C16.1014 5.82067 17.7803 7.4996 19.8514 7.4996Z"
        fill={theme.colors.teal[500]}
      />
    </svg>
  );
}

export { Spinner };
