import * as React from "react";
import {getIn} from "formik";
import PropTypes from "prop-types";
import {Input, InputNumber, Form} from "antd";
import classnames from "classnames";
import {T} from "components/elements";
import {strings} from "common/constants";
import styles from "./field.module.scss";

const {TextArea, Password} = Input;

const sizeClass = (size, type) => {
  if (type === strings.TYPE.NUMBER) {
    switch (size) {
      case "small":
        return styles.numberSmall;
      default:
        return "";
    }
  } else {
    switch (size) {
      case "small":
        return styles.textSmall;
      default:
        return "";
    }
  }
};

const borderClass = (border) => border && styles.border;

const InputField = ({
  field: {onChange, ...field},
  form: {touched, errors, setFieldValue},
  // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  type,
  size,
  border,
  error,
  width,
  formatter,
  parser,
  className,
  precision,
  hasPlaceholder,
  ...props
}) => {
  const errorMsg = getIn(errors, field.name);
  const classes = classnames(
    sizeClass(size),
    borderClass(border),
    styles.input,
    hasPlaceholder ? styles.placeholder : undefined,
    className,
  );

  let Comp = Input;
  switch (type) {
    case "textarea":
      Comp = TextArea;
      break;
    case "password":
      Comp = Password;
      break;
    case "number":
      Comp = InputNumber;
      break;
    default:
      Comp = Input;
  }

  delete props.initialValues;
  delete props.updateFilters;

  return (
    <Form.Item
      label={<T label>{label}</T>}
      help={errorMsg}
      validateStatus={errorMsg ? "error" : undefined}
    >
      <Comp
        {...field}
        {...props}
        className={classes}
        formatter={formatter}
        parser={parser}
        size={size}
        style={{width}}
        onChange={(newValue) => {
          if (type === strings.TYPE.NUMBER) {
            if (precision !== undefined) {
              const toDecimalNewValue = newValue
                ? Number(Number(newValue).toFixed(precision))
                : "";
              setFieldValue(field.name, toDecimalNewValue);
            } else {
              setFieldValue(field.name, newValue);
            }
          } else {
            onChange(newValue);
          }
        }}
      />
    </Form.Item>
  );
};

InputField.propTypes = {
  prefix: PropTypes.element,
  size: PropTypes.string,
  label: PropTypes.string,
};

InputField.defaultProps = {
  size: "default",
  label: "",
};

export default InputField;
