import * as React from "react";
import { useLocationCard } from "./Context";
import Section from "../components/Section";
import { Checkbox, CheckboxSpacer } from "../components/FigmaUI";

interface LocationDetailsProps {
  onAddressChange?: InputChangeType;
  onPhoneNumberChange?: InputChangeType;
}

function LocationDetails({
  onAddressChange = () => null,
  onPhoneNumberChange = () => null,
}: LocationDetailsProps) {
  const { componentConfig } = useLocationCard();
  return (
    <Section
      title="Location Details"
      caption="Choose whether addresses or phone numbers are displayed."
    >
      <Checkbox
        label="Show addresses"
        checked={componentConfig.showAddress}
        name="showAddress"
        onChange={onAddressChange}
      />
      <CheckboxSpacer />
      <Checkbox
        label="Show phone numbers"
        checked={componentConfig.showPhoneNumber}
        name="showPhoneNumber"
        onChange={onPhoneNumberChange}
      />
    </Section>
  );
}

export default LocationDetails;
