import React, { useContext, createContext, useState, useEffect } from "react";
import { uppercaseTitle } from "common/utils";

import { useAPIContext } from "./Api";

const UserContext = createContext();

// This is just a pass through provider after refactoring the UserContext to be the API context
// Nothing more should be needed to add to this
const Provider = ({ children }) => {
  const [rgConfiguration, setRgConfiguration] = useState({});

  const {
    user,
    onChangePlatform,
    client,
    platform,
    activeLocationID,
    usersLocations,
    usersRestaurantGroups,
    onActiveLocationChange,
    logout,
    update,
    setUser,
  } = useAPIContext();

  const contextValues = {
    user,
    isSuperAdmin: user?.role === 0,
    restaurantGroup: usersRestaurantGroups,
    setCurrentUser: setUser,
    update,
    activeLocationID,
    onActiveLocationChange,
    logout,
    locations: usersLocations,
    platform,
    client,
    onChangePlatform,
    rgConfiguration: usersRestaurantGroups?.configurations,
    setRgConfiguration,
  };

  useEffect(() => {
    if (!user || !window.FS) return;
    const { isSuperAdmin } = contextValues;
    const clientName = isSuperAdmin ? "Lunchbox" : uppercaseTitle(client);
    const userVars = FS?.setUserVars({
      displayName: uppercaseTitle(`${user.firstName} ${user.lastName}`),
      email: user.email.value,
      client: clientName,
    });
    FS?.identify(user.id, userVars);
  }, [user?.id, user?.firstName, user?.lastName, user?.email?.value, client]);

  return (
    <UserContext.Provider value={contextValues}>
      {children}
    </UserContext.Provider>
  );
};

const useUserContext = () => {
  const contextValues = useContext(UserContext);
  if (!contextValues) {
    throw new Error("useUserContext must be used within UserContextProvider");
  }
  return contextValues;
};

export { useUserContext };
export { UserContext };
export default {
  Provider,
  UserContext,
};
