import * as React from "react";
import { useOverlayTriggerState } from "@react-stately/overlays";
import {
  OverlayContainer,
  useModal,
  useOverlay,
  usePreventScroll,
} from "@react-aria/overlays";
import { useDialog } from "@react-aria/dialog";
import { FocusScope } from "@react-aria/focus";
import { motion, AnimatePresence } from "framer-motion";
import { Body, HeadingMedium } from "./FigmaUI";
import { Button } from "@lunchboxinc/utensils";

import type { ButtonProps } from "@lunchboxinc/utensils";

function Backdrop(
  props: React.PropsWithChildren<{
    opacity?: number;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  }>,
) {
  return (
    <motion.div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        zIndex: 98,
        background: `rgba(0, 0, 0, ${props?.opacity || 0.25})`,
      }}
      {...props}
    />
  );
}
interface DialogContentProps {
  children?: React.ReactNode;
  closable?: boolean;
  closeWithTint?: boolean;
  content?: string;
  onClose(): void;
  opacity?: number;
  primaryButtonProps?: ButtonProps;
  secondaryButtonProps?: ButtonProps;
  title?: string;
  useTint?: boolean;
}

function DialogContent({
  children,
  closable = true,
  closeWithTint = true,
  content,
  onClose,
  opacity = 0.25,
  primaryButtonProps,
  secondaryButtonProps,
  title,
  useTint = true,
  ...props
}: DialogContentProps) {
  const ref = React.useRef(null);
  const { overlayProps, underlayProps } = useOverlay(props, ref);
  const { modalProps } = useModal();
  const { dialogProps } = useDialog(props, ref);

  usePreventScroll();

  return (
    <OverlayContainer>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        style={{
          position: "fixed",
          zIndex: 100,
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FocusScope contain restoreFocus autoFocus>
          <div
            {...overlayProps}
            {...dialogProps}
            {...modalProps}
            ref={ref}
            aria-label={title}
            style={{
              position: "relative",
              width: "100%",
              maxWidth: 500,
              background: "#fff",
              padding: "1rem",
              boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: 2,
              zIndex: 99,
            }}
          >
            {closable && (
              <div
                style={{
                  cursor: "pointer",
                  fontSize: "1.5rem",
                  height: "1.5rem",
                  lineHeight: "1.5rem",
                  position: "absolute",
                  right: "1rem",
                  top: "1rem",
                  width: "1.5rem",
                }}
                onClick={onClose}
              >
                &times;
              </div>
            )}
            {title && (
              <HeadingMedium style={{ marginBottom: "1.5rem" }}>
                {title}
              </HeadingMedium>
            )}
            {content && (
              <Body style={{ marginBottom: "1.5rem" }}>{content}</Body>
            )}
            {children}
            <div
              style={{
                display: "flex",
                gap: "1rem",
                justifyContent: "flex-end",
              }}
            >
              {secondaryButtonProps && (
                <Button
                  variant="secondary"
                  onClick={secondaryButtonProps.onClick}
                  size="large"
                  {...secondaryButtonProps}
                >
                  {secondaryButtonProps.children}
                </Button>
              )}
              {primaryButtonProps && (
                <Button
                  onClick={primaryButtonProps.onClick}
                  size="large"
                  {...primaryButtonProps}
                >
                  {primaryButtonProps.children}
                </Button>
              )}
            </div>
          </div>
        </FocusScope>
        <Backdrop
          opacity={useTint ? opacity : 0}
          onClick={closeWithTint && closable ? onClose : () => null}
          {...underlayProps}
        />
      </motion.div>
    </OverlayContainer>
  );
}

export interface DialogProps {
  children?: React.ReactNode;
  closable?: boolean;
  content?: string;
  defaultOpen?: boolean;
  isOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  opacity?: number;
  primaryButtonProps?: ButtonProps;
  secondaryButtonProps?: ButtonProps;
  title?: string;
}

function Dialog({
  isOpen,
  defaultOpen = true,
  onOpenChange,
  ...props
}: DialogProps) {
  const state = useOverlayTriggerState({ isOpen, defaultOpen, onOpenChange });

  return (
    <AnimatePresence>
      {state.isOpen && <DialogContent onClose={state.close} {...props} />}
    </AnimatePresence>
  );
}

export default Dialog;
