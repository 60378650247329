import styled from "styled-components";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getColor } from "common/constants/theme";

type IconProps = {
  $isActive: boolean;
};

export namespace S {
  export const FooterButton = styled(Link)`
    text-align: center;
    color: ${getColor("powder")};
    font-size: 14px;
    text-transform: uppercase;
    padding-left: 30px;
    padding-right: 30px;
    cursor: pointer;
  `;

  export const Icon = styled(FontAwesomeIcon)<IconProps>`
    height: 20px;
    ${({ $isActive }) => $isActive && `color: ${getColor("yellow", 500)};`};
  `;

  export const FooterWrapper = styled.div`
    background-color: ${getColor("gray", 10)};
    display: flex;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 10px;
    color: ${getColor("powder")};
    justify-content: center;
    z-index: 2;
  `;
}
