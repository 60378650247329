import React, {useState, useEffect} from "react";
import {message, Input, Popconfirm, Form, Col} from "antd";
import isEqual from "lodash/isEqual";
import {utils, constants} from "common";
import {Row, Button, T, PageSpinner} from "components/elements";
import {Permissions, Condition as Conditions} from "components/fragments";
import {useAPIContext} from "components/providers/Api";
import ServiceAreaMap from "./ServiceAreaMap";
import {getCoordinatesFromOverlay} from "./helpers";
import styles from "./locationStatus.module.css";

const {mapAxiosError} = utils;
const {Permission} = Permissions;
const {Condition} = Conditions;
const {
  permissions: {
    routes: {LOCATIONS_DELIVERY_RADIUS},
    methods: {PUT},
  },
} = constants;

const formatCoordinates = (coordinates) => {
  // Make path into a loop if not already
  const loopStart = coordinates[0];
  const loopEnd = coordinates[coordinates.length - 1];
  if (loopStart.lat !== loopEnd.lat || loopStart.long !== loopEnd.long) {
    coordinates.push(loopStart);
  }

  return coordinates;
};

const LocationArea = ({
  locationId,
  address,
  initialServiceArea,
  initialDeliveryRadius,
  hasDeliveryRadius,
  setLocationData,
  showMapControls,
  onChange,
}) => {
  const {Locations} = useAPIContext();

  const [submitting, setSubmitting] = useState(false);
  const [deliveryRadius, setDeliveryRadius] = useState(0);
  const [serviceArea, setServiceArea] = useState([]);
  const [overlay, setOverlay] = useState();

  const updateLocationDeliveryRadius = async (data) => {
    setSubmitting(true);
    try {
      const res = await Locations.createDeliveryRadius(locationId, data);
      setLocationData(res.data);
      message.success("Delivery Area successfully updated.");
    } catch (e) {
      message.error(mapAxiosError(e));
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    setDeliveryRadius(initialDeliveryRadius || null);
  }, [initialDeliveryRadius]);

  useEffect(() => {
    setServiceArea(initialServiceArea);
  }, [initialServiceArea]);

  const handleSave = () => {
    const overlayCoordinates = getCoordinatesFromOverlay(overlay);
    const loopedCoordinates = formatCoordinates(overlayCoordinates);

    // If the radius is an unaltered circle created using the radius endpoint,
    // use the same endpoint so that deliveryRadius/hasDeliveryRadius are not overwritten
    if (hasDeliveryRadius && isEqual(initialServiceArea, loopedCoordinates)) {
      updateLocationDeliveryRadius({distance: deliveryRadius});
    } else {
      updateLocationDeliveryRadius({serviceArea: loopedCoordinates});
    }
  };

  const handleDeliveryRadiusChange = (e) => {
    setDeliveryRadius(e.target.value);
  };

  const handleDraw = () => {
    updateLocationDeliveryRadius({distance: deliveryRadius});
  };

  const handleClear = () => {
    setOverlay((prevState) => {
      // Remove this overlay from the map
      prevState.setMap(null);
      return prevState;
    });

    if (onChange) onChange([]);
  };

  if (!window.google) return <PageSpinner />;
  return (
    <>
      <Permission
        requiredPermissions={{method: PUT, url: LOCATIONS_DELIVERY_RADIUS}}
        yes={() => (
          <Form>
            <Condition is={showMapControls}>
              <Row>
                <div className={styles.buttonWrapper}>
                  <Button
                    size="small"
                    color="blue"
                    onClick={handleSave}
                    className={styles.saveButton}
                    disabled={submitting}
                  >
                    Save
                  </Button>
                </div>
              </Row>
              <Row spacing={20}>
                <Col xs={24} sm={12}>
                  <div className={styles.radiusWrapper}>
                    <Form.Item
                      label={<T label>Radius</T>}
                      className={styles.radiusInput}
                    >
                      <Input
                        type="number"
                        placeholder="Enter a circular radius in miles"
                        min={0}
                        value={deliveryRadius}
                        onChange={handleDeliveryRadiusChange}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col xs={24} sm={12}>
                  <div className={styles.drawClearButtonWrapper}>
                    <div className={styles.radiusButton}>
                      <Popconfirm
                        placement="bottom"
                        title={
                          <>
                            <div>
                              Drawing a new area will overwrite the currently
                              drawn area.
                            </div>
                            <div>Would you like to continue?</div>
                          </>
                        }
                        onConfirm={handleDraw}
                        okText="Yes"
                        cancelText="No"
                        width={50}
                      >
                        <Button size="small" disabled={submitting}>
                          Draw
                        </Button>
                      </Popconfirm>
                    </div>
                    <div className={styles.radiusButton}>
                      <Popconfirm
                        placement="left"
                        title={
                          <>
                            <div>This will clear the currently drawn area.</div>
                            <div>Would you like to continue?</div>
                          </>
                        }
                        onConfirm={handleClear}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button
                          disabled={!overlay || submitting}
                          color="transparentRed"
                          size="small"
                        >
                          Clear
                        </Button>
                      </Popconfirm>
                    </div>
                  </div>
                </Col>
              </Row>
            </Condition>
          </Form>
        )}
      />
      <ServiceAreaMap
        geo={address.geo}
        serviceArea={serviceArea}
        setServiceArea={setServiceArea}
        overlay={overlay}
        setOverlay={setOverlay}
        onChange={onChange}
      />
    </>
  );
};

export default LocationArea;
