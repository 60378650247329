import * as React from "react";
import {Tag} from "components/elements";
import styles from "./cateringStatus.module.css";

const tagColorData = {
  accepted: styles.accepted,
  declined: styles.declined,
};
const tagColor = (status) => tagColorData[status] || styles.open;

const tagTextData = {
  accepted: "Accepted",
  declined: "Declined",
};
const tagText = (status) => tagTextData[status] || "Open";

const CateringStatus = ({status}) => (
  <Tag className={tagColor(status)}>{tagText(status)}</Tag>
);

export default CateringStatus;
