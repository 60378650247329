import { Card, Col, message } from "antd";
import { LoadingSpinner } from "components/v2/Loading/LoadingSpinner/LoadingSpinner";
import { Elements, Fragments } from "components";
import React, { useState } from "react";
import { useAPIContext } from "components/providers/Api";
import { mapAxiosError } from "common/utils";
import moment from "moment";
import { permissions } from "common/constants";
import { useAnalyticsActions } from "hooks";
import styles from "./analytics.module.css";
import LineGraph from "./LineGraph";
import Header from "./Header";

const {
  Titles: { BlueCardTitle, CardTitle },
  T,
  Row,
  AnalyticsPrice,
  Button,
} = Elements;
const {
  Cards: { StatCard, BlueCard },
  Sales,
  Condition: { Condition: If, True, False },
  Permissions: { Permission },
} = Fragments;

const {
  methods: { POST },
  routes: { ORDERS_UPDATE_COVER_SIZES },
} = permissions;

const SalesAnalytics = ({ locationId }) => {
  const { Orders } = useAPIContext();
  const [updating, setUpdating] = useState(false);
  const { actions, params } = useAnalyticsActions({ locationId });

  const updateCoverSize = async () => {
    setUpdating(true);
    try {
      await Orders.updateCoverSize();
    } catch (e) {
      message(mapAxiosError(e));
    } finally {
      setUpdating(false);
    }
  };

  const totalRevenueLineData = (data) => [
    {
      id: "Total Sales",
      data: data.map((i) => ({
        y: i.totalRevenue,
        x: moment(i._id).format("MMM DD"),
      })),
    },
  ];

  const totalOrdersLineData = (data) => [
    {
      id: "Total Orders",
      data: data.map((i) => ({
        y: i.totalOrders,
        x: moment(i._id).format("MMM DD"),
      })),
    },
  ];

  const platformSalesLineData = (data) => {
    const webData =
      data &&
      data.map((i) => ({
        y: parseFloat(i.totalWebRevenue.toFixed(2), 10),
        x: moment(i._id).format("MMM DD"),
      }));
    const appData =
      data &&
      data.map((i) => ({
        y: parseFloat(
          (i.totalAppleRevenue + i.totalAndroidRevenue).toFixed(2),
          10,
        ),
        x: moment(i._id).format("MMM DD"),
      }));

    return [
      {
        id: "Web Sales",
        data: webData,
      },
      {
        id: "App Sales",
        data: appData,
      },
    ];
  };

  const salesTypeLineData = (data) => {
    const pickupData =
      data &&
      data.map((i) => ({
        y: parseFloat((i.totalPickupRevenue || 0).toFixed(2), 10),
        x: moment(i._id).format("MMM DD"),
      }));
    const deliveryData =
      data &&
      data.map((i) => ({
        y: parseFloat((i.totalDeliveryRevenue || 0).toFixed(2), 10),
        x: moment(i._id).format("MMM DD"),
      }));

    return [
      {
        id: "Pickup Sales",
        data: pickupData,
      },
      {
        id: "Delivery Sales",
        data: deliveryData,
      },
    ];
  };

  const platformOrdersLineData = (data) => {
    const webData =
      data &&
      data.map((i) => ({
        y: i.totalWebOrders,
        x: moment(i._id).format("MMM DD"),
      }));
    const appData =
      data &&
      data.map((i) => ({
        y: i.totalAppleOrders + i.totalAndroidOrders,
        x: moment(i._id).format("MMM DD"),
      }));

    return [
      {
        id: "Web Orders",
        data: webData,
      },
      {
        id: "App Orders",
        data: appData,
      },
    ];
  };

  const orderTypeLineData = (data) => {
    const deliveryData =
      data &&
      data.map((i) => ({
        y: i.totalDeliveryOrders,
        x: moment(i._id).format("MMM DD"),
      }));
    const pickupData =
      data &&
      data.map((i) => ({
        y: i.totalPickupOrders,
        x: moment(i._id).format("MMM DD"),
      }));

    return [
      {
        id: "Delivery Orders",
        data: deliveryData,
      },
      {
        id: "Pickup Orders",
        data: pickupData,
      },
    ];
  };

  return (
    <Sales locationId={locationId} params={params}>
      {({ totals, averages, resource, fetching }) => (
        <LoadingSpinner spinning={fetching}>
          <Card bordered={false}>
            <Header
              actions={actions}
              title="Sales Analytics Breakdown"
              initialDateValues={params.createdAt}
            />
            <Row gutter={30}>
              <Col xs={24} sm={6} style={{ marginBottom: "30px" }}>
                <StatCard
                  title="App Orders"
                  type="value"
                  value={totals.appOrders}
                  icon="mobile-total-icon"
                />
              </Col>

              <Col xs={24} sm={6} style={{ marginBottom: "30px" }}>
                <StatCard
                  title="App Revenue"
                  type="price"
                  value={totals.appRevenue}
                  icon="mobile-orders-icon"
                />
              </Col>

              <Col xs={24} sm={6} style={{ marginBottom: "30px" }}>
                <StatCard
                  title="Web Orders"
                  type="value"
                  value={totals.webOrders}
                  icon="desktop-total-icon"
                />
              </Col>

              <Col xs={24} sm={6} style={{ marginBottom: "30px" }}>
                <StatCard
                  title="Web Revenue"
                  type="price"
                  value={totals.webRevenue}
                  icon="desktop-orders-icon"
                />
              </Col>
            </Row>
          </Card>

          <BlueCard>
            <BlueCardTitle className={styles.breakdownTitle}>
              Sales Analytics
            </BlueCardTitle>

            <Row spacing={30} gutter={30}>
              <Col>
                <Row>
                  <Col xs={24} sm={20}>
                    <CardTitle className={styles.chartTitle}>
                      Total Sales
                    </CardTitle>
                    <LineGraph
                      data={totalRevenueLineData(resource)}
                      unit="currency"
                    />
                  </Col>
                  <Col xs={24} sm={4}>
                    <T>Average Ticket using Covers</T>
                    <AnalyticsPrice
                      align="left"
                      value={averages.averageCoverTicketPrice}
                    />
                    <Permission
                      requiredPermissions={{
                        method: POST,
                        url: ORDERS_UPDATE_COVER_SIZES,
                      }}
                      yes={() => (
                        <Button size="small" onClick={updateCoverSize}>
                          <If is={updating}>
                            <True>
                              <LoadingSpinner />
                            </True>
                            <False>Recalculate</False>
                          </If>
                        </Button>
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={12}>
                    <CardTitle className={styles.chartTitle}>
                      Sales By Platform
                    </CardTitle>
                    <LineGraph
                      data={platformSalesLineData(resource)}
                      legendTranslateX={-67}
                      unit="currency"
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <CardTitle className={styles.chartTitle}>
                      Sales By Type
                    </CardTitle>
                    <LineGraph
                      data={salesTypeLineData(resource)}
                      legendTranslateX={-45}
                      unit="currency"
                    />
                  </Col>
                </Row>
                <Row>
                  <CardTitle className={styles.chartTitle}>
                    Total Orders
                  </CardTitle>
                  <LineGraph
                    data={totalOrdersLineData(resource)}
                    legendTranslateX={-57}
                  />
                </Row>
                <Row>
                  <Col xs={24} sm={12}>
                    <CardTitle className={styles.chartTitle}>
                      Orders By Platform
                    </CardTitle>
                    <LineGraph
                      data={platformOrdersLineData(resource)}
                      legendTranslateX={-60}
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <CardTitle className={styles.chartTitle}>
                      Orders By Type
                    </CardTitle>
                    <LineGraph
                      data={orderTypeLineData(resource)}
                      legendTranslateX={-47}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </BlueCard>
        </LoadingSpinner>
      )}
    </Sales>
  );
};

export default SalesAnalytics;
