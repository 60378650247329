import React from "react";

import LogoImg from "assets/images/Lunchbox-Logo-White.svg";
import css from "./logo.module.css";

const Logo = () => (
  <div className={css.logoContainer}>
    <img className={css.logoImg} src={LogoImg} alt="Lunchbox" />
  </div>
);

export default Logo;
