import * as Yup from "yup";

Yup.addMethod(Yup.number, "defined", function () {
  return this.test(
    "defined",
    "Promotion Code Redemption Type is required",
    (value) => value !== undefined,
  );
});

const newPromotionSchema = Yup.object().shape({
  name: Yup.string().required("Promotion Name is required"),
  description: Yup.string(),
  callToAction: Yup.string(),
  isPromotionStorable: Yup.boolean().required(
    "Storable Promotion selection is required",
  ),
  redemptionLimit: Yup.number()
    .integer()
    .min(0)
    .nullable()
    .defined("Promotion Code Redemption Type is required")
    .positive(),
  generateCodes: Yup.boolean().when("redemptionLimit", {
    is: (redemptionLimit) => redemptionLimit > 0,
    then: Yup.boolean().required("Promotion Code Creation Method is required"),
  }),
  generateCount: Yup.number().when("generateCodes", {
    is: (generateCodes) => generateCodes,
    then: Yup.number().required("Promotion Code Generation Count is required"),
  }),
  customCode: Yup.string().when("generateCodes", {
    is: (generateCodes) => !generateCodes,
    then: Yup.string()
      .matches(/^[a-zA-Z0-9]*$/, {
        message: "Promotion codes should contain only alphanumeric characters",
      })
      .required("Promotion Code is required"),
  }),
});

const updateRedemptionLimitSchema = Yup.object().shape({
  ...newPromotionSchema.fields,
  redemptionLimit: Yup.number()
    .integer()
    .min(0)
    .required("Promotion Code Redemption Limit is required")
    .positive(),
});

export default {
  NewPromotionSchema: newPromotionSchema,
  UpdateRedemptionLimitSchema: updateRedemptionLimitSchema,
};
