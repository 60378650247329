import * as React from "react";
import classnames from "classnames";
import styles from "./price.module.css";

const alignClass = (align) => {
  switch (align) {
    case "right":
      return styles.right;
    case "left":
      return styles.left;
    case "center":
      return styles.center;
    default:
      return null;
  }
};

const Price = ({value, align, inline, className, ...props}) => {
  const price = value || 0;
  const classes = classnames(
    alignClass(align),
    inline && styles.inline,
    className,
  );

  return (
    <div {...props} className={classes}>
      $
      {price.toLocaleString(undefined, {
        minimumFractionDigits: price > 999 ? 0 : 2,
        maximumFractionDigits: price > 999 ? 0 : 2,
      })}
    </div>
  );
};

Price.defaultProps = {
  value: 0,
  align: "right",
};

export default Price;
