import { states } from "./states";
import * as fullstoryCredentials from "./fullstory";
import * as integrationNames from "./integrationNames";
import emailTemplates from "./emailTemplates";
import globals from "./globals";
import pagePermissions from "./pagePermissions";
import permissions from "./permissions";
import strings from "./strings";
import timePresets from "./timePresets";
import timezones from "./timezones";
import urls from "./urls";

export {
  emailTemplates,
  fullstoryCredentials,
  globals,
  integrationNames,
  pagePermissions,
  permissions,
  states,
  strings,
  timePresets,
  timezones,
  urls,
};
export default {
  emailTemplates,
  fullstoryCredentials,
  globals,
  integrationNames,
  pagePermissions,
  permissions,
  states,
  strings,
  timePresets,
  timezones,
  urls,
};
