import React from "react";
import {Col} from "antd";
import styles from "./settingsFieldsGroup.module.css";

const SettingsField = (props) => (
  <Col xs={24} sm={12} className={styles.field}>
    {props.children}
  </Col>
);

export default SettingsField;
