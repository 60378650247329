import React from "react";
import { Elements, Fragments } from "components";
import classnames from "classnames";

import { Card } from "antd";

import styles from "./operatingTimeTable.module.css";

const { HoursTable } = Fragments;

const {
  Titles: { SectionTitle },
  Button,
  Row,
} = Elements;

const OperatingTimeTable = ({
  title,
  fieldName,
  fetching,
  values,
  setFieldValue,
  errors,
  isSubmitting,
  className,
}) => (
  <Card
    loading={fetching}
    bordered={false}
    className={classnames(styles.hoursTableCard, className)}
  >
    <SectionTitle>{`${title} Operating Times`}</SectionTitle>
    <Row spacing={25}>
      <HoursTable
        loading={fetching}
        setFieldValue={setFieldValue}
        values={values}
        index={`${fieldName}Hours`}
        startTitle={`${title} Open`}
        startIndex={`${fieldName}Open`}
        endTitle={`${title} Close`}
        endIndex={`${fieldName}Close`}
        errors={errors}
      />
    </Row>
    <Row>
      <Button
        htmlType="submit"
        size="small"
        disabled={isSubmitting}
        loading={isSubmitting}
        color="blue"
      >
        Save
      </Button>
    </Row>
  </Card>
);

export default OperatingTimeTable;
