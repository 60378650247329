import React, { useState, useEffect, useRef } from "react";
import { LoadingSpinner } from "components/v2/Loading/LoadingSpinner/LoadingSpinner";
import { constants } from "common";
import { usePermission } from "hooks";
import { getCoordinatesFromOverlay } from "./helpers";

const {
  permissions: {
    routes: { LOCATIONS_DELIVERY_RADIUS },
    methods: { PUT },
  },
} = constants;
const regionStyle = {
  strokeColor: "#FF0000",
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: "#FF0000",
  fillOpacity: 0.35,
};

const ServiceAreaMap = ({
  geo,
  serviceArea,
  setServiceArea,
  overlay,
  setOverlay,
  onChange,
}) => {
  const [error, setError] = useState(false);
  const [map, setMap] = useState();
  const nodeRef = useRef();
  const hasEditPermission = usePermission({
    requiredPermissions: [{ method: PUT, url: LOCATIONS_DELIVERY_RADIUS }],
  });

  const setPolygonListeners = (modifiedOverlay) => {
    if (onChange) {
      const handleModifiedOverlay = () => {
        onChange(getCoordinatesFromOverlay(modifiedOverlay));
      };

      const path = modifiedOverlay.getPath();
      window.google.maps.event.addListener(
        path,
        "insert_at",
        handleModifiedOverlay,
      );
      window.google.maps.event.addListener(
        path,
        "remove_at",
        handleModifiedOverlay,
      );
      window.google.maps.event.addListener(
        path,
        "set_at",
        handleModifiedOverlay,
      );
    }
  };

  const mapCoordinates = (data) =>
    data.map((i) => ({ lat: i.lat, lng: i.long }));

  const onPolygonComplete = (e) => {
    // Remove old overlay from map, add new one to remove from map later if needed
    setOverlay((prevState) => {
      prevState.setMap(null);
      return e.overlay;
    });
    setPolygonListeners(e.overlay);

    const newCoordinates = getCoordinatesFromOverlay(e.overlay);
    setServiceArea(newCoordinates);
    if (onChange) onChange(newCoordinates);
  };

  const getBounds = () => {
    // Set the window bounds to contain the service area
    const bounds = new window.google.maps.LatLngBounds();
    serviceArea.forEach((i) => {
      bounds.extend(new window.google.maps.LatLng(i.lat, i.long));
    });
    return bounds;
  };

  useEffect(() => {
    if (window.google) {
      // Create a new map with center at location
      const newMap = new window.google.maps.Map(nodeRef.current, {
        center: { lat: geo.lat, lng: geo.long },
        zoom: 14,
      });
      setMap(newMap);

      // Create a new marker where the location is
      // const marker = new window.google.maps.Marker({
      //   position: { lat: geo.lat, lng: geo.long },
      //   map: newMap,
      //   title: 'Bareburger',
      // }); [NEVER USED]

      const newDrawingManager = new window.google.maps.drawing.DrawingManager({
        drawingControl: true,
        drawingControlOptions: {
          position: window.google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
        },
        polygonOptions: { ...regionStyle, editable: true },
      });
      newDrawingManager.setMap(newMap);

      newMap.fitBounds(getBounds());
      window.google.maps.event.addListener(
        newDrawingManager,
        "overlaycomplete",
        onPolygonComplete,
      );
    } else {
      setError(true);
    }
  }, []);

  useEffect(() => {
    if (map && serviceArea.length > 0) {
      // Update the map bounds when the overlay changes
      map.fitBounds(getBounds());

      if (overlay) {
        // When service area changes and an overlay already exists,
        // update the paths of the overlay to the new service area
        overlay.setPaths(mapCoordinates(serviceArea));
        overlay.setMap(map);
        setPolygonListeners(overlay);
      } else {
        // If the map exists but no overlay has been set yet,
        // set the overlay to the service area
        const newRegion = new window.google.maps.Polygon({
          ...(hasEditPermission && { editable: true }),
          paths: mapCoordinates(serviceArea),
          ...regionStyle,
        });
        setPolygonListeners(newRegion);

        setOverlay(newRegion);
        newRegion.setMap(map);
      }
    }
  }, [serviceArea, overlay, map]);

  return (
    <LoadingSpinner spinning={error} label="Unable to Load Map">
      <div id="map" ref={nodeRef} style={{ height: "65vh" }}>
        {error && <span>Unable to Load Map</span>}
      </div>
    </LoadingSpinner>
  );
};

export default ServiceAreaMap;
