import React, {useEffect, useState} from "react";
import {useWindowSize, usePermission} from "hooks";
import {permissions} from "common/constants";
import EntityAvailabilityButtons from "./EntityAvailabilityButtons";
import EntityActionsDropdown from "./EntityActionsDropdown";

const {
  methods: {PUT},
  routes: {MENU_ITEMS_ITEM, MENUS_ITEM, MENU_GROUPS_ITEM, MENU_OPTIONS_ITEM},
} = permissions;

const selectUrl = (listType) => {
  switch (listType) {
    case "groups":
      return MENU_GROUPS_ITEM;
    case "items":
      return MENU_ITEMS_ITEM;
    case "menus":
      return MENUS_ITEM;
    default:
      return MENU_OPTIONS_ITEM;
  }
};

const EntityAvailability = (props) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 576);
  const {width} = useWindowSize();
  const requiredPermissions = usePermission({
    requiredPermissions: [
      {
        method: PUT,
        url: selectUrl(props.listType),
        field: "isActive",
      },
    ],
  });

  useEffect(() => {
    setIsMobile(width < 576);
  }, [width]);

  return (
    <div>
      {isMobile ? (
        <EntityActionsDropdown
          requiredPermissions={requiredPermissions}
          {...props}
        />
      ) : (
        <EntityAvailabilityButtons
          requiredPermissions={requiredPermissions}
          {...props}
        />
      )}
    </div>
  );
};

export default EntityAvailability;
