import React, {useEffect} from "react";
import {Typography} from "antd";
import useForm from "hooks/useForm";
import styles from "./field.module.scss";

const EditableTextField = ({
  schema,
  save,
  initialText,
  className,
  validation,
  ...props
}) => {
  const [editing, setEditing] = React.useState(false);
  const {values, errors, updateField, updateError} = useForm({
    initialValues: {text: initialText},
  });

  const checkValidation = (str) => {
    return validation !== undefined ? validation(str, values.text) : true;
  };

  const updateText = (str) => {
    if (schema) {
      schema
        .validate(str)
        .then(() => {
          updateField("text", str);
          updateError("text", null);
          save(str);
        })
        .catch((err) => {
          updateError("text", err.message);
        });
    } else {
      updateField("text", str);
      updateError("text", null);
      if (str !== initialText && checkValidation(str)) {
        save(str);
      }
    }
  };

  useEffect(() => {
    updateField("text", props.children || initialText);
  }, [props.children, initialText]);

  const handleChange = (str) => {
    if (!checkValidation(str)) {
      updateText(values.text);
    } else if (str) {
      updateText(str);
    } else {
      updateText("");
      updateField("text", initialText);
    }
    setEditing(false);
  };

  const handleStart = () => {
    if (values.text === initialText) {
      updateField("text", "");
    }
    setEditing(true);
  };

  const errorMsg = errors ? Object.entries(errors)[0][1] : undefined;
  const marginClass = editing ? styles.editMargin : styles.titleMargin;

  return (
    <div className={marginClass} {...props} inline={undefined}>
      <Typography.Text
        editable={{
          onChange: handleChange,
          onStart: handleStart,
        }}
        className={className}
      >
        {values.text}
      </Typography.Text>
      {errorMsg && <div className={styles.errorText}>{errorMsg}</div>}
    </div>
  );
};

EditableTextField.defaultProps = {
  text: "",
};

export default EditableTextField;
