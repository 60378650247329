/* eslint-disable no-template-curly-in-string */
import * as Yup from "yup";
import { baseLocationSchema } from "./location";

const cateringGroupSchema = baseLocationSchema.shape({
  source: Yup.string().required(),
  locations: Yup.array().of(Yup.string()),
  cateringDeliveryMinimum: Yup.number()
    .typeError("Catering Delivery Minimum must be a number")
    .min(0, "Catering Delivery Minimum must be 0 or greater")
    .nullable(true),
});

const newCateringGroupSchema = Yup.object().shape({
  locations: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required(),
        id: Yup.string().required(),
      }),
    )
    .required(),
  source: Yup.object().shape({
    name: Yup.string().required(),
    id: Yup.string().required(),
  }),
  name: Yup.string().required("Catering Group Name is required."),
});

const cateringGroupZoneSchema = Yup.object().shape({
  coordinates: Yup.array().of(Yup.object()),
  deliveryExternalId: Yup.string(),
  deliveryFee: Yup.number().min(
    0,
    "Delivery Fee must be greater than or equal to 0",
  ),
  serviceChargeByDistance: Yup.array().of(
    Yup.object({
      deliveryExternalId: Yup.string()
        .nullable()
        .required("Service Charge GUID is required"),
      deliveryFee: Yup.number()
        .nullable()
        .min(0, "Min must be greater than or equal to 0")
        .required("Delivery Fee is required"),
      min: Yup.number()
        .min(0, "Min must be greater than or equal to 0")
        .nullable()
        .required("Min is required"),
      max: Yup.number()
        .min(0, "Max must be greater than or equal to 0")
        .when("min", {
          is: (val) => val === null,
          then: Yup.number().nullable(),
          otherwise: Yup.number().moreThan(
            Yup.ref("min"),
            "Max must be more than ${more}",
          ),
        })
        .required("Max is required"),
    }),
  ),
  serviceChargeByPrice: Yup.array().of(
    Yup.object({
      deliveryExternalId: Yup.string()
        .nullable()
        .required("Service Charge GUID is required"),
      deliveryFee: Yup.number()
        .nullable()
        .min(0, "Min must be greater than or equal to 0")
        .required("Delivery Fee is required"),
      threshold: Yup.number()
        .min(0, "Threshold must be greater than or equal to 0")
        .nullable()
        .required("Threshold is required"),
    }),
  ),
});

export default {
  cateringGroupSchema,
  newCateringGroupSchema,
  cateringGroupZoneSchema,
};
