import * as React from "react";
import PropTypes from "prop-types";
import {DatePicker, Form} from "antd";
import {T} from "components/elements";
import {utils} from "common";
import moment from "moment";

const {
  pickerTimes: {minutes: minutesArr, hours: hoursArr},
} = utils;

const DateField = ({
  field: {onChange, ...field},
  form: {touched, errors, setFieldValue, setFieldTouched, hours},
  // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  width,
  useNumberComponent = false,
  timeFormat,
  disablePastTimes,
  disablePastDates,
  showTime,
  className,
  ...props
}) => {
  const errorMsg = touched[field.name] && errors[field.name];

  const disablePastTime = (selectedDate) => {
    const now = moment();

    if (selectedDate.format("L") === now.format("L")) {
      return {
        disabledHours: () =>
          hoursArr.filter((num) => num < moment().format("H")),
        disabledMinutes: (currentHour) => {
          if (String(currentHour) === moment().format("H")) {
            return minutesArr.filter((num) => num < moment().format("mm"));
          }
          return [];
        },
      };
    }
  };

  const Comp = DatePicker;
  return (
    <Form.Item
      label={
        <T label>
          <strong>{label}</strong>
        </T>
      }
      help={errorMsg}
      validateStatus={errorMsg ? "error" : undefined}
    >
      <Comp
        className={className}
        style={{width}}
        {...field}
        onBlur={undefined}
        {...props}
        disabledTime={disablePastTimes && disablePastTime}
        showTime={showTime && {format: timeFormat, use12Hours: true}}
        onChange={(newValue, dateString) => setFieldValue(field.name, newValue)}
        onOpenChange={() => {
          setFieldTouched(field.name);
        }}
      />
    </Form.Item>
  );
};

DateField.propTypes = {
  label: PropTypes.string,
  timeFormat: PropTypes.string,
};

DateField.defaultProps = {
  timeFormat: "h:mm a",
};

export default DateField;
