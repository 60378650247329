import React from "react";
import {Transfer as AntTransfer} from "antd";

const Transfer = ({dataSource, selectedKeys, setSelectedKeys, ...props}) => {
  const handleChange = (targetKeys) => {
    setSelectedKeys(targetKeys);
  };

  return (
    <AntTransfer
      dataSource={dataSource}
      listStyle={{
        width: 300,
        height: 300,
      }}
      targetKeys={selectedKeys}
      onChange={handleChange}
      {...props}
    />
  );
};

export default Transfer;
