import * as React from "react";
import Table from "./Table";
import { useHistory } from "react-router-dom";

const InteractiveTable = ({
  children,
  path,
  striped,
  pagination,
  primaryKey = "_id",
  redirect,
  ...props
}) => {
  const history = useHistory();
  const redirectLink = (id) => {
    history.push(path ? `${path}${id}` : id);
  };

  return (
    <Table
      striped={striped}
      pagination={pagination}
      clickable
      onRow={(record) => ({
        onClick: () => {
          if (redirect) {
            redirectLink(redirect(record));
          } else {
            redirectLink(
              record[primaryKey] || record.id || record.promotion.id,
            );
          }
        },
      })}
      {...props}
    >
      {children}
    </Table>
  );
};

InteractiveTable.defaultProps = {
  size: "small",
  striped: true,
  pagination: false,
};

export default InteractiveTable;
