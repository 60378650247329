import React, {useEffect, useState} from "react";
import {useWindowSize} from "hooks";
import LocationAvailabilityButtons from "./LocationAvailabilityButtons";
import LocationActionsDropdown from "./LocationActionsDropdown";

const LocationAvailability = ({dropdownOnly, ...props}) => {
  const [isDropdown, setIsDropdown] = useState(window.innerWidth < 576);
  const {width} = useWindowSize();

  useEffect(() => {
    setIsDropdown(width < 1660);
  }, [width]);

  return (
    <div>
      {isDropdown || dropdownOnly ? (
        <LocationActionsDropdown {...props} />
      ) : (
        <LocationAvailabilityButtons {...props} />
      )}
    </div>
  );
};

export default LocationAvailability;
