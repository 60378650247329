import * as Yup from "yup";

const schema = Yup.object().shape({
  type: Yup.string().oneOf(
    ["pickup", "dinein", "delivery"],
    "Order Type Required",
  ),
  email: Yup.string().email("Invalid email").required("Email is Required"),
});

export default schema;
