import * as Yup from "yup";

const upsellSchema = Yup.object({
  priority: Yup.number()
    .typeError("")
    .min(0, "You must specify a value from 0 to 10,000")
    .max(10000, "You must specify a value from 0 to 10,000"),
});

export default upsellSchema;
