import { Modal, message, Icon } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { constants, utils } from "common";
import { useAPIContext } from "components/providers/Api";

import ReactDiffViewer from "react-diff-viewer";
import moment from "moment";
import { usePermission } from "hooks";
import Button from "../../elements/Button";
import { ResDrawer } from "../../fragments/Drawers";
import { Table } from "../../fragments/Table";

const { mapAxiosError } = utils;
const { permissions } = constants;

const {
  methods: { GET },
  routes: { AUDITS_LIST },
} = permissions;

const paginationObj = (skip, limit, total) => ({
  current: (Number(skip) + Number(limit)) / Number(limit),
  pageSize: Number(limit),
  total: Number(total),
});

const defaultResource = {
  results: [],
  skip: 0,
  limit: 100,
  total: 0,
};
const defaultActiveAudit = {
  before: "{}",
  after: "{}",
};

const columns = [
  {
    title: "Date",
    dataIndex: "createdAt",
    render: (text) => moment(text).format("LLL"),
  },
  {
    title: "User",
    dataIndex: "admin",
    render: (admin) => (admin ? `${admin.firstName} ${admin.lastName}` : "N/A"),
  },
];

export default (Component, paramName) => (props) => {
  const hasRequiredPermission = usePermission({
    requiredPermissions: [
      {
        method: GET,
        url: AUDITS_LIST,
      },
    ],
  });

  const params = useParams();
  const { Audit } = useAPIContext();
  const [resource, setResource] = useState(defaultResource);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [activeAudit, setActiveAudit] = useState(defaultActiveAudit);

  const closeModal = () => setIsOpen(false);

  const toggleDrawer = () => setIsDrawerOpen((val) => !val);
  const closeDrawer = () => setIsDrawerOpen(false);

  const fetchAuditList = async (skip, limit) => {
    setFetching(true);
    try {
      const { data } = await Audit.readMany({
        filters: {
          resourceId: params[paramName],
        },
        skip,
        limit,
      });
      setResource(data);
    } catch (e) {
      setError(mapAxiosError(e));
      message.error(mapAxiosError(e));
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    if (isDrawerOpen) {
      fetchAuditList(resource.skip, resource.limit);
    }
  }, [isDrawerOpen]);

  let auditButton = null;
  if (!paramName || !hasRequiredPermission || error) {
    return <Component {...props} auditButton={auditButton} />;
  }
  auditButton = (
    <Button size="small" onClick={toggleDrawer}>
      <Icon type="history" />
      History
    </Button>
  );

  return (
    <>
      <Modal
        visible={isOpen}
        onOk={closeModal}
        onCancel={closeModal}
        width="90vw"
        style={{ top: 20 }}
      >
        <ReactDiffViewer
          oldValue={activeAudit.before}
          newValue={activeAudit.after}
          showDiffOnly
          useDarkTheme
          hideLineNumbers
          splitView={false}
        />
      </Modal>
      <ResDrawer
        title="Audit trail"
        placement="right"
        width="50vw"
        isOpen={isDrawerOpen}
        onClose={closeDrawer}
      >
        <Table
          loading={fetching}
          dataSource={resource.results}
          columns={columns}
          pagination={paginationObj(
            resource.skip,
            resource.limit,
            resource.total,
          )}
          onChange={({ current }) => {
            fetchAuditList((current - 1) * resource.limit, resource.limit);
          }}
          onRow={(record) => ({
            onClick: () => {
              setActiveAudit({
                ...record,
                before: JSON.stringify(record.before, null, 2),
                after: JSON.stringify(record.after, null, 2),
              });
              setIsOpen(true);
            },
          })}
        />
      </ResDrawer>
      <Component {...props} auditButton={auditButton} />
    </>
  );
};
