import React, { useState } from "react";
import { Price, Row, Column, Titles, Number } from "components/elements";
import { Cards, Tables } from "components/fragments";
import { Icon, message } from "antd";
import { Tooltip } from "@lunchboxinc/utensils";
import { useAnalyticsActions, useRefetchableResource } from "hooks";

import { useAPIContext } from "components/providers/Api";
import { strings } from "common/constants";
import BarGraph from "../Analytics/BarGraph";
import Header from "../Analytics/Header";
import { downloadCsv } from "../../common/utils";
import css from "./home.module.css";

const { CardTitle } = Titles;
const { BlueCard } = Cards;
const { Table } = Tables;

const title = (value, direction = "right") => (
  <span style={{ float: direction }}>{value}</span>
);

const initialValue = {
  location: {
    name: "Total",
  },
  totalRevenue: 0,
  totalOrders: 0,
  totalTax: 0,
  totalTip: 0,
  totalLoyalty: 0,
  totalDeliveryOrders: 0,
  totalDiscounts: 0,
  totalPickupOrders: 0,
  totalSubtotal: 0,
  totalRefund: 0,
  totalDeliveryFee: 0,
  totalAppliedStoredValue: 0,
};

const SalesReportsAnalytics = ({ locationId }) => {
  const { Analytics } = useAPIContext();

  const [submitting, setSubmitting] = useState(false);
  const { actions, params, initialDateValues } = useAnalyticsActions({
    locationId,
  });
  const { resource, fetching } = useRefetchableResource({
    path: "/analytics/locations-simple",
    data: { filters: params },
    method: "GET",
  });

  const revenueSortedLocations = resource.sort(
    (a, b) => b.totalRevenue - a.totalRevenue,
  );
  const totals = revenueSortedLocations.reduce(
    (accu, i) => ({
      location: {
        name: "Total",
      },
      totalRevenue: accu.totalRevenue + i.totalRevenue,
      totalOrders: accu.totalOrders + i.totalOrders,
      totalTax: accu.totalTax + i.totalTax,
      totalTip: accu.totalTip + i.totalTip,
      totalLoyalty: accu.totalLoyalty + i.totalLoyalty,
      totalDeliveryOrders: accu.totalDeliveryOrders + i.totalDeliveryOrders,
      totalDiscounts: accu.totalDiscounts + i.totalDiscounts,
      totalPickupOrders: accu.totalPickupOrders + i.totalPickupOrders,
      totalSubtotal: accu.totalSubtotal + i.totalSubtotal,
      totalRefund: accu.totalRefund + i.totalRefund,
      totalDeliveryFee: accu.totalDeliveryFee + i.totalDeliveryFee,
      totalAppliedStoredValue:
        accu.totalAppliedStoredValue + i.totalAppliedStoredValue,
    }),
    initialValue,
  );

  const locationSalesBarData = (data) =>
    data.map((i) => ({
      location: i.location.name,
      "Total Revenue": i.totalRevenue,
    }));

  const onExportCSV = async () => {
    const isCatering = params.platform[0] === strings.ORDER_TYPE.CATERING;
    if (isCatering) {
      delete params.platform;
    }

    setSubmitting(true);
    try {
      const { data } = isCatering
        ? await Analytics.getCateringSalesCSV({ filters: params })
        : await Analytics.getStandardSalesCSV({ filters: params });
      downloadCsv(data);
    } catch (e) {
      message.error("An error occurred");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <BlueCard bordered={false}>
      <Header
        title="Sales Reports"
        actions={actions}
        exportCSV={onExportCSV}
        initialDateValues={{
          start: initialDateValues.initialStartDate,
          end: initialDateValues.initialEndDate,
        }}
        submitting={submitting}
      />

      <Row spacing={50}>
        <Table
          horizontalScrollSize="large"
          size="small"
          striped
          pagination={false}
          dataSource={[...revenueSortedLocations, totals]}
          rowKey={(i, index) => index}
          loading={fetching}
          className="sales-reports-table"
        >
          <Column.Column
            title={title("Name", "left")}
            dataIndex="location.name"
            render={(text, record) => record.location.name}
            fixed="left"
            width={100}
          />
          <Column.Column
            title={title("Orders")}
            dataIndex="totalOrders"
            render={(val) => <Number align="right" value={val} />}
          />
          <Column.Column
            title={title(
              <>
                <Tooltip
                  key="syncInfo"
                  placement="bottom"
                  popupLabel="The price of all items sold before delivery, applied discounts, tax, tip and loyalty"
                >
                  <Icon type="info-circle" />
                </Tooltip>
                {" Subtotal"}
              </>,
              "right",
            )}
            dataIndex="totalSubtotal"
            render={(val) => <Price align="right" value={val} />}
          />
          <Column.Column
            title={title("Tax")}
            dataIndex="totalTax"
            render={(val) => <Price align="right" value={val} />}
          />
          <Column.Column
            title={title("Delivery Fee")}
            dataIndex="totalDeliveryFee"
            render={(val) => <Price align="right" value={val} />}
          />
          <Column.Column
            title={title("Tip")}
            dataIndex="totalTip"
            render={(val) => <Price align="right" value={val} />}
          />
          <Column.Column
            title={title("Discounts")}
            dataIndex="totalDiscounts"
            render={(val) => <Price align="right" value={val} />}
          />
          <Column.Column
            title={title("Loyalty")}
            dataIndex="totalLoyalty"
            render={(val) => <Price align="right" value={val} />}
          />
          <Column.Column
            title={title("Gift Card")}
            dataIndex="totalAppliedStoredValue"
            render={(val) => <Price align="right" value={val} />}
          />
          <Column.Column
            title={title(
              <>
                <Tooltip
                  key="syncInfo"
                  placement="bottom"
                  popupLabel="The total amount after delivery, applied discounts, tax, tip and loyalty"
                >
                  <Icon type="info-circle" />
                </Tooltip>
                {" Total Amount"}
              </>,
            )}
            dataIndex="totalRevenue"
            render={(val) => <Price align="right" value={val} />}
          />
          <Column.Column
            title={title("Refunds")}
            dataIndex="totalRefund"
            render={(val) => <Price align="right" value={val} />}
          />
        </Table>
      </Row>

      <Row spacing={40}>
        <CardTitle className={css.barTitle}>Location Revenue</CardTitle>
        <BarGraph
          data={locationSalesBarData(revenueSortedLocations)}
          legendTranslateX={-65}
          indexBy="location"
          keys={["Total Revenue"]}
          unit="currency"
        />
      </Row>
    </BlueCard>
  );
};

export default SalesReportsAnalytics;
