import React from "react";
import {Route} from "react-router-dom";
import MountTransition from "./MountTransition";

/**
 * Transition animation wrapper for a single React Router Route
 * @author Htin Linn Aung
 * @memberof Elements.Elements/RouteTransition
 * @prop {JSX.Element | JSX.Element[]} props.children - Children components
 * @prop {boolean} props.exact - Exact path flag
 * @prop {string} props.path - Route path value for a react component
 * @prop {number} props.opacity - Opacity value for slide and slide-up
 * @prop {number} props.slide - Transition slide value
 * @prop {number} props.slideUp - Transition slide-up value
 * @prop {number} props.animationOpacity - Opacity value for animation
 * @prop {number} props.animateX - X value for animation
 * @prop {number} props.animateY - Y value for animation
 * @returns {React.Element} - Transition animation wrapper for a single React Router Route
 */
const RouteTransition = ({
  children,
  exact = false,
  path,
  opacity = 0,
  slide = 50,
  slideUp = 0,
  animationOpacity = 1,
  animateX = 0,
  animateY = 0,
  ...props
}) => {
  const initial = {
    opacity,
    x: slide,
    y: slideUp,
  };
  const exit = {
    opacity,
    x: slide,
    y: slideUp,
  };
  const animate = {
    opacity: animationOpacity,
    x: animateX,
    y: animateY,
  };

  return (
    <Route exact={exact} path={path} {...props}>
      <MountTransition exit={exit} initial={initial} animate={animate}>
        {children}
      </MountTransition>
    </Route>
  );
};

export default RouteTransition;
