import React, {createContext, useState, useContext} from "react";
import {message} from "antd";
import merge from "lodash/merge";
import cloneDeep from "lodash/cloneDeep";
import {useResource} from "hooks";
import {utils} from "common";
import {useAPIContext} from "components/providers/Api";
import {TypeManager} from "./TypeManager";

const {mapAxiosError} = utils;

const defaultQuery = {
  filters: {
    name: "",
  },
  limit: 0,
};

const getIntegration = (integrations, type, key) =>
  integrations.find(
    ({name, type: serviceType}) => name === type.name && serviceType === key,
  );

const IntegrationsContext = createContext();

const Provider = ({children}) => {
  const {RestaurantGroups, Locations} = useAPIContext();
  const [newIntegration, setNewIntegration] = useState();
  const [activeIntegration, setActiveIntegration] = useState();
  const [integrationType, setIntegrationType] = useState();
  const [integrationName, setIntegrationName] = useState();
  const [locationId, setLocationId] = useState();
  const [inputOptions, setInputOptions] = useState({
    diningOptions: [],
    serviceCharges: [],
    paymentOptions: [],
    sections: [],
  });

  const {
    resource: restaurantGroup,
    fetching: fetchingRestaurantGroup,
    error: restaurantGroupError,
    fetch: fetchRestaurantGroup,
  } = useResource({path: "/restaurant-group"});
  const {
    resource: locations,
    fetching: fetchingLocations,
    error: locationError,
    fetch: fetchLocations,
  } = useResource({path: "/locations/", data: defaultQuery});
  const {
    resource: schemas,
    fetching: fetchingSchemas,
    error: schemaError,
  } = useResource({path: "/services"});

  // Restaurant Group related
  const updateRestaurantGroup = async (data) => {
    try {
      await RestaurantGroups.update(restaurantGroup.id, {services: [data]});
      message.success("Successfully Updated");
    } catch (e) {
      message.error(mapAxiosError(e));
    } finally {
      fetchRestaurantGroup();
    }
  };

  // Location related
  const updateLocation = async (id, data) => {
    try {
      await Locations.update(id, {services: [data]});
      message.success("Successfully Updated");
    } catch (e) {
      message.error(mapAxiosError(e));
    } finally {
      fetchLocations();
    }
  };

  const deleteIntegrationForALocation = async (
    locationId,
    integrationType,
    integrationName,
  ) => {
    try {
      await Locations.deleteLocationIntegration(locationId, {
        data: {
          type: integrationType,
          name: integrationName,
        },
      });
      message.success("Successfully Deleted");
    } catch (e) {
      message.error(mapAxiosError(e));
    } finally {
      fetchLocations();
    }
  };

  // Integrations related
  const mergeIntegrations = (integration, integrationSchema) => {
    const integrationCopy = cloneDeep(integration);
    const integrationSchemaCopy = cloneDeep(integrationSchema);
    return {
      ...integrationSchemaCopy,
      ...integrationCopy,
      credentials: {
        ...merge(
          integrationSchemaCopy.credentials,
          integrationCopy.credentials,
        ),
      },
    };
  };

  const addIntegration = (close) => async (type, name) => {
    setNewIntegration({type, name});
    updateRestaurantGroup({type, name, credentials: {}});
    close();
  };

  const updateActiveIntegration = (name, type, keyName) => {
    const integration = getIntegration(restaurantGroup.services, type, keyName);
    if (integration) {
      const integrationKey = Object.values(schemas.RGServicesSchema)
        .find((schemaType) => schemaType.key === keyName)
        .types.find((schema) => schema.name === name);

      const mergedIntegrations = mergeIntegrations(integration, integrationKey);
      setActiveIntegration(mergedIntegrations);
    }
  };

  let fetching =
    fetchingRestaurantGroup || fetchingSchemas || fetchingLocations;
  const setFetching = (value) => {
    fetching = value;
  };

  const contextValues = {
    restaurantGroup,
    fetchingRestaurantGroup,
    restaurantGroupError,
    fetchRestaurantGroup,
    updateRestaurantGroup,
    locations,
    fetchingLocations,
    locationError,
    fetchLocations,
    updateLocation,
    deleteIntegrationForALocation,
    schemas,
    fetchingSchemas,
    schemaError,
    addIntegration,
    updateActiveIntegration,
    mergeIntegrations,
    fetching,
    setFetching,
    newIntegration,
    setNewIntegration,
    activeIntegration,
    setActiveIntegration,
    integrationType,
    setIntegrationType,
    integrationName,
    setIntegrationName,
    locationId,
    setLocationId,
    inputOptions,
    setInputOptions,
  };

  return (
    <TypeManager
      type={activeIntegration?.type}
      name={integrationName}
      locationId={locationId}
      setFetching={setFetching}
      inputOptions={inputOptions}
      setInputOptions={setInputOptions}
      activeIntegration={activeIntegration}
    >
      <IntegrationsContext.Provider value={contextValues}>
        {children}
      </IntegrationsContext.Provider>
    </TypeManager>
  );
};

/**
 * Custom hook for using integrations context
 * @author Htin Linn Aung
 */
const useIntegrationsContext = () => {
  const integrationsContext = useContext(IntegrationsContext);
  if (!integrationsContext) {
    throw new Error(
      "useIntegrationsContext must be used within an IntegrationsProvider.",
    );
  }
  return integrationsContext;
};

export {useIntegrationsContext};
export default {
  Provider,
  IntegrationsContext,
};
