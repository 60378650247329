const { sessionStorage } = window;
const clearAdminSession = () => {
  sessionStorage.setItem(
    "platform",
    JSON.stringify({
      client: "empty",
      platform: "admin",
      restaurantGroupId: "empty",
    }),
  );
};

export default clearAdminSession;
