import DeliveryOrderMinimums from "./DeliveryOrderMinimums";
import PickupOrderMinimums from "./PickupOrderMinimums";
import OrderMinimums from "./OrderMinimums";
import PopoverIconTitle from "./PopoverIconTitle";

export {
  PickupOrderMinimums,
  DeliveryOrderMinimums,
  OrderMinimums,
  PopoverIconTitle,
};
export default {
  PickupOrderMinimums,
  DeliveryOrderMinimums,
  OrderMinimums,
  PopoverIconTitle,
};
