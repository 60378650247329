import { SectionTitle, CardTitle, BlueCardTitle } from "./Title";
import EditableTitle from "./EditableTitle";

export default {
  SectionTitle,
  CardTitle,
  BlueCardTitle,
  EditableTitle,
};
export { SectionTitle, CardTitle, BlueCardTitle, EditableTitle };
