export const hours2OrderTypeNHoursArr = (hours) => [
  {
    orderType: "Pickup",
    open: hours.pickupOpen,
    close: hours.pickupClose,
  },
  {
    orderType: "Delivery",
    open: hours.deliveryOpen,
    close: hours.deliveryClose,
  },
  {
    orderType: "Dine In",
    open: hours.dineInOpen,
    close: hours.dineInClose,
  },
];

export const holidayHours2HoursArray = (hours) => {
  if (hours) {
    return {
      pickupOpen: hours[0].open,
      pickupClose: hours[0].close,
      deliveryOpen: hours[1].open,
      deliveryClose: hours[1].close,
      dineInOpen: hours[2].open,
      dineInClose: hours[2].close,
    };
  }
  return null;
};

export default { hours2OrderTypeNHoursArr, holidayHours2HoursArray };
