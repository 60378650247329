import React from "react";
import {ReactComponent as NoResultsIcon} from "assets/images/no_results_icon.svg";
import styles from "./errorFallback.module.css";

const ErrorFallback = ({title}) => (
  <div className={styles.fallback}>
    <NoResultsIcon className="noResultsIcon" />
    <div className={styles.fallbackText}>
      <span>{title}</span>
    </div>
  </div>
);

export default ErrorFallback;
