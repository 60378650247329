// @ts-nocheck
import React from "react";
import {ResponsiveLine} from "@nivo/line";
import CustomLineTooltip from "./CustomLineTooltip";

const currency = (value) =>
  `$${value.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
const currency1 = (value) => `$${value.toLocaleString()}`;

const unitSymbol = (unit) => {
  switch (unit) {
    case "currency":
      return currency;
    default:
      return (v) => v.toLocaleString();
  }
};
const xAxisUnitSymbol = (unit) => {
  switch (unit) {
    case "currency":
      return currency1;
    default:
      return (v) => v.toLocaleString();
  }
};

const LineGraph = ({data, legendTranslateX = -63, unit}) => {
  return (
    <div style={{height: "300px"}} className="graph">
      <ResponsiveLine
        data={data}
        margin={{
          top: 50,
          right: 50,
          bottom: 75,
          left: 60,
        }}
        yFormat={unitSymbol(unit)}
        axisTop={null}
        axisRight={null}
        curve="monotoneX"
        useMesh
        axisLeft={{
          tickValues: 6,
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "",
          legendOffset: 0,
          format: xAxisUnitSymbol(unit),
        }}
        enableArea
        axisBottom={{
          tickValues: 2,
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 45,
        }}
        offsetType="silhouette"
        colors={["var(--button-blue)", "#43B9F7", "390099"]}
        fillOpacity={1}
        borderColor={{theme: "background"}}
        dotSize={2}
        dotColor={{from: "color"}}
        dotBorderWidth={2}
        dotBorderColor={{from: "color", modifiers: [["darker", 0.7]]}}
        padding={0.3}
        animate
        motionStiffness={90}
        motionDamping={15}
        tooltip={(point) => <CustomLineTooltip {...point} />}
        legends={[
          {
            dataFrom: "keys",
            anchor: "top-left",
            direction: "row",
            justify: false,
            translateX: legendTranslateX,
            translateY: -39,
            itemWidth: 100,
            itemHeight: 10,
            itemsSpacing: 20,
            symbolShape: "circle",
            symbolSize: 15,
            itemDirection: "right-to-left",
          },
        ]}
      />
    </div>
  );
};

LineGraph.defaultProps = {
  data: [],
};
export default LineGraph;
