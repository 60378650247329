import React, { useState, useEffect, useCallback } from "react";
import { Col, Pagination } from "antd";
import { Tab, Tabs, TabList, TabPanel, TabPanels } from "@lunchboxinc/utensils";
import { LoadingSpinner } from "components/v2/Loading/LoadingSpinner/LoadingSpinner";
import { usePrevious, useAPI } from "hooks";
import Uploader from "../Uploader";
import Image from "../Image";
import { Filters as FiltersComp } from "../../Filters";
import { Row, Button } from "../../../elements";
import { NoResults } from "../../../fragments";
import css from "./media.module.css";

type Filters = {
  description: string;
  fileName: string;
  fileType: string;
  tags: string[];
  title: string;
};

interface DefaultQuery {
  filters: Filters;
  skip?: number;
  limit?: number;
}

interface MediaParams {
  imageClass?: string;
  onSelectMedia?: Function;
  onSearchMedia?: Function;
  onPageChange?: Function;
  onPageSizeChange?: Function;
  initialQuery?: DefaultQuery;
}

const DEFAULT_QUERY: DefaultQuery = {
  filters: {
    description: "",
    fileName: "",
    fileType: "",
    tags: [],
    title: "",
  },
  skip: 0,
  limit: 35,
};

const FILTERS = [
  {
    label: "Title",
    fieldName: "filters.title",
    placeholder: "title",
    input: "Input",
  },
  {
    label: "Tags",
    fieldName: "filters.tags",
    placeholder: "Tags",
    input: "Tags",
  },
];

/**
 * Renders Media search UI for filtering and searching a Restaurants uploaded media
 * @param {object} props
 * @param {String} props.imageClass - Optional className to apply to image tag for each image rendered
 * @param {Function} props.onSelectMedia - Callback for if a media result is selected
 * @param {Function} props.onSearchMedia - Callback for if search changes
 * @param {Function} props.onPageChange - Callback for if the page is changed
 * @param {Function} props.onPageSizeChange - Callback for if the number of request results size changes
 * @param {Function} props.onSortChange - Callback for if the sorting of results is changed
 */
function Media({
  imageClass = "",
  onSelectMedia,
  onSearchMedia,
  onPageChange,
  onPageSizeChange,
  initialQuery = DEFAULT_QUERY,
}: MediaParams) {
  const { resource, fetching, data, setData } = useAPI(
    "Media.readMany",
    initialQuery,
  );
  /*
        //@ts-ignore */
  const { results = [], total = 0, limit = 50, skip = 0 } = resource;

  const handleSearchChange = useCallback(
    (data) => {
      if (onSearchMedia) onSearchMedia(data);
      setData(data);
    },
    [onSearchMedia, setData],
  );

  const handlePageChange = (page: any, pageSize: any) => {
    if (onPageChange) onPageChange(page * pageSize - pageSize);
    setData({ ...data, skip: page * pageSize - pageSize });
  };

  const refetch = () => {
    /*
        //@ts-ignore */
    fetch(DEFAULT_QUERY);
    handleSearchChange(DEFAULT_QUERY);
  };

  return (
    <Tabs>
      <TabList>
        <Tab>Search</Tab>
        <Tab>Upload</Tab>
      </TabList>
      <TabPanels>
        <TabPanel className="p-4" key="0">
          <FiltersComp
            updateQuery={setData}
            title="Media"
            filters={FILTERS}
            query={initialQuery}
            defaultValues={DEFAULT_QUERY}
            headerButton={null}
          />

          <LoadingSpinner spinning={fetching}>
            <div className={css.paginationTop}>
              <Pagination
                size="small"
                hideOnSinglePage
                current={(skip + limit) / limit}
                pageSize={limit}
                total={total}
                /*
        //@ts-ignore */
                onChange={handlePageChange}
              />
            </div>
            {results.length ? (
              <div className={css.resultsGrid}>
                {results.map((i: any) => (
                  <Image
                    key={i.id}
                    imageClassName={imageClass}
                    src={i.versions[0].path}
                    onClick={(e) => onSelectMedia && onSelectMedia(i, e)}
                  />
                ))}
              </div>
            ) : (
              <Row type="flex" justify="center">
                <Col>
                  <NoResults />
                </Col>
              </Row>
            )}
            <div className={css.paginationBottom}>
              <Pagination
                size="small"
                hideOnSinglePage
                current={(skip + limit) / limit}
                pageSize={limit}
                total={total}
                onChange={handlePageChange}
              />
            </div>
          </LoadingSpinner>
        </TabPanel>
        <TabPanel className="p-4" key="1">
          <Row>
            <Col>
              {/* /*
        //@ts-ignore */}
              <Uploader onComplete={refetch} />
            </Col>
          </Row>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

export default Media;
export { Media };
