import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import AppBar from "./components/AppBar";
import Sidebar from "./components/Sidebar";
import LocationCard from "./LocationCard";
import { LocationCardProvider } from "./LocationCard/Context";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap');
  html, body {
    background: #FBFBFB!important;
    font-family: Open Sans, sans-serif;
  }
  html {
    font-size: 16px!important;
  }
`;

const stylesNavigation = {
  Components: {
    location: "Location Cards",
    menu: "Menu Items",
  },
};

function AutomationDashboard() {
  return (
    <Router>
      <GlobalStyle />
      <AppBar />
      <div
        style={{
          display: "flex",
          maxWidth: 1280,
          margin: "0 auto",
          paddingTop: "4rem",
        }}
      >
        <Sidebar
          title="Styles"
          caption="Manage style and appearance settings across web and app experiences."
          navigation={stylesNavigation}
        />
        <Switch>
          <Route path="/">
            <LocationCardProvider>
              <LocationCard />
            </LocationCardProvider>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default AutomationDashboard;
