import React, {useState} from "react";
import {Button} from "components/elements";
import {ConfirmationButton} from "components/fragments/ConfirmationButton";
import styles from "../availabilityButtons.module.css";

const LocationAvailabilityButtons = ({selectedIds, onSelection}) => {
  const [fetching, setFetching] = useState(false);

  const onMakeAvailable = () => {
    if (!fetching) {
      onSelection(selectedIds, true);
    }
  };
  const onMakeUnavailable = () => {
    if (!fetching) {
      onSelection(selectedIds, false);
    }
  };

  return (
    <div className={styles.buttonWrapper}>
      <ConfirmationButton
        placement="bottom"
        title="This will affect all selected locations. Are you sure?"
        okText="Yes"
        onConfirm={onMakeAvailable}
      >
        <Button disabled={fetching} color="blue" size="medium">
          Make Available
        </Button>
      </ConfirmationButton>
      &nbsp;
      <ConfirmationButton
        placement="bottom"
        title="This will affect all selected locations. Are you sure?"
        okText="Yes"
        onConfirm={onMakeUnavailable}
      >
        <Button
          disabled={fetching}
          className={styles.unavailableButton}
          color="red"
          size="medium"
        >
          Make Unavailable
        </Button>
      </ConfirmationButton>
    </div>
  );
};

export default LocationAvailabilityButtons;
