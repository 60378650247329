const LOWER_UPPER_LETTER = /[A-Za-z]/;
const NUMBER = /([0-9])/;
const PHONE_NUMBER = /^[0-9 ()+-]{10,}$/i;
const SPECIAL_CHARACTER = /([#?!$%^&*-])/;

const VALIDATION_ARRAY = [LOWER_UPPER_LETTER, NUMBER, SPECIAL_CHARACTER];
const PASSWORD_VALIDATION =
  /^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[!?@#\$%\^&\*])(?=.{8,})/;

export {
  LOWER_UPPER_LETTER,
  NUMBER,
  PASSWORD_VALIDATION,
  PHONE_NUMBER,
  SPECIAL_CHARACTER,
  VALIDATION_ARRAY,
};
