import * as React from "react";
import {T} from "components/elements";

const TwoLineAddress = ({street1, street2, city, state, zip}) => (
  <>
    <T>
      {" "}
      {street1} {street2}
    </T>
    <T>
      {" "}
      {city} {state} {zip}
    </T>
  </>
);

export default TwoLineAddress;
