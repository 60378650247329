import * as Yup from "yup";

const LOYALTY_ACTIONS = {
  CREDIT: "credit",
  POINTS: "points",
  REWARD: "reward",
  WALLET: "wallet",
};

const LOYALTY_EVENTS = {
  BIRTHDAY: "birthday",
  ORDER: "order",
  POINTS: "points",
  REFERRAL: "referral",
  SIGNIN: "signin",
  SIGNUP: "signup",
};

const LOYALTY_SYSTEMS = {
  BANK: "bank",
  CREDIT: "credit",
  DEAL: "deal",
  TIER: "tier",
  VISIT: "visit",
  WALLET: "wallet",
};

const REWARDS_CANNOT_BE_EMPTY = "Rewards section cannot be empty";
const TYPE_MUST_BE_ONE_OF =
  "Type must be one of these values: Credit, Points or Reward";
const ON_WHICH_AMOUNT_MUST_BE_ONE_OF =
  "On which amount must be one of these values: Final Amount";

const baseNestedSchema = {
  conversionType: Yup.mixed()
    .nullable()
    .oneOf(["points-credit", null])
    .default(null),
  isConversion: Yup.boolean().required().default(false),
  isDeal: Yup.boolean().required().default(false),
  isTiered: Yup.boolean().required().default(true),
  loyaltySystem: Yup.array()
    .of(Yup.string().oneOf(Object.values(LOYALTY_SYSTEMS)))
    .min(1)
    .required(),
  name: Yup.string().trim().required(),
  restaurantGroup: Yup.string().trim(),
};

const BirthdayLoyaltyTriggerSchema = Yup.object().shape({
  ...baseNestedSchema,
  actions: Yup.array()
    .of(
      Yup.object().shape({
        type: Yup.string()
          .oneOf(Object.values(LOYALTY_ACTIONS), TYPE_MUST_BE_ONE_OF)
          .required(TYPE_MUST_BE_ONE_OF),
      }),
    )
    .min(1, REWARDS_CANNOT_BE_EMPTY)
    .required(REWARDS_CANNOT_BE_EMPTY),
  criteria: Yup.object()
    .shape({
      event: Yup.string()
        .oneOf(Object.values(LOYALTY_EVENTS))
        .required()
        .default(LOYALTY_EVENTS.BIRTHDAY),
      points: Yup.object()
        .shape({
          max: Yup.number().min(0).required().default(0),
          min: Yup.number().min(0).required().default(0),
          threshold: Yup.number().min(0).required().default(0),
        })
        .required(),
      time: Yup.object()
        .shape({
          max: Yup.number().min(0).required().default(0),
          min: Yup.number().min(0).required().default(0),
        })
        .required(),
      custom: Yup.object().nullable().default(null),
    })
    .required(),
});

const MultiplePointsDayLoyaltyTriggerSchema = Yup.object().shape({
  ...baseNestedSchema,
  actions: Yup.array()
    .of(
      Yup.object().shape({
        type: Yup.string()
          .oneOf(Object.values(LOYALTY_ACTIONS), TYPE_MUST_BE_ONE_OF)
          .required(TYPE_MUST_BE_ONE_OF),
        data: Yup.object().shape({
          multiplier: Yup.number().min(1).required().default(1),
        }),
      }),
    )
    .min(1, REWARDS_CANNOT_BE_EMPTY)
    .required(REWARDS_CANNOT_BE_EMPTY),
  criteria: Yup.object()
    .shape({
      points: Yup.object()
        .shape({
          max: Yup.number().min(0).required().default(0),
          min: Yup.number().min(0).required().default(0),
          threshold: Yup.number().min(0).required().default(0),
        })
        .required(),
      time: Yup.object()
        .shape({
          dayOfWeek: Yup.array().default([]),
          dayOfYear: Yup.array().default([]),
          max: Yup.number().min(0).required().default(0),
          min: Yup.number().min(0).required().default(0),
        })
        .required(),
      custom: Yup.object().nullable().default(null),
    })
    .required(),
});

const ReferralLoyaltyTriggerSchema = Yup.object().shape({
  ...baseNestedSchema,
  actions: Yup.array()
    .of(
      Yup.object().shape({
        type: Yup.string()
          .oneOf(Object.values(LOYALTY_ACTIONS), TYPE_MUST_BE_ONE_OF)
          .required(TYPE_MUST_BE_ONE_OF),
        data: Yup.object().shape({
          isReferredPatron: Yup.boolean().default(true),
        }),
      }),
    )
    .min(1, REWARDS_CANNOT_BE_EMPTY)
    .required(REWARDS_CANNOT_BE_EMPTY),
  criteria: Yup.object()
    .shape({
      event: Yup.string()
        .oneOf(Object.values(LOYALTY_EVENTS))
        .required()
        .default(LOYALTY_EVENTS.REFERRAL),
      points: Yup.object()
        .shape({
          max: Yup.number().min(0).required().default(0),
          min: Yup.number().min(0).required().default(0),
          threshold: Yup.number().min(0).required().default(0),
        })
        .required(),
      time: Yup.object()
        .shape({
          max: Yup.number().min(0).required().default(0),
          min: Yup.number().min(0).required().default(0),
        })
        .required(),
      custom: Yup.object().nullable().default(null),
    })
    .required(),
});

const SignInSignUpLoyaltyTriggerSchema = Yup.object().shape({
  ...baseNestedSchema,
  actions: Yup.array()
    .of(
      Yup.object().shape({
        type: Yup.string()
          .oneOf(Object.values(LOYALTY_ACTIONS), TYPE_MUST_BE_ONE_OF)
          .required(TYPE_MUST_BE_ONE_OF),
        _id: Yup.string().trim(),
      }),
    )
    .min(1, REWARDS_CANNOT_BE_EMPTY)
    .required(REWARDS_CANNOT_BE_EMPTY),
  criteria: Yup.object()
    .shape({
      event: Yup.string()
        .oneOf(Object.values(LOYALTY_EVENTS))
        .required()
        .default(LOYALTY_EVENTS.BIRTHDAY),
      points: Yup.object()
        .shape({
          max: Yup.number().min(0).required().default(0),
          min: Yup.number().min(0).required().default(0),
          threshold: Yup.number().min(0).required().default(0),
        })
        .required(),
      time: Yup.object()
        .shape({
          max: Yup.number().min(0).required().default(0),
          min: Yup.number().min(0).required().default(0),
        })
        .required(),
      custom: Yup.object().nullable().default(null),
    })
    .required(),
});

export default {
  BirthdayLoyaltyTriggerSchema,
  MultiplePointsDayLoyaltyTriggerSchema,
  ReferralLoyaltyTriggerSchema,
  SignInSignUpLoyaltyTriggerSchema,
};
