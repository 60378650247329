import React from "react";
import {List, Col} from "antd";
import {Row} from "components/elements";
import Icons from "../Icons";
import OrderItem from "./OrderItem";
import styles from "./orderItems.module.css";

const OrderItems = ({items}) => (
  <Row spacing={45} gutter={15}>
    <Col xs={24} sm={24}>
      <Row spacing={15} className={styles.orderItemsHeader}>
        <Col xs={10} className={styles.orderItemsHeaderText}>
          <h3>
            <Icons.IconText color="white" type="shopping-cart" text="Items" />
          </h3>
        </Col>
      </Row>
      <List itemLayout="vertical" dataSource={items} renderItem={OrderItem} />
    </Col>
  </Row>
);

export default OrderItems;
