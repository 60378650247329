import { Reducer, useReducer } from "react";

type State<T> = {
  error: Error | null;
  fetching: boolean;
  state: null | T;
};

type Action<T> = { type: "update"; payload: Partial<State<T>> };

const initializeState = <T>(initState = {}): State<T> => {
  return {
    error: null,
    fetching: true,
    state: null,
    ...initState,
  };
};

const reducer = <T>(state: State<T>, action: Action<T>) => {
  switch (action.type) {
    case "update":
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

const useAsyncState = <T>(initState: Partial<State<T>> = {}) => {
  const [state, dispatch] = useReducer<Reducer<State<T>, Action<T>>>(
    reducer,
    initializeState(initState),
  );

  const updater = (nextState: Partial<State<T>>) => {
    dispatch({
      payload: nextState,
      type: "update",
    });
  };
  const toggleFetching = () => {
    updater({ fetching: !state.fetching });
  };
  const setIsFetching = () => {
    updater({ fetching: true });
  };
  const setNotFetching = () => {
    updater({ fetching: false });
  };

  const setError = (error: Error) => {
    updater({ error });
  };
  const clearError = () => {
    updater({ error: null });
  };

  const setState = (nextState: T) => {
    updater({ state: nextState });
  };
  const clearState = () => {
    updater({ state: null });
  };

  const reset = () => {
    updater(initializeState(initState));
  };

  return [
    state,
    {
      clearError,
      clearState,
      reset,
      setError,
      setIsFetching,
      setNotFetching,
      setState,
      toggleFetching,
    },
  ];
};

export default useAsyncState;
