import React, {useRef} from "react";
import PropTypes from "prop-types";
import {Icon} from "antd";
import {Draggable} from "react-beautiful-dnd";
import {T, ImagePreviewModal} from "../../elements";
import {ConfirmationButton} from "../ConfirmationButton";
import css from "./carouselImage.module.css";

const CarouselImage = ({src, index, moveImage, onClickDelete}) => {
  return (
    <Draggable
      key={`carouselImage${index}`}
      draggableId={`carouselImage${index}`}
      index={index}
      droppableId={`carouselImage${index}`}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div
            className={
              css.container
            } /* style={{opacity: isDragging ? 0 : 1}} */
          >
            <span>
              <div className={css.item}>
                <div className={css.itemInfo}>
                  <span>
                    <a
                      className={css.itemThumbnail}
                      href={src}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={src} alt="" className={css.itemImage} />
                    </a>
                  </span>
                </div>
                <span className={css.itemActions}>
                  <ImagePreviewModal src={src} />
                  &nbsp; &nbsp;
                  {onClickDelete && (
                    <ConfirmationButton
                      title="Are you sure you want to delete this?"
                      onConfirm={onClickDelete}
                    >
                      <Icon
                        type="delete"
                        theme="filled"
                        className={css.iconColor}
                      />
                    </ConfirmationButton>
                  )}
                </span>
              </div>
            </span>
          </div>
          <div>
            <T className={css.positionLabel} strong>
              {index + 1}
            </T>
          </div>
        </div>
      )}
    </Draggable>
  );
};

CarouselImage.propTypes = {
  onClickDelete: PropTypes.func,
  src: PropTypes.string.isRequired,
};
CarouselImage.defaultProps = {
  onClickDelete: null,
};

export default CarouselImage;
