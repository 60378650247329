import React from "react";
import {withScope, captureException} from "@sentry/browser";
import ErrorFallback from "../../fragments/ErrorFallback";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  componentDidCatch(error, info) {
    this.setState({hasError: true});

    withScope((scope) => {
      scope.setExtras(info);
      captureException(error);
    });
  }

  render() {
    const {children, render} = this.props;
    const {hasError} = this.state;
    if (hasError) {
      if (render) {
        return render();
      }
      return <ErrorFallback title="We're sorry, something went wrong." />;
    }
    return children;
  }
}

export default ErrorBoundary;
