import React, {useState} from "react";
import moment from "moment";
import {DatePicker} from "antd";
import {T, Row} from "components/elements";
import styles from "./field.module.scss";

const DateRangeField = ({
  startLabel,
  endLabel,
  onStartChange,
  onEndChange,
  initialStartDate,
  initialEndDate,
  errors,
  startFieldStyle,
  endFieldStyle,
}) => {
  const [startDate, setStartDate] = useState(initialStartDate);
  const [startOpen, setStartOpen] = useState();
  const [endDate, setEndDate] = useState(initialEndDate);
  const [endOpen, setEndOpen] = useState();

  const disabledEndDate = (endValue) => {
    if (!endValue || !startDate) {
      return moment().add(-1, "days") >= endValue;
    }
    return (
      endValue.startOf("day").valueOf() < startDate.startOf("day").valueOf()
    );
  };

  const disabledStartDate = (startValue) =>
    startValue.startOf("day").valueOf() < moment().startOf("day").valueOf();

  const disablePastTime = (date) => {
    const hoursArr = [];
    for (let i = 0; i < 24; i++) {
      hoursArr.push(i);
    }
    const minutesArr = [];
    for (let i = 0; i < 60; i++) {
      minutesArr.push(i);
    }

    if (date.format("L") === moment().format("L")) {
      return {
        disabledHours: () => hoursArr.filter((num) => num < moment().hour()),
        disabledMinutes: (currentHour) => {
          if (String(currentHour) === moment().hour()) {
            return minutesArr.filter((num) => num < moment().format("mm"));
          }
          return [];
        },
      };
    }
  };

  const handleStartOpenChange = (open) => {
    setStartOpen(open);
    if (!open) {
      setEndOpen(true);
    } else {
      setEndOpen(false);
      setEndDate(null);
    }
  };

  const handleStartChange = (date) => {
    onStartChange(date);
    if (date) {
      setStartDate(date.startOf("day"));
    }
  };

  const handleEndChange = (date) => {
    onEndChange(date);
    if (date) {
      setEndDate(date.endOf("day"));
    }
  };

  return (
    <>
      <div className={styles.startFieldWrapper}>
        {startLabel && (
          <Row spacing={10}>
            <T>{startLabel}</T>
          </Row>
        )}
        <DatePicker
          disabledDate={disabledStartDate}
          disabledTime={(date) => disablePastTime(date)}
          format="M/D/YY"
          value={startDate}
          placeholder="Start"
          onChange={handleStartChange}
          allowClear={false}
          open={startOpen}
          onOpenChange={handleStartOpenChange}
          style={startFieldStyle}
        />
        {errors && errors.startDate && (
          <div className={styles.errorText}>Start Date is required</div>
        )}
      </div>
      <div className={styles.endFieldWrapper}>
        {endLabel && (
          <Row spacing={10}>
            <T>{endLabel}</T>
          </Row>
        )}
        <DatePicker
          disabledDate={disabledEndDate}
          disabledTime={disablePastTime}
          format="M/D/YY"
          value={endDate}
          placeholder="End"
          onChange={handleEndChange}
          allowClear={false}
          open={endOpen}
          onOpenChange={setEndOpen}
          style={endFieldStyle}
        />
        {errors && errors.endDate && (
          <div className={styles.errorText}>End Date is required</div>
        )}
      </div>
    </>
  );
};

export default DateRangeField;
