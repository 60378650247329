/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { Icon, Layout, Menu, Typography } from "antd";
import React, { useState, useEffect } from "react";
import { pagePermissions, permissions, strings } from "common/constants";
import { useUserContext } from "components/providers/User";
import { useAPIContext } from "components/providers/Api";

import { CustomNavIcon } from "components/fragments/Icons/CustomNavIcon";
import { Logo } from "components/elements";
import ReactDOM from "react-dom";
import { useWindowSize, useSuperAdminCheck } from "hooks";
import NavKeySearch from "./NavKeySearch";
import NavItem from "./NavItem";
import NavSubMenu from "./NavSubMenu";

const {
  methods: { GET, PUT, POST },
  routes: {
    ADMINS_ITEM,
    ADMINS_LIST,
    ANALYTICS_MENU,
    ANALYTICS_SALES,
    APP_HOME_CONTENT,
    CAMPAIGNER,
    CATERING_GROUPS_LIST,
    CATERING_LIST,
    CRON_ACTIONS,
    DINING_HOURS_LIST,
    HOLIDAY_HOURS_LIST,
    DISCOUNTS_LIST,
    LOYALTY_TRIGGER,
    LOCATIONS_LIST,
    MEDIA_LIST,
    EXPORTS_LIST,
    MENU_GROUPS_LIST,
    MENU_ITEMS_LIST,
    MENU_OPTIONS_LIST,
    MENUS_LIST,
    ORDERS_LIST,
    PATRONS_LIST,
    PUSH_NOTIFICATIONS_LIST,
    RESTAURANT_GROUPS_ITEM,
    PAYMENTS,
    RESTAURANT_GROUPS_LIST,
    SERVICES,
    STORED_VALUE_CARDS_LIST,
    STORED_VALUE_CARDS_REPORTS,
    THEMES,
    UPSELLS_LIST,
    GHOST_KITCHEN_LIST,
    MARKETPLACE_ORDERS_LIST,
    SUBSCRIPTION_LIST,
    SUBSCRIPTION_ANALYTICS,
    TAXES_ITEMS_LIST,
    TAXES_LOCATIONS_LIST,
    SERVICE_CHARGES_LIST,
    UNBOXED,
  },
} = permissions;
const {
  emailTemplates,
  analyticsOverview,
  salesReports,
  pincode,
  role,
  payment,
  marketplaces,
} = pagePermissions;
const { Sider } = Layout;
const { Text } = Typography;
const { Item } = Menu;

const withPlatform = (Component, mode) => {
  return (props) => {
    const { platform } = useAPIContext();
    if (platform !== mode) {
      return null;
    }
    return <Component {...props} />;
  };
};

// These variables (AdminNav || AdminSubMenu || MarketplaceNav) act like feature flags for Nav:
// If you want a nav item to show for both RG and Marketplace you can use the (NavItem || NavSubMenu) components.

const AdminNav = withPlatform(NavItem, "admin");
const AdminSubMenu = withPlatform(NavSubMenu, "admin");
const MarketplaceNav = withPlatform(NavItem, "marketplace");

const identifyCanny = (user, platform) => {
  if (window.Canny && process.env.REACT_APP_ENV === strings.ENV.PRODUCTION) {
    const companyArray =
      platform === strings.PLATFORM_TYPE.ADMIN
        ? user?.restaurantGroups
        : user?.marketplaces;
    window.Canny("identify", {
      appID: "60007123e9503a456c14389a",
      user: {
        email: user?.email?.value,
        name: `${user.firstName} ${user.lastName}`,
        id: user.id,
        companies: companyArray.map((i) => ({
          id: i.id,
          name: i.name,
        })),
      },
    });
  }
};

const Nav = ({ onLogout, location }) => {
  const [collapsed, setCollapsed] = useState(false);
  const { breakpoint } = useWindowSize();
  const ref = React.useRef();
  const isSuperAdmin = useSuperAdminCheck();
  const { platform, user } = useUserContext();
  const onCannyClick = () => identifyCanny(user, platform);

  const handleClick = (e) => {
    const node = ReactDOM.findDOMNode(ref.current);
    if (!node.contains(e.target)) {
      setCollapsed(true);
    }
  };

  useEffect(() => {
    if (!collapsed && ["xs", "sm"].includes(breakpoint.name)) {
      setCollapsed(true);
    }
  }, [location]);

  useEffect(() => {
    if (!collapsed && ["xs", "sm"].includes(breakpoint.name)) {
      document.addEventListener("click", handleClick);
    } else {
      document.removeEventListener("click", handleClick);
    }
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [collapsed, breakpoint.name]);

  return (
    <Sider
      width={225}
      ref={ref}
      theme="light"
      collapsed={collapsed}
      onCollapse={(collapse) => setCollapsed(collapse)}
      collapsedWidth="0"
      breakpoint="md"
    >
      <Logo />

      <Menu mode="inline" selectedKeys={[NavKeySearch(location.pathname)]}>
        <NavItem
          requiredPermissions={UNBOXED}
          key="unboxed"
          link={`https://admin.${process.env.REACT_APP_DOMAIN}/unboxed`}
        >
          <Icon type="gift"></Icon>
          Unboxed
        </NavItem>

        <NavItem
          requiredPermissions={salesReports.permissions}
          key="sales-reports-analytics"
          to="/analytics/sales-reports"
          type="dollar"
        >
          Sales Reports
        </NavItem>

        <NavItem
          requiredPermissions={
            platform === strings.PLATFORM_TYPE.ADMIN
              ? ORDERS_LIST
              : MARKETPLACE_ORDERS_LIST
          }
          key="order"
          to="/orders"
        >
          <CustomNavIcon text="Search For Orders" type="orders" />
        </NavItem>

        <AdminSubMenu
          requiredPermissions={[
            MENUS_LIST,
            MENU_GROUPS_LIST,
            MENU_ITEMS_LIST,
            MENU_OPTIONS_LIST,
          ]}
          matchOne
          key="/menus"
          title={
            <span>
              <Icon type="database" />
              <span>Menu Entities</span>
            </span>
          }
        >
          <NavItem requiredPermissions={MENUS_LIST} key="menu" to="/menus">
            Menus
          </NavItem>

          <NavItem
            requiredPermissions={MENU_GROUPS_LIST}
            key="group"
            to="/groups"
          >
            Groups
          </NavItem>

          <NavItem requiredPermissions={MENU_ITEMS_LIST} key="item" to="/items">
            Items
          </NavItem>

          <NavItem
            requiredPermissions={MENU_OPTIONS_LIST}
            key="option"
            to="/options"
          >
            Options
          </NavItem>
        </AdminSubMenu>

        <NavItem
          requiredPermissions={[
            { method: GET, url: TAXES_ITEMS_LIST },
            { method: GET, url: TAXES_LOCATIONS_LIST },
          ]}
          key="taxes"
          to="/taxes"
          type="plus-circle"
        >
          Taxes
        </NavItem>

        <NavSubMenu
          requiredPermissions={[
            STORED_VALUE_CARDS_LIST,
            STORED_VALUE_CARDS_REPORTS,
          ]}
          matchOne
          key="gift-card"
          title={
            <span>
              <Icon type="gift" />
              <span>Gift Cards</span>
            </span>
          }
        >
          <NavItem
            requiredPermissions={STORED_VALUE_CARDS_LIST}
            key="gift-card"
            to="/gift-cards"
          >
            Gift Cards
          </NavItem>

          <NavItem
            requiredPermissions={STORED_VALUE_CARDS_REPORTS}
            key="gift-card-reports"
            to="/analyze/gift-card-reports"
          >
            Gift Card Reports
          </NavItem>
        </NavSubMenu>

        <NavItem
          requiredPermissions={EXPORTS_LIST}
          key="exports"
          to="/exports"
          type="form"
        >
          Exports
        </NavItem>

        <AdminNav
          requiredPermissions={UPSELLS_LIST}
          key="upsell"
          to="/upsells"
          type="arrow-up"
        >
          Upsells
        </AdminNav>

        <AdminNav
          requiredPermissions={DINING_HOURS_LIST}
          key="dining-hour"
          to="/dining-hours"
          type="clock-circle"
        >
          Dining Hours
        </AdminNav>

        <AdminNav
          requiredPermissions={HOLIDAY_HOURS_LIST}
          key="holiday-hour"
          to="/holiday-hours"
          type="clock-circle"
        >
          Holiday Hours
        </AdminNav>

        <NavItem
          requiredPermissions={{ method: GET, url: LOYALTY_TRIGGER }}
          key="loyalty"
          to="/loyalty-triggers"
        >
          <CustomNavIcon text="Loyalty" type="orders" />
        </NavItem>

        <NavItem
          requiredPermissions={DISCOUNTS_LIST}
          key="discount"
          to="/discounts"
          type="barcode"
        >
          Discounts
        </NavItem>

        <NavItem
          requiredPermissions={SERVICE_CHARGES_LIST}
          key="service-charges"
          to="/service-charges"
          type="dollar"
        >
          Service Charges
        </NavItem>

        <AdminNav
          requiredPermissions={LOCATIONS_LIST}
          key="location"
          to="/locations"
          type="environment"
        >
          Locations
        </AdminNav>

        <MarketplaceNav
          requiredPermissions={GHOST_KITCHEN_LIST}
          key="kitchen"
          to="/kitchens"
          type="environment"
        >
          Kitchens
        </MarketplaceNav>

        <AdminSubMenu
          requiredPermissions={[CATERING_LIST, CATERING_GROUPS_LIST]}
          matchOne
          key="/catering"
          title={
            <span>
              <CustomNavIcon text="Catering" type="catering" />
            </span>
          }
        >
          <NavItem
            requiredPermissions={CATERING_LIST}
            key="catering"
            to="/catering"
          >
            Orders
          </NavItem>

          <NavItem
            requiredPermissions={CATERING_GROUPS_LIST}
            key="catering-group"
            to="/catering-groups"
          >
            Groups
          </NavItem>
        </AdminSubMenu>

        <NavItem
          requiredPermissions={PATRONS_LIST}
          key="patron"
          to="/patrons"
          type="team"
        >
          Guests
        </NavItem>

        <AdminSubMenu
          requiredPermissions={[SUBSCRIPTION_LIST]}
          matchOne
          key="/subscriptions"
          title={
            <span>
              <Icon type="redo" />
              <span>Subscriptions</span>
            </span>
          }
        >
          <NavItem
            requiredPermissions={SUBSCRIPTION_LIST}
            key="subscriptions"
            to="/subscriptions"
          >
            Schedule
          </NavItem>
          <NavItem
            requiredPermissions={SUBSCRIPTION_LIST}
            key="subscribers"
            to="/subscribers"
          >
            Subscribers
          </NavItem>
          <NavItem
            requiredPermissions={SUBSCRIPTION_ANALYTICS}
            key="subscription-reports"
            to="/subscription-reports"
          >
            Reports
          </NavItem>
          <NavItem
            requiredPermissions={SUBSCRIPTION_ANALYTICS}
            key="subscription-daily-reports"
            to="/subscription-daily-reports"
          >
            Daily Summary
          </NavItem>
        </AdminSubMenu>

        <NavItem requiredPermissions={ADMINS_LIST} key="admin" to="/admins">
          <CustomNavIcon type="admins" text="Admins" />
        </NavItem>

        <AdminNav
          requiredPermissions={{ method: POST, url: RESTAURANT_GROUPS_LIST }}
          key="restaurant-group"
          to="/restaurant-group"
          type="tool"
        >
          Restaurant Groups
        </AdminNav>

        <NavItem
          requiredPermissions={[...marketplaces.permissions]}
          key="marketplaces"
          to="/marketplaces"
          type="tool"
        >
          Marketplaces
        </NavItem>

        <NavSubMenu
          requiredPermissions={[
            ...analyticsOverview.permissions,
            ANALYTICS_SALES,
            ANALYTICS_MENU,
          ]}
          matchOne
          title={
            <span>
              <Icon type="rise" />
              <span>Analytics</span>
            </span>
          }
        >
          <NavItem
            requiredPermissions={analyticsOverview.permissions}
            key="overview-analytics"
            to="/analyze/overview"
          >
            Overview
          </NavItem>

          <NavItem
            requiredPermissions={ANALYTICS_SALES}
            key="sales-analytics"
            to="/analyze/sales"
          >
            <span className="nav-text">Sales</span>
          </NavItem>

          <NavItem
            requiredPermissions={ANALYTICS_MENU}
            key="item-analytics"
            to="/analyze/item"
          >
            <span className="nav-text">Items</span>
          </NavItem>
        </NavSubMenu>

        <NavSubMenu
          requiredPermissions={[CAMPAIGNER, PUSH_NOTIFICATIONS_LIST]}
          matchOne
          key="marketing"
          title={
            <span>
              <Icon type="mail" />
              <span>Marketing</span>
            </span>
          }
        >
          <NavItem
            requiredPermissions={CAMPAIGNER}
            link="https://fly.customer.io"
          >
            Email Marketing
          </NavItem>

          <NavItem
            requiredPermissions={PUSH_NOTIFICATIONS_LIST}
            key="push-notifications"
            to="/push-notifications"
          >
            Push Notifications
          </NavItem>

          <NavItem
            requiredPermissions={APP_HOME_CONTENT}
            key="app-home-content"
            to="/app-home-content"
          >
            Home Content
          </NavItem>
        </NavSubMenu>

        <AdminNav
          requiredPermissions={MEDIA_LIST}
          key="media"
          to="/media"
          type="picture"
        >
          Media
        </AdminNav>

        {isSuperAdmin && (
          <AdminSubMenu
            key="/app-configuration"
            title={
              <span>
                <Icon type="bg-colors" />
                <span>App Configs</span>
              </span>
            }
          >
            {/* Need to add page-specific permission for this */}
            <NavItem requiredPermissions={THEMES} key="themes" to="/themes">
              <span className="nav-text">Themes</span>
            </NavItem>

            <NavItem key="/app-configuration" to="/app-configuration/web">
              Configurations
            </NavItem>
          </AdminSubMenu>
        )}

        <NavItem key="print" to="/print">
          <Icon type="printer" />
          <span className="nav-text">Print</span>
        </NavItem>

        <NavSubMenu
          requiredPermissions={[
            { method: GET, url: SERVICES },
            { method: GET, url: PAYMENTS },
            { method: PUT, url: RESTAURANT_GROUPS_ITEM },
            ...payment.permissions,
            ...role.permissions,
            ...pincode.permissions,
            ...emailTemplates.permissions,
          ]}
          matchOne
          key="settings"
          title={
            <span>
              <Icon type="setting" />
              <span className="nav-text">Settings</span>
            </span>
          }
        >
          <NavItem
            requiredPermissions={SERVICES}
            key="integrations-editor"
            to="/integrations-editor"
          >
            <span className="nav-text">Integrations</span>
          </NavItem>
          <NavItem
            requiredPermissions={payment.permissions}
            key="payment"
            to="/payments"
          >
            <span className="nav-text">Payments</span>
          </NavItem>
          <NavItem
            requiredPermissions={{ method: PUT, url: RESTAURANT_GROUPS_ITEM }}
            key="settings"
            to="/settings"
          >
            <span className="nav-text">Settings</span>
          </NavItem>
          <NavItem
            requiredPermissions={role.permissions}
            key="role"
            to="/roles"
          >
            <span className="nav-text">Roles</span>
          </NavItem>
          <NavItem
            requiredPermissions={emailTemplates.permissions}
            key="email-templates"
            to="/email-templates"
          >
            <span className="nav-text">Email Templates</span>
          </NavItem>
          <NavItem
            requiredPermissions={pincode.permissions}
            key="pincode"
            to="/pincodes"
          >
            <span className="nav-text">Pincodes</span>
          </NavItem>
          <NavItem
            requiredPermissions={CRON_ACTIONS}
            key="cron-actions"
            to="/cron-actions"
          >
            <span className="nav-text">Cron Actions</span>
          </NavItem>
          <NavItem key="dns-setup" to="/dns-setup">
            <span className="nav-text">DNS Setup</span>
          </NavItem>
        </NavSubMenu>

        <NavItem
          key="profile"
          to="/profile"
          type="user"
          requiredPermissions={ADMINS_ITEM}
        >
          <span className="nav-text">Profile</span>
        </NavItem>

        <NavSubMenu
          key="/features"
          title={
            <span>
              <Icon type="redo" />
              <span>Features</span>
            </span>
          }
        >
          <NavItem
            key="/roadmap"
            onClickHelper={onCannyClick}
            link="https://features.lunchbox.io/"
          >
            Roadmap
          </NavItem>
          <NavItem
            key="/feature-requests"
            onClickHelper={onCannyClick}
            link="https://features.lunchbox.io/feature-requests"
          >
            Request
          </NavItem>
        </NavSubMenu>

        <Item key="logout">
          <PseudoLink onClick={onLogout}>
            <Icon type="logout" />
            <span className="nav-text">Logout</span>
          </PseudoLink>
        </Item>

        <Item>
          <Text code>{`Version: ${process.env.REACT_APP_VERSION}`}</Text>
        </Item>
        {process.env.NODE_ENV !== "production" && (
          <Item>
            <Text code>
              {`Env: ${
                process.env.NODE_ENV.length > 5
                  ? process.env.NODE_ENV.substring(0, 3)
                  : process.env.NODE_ENV
              }`}
            </Text>
          </Item>
        )}
      </Menu>
    </Sider>
  );
};

const PseudoLink = (props) => <a href="#" {...props} />;

export default Nav;
