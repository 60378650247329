import React from "react";
import { Tooltip } from "@lunchboxinc/utensils";
import styles from "./columnListPopover.module.css";

const ColumnListPopover = ({ list, title }) => {
  // If the list has no items, display nothing
  if (!list.length) return null;
  // If the list has only one item, display just that item
  if (list.length === 1) return list.map((i) => i.name).join(" ");
  // If the list has more than one item, display in a popover

  // BUG: list is expected to be array of objects, but it's array of ids instead on certain restaurant groups (dev env, 16 handles)
  return (
    <Tooltip
      popupLabel={
        <div className={styles.popoverContent}>
          {list.map((i) => (
            <div key={i.id}>{i.name}</div>
          ))}
        </div>
      }
    >
      <span className={styles.itemLink}>{title}</span>
    </Tooltip>
  );
};

export default ColumnListPopover;
