import React from "react";
import {motion} from "framer-motion";

/**
 * Mounting animation wrapper component
 * @author Htin Linn Aung
 * @memberof Elements.Elements/RouteTransition
 * @prop {JSX.Element | JSX.Element[]} props.children - Children components
 * @prop {Object} props.exit - Animation exit values - object with value for opacity, x and y
 * @prop {Object} props.inital - Animation inital values - Object with value for opacity, x and y
 * @prop {Object} props.animate - Animate values - object with value for opacity, x and y
 * @returns {React.Element} - Mounting animation wrapper component
 */
const MountTransition = ({children, exit, initial, animate}) => (
  <motion.div exit={exit} initial={initial} animate={animate}>
    {children}
  </motion.div>
);

export default MountTransition;
