const TYPE = {
  NUMBER: "number",
};

const TEMPLATE = {
  DEFAULTS: "defaults",
};

const ORDER_TYPE = {
  PICKUP: "pickup",
  DELIVERY: "delivery",
  DINEIN: "dinein",
  KIOSK: "kiosk",
  CATERING: "catering",
  CATERINGDELIVERY: "cateringDelivery",
};

const DATE_RANGE = {
  INCREMENT: "increment",
  QUARTERS: "quarters",
};

const GIFTCARD_TYPE = {
  PRELOAD: "preload",
};

const PLATFORM_TYPE = {
  ADMIN: "admin",
  STANDARD: "Standard",
  COMBINED: "Combined",
  MARKETPLACE: "marketplace",
};

const ENV = {
  PRODUCTION: "production",
};

const STATUS = {
  OPEN: "open",
  SCHEDULED: "scheduled",
  DECLINED: "declined",
  ACCEPTED: "accepted",
  EMPTY: "empty",
};

const MENUSYNCSTATUS = {
  COMPLETED: "SYNC_COMPLETE",
};
const AVAILABILITY = {
  AVAILABLE: "AVAILABLE",
  UNAVAILABLE: "UNAVAILABLE",
};

const ENTITY_TYPE = {
  MENU: "menu",
  GROUP: "group",
  SUBGROUP: "subgroup",
  ITEM: "item",
};

const PAYMENT_STATUS = {
  VOIDED: "voided",
  ERRORED: "errored",
};

const LOYALTY = {
  VIG: "V.I.G",
  TIER: "Tier",
};

const ERROR = {
  VALIDATIONERROR: "ValidationError",
  DATERANGEERROR: "Date range must be selected",
};
const CLEANJUICE = "cleanjuice";

const EXPLOID = {
  FutureOrders: {
    restaurantGroup: "V4Arp27xmD",
    marketplace: "V4Arp27xmD",
  },
  SalesOverview: {
    restaurantGroup: "ld2AQGmxb5",
    marketplace: "Eb910gWxWQ",
  },
  SalesComparison: {
    restaurantGroup: "0eVx8Lpxpn",
    marketplace: "0eVx8Lpxpn",
  },
  CartSize: {
    restaurantGroup: "qP8xKWXxoZ",
    marketplace: "QjDxeQPxLG",
  },
  ProductMixDashboard: {
    restaurantGroup: "aovARjbAlV",
    marketplace: "7BKxgQeALR",
  },
  ProductMixReport: {
    restaurantGroup: "eaBAM7dxkv",
    marketplace: "nb81JN21ag",
  },
  ModifiersReport: {
    restaurantGroup: "n08YWN9AW4",
    marketplace: "qP8xK3KxoZ",
  },
  GuestMetricsReport: {
    restaurantGroup: "qP8xKezAoZ",
    marketplace: "qP8xKDb1oZ",
  },
  LocationLoyaltyReport: {
    restaurantGroup: "Kjv1OjZxzp",
    marketplace: "QjDxeDXYLG",
  },
  GuestSegmentationDashboard: {
    restaurantGroup: "oE3AaERAOq",
    marketplace: "n08YWDdxW4",
  },
  CohortAnalysisDashBoard: {
    restaurantGroup: "eaBAM831kv",
    marketplace: "5zk1ND2YgX",
  },
  PromotionDashBoard: {
    restaurantGroup: "G2k1LJ6YyJ",
    marketplace: "2bo1qDgxzw",
  },
  KpiDashboard: {
    restaurantGroup: "oE3AaO8xOq",
    marketplace: "",
  },
  YearOverYearDashboard: {
    restaurantGroup: "87Jx6oV1we",
    marketplace: "",
  },
  DaysBetweenOrdersDashboard: {
    restaurantGroup: "n08YWN3AW4",
    marketplace: "n08YWN3AW4",
  },
  ReconciliationDashboard: {
    restaurantGroup: "Dp9173b1a3",
    marketplace: "QjDxea7xLG",
  },
};
export {
  TYPE,
  ORDER_TYPE,
  DATE_RANGE,
  GIFTCARD_TYPE,
  PLATFORM_TYPE,
  ENV,
  STATUS,
  AVAILABILITY,
  ENTITY_TYPE,
  PAYMENT_STATUS,
  LOYALTY,
  ERROR,
  TEMPLATE,
  EXPLOID,
  CLEANJUICE,
  MENUSYNCSTATUS,
};

export default {
  TYPE,
  ORDER_TYPE,
  DATE_RANGE,
  GIFTCARD_TYPE,
  PLATFORM_TYPE,
  ENV,
  STATUS,
  AVAILABILITY,
  ENTITY_TYPE,
  PAYMENT_STATUS,
  LOYALTY,
  ERROR,
  TEMPLATE,
  EXPLOID,
  CLEANJUICE,
  MENUSYNCSTATUS,
};
