import * as React from "react";
import { Card } from "antd";
import { Divider } from "@lunchboxinc/utensils";
import classnames from "classnames";
import { Titles, AnalyticsPrice, InfoPopoverIcon } from "../../../elements";
import { StatCardIcon } from "../../Icons";
import styles from "./statCard.module.css";

const { BlueCardTitle, SectionTitle } = Titles;

const percentageUnavailableText =
  "No percentage change is available because there is no data available for this stat for either the currently selected period or the previous period being used for comparison.";

const Switch = (type, value) => {
  switch (type) {
    case "price":
      return <AnalyticsPrice align="center" value={value} />;
    case "value":
      return Number(value.toFixed(2)).toLocaleString(undefined);
    default:
      return value;
  }
};

/**
 * Card for displaying data in analytics
 * @memberof Components.Fragments
 * @prop {string} props.title - The main text displayed
 * @prop {string} props.description - Will display a info icon next to title with entered text
 * @prop {"price" | "value"} props.type - Decided whether a dollar sign is displayed or not
 * @prop {integer} props.value - The value to be displayed below title
 * @prop {string} props.icon - What custom icon should be displayed
 * @prop {string} props.standardIcon - What antd icon should be displayed
 * @prop {number} props.percentageChange - The number displayed as a change in value below values
 */

const StatCard = ({
  title,
  description,
  type,
  value,
  icon,
  standardIcon,
  percentageChange,
}) => {
  const percentageChangeUnavailable = typeof percentageChange !== "number";

  const percentageChangeText = percentageChange >= 0 ? "UP" : "DOWN";
  const percentageChangeClass = () => {
    if (percentageChangeUnavailable) return styles.notAvailable;
    if (percentageChange >= 0) return styles.positive;
    return styles.negative;
  };

  const percentageChangeValue = () => {
    if (percentageChange === undefined) {
      return null;
    }
    if (percentageChange > 1000 || percentageChange < -1000) {
      return "> 1,000% ";
    }
    return `${Math.abs(percentageChange)}% `;
  };

  return (
    <Card bordered={false} className={styles.statCard}>
      <div className={styles.statContainer}>
        <StatCardIcon
          color="var(--card-blue)"
          customType={icon}
          type={standardIcon}
        />

        <BlueCardTitle className={styles.statValue}>
          {Switch(type, value)}
        </BlueCardTitle>
        {!!percentageChangeValue() && (
          <SectionTitle
            className={classnames(
              percentageChangeClass(),
              styles.percentageChange,
            )}
          >
            {percentageChangeUnavailable ? (
              <>
                (N/A)
                <InfoPopoverIcon popoverContent={percentageUnavailableText} />
              </>
            ) : (
              `(${percentageChangeValue()}${percentageChangeText})`
            )}
          </SectionTitle>
        )}
        <Divider className={styles.statCardDivider} />
        <SectionTitle className={styles.statCardTitle}>
          {` ${title}`}
          {description && <InfoPopoverIcon popoverContent={description} />}
        </SectionTitle>
      </div>
    </Card>
  );
};
export default StatCard;
