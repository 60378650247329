import React from "react";
import {getDisplayName} from "common/utils";
import {useAPIContext} from "components/providers/Api";

const withAPIContext = (Component) => {
  const ComponentWithAPI = (props) => {
    const context = useAPIContext();
    return <Component {...props} API={context} />;
  };
  ComponentWithAPI.displayName = `withStyle(${getDisplayName(Component)})`;
  return ComponentWithAPI;
};

export {withAPIContext};
