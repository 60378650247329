import * as React from "react";
import { range } from "lodash";
import { Fields } from "components";
import { Field } from "formik";

const MAX_OPTIONS = 46;

const OrderInAdvanceOptions = range(-1, MAX_OPTIONS).map((i) => ({
  name: `${i}`,
  value: i,
}));

const OrderInAdvance = () => (
  <Field
    label="Order in Advance (days)"
    name="dateSpan"
    component={Fields.Select}
    options={OrderInAdvanceOptions}
  />
);

export default OrderInAdvance;
