export const STRIPE_FILES = "https://files.stripe.com/links";
export const STRIPE_UPLOAD_FILE =
  process.env.NODE_ENV === "production"
    ? "https://api.lunchbox.io/payments/v1/files"
    : "https://api.lunchbox.env/payments/v1/files";

export default {
  STRIPE_FILES,
  STRIPE_UPLOAD_FILE,
};
