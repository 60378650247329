import * as React from "react";
import classnames from "classnames";
import styles from "./number.module.css";

const alignClass = (align) => {
  switch (align) {
    case "right":
      return styles.right;
    case "left":
      return styles.left;
    case "center":
      return styles.center;
    default:
      return null;
  }
};
const decimalOptions = {minimumFractionDigits: 2, maximumFractionDigits: 2};

const Number = ({value, align, inline, className, decimals, ...props}) => {
  const classes = classnames(
    alignClass(align),
    inline && styles.inline,
    className,
  );

  return (
    <span {...props} className={classes}>
      {(+(value || 0).toFixed(2)).toLocaleString(
        undefined,
        decimals && decimalOptions,
      )}
    </span>
  );
};

Number.defaultProps = {
  value: 0,
  align: "right",
};

export default Number;
