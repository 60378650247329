import * as React from "react";
import styled from "styled-components";
import { theme } from "common/constants/theme";

const BackToTopButton = styled.button`
  position: fixed;
  margin-right: 1rem;
  bottom: 15px;
  right: 155px;
  font-size: 30px;
  height: 45px;
  width: 45px;
  background-color: ${theme.colors.gray[10]};
  color: ${theme.colors.neutral[400]};
  cursor: pointer;
  border-radius: 50%;
  border: none;
  z-index: 100;
  box-shadow: 0 5px 10px ${theme.colors.neutral[400]};
  &:hover {
    background-color: ${theme.colors.yellow[500]};
    color: ${theme.colors.bobblack};
  }
`;

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

function BackToTop() {
  const [showButton, setShowButton] = React.useState(false);
  const handleScroll = () => {
    if (window.pageYOffset > 300) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    showButton && (
      <BackToTopButton aria-label="Back to top" onClick={scrollToTop}>
        &#8679;
      </BackToTopButton>
    )
  );
}

export { BackToTop };
