import React from "react";
import {Icon} from "antd";
import {CustomIcon} from "components/elements";
import css from "./statCardIcon.module.css";

/**
 * Icons to display in Components.Elements.StatCard
 * @memberof Components.Fragments
 * @param {string} props.type - String that chooses which antd icon to display
 * @param {string} props.customType - String that chooses which custom icon to display
 * @param {string} props.color - String that chooses what color icon should be
 */

const StatCardIcon = ({type, customType, color}) => {
  const IconComponent = type ? Icon : CustomIcon;

  return (
    <div
      className={css.icon}
      style={{backgroundColor: color, borderColor: color}}
    >
      <IconComponent type={type || customType} className={css.graphic} />
    </div>
  );
};

StatCardIcon.defaultProps = {
  color: "white",
};

export default StatCardIcon;
