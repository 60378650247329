// eslint-disable-next-line
import React, { useMemo, useEffect } from "react";
import ReactDOM from "react-dom";
// eslint-disable-next-line
import PropTypes from "prop-types";

const DEFAULT_NODE = document.getElementById("portals");

// Portal -- Using React Class Component
const Portal = ({ container, children, style, className }) => {
  const el = useMemo(() => {
    const element = document.createElement("div");
    element.className = className;
    Object.entries(style).forEach(([key, value]) => {
      element.style[key] = value;
    });

    return element;
  }, []);

  useEffect(() => {
    container.appendChild(el);
    return () => {
      container.removeChild(el);
    };
  }, []);

  useEffect(() => {
    Object.entries(style).forEach(([key, value]) => {
      el.style[key] = value;
    });
  }, [el, style]);

  useEffect(() => {
    el.className = className || "";
  }, [className]);
  return ReactDOM.createPortal(children, el);
};

Portal.defaultProps = {
  container: DEFAULT_NODE,
};

export default Portal;
