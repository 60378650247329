import * as Yup from "yup";
import { PASSWORD_VALIDATION, PHONE_NUMBER } from "common/constants/regEx";

export const phoneNumber = Yup.string().matches(
  PHONE_NUMBER,
  "Phone number is not valid",
);

export const password = Yup.object().shape({
  password: Yup.string()
    .matches(
      PASSWORD_VALIDATION,
      "Must contain 8+ characters, be alphanumeric, include a special character",
    )
    .notRequired(),
  passwordConfirm: Yup.string().when("password", {
    is: (pw) => pw && pw.length > 0,
    then: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords do not match")
      .required("Please re-enter password"),
    otherwise: Yup.string().notRequired(),
  }),
});

export const resetPassword = Yup.object().shape({
  password: Yup.string()
    .required("Please enter password")
    .matches(PASSWORD_VALIDATION, "Password requirements not met"),
  passwordConfirm: Yup.string().when("password", {
    is: (pw) => pw && pw.length > 0,
    then: Yup.string()
      .required("Please re-enter password")
      .oneOf([Yup.ref("password"), null], "Passwords do not match"),
    otherwise: Yup.string().notRequired(),
  }),
});

export default { phoneNumber, password, resetPassword };
