import * as React from "react";
import { ToggleSidebar } from "./ToggleSidebar";
import { Logo } from "./Logo";
import { LocationDropdown } from "./LocationDropdown";
import { MenuButtons } from "./MenuButtons";
import { ProfileSelect } from "./ProfileSelect";
import { RestaurantDropdown } from "./RestaurantDropdown";
import { SettingsButton } from "./SettingsButton";
import { S } from "./styled";
import { ANALYZE_NAV, SETTINGS_NAV } from "../menuConfig";

type TopbarProps = {
  isMobile: boolean;
  minNav: boolean;
  toggleSidebarRef?: React.Ref<HTMLButtonElement>;
  navType: string;
  onLogout: () => void;
  setMinNav: (value: boolean) => void;
  setNavType: (menuType: string) => void;
};

const Topbar = (props: TopbarProps) => {
  const {
    isMobile,
    minNav,
    toggleSidebarRef,
    navType,
    onLogout,
    setMinNav,
    setNavType,
  } = props;
  const showLocation = isMobile || navType !== ANALYZE_NAV;

  const onMenuButtonClick = (menuType: string) => {
    setNavType(menuType);
  };
  const onSettingsClick = () => {
    setNavType(SETTINGS_NAV);
    isMobile ? setMinNav(true) : setMinNav(false);
  };

  return (
    <S.TopbarWrapper aria-label="main">
      <ToggleSidebar
        isDisabled={navType === SETTINGS_NAV && !isMobile}
        minNav={minNav}
        toggleSidebarRef={toggleSidebarRef}
        handleClick={() => setMinNav(!minNav)}
      />

      <Logo isMobile={isMobile} />

      {!isMobile && (
        <MenuButtons navType={navType} handleClick={onMenuButtonClick} />
      )}

      <S.RightAlignedButtons>
        {showLocation && <LocationDropdown />}

        {!isMobile && <RestaurantDropdown />}

        {!isMobile && (
          <SettingsButton
            isActive={navType === SETTINGS_NAV}
            handleClick={onSettingsClick}
          />
        )}

        <ProfileSelect
          isMobile={isMobile}
          onLogout={onLogout}
          handleSettingsClick={onSettingsClick}
        />
      </S.RightAlignedButtons>
    </S.TopbarWrapper>
  );
};

export { Topbar };
