import React from "react";
import {ResponsiveBar} from "@nivo/bar";

const currency = (value) =>
  `$${value.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
const currency1 = (value) => `$${value.toLocaleString()}`;

const unitSymbol = (unit) => {
  switch (unit) {
    case "currency":
      return currency;
    default:
      return (v) => v.toLocaleString();
  }
};
const xAxisUnitSymbol = (unit) => {
  switch (unit) {
    case "currency":
      return currency1;
    default:
      return (v) => v.toLocaleString();
  }
};

const BarGraph = ({data, indexBy, keys, legendTranslateX = "-116", unit}) => {
  const totalData = data.length;
  const lowerBoundary = totalData * 0.1 - 1;
  const upperBoundary = totalData * 0.9 - 1;

  return (
    <div style={{height: "350px"}}>
      <ResponsiveBar
        data={data}
        keys={keys}
        curve="linear"
        groupMode="grouped"
        indexBy={indexBy}
        margin={{
          top: 30,
          right: 0,
          bottom: 75,
          left: 60,
        }}
        tooltipFormat={unitSymbol(unit)}
        padding={0.3}
        colors={({id, data, index}) => {
          if (index <= lowerBoundary || index === 0) {
            return totalData > 1 ? "#43B9F7" : "var(--button-blue)";
          }
          if (index >= upperBoundary) {
            return "#390099";
          }
          return "var(--button-blue)";
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 0,
          tickPadding: 15,
          tickRotation: 45,
        }}
        axisLeft={{
          tickValues: 6,
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          format: xAxisUnitSymbol(unit),
        }}
        enableLabel={false}
        animate
        motionStiffness={90}
        motionDamping={15}
      />
    </div>
  );
};

BarGraph.defaultProps = {
  data: [],
};
export default BarGraph;
