const NavKeys = {
  menus: "menu",
  groups: "group",
  orders: "order",
  items: "item",
  options: "option",
  upsells: "upsell",
  "dining-hours": "dining-hour",
  "holiday-hours": "holiday-hour",
  discounts: "discount",
  promotion: "discount",
  locations: "location",
  caterings: "catering",
  patrons: "patron",
  admins: "admin",
  payments: "payment",
  roles: "role",
  pincodes: "pincode",
  "catering-groups": "catering-group",
  "gift-cards": "gift-card",
};

export default NavKeys;
