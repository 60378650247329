import React from "react";
import {Icon} from "antd";
import {CustomIcon} from "components/elements";
import classnames from "classnames";
import styles from "./orderIcon.module.css";

const OrderIcon = ({type, customType, color, className}) => {
  const IconComponent = type ? Icon : CustomIcon;

  return (
    <span
      className={classnames(styles.icon, className)}
      style={{backgroundColor: color, borderColor: color}}
    >
      <IconComponent type={type || customType} />
    </span>
  );
};

OrderIcon.defaultProps = {
  color: "white",
};

export default OrderIcon;
