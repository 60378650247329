import { useEffect } from "react";
import { message } from "antd";
import { mapAxiosError } from "common/utils";
import { useAPIContext } from "components/providers/Api";

const ToastIntegration = ({
  children,
  inputOptions,
  setInputOptions,
  locationId,
  setFetching,
}) => {
  const { Locations } = useAPIContext();

  useEffect(() => {
    const fetchInputOptions = async () => {
      try {
        let deliveryFeeOptions = [];
        let diningOptions = [];
        let serviceCharges = [];
        let paymentOptions = [];
        let revenueCenter = [];

        if (locationId) {
          const [
            deliveryFeeOptionsRes,
            diningOptionsRes,
            serviceChargesRes,
            paymentOptionsRes,
            revenueCenterRes,
          ] = await Promise.all([
            Locations.getDeliveryFeeOptions(locationId),
            Locations.getDiningOptions(locationId),
            Locations.getServiceCharges(locationId),
            Locations.getPaymentOptions(locationId),
            Locations.getRevenueCenter(locationId),
          ]);
          deliveryFeeOptions = deliveryFeeOptionsRes.data;
          diningOptions = diningOptionsRes.data;
          serviceCharges = serviceChargesRes.data;
          paymentOptions = paymentOptionsRes.data;
          revenueCenter = revenueCenterRes.data;
        } else {
          const paymentOptionsRes = await Locations.getPaymentOptions();
          paymentOptions = paymentOptionsRes.data || [];
        }

        setInputOptions({
          ...inputOptions,
          deliveryFeeOptions,
          diningOptions,
          paymentOptions,
          serviceCharges,
          sections: [],
          revenueCenter,
        });
      } catch (e) {
        message.error(mapAxiosError(e));
      } finally {
        setFetching(false);
      }
    };

    fetchInputOptions();
  }, [setInputOptions, locationId]);

  return children;
};

export default ToastIntegration;
