import React, {useEffect, useLayoutEffect, useState} from "react";
import {Drawer as AntDrawer} from "antd";

/**
 * Wrapper for the antd drawer component
 * @memberof Components.Fragments
 * @prop {JSX.Element | JSX.Element[]} props.children - Children components
 * @prop {"top" | "right" | "bottom" | "left"} props.placement - The side of the screen the drawer will be displayed
 * @prop {boolean} props.isOpen - The conditional when drawer should be open (generally used with state)
 * @prop {JSX.Element | JSX.Element[]} props.trigger - Will display an element that will open drawer onClick
 * @prop {object} props.drawerProps - An object that contains default antd drawer prop values
 * @prop {string} props.title - Text that will be displayed at the top of the drawer
 * @prop {function} props.onClose - Function that will trigger when drawer is closed
 * @returns {React.Element} - Returns a drawer component that by default will be hidden unless the isOpen prop is passed or a trigger element is passed
 */

const Drawer = ({
  children,
  placement,
  isOpen,
  trigger,
  drawerProps,
  title,
  onClose,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(isOpen || false);
  const toggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const close = () => {
    if (onClose) onClose();
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    setIsDrawerOpen(isOpen);
  }, [isOpen]);

  useLayoutEffect(() => {
    if (!isDrawerOpen || !isOpen) {
      document.body.style.overflow = "initial";
      document.body.style.touchAction = "";
    } else {
      document.body.style.overflow = "hidden";
      document.body.style.touchAction = "none";
    }
  }, [isDrawerOpen, isOpen]);

  let triggerElement = null;
  if (trigger) {
    if (typeof trigger === "function") {
      triggerElement = trigger({onClick: toggle});
    } else {
      triggerElement = React.cloneElement(trigger, {onClick: toggle});
    }
  }

  return (
    <>
      {triggerElement}
      <AntDrawer
        placement={placement}
        visible={Boolean(isDrawerOpen)}
        onClose={close}
        title={title}
        {...drawerProps}
      >
        {drawerProps.destroyOnClose && !isDrawerOpen
          ? null
          : typeof children === "function"
          ? React.Children.only(children({close}))
          : React.Children.only(React.cloneElement(children, {close}))}
      </AntDrawer>
    </>
  );
};

export default Drawer;
