import moment from "moment";

const RANGES_1 = {
  "Last Hour": [moment().startOf("hour"), moment().endOf("hour")],
  Today: [moment().startOf("day"), moment().endOf("day")],
  "Last 12 Hours": [moment().subtract("12", "hour"), moment()],
  "This Week": [moment().startOf("week"), moment().endOf("week")],
  "This Month": [moment().startOf("month"), moment().endOf("month")],
};

const RANGES_2 = {
  "Last Week": [
    moment().startOf("w").subtract(1, "w"),
    moment().endOf("w").subtract(1, "w"),
  ],
  "2 Weeks Ago": [
    moment().startOf("w").subtract(2, "w"),
    moment().endOf("w").subtract(2, "w"),
  ],
  "This Month": [moment().startOf("M"), moment().endOf("M")],
  "Last Month": [
    moment().startOf("M").subtract(1, "M"),
    moment().endOf("M").subtract(1, "M"),
  ],
  "Last 3 Month": [moment().subtract(3, "M").startOf("M"), moment().endOf("M")],
};

export default {
  RANGES_1,
  RANGES_2,
};
