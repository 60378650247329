import * as Yup from "yup";

const schema = Yup.object().shape({
  hours: Yup.array().of(
    Yup.object().shape(
      {
        start: Yup.string()
          .nullable()
          .when("end", {
            is: (end) => end,
            then: Yup.string().typeError("Required").required("Required"),
          }),
        end: Yup.string()
          .nullable()
          .when("start", {
            is: (start) => start,
            then: Yup.string().typeError("Required").required("Required"),
          }),
      },
      ["start", "end"],
    ),
  ),
  name: Yup.string("Please specify a title").required("Please specify a title"),
});

export default schema;
