import * as Yup from "yup";

const marketplaceSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  appId: Yup.string().lowercase().required("Client ID field is required"),
  sourceRestaurantGroup: Yup.string().required(
    "Source Restaurant Group is required",
  ),
  restaurantGroups: Yup.array()
    .of(Yup.string())
    .min(1, "At least one restaurant group must be assigned to a marketplace")
    .required("List of restaurant groups is required"),
});

export default {
  NewMarketplaceSchema: marketplaceSchema,
};
