import React from "react";
import {Field} from "formik";
import {Col} from "antd";
import {Row, Titles} from "components/elements";
import {Condition} from "components/fragments";
import styles from "./settingsFieldsGroup.module.css";
import SettingsField from "./SettingsField";

const {CardTitle} = Titles;

const SettingsFieldsGroup = ({sectionTitle, fieldProps}) => (
  <>
    <Condition.Condition is={!!sectionTitle}>
      <Row>
        <CardTitle>{sectionTitle}</CardTitle>
      </Row>
    </Condition.Condition>
    <Row gutter={15} spacing={30} className={styles.fieldWrapper}>
      {fieldProps?.map((fieldProp) => (
        <Col sm={24} md={12} lg={6}>
          <SettingsField>
            <Field label={fieldProp.label} {...fieldProp} />
          </SettingsField>
        </Col>
      ))}
    </Row>
  </>
);

export default SettingsFieldsGroup;
