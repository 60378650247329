import React from "react";
import {ReactComponent as MobileOrdersIcon} from "assets/images/mobile_orders_icon.svg";
import {ReactComponent as MobileTotalIcon} from "assets/images/mobile_total_icon.svg";
import {ReactComponent as DesktopOrdersIcon} from "assets/images/desktop_orders_icon.svg";
import {ReactComponent as DesktopTotalIcon} from "assets/images/desktop_total_icon.svg";
import {ReactComponent as AdminsIcon} from "assets/images/admins_icon.svg";
import {ReactComponent as CateringIcon} from "assets/images/catering_icon.svg";
import {ReactComponent as OrdersIcon} from "assets/images/orders_icon.svg";

const IconPicker = (icon) => {
  let Icon;
  switch (icon) {
    case "catering":
      Icon = CateringIcon;
      break;
    case "orders":
      Icon = OrdersIcon;
      break;
    case "admins":
      Icon = AdminsIcon;
      break;
    case "mobile-orders-icon":
      Icon = MobileOrdersIcon;
      break;
    case "mobile-total-icon":
      Icon = MobileTotalIcon;
      break;
    case "desktop-orders-icon":
      Icon = DesktopOrdersIcon;
      break;
    case "desktop-total-icon":
      Icon = DesktopTotalIcon;
      break;
    default:
      Icon = undefined;
      break;
  }
  return Icon;
};

const CustomIcon = ({type, text, className}) => {
  const Icon = IconPicker(type);

  return <Icon className={className} text={text} />;
};

export default CustomIcon;
