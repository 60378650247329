import React from "react";
import propTypes from "prop-types";
import {Col} from "antd";
import {Row} from "components/elements";
import {Icons} from "components/fragments";
import {convertArray2ObjectOfArrays, convertArray2Object} from "common/utils";
import PaymentSummaryRow from "./PaymentSummaryRow";
import css from "./paymentSummary.module.css";

const BasketItemRow = (props) => (
  <PaymentSummaryRow className={css.paymentRow} {...props} />
);

/**
 * Renders a basket breakdown section with subtotals combined for each RG brand and listed for Marketplace order
 * @author Htin Linn Aung
 * @memberof Templates.Templates/PaymentSummary
 * @param {Array} items -
 * @param {Array} associatedRestaurants -
 * @returns {React.Element} - A basket breakdown section with subtotals combined for each RG brand and listed for Marketplace order
 */
const BasketBreakdown = ({items, associatedRestaurants}) => {
  if (!associatedRestaurants.length || !items.length) {
    return null;
  }

  const brandNamesMap = convertArray2Object(associatedRestaurants, "id");
  const itemsWithAssociatedRGs = items.map((item) => {
    const newItem = {...item};
    newItem.brandName = brandNamesMap[item.restaurantGroup].name;
    return newItem;
  });
  const brandItemsMap = convertArray2ObjectOfArrays(
    itemsWithAssociatedRGs,
    "brandName",
  );

  const basketBreakdownRow = Object.keys(brandItemsMap)
    .sort()
    .map((key, index) => {
      const brandItems = brandItemsMap[key];
      const {brandName} = brandItems[0];
      const subtotalsCombined = brandItems
        .map((i) => i.price)
        .reduce((total, price) => total + price, 0);
      return (
        <BasketItemRow
          key={index}
          label={brandName}
          value={subtotalsCombined}
        />
      );
    });

  return (
    <Row spacing={30} gutter={15}>
      <Col xs={24} sm={24}>
        <Row spacing={15} className={css.paymentHeader}>
          <Col xs={18} className={css.paymentHeaderText}>
            <h3>
              <Icons.IconText
                color="white"
                type="shopping"
                text="Basket Breakdown"
              />
            </h3>
          </Col>
        </Row>
        {brandItemsMap && basketBreakdownRow}
      </Col>
    </Row>
  );
};

const itemPropsType = {
  price: propTypes.number,
};
const associatedRestaurantsPropTypes = {
  id: propTypes.string,
  name: propTypes.string,
};

BasketBreakdown.propTypes = {
  items: propTypes.arrayOf(itemPropsType),
  associatedRestaurants: propTypes.arrayOf(associatedRestaurantsPropTypes),
};
BasketBreakdown.defaultProps = {
  items: [],
  associatedRestaurants: [],
};

export default BasketBreakdown;
