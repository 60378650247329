import React, {useState, useRef} from "react";
import {message} from "antd";
import debounce from "lodash/debounce";
import axios from "axios";
import {Fields} from "components";
import {utils} from "common";

const {mapAxiosError} = utils;
const {LiveSearch} = Fields;

const DEBOUNCE_RATE = 300;

const AddressLiveSearchField = (props) => {
  const [items, setItems] = useState([]);

  const debouncedFetch = useRef(
    debounce(async (searchValue) => {
      try {
        const {data} = await axios({
          method: "GET",
          url: `https://patron.${process.env.REACT_APP_DOMAIN}/v0/address`,
          params: {address: searchValue},
          headers: {
            client: "bareburger",
          },
        });
        setItems(data);
      } catch (error) {
        message.error(mapAxiosError(error));
      }
    }, DEBOUNCE_RATE),
  );

  return (
    <LiveSearch onSearch={debouncedFetch.current} {...props}>
      {items}
    </LiveSearch>
  );
};

export default AddressLiveSearchField;
