import React, {memo} from "react";
import PropTypes from "prop-types";
import {BasicTooltip} from "@nivo/tooltip";
import styles from "./analytics.module.css";

const CustomLineTooltip = ({point}) => {
  return (
    <BasicTooltip
      id={
        <span className={styles.tooltip}>
          {point.serieId}
          {`: `}
          {point.data.yFormatted}
          {`  `}
        </span>
      }
      enableChip
      color={point.serieColor}
    />
  );
};

CustomLineTooltip.propTypes = {
  point: PropTypes.object.isRequired,
};

export default memo(CustomLineTooltip);
