import React from "react";
import {Formik, Field} from "formik";
import {Form, message} from "antd";
import {Fields} from "components";
import {Button} from "components/elements";
import {useAPIContext} from "components/providers/Api";
import {mapAxiosError, uuid} from "common/utils";
import {entitiesSchemas} from "common/schemas";
import {LocationSelector} from "./LocationSelector";

const CreateOption = ({drawerProps, itemId, fetchResults}) => {
  const {MenuOptions} = useAPIContext();

  const save = async (formValues, actions) => {
    const body = {
      ...formValues,
      externalId: uuid(),
    };

    if (itemId) body.item = itemId;

    try {
      actions.setSubmitting(true);
      await MenuOptions.create(body);
      message.success("Successfully Saved");
      fetchResults();
    } catch (e) {
      message.error(mapAxiosError(e));
    } finally {
      actions.setSubmitting(false);
      drawerProps.close();
    }
  };

  return (
    <Formik
      initialValues={{
        isDeleted: false,
        isOrderableOnline: true,
        isActive: true,
        visibility: "ALL",
        min: 0,
        max: 0,
      }}
      validationSchema={entitiesSchemas.optionSchema}
      onSubmit={(formValues, actions) => save(formValues, actions)}
    >
      {({handleSubmit, isSubmitting, setFieldValue, values}) => (
        <Form onSubmit={handleSubmit}>
          <Field
            label="Name"
            name="name"
            placeholder="Name"
            component={Fields.Input}
          />
          <Field
            type="number"
            label="Min Number Required"
            name="min"
            component={Fields.Input}
          />
          <Field
            type="number"
            label="Max Number Required"
            name="max"
            component={Fields.Input}
          />
          <LocationSelector
            title="Excluded Locations"
            addLocations={(locations) =>
              setFieldValue("excludedLocations", locations)
            }
          />
          <Button
            htmlType="submit"
            size="small"
            color="blue"
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Create
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export {CreateOption};
