import * as Yup from "yup";

const colorHexCodeRegex = /^#([0-9a-f]{3}|[0-9a-f]{6})$/;

const styleSchema = Yup.array().of(
  Yup.object().shape({
    cssName: Yup.string().required("CSS property name is required"),
    value: Yup.string().required("CSS property value is required"),
  }),
);

const emailTemplateSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  subject: Yup.string().required("Subject is required"),
  copyright: Yup.string().required("Copyright is required"),
  images: Yup.array().of(
    Yup.object().shape({
      src: Yup.string().required("Image source is required"),
      href: Yup.string().ensure(),
      section: Yup.string().required("Section is required"),
      style: styleSchema,
    }),
  ),
  links: Yup.array().of(
    Yup.object().shape({
      href: Yup.string().required("Link URL is required"),
      section: Yup.string().required("Section is required"),
      value: Yup.string().required("Link text is required"),
      style: styleSchema,
    }),
  ),
  text: Yup.array().of(
    Yup.object().shape({
      value: Yup.string().required("Text value is required"),
      section: Yup.string().required("Section is required"),
      style: styleSchema,
    }),
  ),
  socialLinks: Yup.array().of(
    Yup.object().shape({
      href: Yup.string().required("Link URL is required"),
      type: Yup.string().required("Type is required"),
    }),
  ),
  footerBackgroundColor: Yup.string()
    .typeError(
      "Please specify a valid color hex code for footerBackgroundColor",
    )
    .matches(
      colorHexCodeRegex,
      "Invalid color hex code format. (e.g. #ffffff and #fff are in valid format)",
    ),
  footerTextColor: Yup.string()
    .typeError("Please specify a valid color hex code for footerTextColor")
    .matches(
      colorHexCodeRegex,
      "Invalid color hex code format. (e.g. #000000 and #000 are in valid format)",
    ),
});

export default emailTemplateSchema;
