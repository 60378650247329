import * as React from "react";
import { useLocalStorage } from "hooks";

type UIContextValues = {
  newUI: boolean;
  setNewUI: React.Dispatch<React.SetStateAction<boolean>>;
};

const defaultState = {
  newUI: false,
  setNewUI: () => {},
};

const UIContext = React.createContext<UIContextValues>(defaultState);

const Provider = ({ children }) => {
  const [newUI, setNewUI] = useLocalStorage("newUI", false);

  const values = {
    newUI,
    setNewUI,
  };

  return <UIContext.Provider value={values}>{children}</UIContext.Provider>;
};

export { Provider as UIProvider, UIContext };
