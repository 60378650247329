import React from "react";
import classnames from "classnames";
import {Switch} from "antd";
import styles from "./toggle.module.css";

const AvailabilityToggle = ({onChange, className, ...props}) => (
  <Switch
    className={classnames(styles.largeToggle, "largeToggle", className)}
    checkedChildren="Available"
    unCheckedChildren="Unavailable"
    onChange={onChange}
    {...props}
  />
);

export default AvailabilityToggle;
