import * as Yup from "yup";

const pushNotificationSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  text: Yup.string().required("Body text is required"),
  scheduleDate: Yup.date().min(
    new Date(),
    "Scheduled date cannot be in the past",
  ),
});

export default { PushNotificationSchema: pushNotificationSchema };
