import production from "./production";
import local from "./local";
import development from "./development";
import stage from "./stage";
import shared from "./shared";

let env = local;

switch (process.env.REACT_APP_ENV) {
  case "production":
    env = production;
    break;
  case "stage":
    env = stage;
    break;
  case "development":
    env = development;
    break;
  default:
    env = development;
    break;
}

export default {
  ...shared(),
  ...env(),
};
