import * as React from "react";
import PropTypes from "prop-types";
import {T} from "components/elements";
import {Checkbox} from "antd";

const CheckboxField = ({
  onChange,
  checked,
  label: boxLabel,
  disabled,
  ...props
}) => (
  <>
    <Checkbox
      disabled={disabled}
      checked={checked}
      onBlur={null}
      onChange={onChange}
    >
      <T inline label>
        {boxLabel}
      </T>
    </Checkbox>
  </>
);

Checkbox.propTypes = {
  label: PropTypes.string,
};

export default CheckboxField;
