import React from "react";
import {Elements, Fragments} from "components";
import {Col, Table} from "antd";

const {
  Tables: {Table: CustomTable},
} = Fragments;

const {
  Titles: {SectionTitle},
  Row,
  InfoPopoverIcon,
} = Elements;

const popoverContent =
  "Adjusting these options will enable/disable a guest from placing an asap order for that order type. This feature is not available for the brands' locations that are under marketplace";

const ASAPOrderingStatus = ({fetching, dataSource, title}) => {
  return (
    <>
      <Row spacing={20}>
        <Col xs={12}>
          <SectionTitle>
            {title}
            <InfoPopoverIcon popoverContent={popoverContent} />
          </SectionTitle>
        </Col>
      </Row>
      <CustomTable
        size="small"
        dataSource={dataSource}
        rowKey={(i) => i.name}
        pagination={false}
        loading={fetching}
      >
        <Table.Column dataIndex="name" width={300} />
        <Table.Column
          dataIndex="toggle"
          width={20}
          title="Status"
          render={(text, i) => (
            <span className="asapOrderingStatusSwitch">{i.toggle}</span>
          )}
        />
      </CustomTable>
    </>
  );
};

export default ASAPOrderingStatus;
