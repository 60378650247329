import Qs from "qs";
import axios from "axios";

let url = "";
if (process.env.NODE_ENV === "production") {
  url = `${process.env.REACT_APP_API_URL}`;
}

const storageKey = process.env.REACT_APP_LOCAL_STORAGE_KEY;

export const createInstance = ({ client, platform }) => {
  const instance = axios.create({
    baseURL: url,
    headers: {
      client,
      platform,
    },
  });
  instance.defaults.withCredentials = true;
  instance.defaults.paramsSerializer = (params) =>
    Qs.stringify(params, { arrayFormat: "brackets" });

  /**
   * on each request we need to send auth headers
   */
  instance.interceptors.request.use(
    (config) => {
      const { accessToken } =
        JSON.parse(localStorage.getItem(storageKey)) ?? {};
      Object.assign(config.headers.common, {
        authorization: `Bearer ${accessToken}`,
      });

      return config;
    },
    (error) => Promise.reject(error),
  );
  /**
   * on each response we need to grab the auth headers
   * and persist it to a local storage
   */
  instance.interceptors.response.use(
    (response) => {
      const accessToken = response.headers.authorization;
      if (accessToken) {
        localStorage.setItem(storageKey, JSON.stringify({ accessToken }));
      }
      return response;
    },
    (error) => Promise.reject(error),
  );
  return instance;
};
