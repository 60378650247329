import {useMemo} from "react";
import {useRefetchableResource} from "hooks";

const Sales = ({locationId, params, ...props}) => {
  const {resource, fetching} = useRefetchableResource({
    path: "/analytics/sales",
    data: {filters: params},
    method: "GET",
  });

  const {totals, averages} = useMemo(() => {
    const computedTotals = resource.reduce(
      (accu, i) => {
        accu.appOrders =
          accu.appOrders + i.totalAppleOrders + i.totalAndroidOrders;
        accu.webOrders += i.totalWebOrders;
        accu.appRevenue =
          accu.appRevenue + i.totalAndroidRevenue + i.totalAppleRevenue;
        accu.webRevenue += i.totalWebRevenue;
        accu.totalOrders += i.totalOrders;
        accu.totalRevenue += i.totalRevenue;
        accu.totalPickupOrders += i.totalPickupOrders;
        accu.totalDeliveryOrders += i.totalDeliveryOrders;
        accu.averageCoverTicketPrice += i.averageCoverTicketPrice;
        return accu;
      },
      {
        appOrders: 0,
        webOrders: 0,
        appRevenue: 0,
        webRevenue: 0,
        totalOrders: 0,
        totalRevenue: 0,
        totalPickupOrders: 0,
        totalDeliveryOrders: 0,
        averageCoverTicketPrice: 0,
      },
    );

    const averagedValues = {
      averageCoverTicketPrice:
        computedTotals.averageCoverTicketPrice / resource.length,
    };

    return {
      totals: computedTotals,
      averages: averagedValues,
    };
  }, [fetching]);

  const renderValues = {
    resource,
    totals,
    averages,
    fetching,
  };

  if (props.render) {
    return props.render(renderValues);
  }
  if (typeof props.children === "function") {
    return props.children(renderValues);
  }

  return props.children;
};

Sales.defaultProps = {
  location: "",
  createdAt: {
    start: "",
    end: "",
  },
};

export default Sales;
