import * as Yup from "yup";
import { password } from "./shared";
import { PASSWORD_VALIDATION, PHONE_NUMBER } from "common/constants/regEx";

const adminSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
});

const editAdminSchema = adminSchema.shape({
  isSuperAdmin: Yup.boolean().required("Super admin selection is required"),
  isOwner: Yup.boolean().required("Location access selection is required"),
  permissionGroups: Yup.string().when("isSuperAdmin", {
    is: false,
    then: Yup.string().required("Role is required"),
    otherwise: Yup.string(),
  }),
  locations: Yup.array().when(["isOwner", "isSuperAdmin"], {
    is: (isOwner, isSuperAdmin) => !isOwner && !isSuperAdmin,
    then: Yup.array().min(1, "At least 1 location is required").required(),
    otherwise: Yup.array().min(0),
  }),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.string()
    .matches(PHONE_NUMBER, "Phone number is not valid")
    .required("Phone number is required"),
});

const newAdminSchema = editAdminSchema.shape({
  password: Yup.string()
    .required("Password is required")
    .matches(
      PASSWORD_VALIDATION,
      "Must contain 8+ characters, be alphanumeric, include a special character",
    ),
  passwordConfirm: Yup.string().when("password", {
    is: (value) => value.length !== 0,
    then: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords do not match")
      .required("Please re-enter password"),
    otherwise: Yup.string().notRequired(),
  }),
});

const newAdminNotificationSchema = Yup.object({
  adminId: Yup.string(),
  phone: Yup.string(),
  email: Yup.string(),
});

const adminProfileSchema = adminSchema.concat(password);

export default {
  NewAdminSchema: newAdminSchema,
  AdminProfile: adminProfileSchema,
  EditAdminSchema: editAdminSchema,
  NewAdminNotificationSchema: newAdminNotificationSchema,
};
