import React from "react";
import classnames from "classnames";
import styles from "./tag.module.css";

/**
 * Displays children inside a tag wrapper. Inconsistent use between this component and antd tag component
 * @memberof Components.Elements
 * @prop {JSX.Element | JSX.Element[]} props.children - Children components
 * @prop {string} props.color - Will apply an inline style color value
 * @prop {string} props.backgroundColor - Will apply an inline style background-color value
 * @prop {string} props.className - Will apply a css class
 * @prop {Object} props.style - Will apply inline style object
 * @returns {React.Element} - Returns text displayed inside a tag wrapper
 */

const Tag = ({color, backgroundColor, children, className, style}) => {
  return (
    <div
      className={classnames(styles.tag, className)}
      style={{
        backgroundColor,
        color,
        borderColor: color,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default Tag;
