import React from "react";
import {Titles} from "components/elements";
import {BlueCard} from "../Cards";
import styles from "./stepSection.module.css";

// const { BlueCard } = Cards;
const {CardTitle} = Titles;

const StepSection = ({step, title, details, children}) => (
  <>
    <BlueCard className={styles.stepSectionHeader}>
      <CardTitle className={styles.stepSectionTitle}>
        {`Step ${step}:`}
      </CardTitle>
      <CardTitle className={styles.stepSectionTitle}>{`${title}`}</CardTitle>
      <CardTitle className={styles.stepSectionDetails}>
        {typeof details === "string" ? `${details}` : details}
      </CardTitle>
    </BlueCard>
    <div className={styles.stepSectionContent}>{children}</div>
  </>
);

export default StepSection;
