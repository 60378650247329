import React from "react";
import {CustomIcon} from "components/elements";
import classnames from "classnames";
import styles from "./customNavIcon.module.css";

const CustomNavIcon = ({type, text}) => (
  <>
    <CustomIcon
      type={type}
      className={classnames(styles.customNavIcon, "customNavIcon")}
    />
    <span className={text && styles.text}>{text}</span>
  </>
);

export default CustomNavIcon;
