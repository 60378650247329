import * as Yup from "yup";

const schema = Yup.object({
  isAsapOrderingEnabled: Yup.object().shape({
    dineIn: Yup.boolean(),
    pickup: Yup.boolean(),
    delivery: Yup.boolean(),
    kiosk: Yup.boolean(),
  }),
  tipToPOS: Yup.object().shape({
    cateringDelivery: Yup.boolean(),
    cateringPickup: Yup.boolean(),
    delivery: Yup.boolean(),
    dinein: Yup.boolean(),
    pickup: Yup.boolean(),
  }),
  serviceEstimates: Yup.object()
    .shape({
      prep: Yup.number()
        .typeError("Prep Estimate must be a number")
        .min(0, "Prep Estimate must be 0 or greater")
        .nullable(),
      delivery: Yup.number()
        .typeError("Delivery Estimate must be a number")
        .min(0, "Delivery Estimate must be 0 or greater")
        .nullable(),
      threshold: Yup.number()
        .typeError("Estimate Threshold must be a number")
        .min(1, "Estimate Threshold must be 1 or greater")
        .nullable(),
      increment: Yup.number()
        .typeError("Estimate Increment must be a number")
        .min(0, "Estimate Increment must be 0 or greater")
        .nullable(),
    })
    .required(),
  orderMinimum: Yup.object()
    .shape({
      standardDelivery: Yup.number()
        .typeError("Standard Delivery Minimum must be a number")
        .min(0, "Standard Delivery Minimum must be 0 or greater")
        .required(),
      standardPickup: Yup.number()
        .typeError("Standard Pickup Minimum must be a number")
        .min(0, "Standard Pickup Minimum must be 0 or greater")
        .required(),
      cateringDelivery: Yup.number()
        .typeError("Catering Delivery Minimum must be a number")
        .min(0, "Catering Delivery Minimum must be 0 or greater")
        .required(),
      cateringPickup: Yup.number()
        .typeError("Catering Pickup Minimum must be a number")
        .min(0, "Catering Pickup Minimum must be 0 or greater")
        .required(),
    })
    .required(),
  orderThrottling: Yup.object({
    standard: Yup.object({
      deliver: Yup.object({
        count: Yup.number()
          .transform((value) => (isNaN(value) ? null : value))
          .nullable(),
      }),
      pickup: Yup.object({
        count: Yup.number()
          .transform((value) => (isNaN(value) ? null : value))
          .nullable(),
      }),
    }),
    catering: Yup.object({
      deliver: Yup.object({
        count: Yup.number()
          .transform((value) => (isNaN(value) ? null : value))
          .nullable(),
      }),
      pickup: Yup.object({
        count: Yup.number()
          .transform((value) => (isNaN(value) ? null : value))
          .nullable(),
      }),
    }),
  }),
  media: Yup.object({
    restaurantBanner: Yup.string().url().trim().nullable(),
    restaurantIcon: Yup.string().url().trim().nullable(),
    restaurantLogo: Yup.string().url().trim().nullable(),
    restaurantThumbnail: Yup.string().url().trim().nullable(),
    restaurantSelectedIcon: Yup.string().url().trim().nullable(),
    restaurantUnselectedIcon: Yup.string().url().trim().nullable(),
  }),
  isGooglePayAccepted: Yup.boolean(),
  isApplePayAccepted: Yup.boolean(),
  featureFlags: Yup.object({
    isRefundSyncEnabled: Yup.boolean(),
    isMenuSyncV2Enabled: Yup.boolean(),
  }),
  configurations: Yup.object({
    discountCreate: Yup.boolean().nullable(),
    discountEdit: Yup.boolean().nullable(),
    menuEntityCreate: Yup.boolean().nullable(),
    menuEntityEdit: Yup.boolean().nullable(),
  }),
});

export default schema;
