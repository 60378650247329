import * as React from "react";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { usePagesVisited } from "components/providers/PagesVisited";
import { BreadcrumbNav } from "components/common/BreadcrumbNav";
import styled from "styled-components";

const BreadcrumbContainer = styled.div`
  padding: 1.25rem 1.5rem 1.25rem 1.5rem;
`;

const maxBreadCrumbTrailSize = 6;

interface location {
  id: string;
  isActive: boolean;
  name: string;
  phone: {
    isVerified: boolean;
    value: string;
  };
}
interface WithProps {
  locations: location[];
  locationId: string;
}

const withBreadcrumbs =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props: WithProps) => {
    const [name, setName] = React.useState();
    const { pages, pushPage } = usePagesVisited();
    const [breadcrumbItems, setBreadcrumbItems] = React.useState([]);
    const { pathname } = useLocation();

    const breadCrumbAmount =
      pages.length < maxBreadCrumbTrailSize
        ? pages.length
        : maxBreadCrumbTrailSize;

    const breadCrumbStartingPoint =
      pages.length < maxBreadCrumbTrailSize
        ? 0
        : pages.length - maxBreadCrumbTrailSize;

    React.useEffect(() => {
      if (pages[pages.length - 1]?.route !== pathname && name) {
        pushPage({ name, route: pathname });
      }
    }, [name]);

    React.useEffect(() => {
      const breadcrumbItemsTemp = [];
      for (let i = 0; i < breadCrumbAmount; i++) {
        const index = i + breadCrumbStartingPoint;
        breadcrumbItemsTemp.push({
          name: pages[index].name,
          route: pages[index].route,
        });
      }

      setBreadcrumbItems(breadcrumbItemsTemp);
    }, [pages]);

    return (
      <>
        <BreadcrumbContainer>
          <BreadcrumbNav pages={breadcrumbItems} />
        </BreadcrumbContainer>
        <Component setName={setName} {...(props as P)} />
      </>
    );
  };

export { withBreadcrumbs };
