import withCustomClass from "../../hocs/withCustomClass";
import css from "./title.module.css";

/**
 * Styled div for styling child text as Title text
 * @memberof Components.Elements
 * @returns {React.Element} - Returns a styled div element
 */

const Title = withCustomClass("div", css.title);
const SectionTitle = withCustomClass(Title, css.sectionTitle);
const CardTitle = withCustomClass(Title, css.cardTitle);
const BlueCardTitle = withCustomClass(Title, css.blueCardTitle);

export {SectionTitle, CardTitle, BlueCardTitle};

export default {
  SectionTitle,
  CardTitle,
  BlueCardTitle,
};
