import * as React from "react";
import {Switch, Form} from "antd";
import {T} from "../elements";

const Toggle = ({
  field: {name, value},
  form: {setFieldValue},
  label,
  disabled,
  htmlFor,
}) => (
  <Form.Item htmlFor={htmlFor} label={<T label>{label}</T>}>
    <Switch
      id={htmlFor}
      checked={value}
      onChange={(checked) => setFieldValue(name, checked)}
      disabled={disabled}
    />
  </Form.Item>
);

export default Toggle;
