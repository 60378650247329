const appcuesPermissions = ({
  isRoute,
  isNav,
  hasPermission,
  permissionLabels,
}) => {
  const hasPageAccess = isRoute && hasPermission;

  let permissionsData;
  if (hasPageAccess !== undefined) {
    permissionsData = { hasPageAccess };
  }
  if (!isNav && !isRoute) {
    permissionsData = permissionLabels.reduce(
      (accu, curr) => ({
        ...accu,
        [curr]: true,
      }),
      permissionsData,
    );
  }

  return permissionsData;
};

export default appcuesPermissions;
